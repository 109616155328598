// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\"advanced-channel\">"
    + alias4(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "</span>&nbsp;/&nbsp;<span class=\"advanced-date\">"
    + alias4(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + " "
    + alias4((helpers.getMonth || (depth0 && depth0.getMonth) || alias2).call(alias1,(depth0 != null ? depth0.month : depth0),{"name":"getMonth","hash":{},"data":data}))
    + "</span>";
},"useData":true});
