/**
 * Created by sergey on 19.11.2015.
 */

module.exports = Backbone.Model.extend({
	url: GlobalConfig.urls.api_dashboard_get_params,
	initialize: function () {

	},
	codeFailMap: {
		"-1": "These passwords don't match. Try again.",
		"-2": "The password that you entered is incorrect. Make your password at 8 characters in length. Password MUST include at least 1 alphabetic character and 1 numeric character. Use a combination of English letters and numbers (0–9).",
		"-3": "The question or answer that you entered is incorrect. Try again.",
		"-4": "The question or answer that you entered is incorrect. Try again.",
		"-5": "Can not set password",
		"-6": "The question or answer that you entered is incorrect. You cannot generate a new password. Please contact partner care at <a class='b-error-link' href='mailto:support@quizgroup.com'>support@quizgroup.com.</a>",
		"-7": "The question or answer that you entered is incorrect. Try again.",
		"-11": "You cannot enable the \"Password access\" option within 12 hours after your password has been seted/changed",
		"-12": "allready activate",
		"-13": "No password"
	},
	parse: function(resp){
		var result = {};
		var body = resp.body;
		return body;
	},
	saveAboutContact: function(){
		var me = this;
		this.type = "about";
		var handlerSuccess = function(){
			me.trigger("about:data:success");
		};
		var handlerFail = function(xhr, stringResult, result){
			if(!_.isEmpty(xhr.responseJSON.body)){
				var code = xhr.responseJSON.body.code;
				me.trigger("about:data:fail", me, xhr.responseJSON.body.bad_field, code);
			}
		};
		var data = {
			date_of_born: me.get("date_of_born"),
			given_name: me.get("given_name"),
			family_name: me.get("family_name")
		};
		$.ajax({
			url: "/api/dashboard/update_params",
			method: "POST",
			data: data
		}).then(handlerSuccess, handlerFail)
	},
	saveContacts: function(){
		var me = this;
		this.validation = {
			email: {
				pattern: 'email'
			},
			email_for: {
				pattern: 'email'
			}
		};
		this.type = "contact";
		var handlerSuccess = function(){
			me.validation = {};
			me.trigger("contacts:data:success");
		};
		var handlerFail = function(xhr, stringResult, result){
			if(!_.isEmpty(xhr.responseJSON.body)){
				var code = xhr.responseJSON.body.code;
				me.trigger("contacts:data:fail", me, xhr.responseJSON.body.bad_field, code);
			}
		};
		var data = {
			country: me.get("country"),
			town: me.get("town"),
			address: me.get("address"),
			email: me.get("email"),
			email_for: me.get("email_for")
		};
		if(this.isValid(true)){
			$.ajax({
				url: "/api/dashboard/update_params",
				method: "POST",
				data: data
			}).then(handlerSuccess, handlerFail)
		}

	},
	savePassword: function(data){
		var me = this,
			code;
		this.validation = {
			answer: "validationAnswer"
		};
		this.type = "security";
		var handlerSuccess = function(){
			me.validation = {};
			me.trigger("profile:password:set:success");
		};
		var handlerFail = function(xhr, stringResult, result){
			var message = "";
			if(!_.isEmpty(xhr.responseJSON.body)){
				code = xhr.responseJSON.body.code;
				message = me.codeFailMap[String(code)];
				me.trigger("profile:password:set:fail", me, message, code);
			}
		};
		this.set(data);
		var sendData = {
			password: me.get("password"),
			repassword: me.get("repassword"),
			question: me.get("question"),
			answer: me.get("answer")
		};
		if(this.isValid(true)){
			$.ajax({
				url: "/api/account/set_profile_password",
				method: "POST",
				data: sendData
			}).then(handlerSuccess, handlerFail)
		}
	},
	uniteDashboard: function(data){
		var handlerSuccess = function(){
			me.trigger("unite:dashboard:success");
		};
		var me = this;
		$.ajax({
			url: "/api/requests/create_union",
			method: "POST"
		}).then(handlerSuccess)
	},
	setPasswordAccess: function(data){
		var me = this,
			code;
		this.validation = {
			question: "validationPassAccess"
		};
		this.type = "passwordaccess";
		var handlerSuccess = function(data){
			me.validation = {};
			me.set({activated_pass_access: true});
			me.trigger("profile:password:access:set:success",  data.body.codes);
		};
		var handlerFail = function(xhr, stringResult, result){
			var message = "";
			if(!_.isEmpty(xhr.responseJSON.body)){
				code = xhr.responseJSON.body.code;
				message = me.codeFailMap[String(code)];
				me.trigger("profile:password:access:set:fail", me, message);
			}
		};
		this.set(data);
		var sendData = {
			question: me.get("question"),
			answer: me.get("answer")
		};
		if(this.isValid(true)){
			$.ajax({
				url: "/api/account/set_password_access",
				method: "POST",
				data: sendData
			}).then(handlerSuccess, handlerFail)
		}
	},
	getType: function () {
		return 	this.type;
	},
	passwordAccessOff: function(){
		var me = this,
			code;
		var handlerSuccess = function(data){
			me.validation = {};
			me.set({activated_pass_access: false});
			me.trigger("profile:password:access:off:success");
		};
		var handlerFail = function(xhr, stringResult, result){
			var message = "";
			if(!_.isEmpty(xhr.responseJSON.body)){
				code = xhr.responseJSON.body.code;
				message = me.codeFailMap[String(code)];
				me.trigger("profile:password:access:set:fail", me, message);
			}
		};
		$.ajax({
			url: "/api/account/password_access_off",
			method: "POST",
			data: {}
		}).then(handlerSuccess, handlerFail)
	},
	validationAnswer: function (value, attr, computedState) {
			var totalNotBlank =
				(computedState["answer"] != "" ? 1 : 0);

			if (totalNotBlank == 0) {
				$('.b-input_question').addClass('b-input-error');
				$('.b-input_email').removeClass('b-input-error');
				return "The question or answer that you entered is incorrect. Try again.";

			}else if(totalNotBlank >= 1){
				$('.b-input_question').removeClass('b-input-error');
				this.validation = {
					password: "validationPass"
				};
			}

	},
	validationPassAccess: function (value, attr, computedState) {
			var totalNotBlank =
				(computedState["question"] != "" ? 1 : 0);

			if (totalNotBlank == 0) {
				$('.b-input_answer').addClass('b-input-error');
				return "The question or answer that you entered is incorrect. Try again.";

			}else if(totalNotBlank >= 1){
				this.validation = {
					answer: {
						required: true,
						msg: "The question or answer that you entered is incorrect. Try again."
					}	
				};
			}
	},
	validationPass: function (value, attr, computedState) {
			var totalNotBlank =
				(computedState["password"] != "" ? 1 : 0);

			if (totalNotBlank == 0) {
				$('.b-input_email').addClass('b-input-error');
				return "The password that you entered is incorrect. Make your password at 8 characters in length. Password MUST include at least 1 alphabetic character and 1 numeric character. Use a combination of English letters and numbers (0–9).";

			}else if(totalNotBlank >= 1){
				$('.b-input_email').removeClass('b-input-error');
			}
	}
});