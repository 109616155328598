var DashboardPassRecovery = require("./view/DashboardPassRecovery");
var DashboardPasswordRecoveryModel = require("models/DashboardPasswordRecovery/DashboardPasswordRecovery");
var AccountEntities = require("entities/Account");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
		this.redirectToLogin = function(){
			Radio.channel('login').trigger("user:answer:secret:question:success");
		};
		AccountEntities.initialize();
		this.codeMap ={
			"-7": "showGAthError",
			"-1": "showUserIsNotExistError",
			"-2": "showUserBlockError",
			"-3": "showPasswordError",
			"-100": "showUnknownError"
		};
	},
	show: function(){
		var fetchQuestion = Radio.channel("global").request("account:secret:questions:entities");
		var me = this;

		$.when(fetchQuestion).done(function(questions){
			var recoveryModel = new DashboardPasswordRecoveryModel();
			var	mainRegion =  Radio.channel("layout").request("get:main:region");
			me.dashboardRecovery = new DashboardPassRecovery({questions: questions.toJSON(), model: recoveryModel});
			me.listenTo(me.dashboardRecovery, "check:quest", function(data){
				recoveryModel.checkQuestion(data, {success: me.onSuccessHandler.bind(me), fail: me.onFailHandler.bind(me)});
			});
			me.listenTo(me.dashboardRecovery, "set:pass", function(data){
				recoveryModel.setPassword(data, {success: me.onSuccessSetHandler.bind(me), fail: me.onFailSetHandler.bind(me)});
			});
			me.listenTo(me.dashboardRecovery, "show", function(){
				Radio.channel("layout").trigger("show:wrapper_n");
			});
			mainRegion.show(me.dashboardRecovery);
		});
		Backbone.history.navigate("?page=dashboardpasswordrecovery");

	},
	onSuccessHandler: function(resp, stringResult, xhr){
		if(resp.body.status === 1){
			this.dashboardRecovery.loginErrorHide();
			this.dashboardRecovery.showPassForm();
		}
	},
	onFailHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardRecovery;
		if(!_.isEmpty(xhr.responseJSON.body)){
			code = xhr.responseJSON.body.code;
			typeof  view[me.codeMap[String(code)]] === "function" ? view[me.codeMap[String(code)]](): view.showUnknownError();
		}
		view.loginError();
	},
	onSuccessSetHandler: function(resp, stringResult, xhr){
		var code,
			me = this;
			view = this.dashboardRecovery;
		view.passErrorHide();
		this.redirectToLogin();
	},
	onFailSetHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardRecovery;
		// if(!_.isEmpty(xhr.responseJSON.body)){
		// 	code = xhr.responseJSON.body.code;
		// 	typeof  view[me.codeMap[String(code)]] === "function" ? view[me.codeMap[String(code)]](): view.showUnknownError();
		// }
		view.passError();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:dashboard:password:recovery", function(){
			me.show();
		});
	}
});
