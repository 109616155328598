var UniteDashboard = require("./view/UniteDashboard");
module.exports = Marionette.Object.extend({
	initialize: function(){

		this.setHandlers();
	},
	show: function(){
		var me = this;
		var uniteDashboard = new UniteDashboard();
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		me.listenTo(uniteDashboard, "check:link", function(data){
			$.ajax({
				type: "GET",
				url: "/api/requests/check_link?tt="+data.tt+"&cc="+data.cc+"",
				dataType: "json"
			});
		})
		mainRegion.show(uniteDashboard);
		Backbone.history.navigate("?page=unitedashboard");
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:unitedashboard", function() {
			me.show();
		});
	}
});