// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-sum-block__item js-channels-header-payout\">\n</div>\n<div class=\"b-sum-block__item js-channels-header-views\">\n</div>\n<div class=\"b-sum-block__item\">\n    <span class=\"b-sum-count b-sum-count_left\">"
    + alias4(((helper = (helper = helpers.blocked_channels || (depth0 != null ? depth0.blocked_channels : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"blocked_channels","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"b-sum-title b-title_left\">Terminated <br> channels</span>\n</div>\n<div class=\"b-sum-block__item\">\n    <span class=\"b-sum-count b-sum-count_left\">"
    + alias4(((helper = (helper = helpers.active_channels || (depth0 != null ? depth0.active_channels : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"active_channels","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"b-sum-title b-title_left\">Active <br> channels</span>\n</div>";
},"useData":true});
