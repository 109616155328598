/**
 * Created by sergey on 04.12.2015.
 */
module.exports = Backbone.Model.extend({
	defaults: {
		offset: 0,
		limit: 20
	},
	initialize: function(options){
		this.options = options;
	},
	url: function(){
		var lang = Radio.channel("login").request("user:lang");
		return  "/api/dashboard/get_referal_list?offset=" + this.get("offset") +"&limit="+ this.get("limit");
	},
	parse: function(resp){
		var result ={};
		result.sum = resp.body.sum;
		result.rows = resp.body.referal_channels;
		result.referal_channels = resp.body.referal_channels;
		result.total_count = resp.body.sum_rows;
		return result;
	},
	getSearchList: function(){
		var result = _.map(this.get("referal_channels"), function(r){return r.channel_name})
			.concat(_.map(this.get("referal_channels"), function(r){if(r.channel_short_name !=r.channel_name) return r.channel_short_name})).
			concat(_.map(this.get("referal_channels"), function(r){return r.channel_id})).filter(function(x) {
				return typeof x !== 'undefined';
			});
		return result;
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		} else{
			return  this.get("offset")+this.get("limit");
		}
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var rowsListLength = this.get("rows").length;
		if(rowsListLength !== totalCount){
			result = true;
		}
		return result;
	},
	getMoreChannels: function () {
		var me = this;
		this.set({"offset": me.get("offset")+20});
		$.ajax({
			method: "GET",
			url: "/api/dashboard/get_referal_list?offset=" + me.get("offset") +"&limit="+ me.get("limit")
		}).then(function (resp) {
			var newArr = resp.body.referal_channels;
			var oldArr = me.get("referal_channels");
			me.set({"rows": oldArr.concat(newArr)});
			me.trigger("refresh:filter:rows", newArr);
		})
	}
});