// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_pdf : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<div class=\"b-support-list-item\">\n							<div class=\"b-support-item-header  b-header-question\" id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n								<div class=\"b-arrow-wrap b-ib\">\n									<span class=\"i-edit i-edit-arrow\"></span>\n									<span class=\"i-edit i-edit-arrow i-edit-invis\"></span>\n								</div>\n								<div class=\"b-support-title b-ib\">\n									"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n								</div>\n							</div>\n							<div class=\"b-support-item-body\">\n								<div class=\"b-support-item-wrap\">\n									<div class=\"b-support-item-text\" id=\"text_"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n									</div>\n									<div class=\"b-support-info\">\n										<span class=\"b-suppore-more f_medium\">More information:</span><br>\n										<a href=\"#?page=inbox&subpage=tickets\" class=\"b-page-link\">\n											<span class=\"i-edit i-edit-support i-edit-tickets\"></span>\n											<span class=\"i-edit i-edit-support i-edit-tickets i-edit-invis\"></span>\n											<span>Tickets</span>\n										</a>\n									</div>\n								</div>\n							</div>\n						</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_pdf : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<div class=\"b-support-list-item\">\n							<div class=\"b-support-item-header  b-header-instruct\" id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n								<div class=\"b-arrow-wrap b-ib\">\n									<span class=\"i-edit i-edit-arrow\"></span>\n									<span class=\"i-edit i-edit-arrow i-edit-invis\"></span>\n								</div>\n								<div class=\"b-support-title b-ib\">\n									"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n								</div>\n							</div>\n							<div class=\"b-support-item-body\">\n								<div class=\"b-support-pdf-wrap\">\n									<div class=\"b-support-item-pdf\" id=\"pdf_"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n									</div>\n									<div class=\"b-support-info b-support-pdf\">\n										<span class=\"b-suppore-more f_medium\">More information:</span><br>\n										<a href=\"#?page=inbox&subpage=tickets\" class=\"b-page-link\">\n											<span class=\"i-edit i-edit-support i-edit-tickets\"></span>\n											<span class=\"i-edit i-edit-support i-edit-tickets i-edit-invis\"></span>\n											<span>Tickets</span>\n										</a>\n									</div>\n								</div>\n							</div>\n						</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"b-page-content\">\n	<div class=\"b-page-header\">\n		<h1 class=\"b-page-title b-title_header b-title_support\">Support</h1>\n	</div>			\n	<div class=\"b-page-block b-support-page-block\">\n		<div class=\"b-support-select\">\n			<div class=\"b-support-item b-ib sup-1 orange\" data-type=\"sup-1\">\n				<div class=\"b-select-item-container\">\n					<div class=\"b-select-item-inner\">\n						<div class=\"b-ico-wrap\">\n							<span class=\"i-edit i-edit-questions\"></span>\n							<span class=\"i-edit i-edit-questions i-edit-invis\"></span>\n						</div>\n						<br>\n						<span>Questions</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"b-support-item b-ib sup-2 green\" data-type=\"sup-2\">\n				<div class=\"b-select-item-container\">\n					<div class=\"b-select-item-inner\">\n						<div class=\"b-ico-wrap\">\n							<span class=\"i-edit i-edit-instructions\"></span>\n							<span class=\"i-edit i-edit-instructions i-edit-invis\"></span>\n						</div>\n						<br>\n						<span>Instructions</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"b-support-item b-ib sup-3 gray\" data-type=\"sup-3\">\n				<div class=\"b-select-item-container\">\n					<div class=\"b-select-item-inner\">\n						<div class=\"b-ico-wrap\">\n							<span class=\"i-edit i-edit-search\"></span>\n							<span class=\"i-edit i-edit-search i-edit-invis\"></span>\n						</div>\n						<br>\n						<span>Search</span>\n						<div class=\"b-search-wrap clearfix\">\n							<div class=\"b-search-container\">\n								<div class=\"b-search-container-inner\">\n									<form>\n										<button type=\"submit\" class=\"b-search-btn\"></button>\n										<input type=\"text\" class=\"b-page-input b-search-input\" placeholder=\"Enter a keyword\" />\n									</form>\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div class=\"b-box sup-1\">\n			<div class=\"b-support-list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.faq_list : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n		<div class=\"b-box sup-2\">\n			<div class=\"b-support-list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.faq_list : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n		<div class=\"b-box sup-3 js-support-search-block\">\n			\n		</div>\n	</div>\n</div>\n"
    + ((stack1 = container.invokePartial(partials.limiter,depth0,{"name":"limiter","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
