/**
 * Created by sergey on 11.11.2015.
 */
var NewsList = require("./list/NewsList");
var ProfileBlock = require("./blocks/ProfileBlock");
var ChannelBlock = require("./blocks/ChannelsBlock");
var PayoutBlock = require("./blocks/PayoutBlock");
var InboxBlock = require("./blocks/InboxBlock");
var ReferralBlock = require("./blocks/ReferralsBlock");
var LayoutTemplate = require("./templates/DashboardLayout.hbs");

module.exports = Marionette.LayoutView.extend({
	initialize: function(){
		this.newsList = new NewsList();
		this.profileBlock = new ProfileBlock();
		this.channelBlock = new ChannelBlock();
		this.payoutBlock = new PayoutBlock();
		this.inboxBlock = new InboxBlock();
		this.referralBlock = new ReferralBlock();
	},
	ui: {
		openBtn: ".b-content-news-open"
	},
	events: {
		"click @ui.openBtn": "openNews"
	},
	className: "wrapper_n",
	template: LayoutTemplate,
	regions: {
		"news": ".b-content-news",
		"profile": ".js-dashboard-profile",
		"channels": ".js-dashboard-channels-panel",
		"payouts": ".js-dashboard-payouts-panel",
		"referral": ".js-dashboard-referral-panel",
		"inbox": ".js-dashboard-inbox-panel",
		"inbox768": ".js-dashboard-inbox-panel-768"
	},
	onShow: function(){
		this.newsList.show(this.getRegion("news"));
		this.profileBlock.show(this.getRegion("profile"));
		this.channelBlock.show(this.getRegion("channels"));
		this.payoutBlock.show(this.getRegion("payouts"));
		this.referralBlock.show(this.getRegion("referral"));
		this.inboxBlock.show(this.getRegion("inbox"), this.getRegion("inbox768"));
	},
	openNews: function(e){
		var newsHeight = $(e.currentTarget).closest('.b-content-news-list').find('.b-hidden-text').outerHeight();
		$(e.currentTarget).toggleClass('_open');
		if($(e.currentTarget).hasClass('_open')){
			$(e.currentTarget).closest('.b-content-news-list').find('.b-visible-text').addClass('_hide');
			$(e.currentTarget).closest('.b-content-news-list').find('.b-hidden-text').addClass('_show');
			$(e.currentTarget).closest('.b-content-news-list').find('.b-content-news-text').animate({'height': newsHeight +29+'px'},200);
		}else{
			setTimeout(function(){
				$(e.currentTarget).closest('.b-content-news-list').find('.b-visible-text').removeClass('_hide');
				$(e.currentTarget).closest('.b-content-news-list').find('.b-hidden-text').removeClass('_show');
			}, 200);
			$(e.currentTarget).closest('.b-content-news-list').find('.b-content-news-text').animate({'height': 65+'px'},200);
		}
	}
});