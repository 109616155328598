/**
 * Created by gila on 10.12.2015.
 */
var MusicCategories = require("../collections/Music/Categories");
var SoundCategories = require("../models/Music/SoundEffects");
var MusicListCollection = require("../collections/Music/Music");
var MusicModel =  require("../models/Music/Music");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getMusicCategories: function(options){
		var options = options || {};
		var musicCategories = new MusicCategories(options);
		var defer = $.Deferred();
		musicCategories.fetch({
			success: function(){
				defer.resolve(musicCategories);
			}
		});
		return defer.promise();
	},
	getSoundCategories: function(options){
		var options = options || {};
		var soundCategories = new SoundCategories(options);
		var defer = $.Deferred();
		soundCategories.fetch({
			success: function(){
				defer.resolve(soundCategories);
			}
		});
		return defer.promise();
	},
	getMusicList: function(options){
		var options = options || {};
		var musicList = new MusicListCollection(options);
		var defer = $.Deferred();
		musicList.fetch({
			success: function(){
				defer.resolve(musicList);
			}
		});
		return defer.promise();
	},
	getNewMusicEntity: function(){
		return new MusicModel()
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("music:music:categories:entities", function(options){
			return me.getMusicCategories(options);
		});
		Radio.channel("global").reply("music:sound:entity", function(options){
			return me.getSoundCategories(options);
		});
		Radio.channel("global").reply("music:list:entities", function(options){
			return me.getMusicList(options);
		});
		Radio.channel("global").reply("music:new:entity", function(){
			return me.getNewMusicEntity();
		});
	}
};