/**
 * Created by sergey on 26.11.2015.
 */

module.exports = Backbone.Model.extend({
	url: "/api/payment_account/get_params",
	codeMap: {
		"1": "bank",
		"7": "webmoney",
		"11": "payoneer"
	},
	statusMap: {
		"-2": "You cannot use this Payment Account, because it has already signed with the QuizGroup Partner Program. Please enter another Payment Account.",
		"-3": "You cannot change payment method right now. Please try again in 12 hours.",
		"-5": "Wrong format input data.",
		"-6": "Bank beneficiary error.",
		"-100": "Unknown error."
	},
	validation: {},
	parse: function(resp){
		this.defaultValue = resp.body;
		return resp.body;
	},
	saveWebMoney: function(data){
		var me = this;
		var handlerSuccess = function(){
			me.set("payment_type", 7, {silent: true});
			me.unset("payoneer_status", {silent: true});
			me.defaultValue  = me.toJSON();
			me.trigger("webmoney:save:success");
		};
		var handlerFail = function(xhr, stringResult, result){
			me.trigger("invlaid:user:save", me.statusMap[xhr.responseJSON.body.status]);
			me.trigger("webmoney:save:fail");
		};
		this.set(data);
		if(this.isValid(true)){
			var sendData = {
				payment_account: me.get("payment_account")
			};
			$.ajax({
				url: "/api/payment_account/set_webmoney",
				method: "POST",
				data: sendData
			}).then(handlerSuccess, handlerFail)
		} else {
			if(this.defaultValue.payment_type === this.get("payment_type")){
				this.set({payment_account: this.defaultValue.payment_account}, {silent: true});
			}else {
				this.set({payment_account: this.previous("name")}, {silent: true});
			}
		}

	},
	saveBank: function(data){
		var me = this,
			previous;
		var handlerSuccess = function(){
			me.set({payment_type: 1}, {silent: true});
			me.unset("payoneer_status", {silent: true});
			me.set({payment_account: me.get("bank_beneficiary")}, {silent: true});
			me.defaultValue  = me.toJSON();
			me.trigger("bank:save:success");
		};
		var handlerFail = function(xhr, stringResult, result){
			var status = me.statusMap[xhr.responseJSON.body.status];
			if(xhr.responseJSON.body.status === -5|| xhr.responseJSON.body.status === -6) {
				me.trigger("invlaid:user:save", me.statusMap[xhr.responseJSON.body.status], xhr.responseJSON.body.err_fields);
				me.trigger("bank:save:fail", xhr.responseJSON.body.err_fields);
			}else{
				me.trigger("invlaid:user:save", me.statusMap[xhr.responseJSON.body.status]);
				me.trigger("bank:save:fail", []);
			}


		};
		this.set(data);
		if(this.isValid(true)) {
			$.ajax({
				url: "/api/payment_account/set_bank",
				method: "POST",
				data: data
			}).then(handlerSuccess, handlerFail)
		} else {
			if(this.defaultValue.payment_type === this.get("payment_type")){
				this.set(this.defaultValue, {silent: true});
			}else {
				previous = this.previousAttributes();
				delete previous.payment_type;
				this.set(previous, {silent: true});
			}
		}
	},
	setPayoneer: function(){
		var me = this;
		var handlerSuccess = function(resp){
			me.set({payment_type: 11}, {silent: true});
			me.set({payoneer_status: resp.body.status}, {silent: true});
			me.unset("payment_account", {silent: true});
			if(resp.body.status == 1){
				me.trigger("payoneer:iframe:insert", resp.body.link);
			}else{
				me.trigger("payoneer:status:set", resp.body.status);
			}

		};
		var handlerFail = function(){
			me.trigger("payoneer:set:fail");
		};
		$.ajax({
			url: "/api/payment_account/set_payoneer",
			method: "POST",
			data: {}
		}).then(handlerSuccess, handlerFail)
	},

	isPayoneerExist: function(){
		var defer = $.Deferred(),
			me = this;
		var handlerSuccess = function(resp){
			me.payoneerState = resp.body.status;
			defer.resolve();
		};
		var handlerFail = function(){

		};
		$.ajax({
			url: "/api/payment_account/payoneer_exist",
			method: "POST",
			data: {}
		}).then(handlerSuccess, handlerFail);

		return defer.promise();
	},
	validateAccounts: function (value, attr, computedState) {
			var totalNotBlank =
				(computedState["bank_iban"] != "" ? 1 : 0)
				+ (computedState["bank_beneficiary_account"] != "" ? 1 : 0);

			if (totalNotBlank == 0) {
				return "Need to enter IBAN or Account";
			}

	},
	validateNumbers: function (value, attr, computedState) {
			var totalNotBlank =
				(computedState["bank_swift"] != "" ? 1 : 0)
				+ (computedState["bank_routing_code"] != "" ? 1 : 0);

			if (totalNotBlank == 0) {
				return "Need to enter SWIFT or Routing code";
			}

	},
	validateRouting: function (value, attr, computedState) {
			var totalNotBlank =
				(computedState["bank_routing_code"] != "" ? 1 : 0)
				+ (computedState["bank_swift"] != "" ? 1 : 0);

			if (totalNotBlank == 0) {
				return "Need to enter SWIFT or Routing code";
			}

	},

	setDefaultValue: function(){
		if(this.defaultValue.payment_type == this.get("payment_type"))
		{
			this.set(this.defaultValue, {silent:true});
		} else{
			this.clear({silent:true});
		}

	}
});


