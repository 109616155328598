var App = require('core/Core');
var dashboardApp = new App();
var defer = $.Deferred();
$(function() {
	defer.resolve();
});

$.when(defer.promise).then(function(){
	i18n.init({
		ns: "js0.1",
		fallbackLng: 'en',
		useCookie: false,
		resGetPath: "/api/i18n/get?ns=__ns__&lang=__lng__",
		resPostPath: "/api/i18n/not_found?ns=__ns__&lang=__lng__",
		shortcutFunction: 'defaultValue'
	}, function(){
		dashboardApp.start();
	});

});