var GoogleInfo = require("../models/Header/GoogleInfo");
var HeaderModel = require("../models/Header/Header");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getGoogleInfo: function(options){
		var options = options || {};
		var googleInfo = new GoogleInfo(options);
		var defer = $.Deferred();
		googleInfo.fetch({
			success: function(){
				defer.resolve(googleInfo);
			}
		});
		return defer.promise();
	},
	getNewHeaderEntity: function(options){
		var options = options || {};
		var header = new HeaderModel(options);
		return header.getUserInfo()
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("header:get:google:info:entity", function(options){
			return me.getGoogleInfo(options);
		});
		Radio.channel("global").reply("header:new:entity", function(options){
			return me.getNewHeaderEntity(options);
		});
	}
};