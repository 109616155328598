var InboxLayout = require("./views/InboxLayout");
var InboxEntities = require("entities/Inbox");
var ToolbarView = require("./views/ToolBar");
var NotificationTable = require("./views/NotificationTable");
var NewsTable = require("./views/NewsTable");
var RecentTicketsTable = require("./views/RecentTicketsTable");
var HistoryTicketsTable = require("./views/HistoryTicketsTable");
var TicketsLayout = require("./views/TicketsLayout");
var TicketsForm = require("./views/TicketsCreateForm");
module.exports = Marionette.Object.extend({
	state: undefined,
	selectedIds: [],
	states: {
		init: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {

			},
			notification: function () {
				this.target.changeState(this.target.states.notification);
			},
			tickets: function () {
				this.target.changeState(this.target.states.tickets);
			},
			news: function () {
				this.target.changeState(this.target.states.news);
			}
		},
		notification: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {
				delete this.notificationsModel;
			},
			execute: function () {

			},
			enter: function () {
				this.target.showNotification();
			},
			notification: function () {

			},
			tickets: function () {
				this.target.changeState(this.target.states.tickets);
			},
			news: function () {
				this.target.changeState(this.target.states.news);
			}
		},
		tickets: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showTickets(this.target.params);
			},
			tickets: function () {

			},
			notification: function () {
				this.target.changeState(this.target.states.notification);
			},
			news: function () {
				this.target.changeState(this.target.states.news);
			}

		},
		news: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {
				delete this.newModel;
			},
			execute: function () {

			},
			enter: function () {
				this.target.showNews(this.target.params);
			},
			news: function () {

			},
			notification: function () {
				this.target.changeState(this.target.states.notification);
			},
			tickets: function () {
				this.target.changeState(this.target.states.tickets);
			}

		}
	},
	initialize: function(){
		this.setHandlers();
		InboxEntities.initialize(this);
		this.states.init.initialize(this);
		this.states.news.initialize(this);
		this.states.notification.initialize(this);
		this.states.tickets.initialize(this);
		this.state = this.states.init;
	},
	changeState: function(state) {
		if (this.state !== state) {
			this.state.exit();
			this.state = state;
			this.state.enter();
			this.state.execute();
		}
	},
	init: function(){
		this.state.init();
	},
	news: function(){
		this.state.news();
	},
	notification: function(){
		this.state.notification();
	},
	tickets: function(){
		this.state.tickets();
	},

	showNotification: function () {
		var fetchNotifications = Radio.channel("global").request("inbox:notification:entity");
		var me = this;
		$.when(fetchNotifications).done(function(notifications){
			me.notificationsModel = notifications;
			if(notifications.needShowMoreBtn()){
				me.inboxLayout.showMoreBtn();
			}else{
				me.inboxLayout.hideMoreBtn();
			}
			me.inboxLayout.setCountPage({offset: notifications.getPageCount(), total_count: notifications.get("total_count")});

			me.inboxLayout.model.setActiveTab("notifications");
			me.inboxLayout.setActiveTab();

			var toolBar = new ToolbarView({model: notifications, type: "notifications"});
			var notificationTable = new NotificationTable({model: notifications});

			me.listenTo(notificationTable, "user:click:row", function(cb, id){
				notifications.getNotification(cb, id);
			});
			me.listenTo(toolBar, "selected:filter", function(data){
				notifications.unset("filter", {silent: true});
				notifications.set("filter", data);
			});
			me.listenTo(notificationTable, "user:click:starred", function(data){
				me.selectedIds = notificationTable.getSelection();
				notifications.setStarred(data);
			});

			me.listenTo(toolBar, "selected:action", function(data){
				if(data === "createticket"){
					this.params = {
						page: "inbox",
						subpage: "tickets",
						action: "create"
					};
					this.tickets();
					return;
				}
				notifications.set("action", data);
				var ids = notificationTable.getSelection();
				notifications.filterAction(ids);
				notifications.unSetFilterValue();

			});
			me.listenTo(notifications, "delete:notifies", function(objectMap){
					var limit = 20,
						offset = 0;
					notifications.setTotalCount(objectMap);
					me.inboxLayout.setCountPage({offset: notifications.getPageCount(objectMap), total_count: notifications.get("total_count")});
					notificationTable.removeDetailRows(objectMap);
					notificationTable.refreshData();
					toolBar.uncheckAllInput();
					if(notifications.rowsLength() < 20 &&  notifications.get("total_count") != 0){
						if(notifications.rowsLength() == 0){
							limit = 20;
							offset = 0;
						}else{
							limit =  Math.abs(20 - notifications.rowsLength());
							offset = notifications.rowsLength();
						}
						notifications.getMoreNotifications(offset, limit);
					}
			});
			me.listenTo(notifications, "refresh:filter:rows", function(data){
				me.inboxLayout.setCountPage({offset: notifications.getPageCount(), total_count: notifications.get("total_count")});
				notificationTable.refreshData(data);
				toolBar.setSearchList();
				if(me.selectedIds && me.selectedIds.length > 0){
					notificationTable.selectRows(me.selectedIds);
					me.selectedIds = [];
				}else{
					me.selectedIds = [];
				}
				if(notifications.rowsLength() === 0 ){
					toolBar.uncheckAllInput();
				}
				if(notifications.needShowMoreBtn()){
					me.inboxLayout.showMoreBtn();
				}else{
					me.inboxLayout.hideMoreBtn();
				}
			});
			me.listenTo(toolBar, "search", function(data){
				notifications.set("query", data);
			});
			me.listenTo(notificationTable, "filtered:table", function(){
				if(notifications.get("query")){
					me.inboxLayout.hideMoreBtn();
					me.inboxLayout.hideCountPages();
				}else{
					me.inboxLayout.showMoreBtn();
					me.inboxLayout.showCountPages();
				}
			});
			me.listenTo(notificationTable, "item:selected", function(data){
				toolBar.checkAllInput();
				toolBar.activedActionMenu();
			});
			me.listenTo(notificationTable, "item:unselected", function(data){
				toolBar.uncheckAllInput();
				toolBar.deactivedActionMenu();
			});

			me.inboxLayout.toolBarRegion.show(toolBar);
			me.inboxLayout.mainContentRegion.show(notificationTable);
			Backbone.history.navigate("?page=inbox&subpage=notifications");
		});

	},
	showTickets: function (params) {
		var fetchChannel = Radio.channel("global").request("channels:channels:entity");
		var fetchTickets = Radio.channel("global").request("inbox:tickets:entity");
		var deferRecentTickets = $.Deferred();
		var deferHistoryTickets = $.Deferred();
		var defferArray = [deferRecentTickets, deferHistoryTickets];
		var fetchArray = [fetchChannel, fetchTickets],
			me = this;
		$.when.apply($, fetchArray).done(function(channels, tickets){
			me.ticketsModel = tickets;
			var tickerLayout = new TicketsLayout();
			var toolBar = new ToolbarView({model: tickets,  type: "tickets"});
			var ticketForm = new TicketsForm({model: tickets, channels: channels.get("rows")});
			var recentTicketsTable = new RecentTicketsTable({model: tickets});
			var historyTicketsTable = new HistoryTicketsTable({model: tickets});

			me.inboxLayout.model.setActiveTab("tickets");
			me.inboxLayout.setActiveTab();


			me.listenTo(toolBar, "selected:filter", function(data){
				tickets.unset("filter", {silent: true});
				tickets.set("filter", data);
			});

			me.listenTo(recentTicketsTable, "user:click:starred", function(data){
				me.openSelectedIds = recentTicketsTable.getSelection();
				tickets.setStarred(data);
			});

			me.listenTo(historyTicketsTable, "user:click:starred", function(data){
				me.closeSelectedIds = historyTicketsTable.getSelection();
				tickets.setStarred(data);
			});

			me.listenTo(ticketForm, "user:create:ticket", function(data){
				tickets.createTicket(data);
			});
			me.listenTo(ticketForm, "user:cancel:ticket", function(data){
				toolBar.createTicket();
			});

			me.listenTo(recentTicketsTable, "user:click:row", function(cb, id){
				tickets.getTicket(cb, id);
			});

			me.listenTo(historyTicketsTable, "user:click:row", function(cb, id){
				tickets.getTicket(cb, id);
			});

			me.listenTo(toolBar, "selected:action", function(data){
				tickets.set("action", data);
				var ids,
					recentIds = recentTicketsTable.getSelection(),
					historyIds = historyTicketsTable.getSelection(),
					list="";
				ids = recentIds.concat(historyIds);
				tickets.filterAction(ids);
				tickets.unSetFilterValue();
			});


			me.listenTo(tickets, "refresh:filter:rows", function(data){
				tickerLayout.setCountPage({offset: tickets.getPageCount(), total_count: tickets.get("total_count")});
				historyTicketsTable.refreshData(data);
				toolBar.setSearchList();
				if(tickets.rowsLength() === 0 ){
					toolBar.uncheckAllInput();
				}

				if(tickets.needShowMoreBtn()){
					me.inboxLayout.showMoreBtn();
				}else{
					me.inboxLayout.hideMoreBtn();
				}

			});

			me.listenTo(toolBar, "search", function(data){
				tickets.set("query", data);
			});

			me.listenTo(recentTicketsTable, "item:selected", function(data){
				if(recentTicketsTable.getSelection().length  > 0 || historyTicketsTable.getSelection().length > 0){
					toolBar.activedActionMenu();
				}
			});

			me.listenTo(recentTicketsTable, "item:unselected", function(data){
				if(historyTicketsTable.getSelection().length  === 0 && recentTicketsTable.getSelection().length === 0) {
					toolBar.uncheckAllInput();
					toolBar.deactivedActionMenu();
				}
			});

			me.listenTo(historyTicketsTable, "item:selected", function(data){
				if(recentTicketsTable.getSelection().length  > 0 || historyTicketsTable.getSelection().length > 0){
					toolBar.activedActionMenu();
				}
			});

			me.listenTo(historyTicketsTable, "item:unselected", function(data){
				if(historyTicketsTable.getSelection().length  === 0 && recentTicketsTable.getSelection().length === 0) {
					toolBar.uncheckAllInput();
					toolBar.deactivedActionMenu();
				}
			});

			me.listenTo(tickerLayout, "show", function(){
				me.inboxLayout.hideCountPages();
				if(tickets.needShowMoreBtn()){
					me.inboxLayout.showMoreBtn();
				}else{
					me.inboxLayout.hideMoreBtn();
				}

				tickerLayout.setCountPage({offset: tickets.getPageCount(), total_count: tickets.get("total_count")});
				tickerLayout.ticketsRecentRegion.show(recentTicketsTable);
				tickerLayout.ticketsHistoryRegion.show(historyTicketsTable);
				toolBar.showCreateBlock();
			});


			me.listenTo(tickerLayout, "show", function(){
				me.inboxLayout.hideCountPages();
				if(tickets.needShowMoreBtn()){
					me.inboxLayout.showMoreBtn();
				}else{
					me.inboxLayout.hideMoreBtn();
				}

				tickerLayout.setCountPage({offset: tickets.getPageCount(), total_count: tickets.get("total_count")});
				tickerLayout.ticketsRecentRegion.show(recentTicketsTable);
				tickerLayout.ticketsHistoryRegion.show(historyTicketsTable);
				toolBar.showCreateBlock();
			});

			me.listenTo(ticketForm, "show", function(){
				if(params ? params.action === "create" :0){
					toolBar.createTicket();
					if(params.channel_id){
						ticketForm.setChannelId(params.channel_id);
					}
				}
			});

			me.listenTo(toolBar, "show", function(){
				toolBar.ticketsFormRegion.show(ticketForm);
			});

			me.inboxLayout.toolBarRegion.show(toolBar);
			me.inboxLayout.mainContentRegion.show(tickerLayout);
			if(params ? params.action !== "create" :0){
				Backbone.history.navigate("?page=inbox&subpage=tickets");
			}
		});

	},
	showNews: function(params){
		var fetchNews = Radio.channel("global").request("inbox:news:entity");
		var me = this;
		$.when(fetchNews).done(function(news){
			me.newsModel = news;
			var options = params || {};
			var optionsTable  = {};
			if(typeof options.news_id === "string"){
				optionsTable.news_id = options.news_id;
			}
			_.extend(optionsTable, {model: news});
			me.inboxLayout.model.setActiveTab("news");
			me.inboxLayout.setActiveTab();
			if(news.needShowMoreBtn()){
				me.inboxLayout.showMoreBtn();
			}else{
				me.inboxLayout.hideMoreBtn();
			}
			me.inboxLayout.setCountPage({offset: news.getPageCount(), total_count: news.get("total_count")});

			var toolBar = new ToolbarView({model: news, type: "news"});
			var newsTable = new NewsTable(optionsTable);

			me.listenTo(toolBar, "selected:filter", function(data){
				news.unset("filter", {silent: true});
				news.set("filter", data);
			});

			me.listenTo(newsTable, "user:click:starred", function(data){
				me.selectedIds = newsTable.getSelection();
				news.setStarred(data);
			});

			me.listenTo(newsTable, "user:click:row", function(cb, id){
				news.getNews(cb, id);
			});

			me.listenTo(toolBar, "selected:action", function(data){
				if(data === "createticket"){
					this.params = {
						page: "inbox",
						subpage: "tickets",
						action: "create"
					};
					this.tickets();
					return;
				}
				news.set("action", data);
				var ids = newsTable.getSelection();
				news.filterAction(ids);
				news.unSetFilterValue();
			});

			me.listenTo(newsTable, "filtered:table", function(){
				if(news.get("query")){
					me.inboxLayout.hideMoreBtn();
					me.inboxLayout.hideCountPages();
				}else{
					me.inboxLayout.showMoreBtn();
					me.inboxLayout.showCountPages();
				}
			});

			me.listenTo(news, "refresh:filter:rows", function(data){
				me.inboxLayout.setCountPage({offset: news.getPageCount(), total_count: news.get("total_count")});
				newsTable.refreshData(data);
				toolBar.setSearchList();
				if(me.selectedIds && me.selectedIds.length > 0){
					newsTable.selectRows(me.selectedIds);
					me.selectedIds = [];
				}else{
					me.selectedIds = [];
				}
				if(news.rowsLength() === 0 ){
					toolBar.uncheckAllInput();
				}
				if(news.needShowMoreBtn()){
					me.inboxLayout.showMoreBtn();
				}else{
					me.inboxLayout.hideMoreBtn();
				}
			});

			me.listenTo(toolBar, "search", function(data){
				news.set("query", data);
			});
			me.listenTo(newsTable, "item:selected", function(data){
					toolBar.activedActionMenu();
			});
			me.listenTo(newsTable, "item:unselected", function(data){
				toolBar.uncheckAllInput();
				toolBar.deactivedActionMenu();
			});
			me.inboxLayout.toolBarRegion.show(toolBar);
			me.inboxLayout.mainContentRegion.show(newsTable);
			Backbone.history.navigate("?page=inbox&subpage=news");
		});

	},
	show: function(params){
		var me = this,
			promises = [];
		this.params = params;
		promises.push($.get("/api/inbox/ticket_list?&only_count=true"));
		promises.push($.get("/api/inbox/notification_list?isread=false&only_count=true"));
		$.when.apply($, promises).done(function(ticketListCount, notificationListCount){
			var options = {
				notificationSum: notificationListCount[0].body.result_count,
				ticketsSum: ticketListCount[0].body.unread
			};

			me.layoutModel = Radio.channel("global").request("inbox:new:inbox:entity", options);
			me.inboxLayout = new InboxLayout({model: me.layoutModel});
			me.listenTo(me.inboxLayout, "news:more:btn:click", function(){
				me.newsModel.getMoreNews();
			});
			me.listenTo(me.inboxLayout, "notifications:more:btn:click", function(){
				me.notificationsModel.getMoreNotifications();
			});
			me.listenTo(me.inboxLayout, "tickets:more:btn:click", function(){
				me.ticketsModel.getMoreTickets();
			});
			var mainRegion =  Radio.channel("layout").request("get:main:region");
			me.listenTo(me.inboxLayout, "show", function(data){
				if(params ? params.subpage ? 1: 0 : 0){
					if(params.subpage === "notifications"){
						this.notification();
					}else if(params.subpage === "news"){
						this.news();
					}else if(params.subpage === "tickets"){
						this.tickets();
					}else{
						this.notification();
					}
				}else{
					this.notification();
				}
			});

			me.listenTo(me.inboxLayout, "user:click:tab", function(data){
				if(data === "notifications"){
					this.notification();
				}else if(data === "news"){
					this.news();
				}else if(data === "tickets"){
					this.tickets();
				}
			});
			mainRegion.show(me.inboxLayout);
		});

	},
	readMessage: function(id){
		this.layoutModel.readNotificationMessage(id);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:inbox", function(params) {
			me.state = me.states.init;
			me.show(params);
		});
		Radio.channel("inbox").on("user:read:notification", function(id) {
			me.readMessage(id);
		});
		Radio.channel("inbox").on("user:read:ticket", function(id) {
			me.readMessage(id);
		});
	}
});
