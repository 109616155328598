/**
 * Created by sergey on 02.12.2015.
 */
var tableMixin = require("mixins/Table");
var TemplateTablePayout = require("./templates/PayoutTable.hbs");
var Helpers = require("helpers/Helpers");
var EarningTemplate = require("./templates/EarningTemplate.hbs");
var PayoutStatusTemplate = require("./templates/PayoutStatusTemplate.hbs");
module.exports =  Marionette.ItemView.extend(_.extend(tableMixin, {
	className: "b-table f_12 payout-table",
	tagName: "table",
	template: TemplateTablePayout,
	refreshData: function(data){
		this.drawTable(data);
	},
	getData: function(){
		return this.model.get("payments");
	},
	getConfig: function () {
		var me = this;
		return {
			"paging": false,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns(),
			"language": {
				"emptyTable": "N/A"
			}
		}
	},
	getColumns: function(){
		return [
			{type: 'de_date', data: "bill_date", render: function(data, type, full, meta){
				return Helpers.revertDate(full.last_day_of_month);
		}},{className: "b-channel-name", render: function(data, type, full, meta){
				if(full.is_referral){
					return '<a class="b-tooltip b-tooltip_f-12 b-tooltip-nowrap" data-desc="'+full.ref_channel_name+'" href="http://www.youtube.com/channel/'+full.ref_channel_id+'" target="_blank"><span>'+full.ref_channel_name+'</span></a>';
				}else{
					return '<a class="b-tooltip b-tooltip_f-12 b-tooltip-nowrap" data-desc="'+full.channel_name+'" href="http://www.youtube.com/channel/'+full.channel_id+'" target="_blank"><span>'+full.channel_name+'</span></a>';
				}
			}},
			{data: "earning_amount", className: "b-table-earning", render: function(data, type, full, meta){
			return EarningTemplate({earning_amount: data, is_referral: full.is_referral, url: full.report_url});}},
			{data: "earnings_type", render: function(data, type, full, meta){
				if(data === 4){
					return "YouTube RED";
				}
				return "YouTube";
			}},
			{data: "payment_date",  render: function(data, type, full, meta){
				return Helpers.revertDate(data);
			}},
			{data: "payment_amount", className:"b-table-pay", render: function(data, type, full, meta){
				if(full.payment_amount){
					return full.payment_amount
				}else{
					return "";
				}
			}},
			// {className: "b-table-reason", render: function(data, type, full, meta){
			// 	if(full.deny_reason){
			// 		return full.deny_reason
			// 	}else{
			// 		return "";
			// 	}
			// }},
			{data: "p_account",  render: function(data, type, full, meta){
				var result = "";
				if(data){
					if(full.payment_type == 7){
						return '<span class="b-download-link right" data-desc="'+data.account+'">WebMoney</span>';
					}else if(full.payment_type == 11){
						return '<span class="b-download-link right" data-desc="'+data.account+'">Payoneer</span>';
					}else if(full.payment_type == 1){
						return '<span class="b-download-link right" data-desc="'+data.account+'"  style="padding-left: 11px;">Bank</span>';
					}else if(full.payment_type == 4){
						return '<span class="b-download-link right" data-desc="'+data.account+'">PayPal</span>';
					}
				}
				return result;
			}},
			{data: "payments_status", render:function(data, type, full, meta){
				return PayoutStatusTemplate({status: data})
			}}
		];
	},
	createRow: function( row, data, dataIndex ) {
		if ( data.payments_status < 0 ) {
			$(row).addClass("b-rejected-row");
		}
	},
	drawTable: function(data) {
		var me = this;
		if (data) {
			this.table.rows.add(data); // Add new data
			this.table.columns.adjust().draw(false); // Redraw the DataTable
		} else {
			this.table.clear().draw();
			this.table.rows.add(me.getData()); // Add new data
			this.table.columns.adjust().draw(); // Redraw the DataTable
		}
	}
}));