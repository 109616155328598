/**
 * Created by sergey on 02.12.2015.
 */
module.exports = {
	onShow: function(){
		var me = this;
		this.table = this.$el.DataTable(me.getConfig());
		this.addBodyClass();
		this.addSelected();
	},
	getConfig: function(){
		var me = this;
		return {
			"paging": false,
			"createdRow": me.createRow,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns()
		}
	},
	drawTable: function(){
		var me = this;
		this.table.clear().draw();
		this.table.rows.add(me.getData()); // Add new data
		this.table.columns.adjust().draw(); // Redraw the DataTable
	},
	createRow: function () {

	},
	onBeforeDestroy: function(){
		this.table.destroy();
	},
	addSelected: function(){

	},
	selectAll: function(){
		var me = this;
		this.table.rows().data().each(function(el,index){
			var $node = $(me.table.row(index).node());
			$node.addClass("selected");
			$node.find("input[type='checkbox']").prop("checked", true);
		});
	},
	selectRows: function (ids) {
		var me = this;
		this.table.rows().data().each(function(el,index){
			var idIndex = _.indexOf(ids, el.channel_id);
			var $node = $(me.table.row(index).node());
			if(idIndex != -1 ){
				$node.addClass("selected");
				$node.find("input[type='checkbox']").prop("checked", true);
			}
		});
	},
	deSelectAll: function(){
		var me = this;
		this.table.rows().data().each(function(el,index){
			var $node = $(me.table.row(index).node());
			$node.removeClass("selected");
			$node.find("input[type='checkbox']").prop("checked", false);
		});
	},
	addBodyClass: function(){
		this.table = this.$el.DataTable();
		$(this.table.table().body()).addClass('b-channels-table-body');
	}
};
