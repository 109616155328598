var DashboardLayout = require("./views/DashboardLayout");
var DashboardModal = require("./views/DashboardModal");
var DashboardModel = require("models/Dashboard/DashboardProfile");

module.exports = Marionette.Object.extend({
	initialize: function(){

		this.setHandlers();
	},
	show: function(){
		var me = this;
		var uniteCabId = Radio.channel("login").request("user:unite:cabid");
		var dashboardModel = new DashboardModel();

		var mainRegion =  Radio.channel("layout").request("get:main:region");

		dashboardModel.set("family_name", '');
		dashboardModel.set("given_name",  '');
		dashboardModel.set("email",	'');
		dashboardModel.set("picture",	  '/assets/img/photo.jpg');

		// /api/account/get_gplus_info

		//$.when(Radio.channel("global").request("header:get:google:info:entity")).then(function(ginfo)
		//{
		//	me.dashboard.set(ginfo.attributes);
		//});

		var dashboard = new DashboardLayout({model: dashboardModel});
				
		if(uniteCabId){
			var notify = new DashboardModal({cabId: uniteCabId});
			me.notify(notify);
		}
		me.listenTo(notify,"unite:dashboard:success", function(data){
			dashboardModel.agreeUniteDashboard();
		});

		me.listenTo(notify,"decline:unite", function(data){
			dashboardModel.declineUnite();
		});

		var gInfo;// = Radio.channel("global").request("header:get:google:info:entity");

		//$('.b-content-info').css('display','none');
		//$('.b-content-news').css('padding-top','0px');

		mainRegion.show(dashboard);
		Backbone.history.navigate("?page=dashboard");
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:dashboard", function() {
			me.show();
		});
	},
	notify: function (view) {		
		var modalRegion =  Radio.channel("layout").request("get:modal:region");
		modalRegion.show(view);
	}
});