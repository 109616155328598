var Views = {};

var SumViewsChannelsTemplate = require("./templates/ChannelsSumViews.hbs");


Views.SumViewsChannels= Marionette.ItemView.extend({
	ui: {
		sumViews: ".b-sum-count"
	},
	templateHelpers: function(){
		var me = this;
		return {
			sumViews: me.options["sumViews"]
		}
	},
	template: SumViewsChannelsTemplate,
	onShow: function(){
		this.sumFontSizeChange();
		this.sumShort();
	},
	sumShort: function(){
		var views = this.ui.sumViews.text();
		if(views.length > 6){
			var arrayPower = [1, 1000, 1000000, 1000000000];
			var moneyMap = {0: "", 1: "K", 2: "M", 3: "B"};
			var index = _.findIndex(arrayPower, function(item) {
				return item > views
			});
			if(index <= 0) {
				views = String(views);
			}else {
				views = parseFloat(String(views / arrayPower[index - 1])).toFixed(1) + " " + moneyMap[index - 1];
			}
			this.ui.sumViews.html(views);
			this.ui.sumViews.addClass('b-tooltip b-tooltip_sum');
		}
	},
	sumFontSizeChange: function(){
		var str = this.ui.sumViews.text(),
			sumCount = $('.b-sum-count');
		if (str.length > 3){
            sumCount.css("font-size", "48px");
        }else if(str.length > 4){
        	sumCount.css("font-size", "45px");
        }else if(str.length > 5){
        	sumCount.css("font-size", "42px");
        }
	}
});
module.exports = Marionette.Object.extend({
	createView: function(region, resp){
		this.sumViewsChannels = new Views.SumViewsChannels({sumViews: resp.body.views});
		region.show(this.sumViewsChannels);
	},
	show: function(region){
		var me = this,
			month = Radio.channel("login").request("user:month:of:payment"),
			year = Radio.channel("login").request("user:year:of:payment"),
			prevMonth = month - 1,
			promises = [];
		$.get("/api/analytics/get_views?year="+year+"&month="+month).done(me.createView.bind(me, region))
	}
});