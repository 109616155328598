var DashboardLoginPasswordView = require("./view/DashboardLoginPassword");
var DashboardLoginModel = require("models/DashboardPasswordLogin/DasboardLoginPassword");

module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
		this.redirectToDashboard = function(){
			Radio.channel("login").trigger("user:success:login");
		};
		this.codeLoginMap ={
			"-10": "showGAthError",
			"-1": "showLoginError",
			"-2": "showLimitError",
			"-3": "showLoginError",
			"-100": "showUnknownError"
		};
		this.codeMap ={
			"-10": "showGAthError",
			"-1": "showCodeError",
			"-2": "showCodeLimitError",
			"-3": "showUserIsNotExistError",
			"-100": "showUnknownError"
		};
	},
	show: function(){
		var dashboardModel = new DashboardLoginModel();
		this.dashboardLoginPassw = new DashboardLoginPasswordView({model: dashboardModel});
		var	mainRegion =  Radio.channel("layout").request("get:main:region"),
			me = this;
		this.listenTo(this.dashboardLoginPassw, "send:login", function(data){
			dashboardModel.sendLogin(data, {success: me.onSuccessLoginHandler.bind(me), fail: me.onFailLoginHandler.bind(me)});
		});
		this.listenTo(this.dashboardLoginPassw, "send:code", function(data){
			dashboardModel.sendCode(data, {success: me.onSuccessCodeHandler.bind(me), fail: me.onFailCodeHandler.bind(me)});
		});
		mainRegion.show(this.dashboardLoginPassw);
		Backbone.history.navigate("?page=dashboardloginpassword");
	},
	onSuccessLoginHandler: function(resp, stringResult, xhr){
		if(resp.body.result === 1){
			this.dashboardLoginPassw.loginErrorHide();
			this.dashboardLoginPassw.showCodeForm(resp.body.code_num);
		}
	},
	onFailLoginHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardLoginPassw;
		if(!_.isEmpty(xhr.responseJSON.body)){
			view.loginError();
			code = xhr.responseJSON.body.code;
			typeof  view[me.codeLoginMap[String(code)]] === "function" ? view[me.codeLoginMap[String(code)]](): view.showUnknownError();
		}

	},
	onSuccessCodeHandler: function(resp, stringResult, xhr){
		this.redirectToDashboard();
	},
	onFailCodeHandler: function(xhr, stringResult, result){
		var code,
			me = this;
		view = this.dashboardLoginPassw;
		if(!_.isEmpty(xhr.responseJSON.body)){

			view.codeError();
			code = xhr.responseJSON.body.code;
			typeof  view[me.codeMap[String(code)]] === "function" ? view[me.codeMap[String(code)]](): view.showUnknownError();
		}
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:dashboard:login:with:password", function(){
			me.show();
		});
	}
});
