var UniteTemplate = require("./template/UniteDashboard.hbs");
var Helpers = require("helpers/Helpers.js");
module.exports = Marionette.LayoutView.extend({
	className: "wrapper_n",
	template: UniteTemplate,
	ui: {
		confirmBtn: ".b-button_yes"
	},
	events: {
		"click @ui.confirmBtn": "goToDashboard"
	},
	onShow: function(){
		this.getQueryVariable();
	},
	getQueryVariable: function(){
		var tt = Helpers.uniteDashboardParse(window.location.href,"tt");
		var cc = Helpers.uniteDashboardParse(window.location.href,"cc");
		var data = {
			cc : cc,
			tt: tt
		};
		this.trigger("check:link",data);
	},
	goToDashboard: function(){
		window.location.href = '/';
	}
});