// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"navbar-slide-ico\">\n	<div class=\"navbar-menu-ico\">\n		<span class=\"navbar-menu-row\"></span>\n	</div>\n	<div class=\"navbar-slide-menu\">\n		<div class=\"navbar-menu-close\">\n			<div class=\"navbar-menu-ico\">\n				<span class=\"navbar-menu-row\"></span>\n			</div>\n		</div>\n		<ul class=\"navbar-slide-list\">\n			<li class=\"navbar-slide-item\"><a href=\"#?page=profile\">My profile</a></li>\n			<li class=\"navbar-slide-item\"><a class=\"active js-logout\" href=\"#\">Log out</a></li>\n		</ul>\n	</div>\n    <div class=\"b-blackout\"></div>\n</div>\n<div class=\"navbar-info\">\n	<div class=\"navbar-g-ico\">\n		<img class=\"navbar-g-ico-img\" src=\""
    + alias4(((helper = (helper = helpers.picture || (depth0 != null ? depth0.picture : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"picture","hash":{},"data":data}) : helper)))
    + "\" alt=\"\">\n		<div class=\"navbar-menu\">\n			<ul class=\"navbar-menu-list\">\n				<li class=\"navbar-menu-item\"><a class=\"active js-logout\" href=\"#\">Log out</a></li>\n			</ul>\n		</div>\n	</div>\n	<div class=\"navbar-g-info\">\n		<span class=\"navbar-info-name info-name\">"
    + alias4(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + "&nbsp;"
    + alias4(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + "</span>\n		<br>\n		<span class=\"navbar-info-email info-email\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</span>\n	</div>\n</div>";
},"useData":true});
