/**
 * Created by gila on 23.11.2015.
 */
var ProfileSecurity = require("./templates/ProfileSecurityModal.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		setPasswordBtn: ".js-set-profile-password",
		errorBox: ".js-b-error-profile-security-text"
	},
	events: {
		"click @ui.setPasswordBtn": "sendData",
		"submit form": "sendData"
	},
	template: ProfileSecurity,
	initialize: function(){
	},
	templateHelpers: function(){
		var me = this;
		return {
			questions: me.options["questions"]
		}
	},
	sendData: function (e) {
		e.preventDefault();
		this.ui.errorBox.fadeOut(300);
		var data = Backbone.Syphon.serialize(this);
		this.trigger("send:password", data);
	}
});