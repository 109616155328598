/**
 * Created by sergey on 16.12.2015.
 */
var TicketLayoutTemplate = require("./templates/TicketsLayout.hbs");
var CountTemplate = require("./templates/CountPage.hbs");
module.exports = Marionette.LayoutView.extend({
	template: TicketLayoutTemplate,
	ui: {
		countPage: ".js-inbox-table-page"
	},
	regions: {
		"ticketsRecentRegion": ".js-inbox-tickets-open-region",
		"ticketsHistoryRegion": ".js-inbox-tickets-close-region"
	},
	showCountPages: function(){
		this.ui.countPage.removeClass("b-hide-item");
	},
	hideCountPages: function(){
		this.ui.countPage.addClass("b-hide-item");
	},
	setCountPage: function(data){
		if(data.total_count < 20){
			this.ui.countPage.addClass("b-hide-item");
		}else{
			this.ui.countPage.html(CountTemplate(data));
			this.ui.countPage.removeClass("b-hide-item");
		}
	}
});