// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<ul class=\"b-payout-tabs-list\">\n    <li class=\"b-payout-tabs-item b-tabs_hover f_12 js-tab-channel-payout\">\n        <span class=\"b-payout-tabs-title\">Channels</span>\n        <div class=\"b-tabs_hover__item\">\n            <span class=\"b-sum-desc b-ib f_16 f_bold\">$"
    + alias4(((helper = (helper = helpers.channel_earnings || (depth0 != null ? depth0.channel_earnings : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_earnings","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"b-sum-desc b-ib f_12 f_light\"> - Sum earnings</span>\n        </div>\n    </li>\n    <li class=\"b-payout-tabs-item b-tabs_hover f_12 js-tab-referrals-payout\">\n        <span class=\"b-payout-tabs-title\">Referrals</span>\n        <div class=\"b-tabs_hover__item\">\n            <span class=\"b-sum-desc b-ib f_16 f_bold\">$"
    + alias4(((helper = (helper = helpers.referral_earnings || (depth0 != null ? depth0.referral_earnings : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"referral_earnings","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"b-sum-desc b-ib f_12 f_light\"> - Sum earnings</span>\n        </div>\n    </li>\n</ul>";
},"useData":true});
