// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "    <div class=\"b-content-stats__item b-content-stats-item_left\">\n        <span class=\"b-content-stats__title\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Profile","key":"qg_partner.dashboard.profile_block_name"},"data":data}))
    + "</span>\n        <br>\n        <span class=\"i-edit i-edit_sp-ico i-edit_profile-ico\"></span>\n        <span class=\"i-edit i-edit_sp-ico i-edit_profile-ico i-edit-sp-ico_invis\"></span>\n    </div>\n    <div class=\"b-content-stats__item b-content-stats-item-tight_right\">\n        <div class=\"b-content-stats__container\">\n            <div class=\"b-content-stats__container-inner\">\n                <span class=\"b-content-stats__text b-stats-name\">"
    + alias3(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + " <br> "
    + alias3(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + "</span>\n                <div class=\"b-content-stats__down-desc\">\n                    <span class=\"b-down-desc_1\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Payment method","key":"qg_partner.dashboard.profile_block_payment_method"},"data":data}))
    + ":</span>&nbsp;\n                    <span class=\"b-down-desc_2\">"
    + alias3((helpers.paymentType || (depth0 && depth0.paymentType) || alias2).call(alias1,(depth0 != null ? depth0.payment_type : depth0),{"name":"paymentType","hash":{},"data":data}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>";
},"useData":true});
