// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "views";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"b-content-stats__item b-content-stats-item_left\">\n    <span class=\"b-content-stats__title\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Channels","key":"qg_partner.dashboard.channels_block_name"},"data":data}))
    + "</span>\n    <br>\n    <span class=\"i-edit i-edit_sp-ico i-edit_channels-ico\"></span>\n    <span class=\"i-edit i-edit_sp-ico i-edit_channels-ico i-edit-sp-ico_invis\"></span>\n</div>\n<div class=\"b-content-stats__item b-content-stats-item_center\">\n    <div class=\"b-content-stats__container\">\n        <div class=\"b-content-stats__container-inner\">\n            <span class=\"b-content-stats__number\">"
    + alias3(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"count","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"b-content-stats__text\">\n                <span>"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Active","key":"qg_partner.dashboard.channels_block_name_active_channels"},"data":data}))
    + "</span> <span>"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Channels","key":"qg_partner.dashboard.active_channels_block_name"},"data":data}))
    + "</span>\n            </span>\n            <div class=\"b-content-stats__down-desc b-content-stats-desc_invis\">\n                "
    + alias3((helpers.getDiffViews || (depth0 && depth0.getDiffViews) || alias2).call(alias1,(depth0 != null ? depth0.viewsCurrentMonth : depth0),(depth0 != null ? depth0.viewsPrevMonth : depth0),{"name":"getDiffViews","hash":{},"data":data}))
    + " \n                <span class=\"b-down-desc_2\">\n                     "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"vs prior 30 days","key":"qg_partner.vs_period_30_days"},"data":data}))
    + "\n                </span>\n            </div>\n        </div>\n    </div>\n</div>\n<div class=\"b-content-stats__item b-content-stats-item_right\">\n    <div class=\"b-content-stats__container\">\n        <div class=\"b-content-stats__container-inner\">\n            <span>"
    + alias3((helpers.getViews || (depth0 && depth0.getViews) || alias2).call(alias1,(depth0 != null ? depth0.viewsCurrentMonth : depth0),{"name":"getViews","hash":{},"data":data}))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.viewsCurrentMonth : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</span><br>\n            "
    + alias3((helpers.getDiffViews || (depth0 && depth0.getDiffViews) || alias2).call(alias1,(depth0 != null ? depth0.viewsCurrentMonth : depth0),(depth0 != null ? depth0.viewsPrevMonth : depth0),"vs prior 30 days",{"name":"getDiffViews","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n</div>";
},"useData":true});
