/**
 * Created by sergey on 23.11.2015.
 */
module.exports = Backbone.Model.extend({
	url: GlobalConfig.urls.api_dashboard_get_params,
	parse: function(resp){
		return resp.body;
	},
	agreeUniteDashboard: function(data){
		var handlerSuccess = function(){
			me.trigger("unite:dashboard:success");
		};
		var me = this;
		$.ajax({
			url: "/api/requests/agree_union",
			method: "POST"
		}).then(handlerSuccess)
	},
	declineUnite: function(data){
		var me = this;
		$.ajax({
			url: "/api/requests/decline_union",
			method: "POST"
		})
	}
});