var Step1Template = require("./template/Step1.hbs");

module.exports = Marionette.LayoutView.extend({
	ui: {
		payTypeBtn: ".j-pay-type-btn"
	},
	events: {
		"click @ui.payTypeBtn" : "changePayType"
	},
	className: "wrapper_n",
	template: Step1Template,
	onShow: function(){
	},
	changePayType: function(e){
		var payType = $(e.currentTarget).data("type");
		this.$el.find('.j-pay-block').hide();		
		if(payType == 11){
			this.$el.find('.j-payoneer-block').show();
		}else if(payType == 7){
			this.$el.find('.j-wm-block').show();
		}else{
			this.trigger("bank:show");
		}
	}
});