/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var ChannelDashboardPanelTemplate = require("./templates/ChanelsBlock.hbs");


Views.ChannelDashboardBlockView = Marionette.ItemView.extend({
	templateHelpers: function(){
		var me = this;
		return {
			count: me.options["count"],
			viewsCurrentMonth: me.options["viewsCurrentMonth"],
			viewsPrevMonth: me.options["viewsPrevMonth"]
		}
	},
	template: ChannelDashboardPanelTemplate,
	tagName: "a",
	attributes:{
		href: "#?page=channels"
	}
});
module.exports = Marionette.Object.extend({
	createView: function(region, respCount, respCurrentMonth, respPrevMonth){
		this.channelDashboardBlock = new Views.ChannelDashboardBlockView({count: respCount[0].body.result_count.active_channels,
			viewsCurrentMonth: respCurrentMonth[0].body.views,	viewsPrevMonth: respPrevMonth[0].body.views});
		region.show(this.channelDashboardBlock);
	},
	show: function(region){
		var  prevMonth = 1,
			 prevYear = new Date().getFullYear();
		var me = this,
			month = Radio.channel("login").request("user:month:of:payment"),
			year = Radio.channel("login").request("user:year:of:payment");
		if(month !== 1){
			prevMonth = month - 1;
			prevYear = year;
		}else{
			prevMonth = 12;
			prevYear = year - 1;
		}
		var promises = [];
		promises.push($.get(GlobalConfig.urls.api_dashboard_get_channel_list+"?only_count=true"));
		promises.push($.get("/api/analytics/get_views?year="+year+"&month="+month));
		promises.push($.get("/api/analytics/get_views?year="+prevYear+"&month="+prevMonth));
		$.when.apply($, promises).done(me.createView.bind(me, region))
	}
});