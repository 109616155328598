/**
 * Created by gila on 23.11.2015.
 */
var ProfilePasswordAccessTemplate = require("./templates/ProfilePasswordAccess.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		setPasswordBtn: ".js-profile-password-access-set",
		errorBox: ".js-b-error-profile-password-access-text",
		passOkBtn: ".b-pass-access-ok",
		passUniqueFormHide: ".b-pass-access-hide",
		passUniqueFormShow: ".b-pass-access-ok",
		passDisableBtn: ".b-disable-access-option-btn",
		passFormShow: ".b-show-form-btn",
		passFormHide: ".b-hide-form-btn",
		loginLink: ".js-additional-profile-settings-pass-access-login-link",
		hideErrorBtn: ".b-button_no"
	},
	events: {
		"click @ui.setPasswordBtn": "sendData",
		"submit form": "sendData",
		"click @ui.passOkBtn": "hideUniqueForm",
		"click @ui.passUniqueFormHide": "hidePassAccessForm",
		"click @ui.passDisableBtn": "disablePassAccess",
		"click @ui.passFormShow": "passAccessFormShow",
		"click @ui.passFormHide": "passAccessFormHide",
		"click @ui.hideErrorBtn": "hideError"
	},
	triggers: {
		"click @ui.loginLink": "user:go:to:login:dashboard"
	},
	template: ProfilePasswordAccessTemplate,
	templateHelpers: function(){
		var me = this;
		return {
			questions: me.options["questions"]
		}
	},
	sendData: function (e) {
		e.preventDefault();
		this.ui.errorBox.fadeOut(300);
		var data = Backbone.Syphon.serialize(this);
		this.trigger("send:question", data);
	},
	showUniqueForm: function(data){
		$('.b-unique-list').html('');
		var count = 1;
		$.each(data, function(i,item){
			if(i%2 != 0){
				$('.b-unique-list').append('<li class="b-unique-item"><span class="b-unique-number">'+count+'. </span>'+data[i]+'</li>');
				count++;		
			}
		});
		this.$el.find('.b-pass-access-form').slideUp(300);
		this.$el.find('.b-unique-form').slideDown(300);
	},
	hideUniqueForm: function(){
		this.$el.find('.b-pass-access-info').html('The "Password access" ' +
			'option has been successecfully activated. ' +
			'To log into your dashboard via username and password go to the <a target="_blank" class="js-additional-profile-settings-pass-access-login-link" href="#">Dashboard login</a> link. ' +
			'Please use only that link to login via username/ password. If you login at <a href="/">join.quizgroup.com</a>, you should sign in your Google-account.'
		);
		this.$el.find('.b-unique-list').fadeOut(100);
		this.$el.find('.valid-codes').fadeOut(100);
		this.ui.passOkBtn.hide();
		this.ui.passUniqueFormHide.show();
		// this.$el.find('#pass_access').prop("disabled",true);
	},
	hidePassAccessForm: function(e){
		this.$el.find('.b-unique-form').slideUp(300);
		if($(e.currentTarget).hasClass('b-pass-access-hide-2')){
			this.$el.find('.b-pass-access-dis').hide();	
			this.$el.find('.b-pass-access-active').show();
		}else{
			this.$el.find('.b-pass-access-dis').show();	
			this.$el.find('.b-pass-access-active').hide();
		}
		this.ui.passUniqueFormHide.hide();
		this.ui.passUniqueFormShow.show();
		this.$el.find('#pass_access').removeClass('_show');
		this.$el.find('.b-change-link').text('Disable');
		this.$el.find('.additional-success').removeClass('b-hide-item');
		this.$el.find('.b-settings-row__item').removeClass('_show');
	},
	disablePassAccess: function(e){
		this.trigger("disable:access");
		$(e.currentTarget).closest('.b-agree-form').slideUp(300);
		this.$el.find('#pass_access').removeClass('_show');
		this.$el.find('.b-change-link').text('Enable');
		this.$el.find('.additional-success').addClass('b-hide-item');
		this.$el.find('.b-pass-access-dis').hide();
		this.$el.find('.b-pass-access-active').show();
		this.$el.find('.b-unique-list').show();
		this.$el.find('.valid-codes').show();
		this.$el.find('.b-pass-access-info').html('Save your unique codes:')
	},
	passAccessFormShow: function(){
		this.$el.find('.b-settings-row__item').removeClass('_show');
		this.$el.find('.b-pass-access-active').hide();
		this.$el.find('.b-pass-access-act').show();
	},
	passAccessFormHide: function(){
		this.$el.find('.b-pass-access-active').show();
		this.$el.find('.b-pass-access-act').hide();
	},
	hideError: function(){
		this.ui.errorBox.fadeOut(300);
	}
});