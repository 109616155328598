var PayoutTemplate = require("./templates/PayoutLayout.hbs");
var CountTemplate = require("./templates/CountPage.hbs");

module.exports = Marionette.LayoutView.extend({
	className: "wrapper_n",
	template: PayoutTemplate,
	ui: {
		changeSearch: ".b-reports-change",
		moreBtn: ".js-payout-more-btn",
		countPage: ".js-payout-table-page",
		upBtn: ".b-up-button",
		payoutBLock: ".b-payout-block",
		resetBtn: ".js-reset-payout-filter"
	},
	regions: {
		toolbarMonthRegion: ".js-toolbar-month",
		toolbarYearRegion: ".js-toolbar-year",
		payoutHeaderRegion: ".js-header-payout",
		payoutTableRegion: ".js-payout-table",
		toolbarRegion: ".js-payout-advanced-toolbar"
	},
	events: {
		"click @ui.moreBtn"	: "onMoreBtnClick",
		"click @ui.resetBtn": "onResetFilterBtn",
		"click @ui.changeSearch": "showConditions"
	},
	onShow: function(e){
	},
	onMoreBtnClick: function () {
		this.trigger("more:btn:click");
	},
	showMoreBtn: function(){
		this.ui.moreBtn.removeClass("b-hide-item");
	},
	hideMoreBtn: function(){
		this.ui.moreBtn.addClass("b-hide-item");
	},
	showResetBtn: function(){
		this.ui.resetBtn.removeClass("b-hide-item");
	},
	hideResetBtn: function(){
		this.ui.resetBtn.addClass("b-hide-item");
	},
	showCountPages: function(){
		this.ui.countPage.removeClass("b-hide-item");
		this.ui.payoutBLock.addClass("b-show-count");
	},
	hideCountPages: function(){
		this.ui.countPage.addClass("b-hide-item");
		this.ui.payoutBLock.removeClass("b-show-count");
	},
	setCountPage: function(data){
		if(data.total_count < 20){
			this.ui.countPage.addClass("b-hide-item");
			this.ui.payoutBLock.removeClass("b-show-count");
		}else{
			this.ui.countPage.html(CountTemplate(data));
			this.ui.countPage.removeClass("b-hide-item");
			this.ui.payoutBLock.addClass("b-show-count");
		}
	},
	onResetFilterBtn: function () {
		this.hideResetBtn();
		this.trigger("user:click:reset:btn")
	},
	showConditions: function(){
		this.ui.changeSearch.fadeOut(200);
		this.$el.find('.b-settings-open').addClass('open');
	},
	hideConditions: function(){
		this.ui.changeSearch.fadeIn(200);
		this.$el.find('.b-settings-open').removeClass('open');
	}
});