/**
 * Created by sergey on 20.11.2015.
 */

var AppConfig = {
	code401: function(){

	},
	code400: function(){

	},
	code403: function(){

	},
	code500: function(){

	},
	code502: function(){
		Radio.channel("error").trigger("502:notify");
	}
};

module.exports = function(ctx) {
	//Setup jquery ajax requests
	var me =  this;
	var backboneSync = Backbone.sync;
	Backbone.sync = function(method, model, options) {
		var opt = {};
		_.extend(opt,
			{
				statusCode:{
					400:function(xhr, model, options) {
						AppConfig.code400(xhr, model, options, ctx);
					},
					401:function(xhr, model, options) {
						AppConfig.code401(xhr, model, options, ctx);
					},
					500:function(xhr, model, options) {
						AppConfig.code500(xhr, model, options, ctx);
					},
					502:function(xhr, model, options) {
						AppConfig.code502(xhr, model, options, ctx);
					}
			}
		});
		_.extend(opt, options);

		backboneSync(method, model, opt);
	};
	$.ajaxSetup({
		statusCode: {
			400: AppConfig.code401.bind(me, ctx),
			401: AppConfig.code400.bind(me, ctx),
			403: AppConfig.code403.bind(me, ctx),
			500: AppConfig.code500.bind(me, ctx),
			502: AppConfig.code502.bind(me, ctx)
		},
		cache: false
	});

	$.ajaxPrefilter(function(options, originalOptions, jqXHR) {
			options.xhrFields = {
				withCredentials: true,
				cache: false
			}
		}
	);
};