// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<span class=\"b-pament-status b-status-"
    + alias3((helpers.getPayoutStatus || (depth0 && depth0.getPayoutStatus) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"getPayoutStatus","hash":{},"data":data}))
    + " b-download-link right\" data-desc=\""
    + alias3((helpers.getPayoutDesc || (depth0 && depth0.getPayoutDesc) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"getPayoutDesc","hash":{},"data":data}))
    + "\">"
    + alias3(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</span>";
},"useData":true});
