/**
 * Created by sergey on 28.12.2015.
 */
AdvanceReportModel = require("../models/AdvanceReport/AdvanceReport");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getAdvanceReport: function(options){
		var params = options || {};
		var advanceReportModel = new AdvanceReportModel(params);
		return advanceReportModel.getData();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("advancereport:advancereport:entity", function(options){
			return me.getAdvanceReport(options);
		});
	}
};