/**
 * Created by sergey on 28.12.2015.
 */
var DiagramTemplate = require("./templates/AdvanceReportDiagram.hbs");
var Helpers = require("helpers/Helpers");
module.exports = Marionette.ItemView.extend({
	initialize: function () {
		this.id = _.uniqueId('diagram_');
	},
	templateHelpers: function () {
		var me = this;
		return {
			id: me.id
		}
	},
	template: DiagramTemplate,
	onShow: function () {
		var me = this;
		this.chart = new Highcharts.Chart(this.getConfig());
	},
	getConfig: function () {
		var me = this;
		var data =  me.getData();
		return {
			chart: {
				renderTo: me.id,
				type: 'column',
				margin: 75,
				height: 400,
				backgroundColor: "#FFFFFF"
			},
			title: {
				text: ' '
			},
			xAxis: {
				categories: me.getTickets(),
				gridLineWidth: 0
			},
			yAxis: {
				title: {
					text: '$, USD'
				}
			},
			plotOptions: {
				column: {
					depth: 25
				}
			},
			legend: {
				verticalAlign: 'top'
			},
			series: [{
				name: "Earnings",
				color:'rgb(251,64,0)',
				data: data[0]
			},{
				name: "Payments",
				color:'rgb(39,178,159)',
				data: data[1]
			}]
		}
	},
	getTickets: function(){
		return this.model.getTickets();
	},
	refreshData: function(){
		this.chart.destroy();
		this.chart = new Highcharts.Chart(this.getConfig())
	},
	getData: function(){
		return this.model.getDiagramData();
	}
});