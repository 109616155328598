/**
 * Created by sergey on 01.12.2015.
 */
module.exports = Backbone.Model.extend({
	url: "/api/account/pswd_login",
	validation: {
		"login": {
			required: true
		},
		"password": {
			required: true
		}
	},
	sendLogin: function(data, options){
		var me = this;
		this.validation = {};
		this.validation = {
			"login": {
				required: true
			},
			"password": {
				required: true
			}
		};
		this.set({password: data.pswd, login: data.login});
		if(this.isValid(true)){
			$.ajax({
				type: "POST",
				url: "/api/account/pswd_login",
				data: {password: me.get("password"), login: me.get("login")},
				dataType: "json"
			}).then(options.success, options.fail);
		}
	},
	sendCode: function(data, options){
		var me =  this;
		this.validation = {};
		this.validation.code = {
			required: true
		};
		this.set({code: data});
		if(this.isValid(true)){
			$.ajax({
				type: "POST",
				url: "/api/account/code_check",
				data: {code: me.get("code")},
				dataType: "json"
			}).then(options.success, options.fail);
		}
	}
});