// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-music-row\" id=\"track_"
    + alias4(((helper = (helper = helpers.fileNameOnly || (depth0 != null ? depth0.fileNameOnly : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileNameOnly","hash":{},"data":data}) : helper)))
    + "\" data-play=\""
    + alias4(((helper = (helper = helpers.fileNameOnly || (depth0 != null ? depth0.fileNameOnly : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileNameOnly","hash":{},"data":data}) : helper)))
    + "\" data-url=\""
    + alias4(((helper = (helper = helpers.previewUrl || (depth0 != null ? depth0.previewUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"previewUrl","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"b-music-item b-music-left\">\n		<div class=\"b-play b-ib\" id=\"play_"
    + alias4(((helper = (helper = helpers.fileNameOnly || (depth0 != null ? depth0.fileNameOnly : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileNameOnly","hash":{},"data":data}) : helper)))
    + "\">\n			<span class=\"i-edit i-edit-play\"></span>\n			<span class=\"i-edit i-edit-play i-edit-invis i-edit-play-invis\"></span>\n		</div>\n		<div class=\"b-music-name b-ib\">\n			<span class=\"b-music-titles\">"
    + alias4(((helper = (helper = helpers.metaTitle || (depth0 != null ? depth0.metaTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"metaTitle","hash":{},"data":data}) : helper)))
    + "</span><br>\n			<span class=\"b-music-artist\">"
    + alias4(((helper = (helper = helpers.artist || (depth0 != null ? depth0.artist : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"artist","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n	</div>\n	<div class=\"b-music-item b-music-center\">\n		<label>Format:&nbsp;</label><span class=\"b-music-format\">"
    + alias4(((helper = (helper = helpers.fileType || (depth0 != null ? depth0.fileType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileType","hash":{},"data":data}) : helper)))
    + "</span>&nbsp;|\n		<label>Long:&nbsp;</label><span class=\"b-music-long\">"
    + alias4(((helper = (helper = helpers.trackLength || (depth0 != null ? depth0.trackLength : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackLength","hash":{},"data":data}) : helper)))
    + "</span>&nbsp;|\n		<label>BitRate:&nbsp;</label><span class=\"b-music-long\">"
    + alias4(((helper = (helper = helpers.bitRate || (depth0 != null ? depth0.bitRate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bitRate","hash":{},"data":data}) : helper)))
    + "</span>&nbsp;|\n		<label>Refresh Rate KHz:&nbsp;</label><span class=\"b-music-long\">"
    + alias4(((helper = (helper = helpers.refreshRateKHz || (depth0 != null ? depth0.refreshRateKHz : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"refreshRateKHz","hash":{},"data":data}) : helper)))
    + "</span>\n		<p class=\"b-music-tags "
    + alias4((helpers.tagsDots || (depth0 && depth0.tagsDots) || alias2).call(alias1,(depth0 != null ? depth0.tags : depth0),{"name":"tagsDots","hash":{},"data":data}))
    + "\"><span class=\"b-tags-content\" id=\"tags_"
    + alias4(((helper = (helper = helpers.fileID || (depth0 != null ? depth0.fileID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileID","hash":{},"data":data}) : helper)))
    + "\"><label>Tags:&nbsp;</label> "
    + alias4(((helper = (helper = helpers.tags || (depth0 != null ? depth0.tags : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tags","hash":{},"data":data}) : helper)))
    + "</span><span class=\"b-tags-dots\">...</span></p>\n		<p class=\"b-music-tags "
    + alias4((helpers.tagsDots || (depth0 && depth0.tagsDots) || alias2).call(alias1,(depth0 != null ? depth0.details : depth0),{"name":"tagsDots","hash":{},"data":data}))
    + "\"><span class=\"b-tags-content\" id=\"desc_"
    + alias4(((helper = (helper = helpers.fileID || (depth0 != null ? depth0.fileID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileID","hash":{},"data":data}) : helper)))
    + "\"><label>Description:&nbsp;</label> "
    + alias4(((helper = (helper = helpers.details || (depth0 != null ? depth0.details : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"details","hash":{},"data":data}) : helper)))
    + "</span><span class=\"b-tags-dots\">...</span></p>\n		<br>\n		"
    + alias4((helpers.textCount || (depth0 && depth0.textCount) || alias2).call(alias1,(depth0 != null ? depth0.tags : depth0),(depth0 != null ? depth0.details : depth0),{"name":"textCount","hash":{},"data":data}))
    + "\n	</div>\n	<div class=\"b-music-item b-music-right\">\n		<button class=\"b-music-download f_18\" data-id=\""
    + alias4(((helper = (helper = helpers.fileID || (depth0 != null ? depth0.fileID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileID","hash":{},"data":data}) : helper)))
    + "\" >Download</button>\n		<button class=\"b-music-star "
    + ((stack1 = (helpers.isExistInArray || (depth0 && depth0.isExistInArray) || alias2).call(alias1,(depths[1] != null ? depths[1].selectedIds : depths[1]),(depth0 != null ? depth0.fileID : depth0),{"name":"isExistInArray","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.fileID || (depth0 != null ? depth0.fileID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileID","hash":{},"data":data}) : helper)))
    + "\">\n			<span class=\"i-edit\"></span>\n			<span class=\"i-edit i-edit-invis\"></span>\n		</button>\n	</div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<h2 id=\"b1\"></h2>";
},"useData":true,"useDepths":true});
