/**
 * Created by gila on 14.12.2015.
 */
var MusicListTemplate = require("./templates/MusicList.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		circleItem: ".b-play",
		showAllTags: ".b-tags-show",
		starredBtn: ".b-music-star"
	},
	events: {
		"click @ui.circleItem":"playMusic",
		"click @ui.showAllTags":"showTags",
		"click @ui.starredBtn":"onClickStar"
	},
	template: MusicListTemplate,
	progressBar: 0,
	initialize: function(){
		this.listenTo(this.collection, "reset", this.render);
	},
	onRender: function(){
		this.ui.circleItem.circleProgress({
            size: 66,
            animation: false,
            fill: {
                color: "#fb4000"
            }
		});
	},
	templateHelpers: function(){
		var me = this;
		return {
			selectedIds: me.options["selectedIds"]
		};
	},
	playMusic: function(e){
		var me = this;
		var itemId = $(e.currentTarget).closest('.b-music-row').data('play');
		var itemUrl = $(e.currentTarget).closest('.b-music-row').data('url');
		soundManager.createSound({
			id: itemId,
			url: itemUrl,
			whileplaying: function() {
				$('#play_'+itemId).circleProgress({value: this.position/this.duration});
			},
			onfinish: function() {
			    $('.b-music-row').removeClass('play');
			    $('.b-play').removeClass('pause').removeClass('play');
			}
		});
		if($('#track_'+itemId).hasClass('play')){
			if($('#play_'+itemId).hasClass('pause')){
				soundManager.pause(itemId);
				$('#play_'+itemId).removeClass('pause').addClass('play');
			}else{
				soundManager.play(itemId);
				$('#play_'+itemId).addClass('pause').removeClass('play');
			}
		}else{
			soundManager.stopAll();
			soundManager.play(itemId);
			$('.b-music-row').removeClass('play');
			$('.b-play').removeClass('pause').removeClass('play');
			$('#play_'+itemId).addClass('pause');
			$('#track_'+itemId).addClass('play');
		}
	},
	showTags: function(e){
		var showLink = $(e.currentTarget);
		showLink.toggleClass('_open');
		showLink.closest('.b-music-item').find('.b-music-tags').toggleClass('_show');
		if(showLink.hasClass('_open')){
			showLink.html('hide <<');
		}else{
			showLink.html('show all >>');
		}
	},
	onClickStar: function(e){
		var target = $(e.currentTarget);
		var id = target.data("id");
		var item = this.collection.findWhere({fileID: String(id)});
		target.toggleClass("selected");
		item.set("selected", $(e.currentTarget).hasClass("selected"));
		this.trigger("user:click:track", item.toJSON());
	}
});