/**
 * Created by gila on 23.11.2015.
 */
var Helpers = require("helpers/Helpers");
var ProfileUniteDashboardTemplate = require("./templates/ProfileUniteDashboardForm.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		uniteBtn: ".js-unite-btn",
		errorBox: ".error-box"
	},
	events: {
		"click @ui.uniteBtn": "sendData",
		"submit form": "sendData"
	},
	template: ProfileUniteDashboardTemplate,
	initialize: function(){

		//this.listenTo(this.model, "validated:invalid", this.showServerError);
	},
	templateHelpers: function(){
		var me = this;
		return {
			questions: me.options["questions"]
		}
	},
	onShow: function(){
		var me = this;
	},
	showValidationError: function(view, attr){
		var $el = view.$("[name='"+attr+"']");
		$el.addClass('b-input-error');
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$("[name='"+attr+"']");
		$el.removeClass('b-input-error');
	},
	sendData: function (e) {
		e.preventDefault();
		this.trigger("unite:dashboard");
	}
});