'use strict';



var Handlebars = require('hbsfy/runtime');

Handlebars.registerHelper('log', function (data) {

});

Handlebars.registerHelper('dateOfBorn', function (context, options) {
	if(!context){
		return;
	}
	var result = context.split("-");
	return options.fn({year: result[0], month: result[1], day: result[2]});
});

Handlebars.registerHelper('getCountryOnCode', function (countries, code) {
	var result = _.findWhere(countries, {"code2": code});
	if(!result){
		return "";
	}
	return result.country_name;
});


Handlebars.registerHelper('select', function(value, options){
	var $el = $('<select />').html( options.fn(this) );
	$el.find('[value=' + value + ']').attr({'selected':'selected'});
	return $el.html();
});

Handlebars.registerHelper('ifCond', function(v1, v2, options) {
	if(v1 === v2) {
		return options.fn(this);
	}
	return options.inverse(this);
});

Handlebars.registerHelper('unlessCond', function(v1, v2, options) {
	if(v1 !== v2) {
		return options.fn(this);
	}
	return options.inverse(this);
});

Handlebars.registerHelper('paymentType', function(value, options) {
	var result = "";
	if(value == 1){
		result = "Bank";
	}else if(value == 7){
		result = "WebMoney";
	}else if(value == 11) {
		result = "Payoneer";
	}
	return result;
});

Handlebars.registerHelper('getPayonnerStatus', function(value) {
	var result = "";
	if(value == 10){
		result = "Approved";
	}else if(value >= 2 && value <=9){
		result = "Pending";
	}else if(value == 1){
		result = "Pending";
	}else if(value < 0){
		result = "Denied";
	}
	return result;
});

Handlebars.registerHelper('getPayoneerInfo', function(value,text,url) {
	var url =  Handlebars.Utils.escapeExpression('https://payoneer.custhelp.com/app/Contact_us');
	var result = "";
	if(value == 10){
		result = "Your Payoneer account has been successfully approved.";
	}else if(value >= 2 && value <=9){
		result = "At the moment your application is being reviewed by Payoneer. As soon as review is completed the status will be changed to \"Approved\". Please note that approvement process may take up to several business days for new Payoneer accounts.";
	}else if(value == 1){
		result = "At the moment your application is being reviewed by Payoneer. As soon as review is completed the status will be changed to \"Approved\". Please note that approvement process may take up to several business days for new Payoneer accounts.";
	}else if(value < 0){
		result = 'Your account has been rejected by Payoneer. Please contact Payoneer at <a class="b-page-link" target="_blank" href="'+url+'">'+url+'</a>';
	}
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('getDashboardNews', function(description) {
	return new Handlebars.SafeString(description);
});

Handlebars.registerHelper('getPayoutStatus', function(value) {
	var result = "";
	if(!value){
		result = "pending";
	}else if(value < 0){
		result = "rejected";
	}else if(value >= 200){
		result = "ok";
	}
	return result;
});

Handlebars.registerHelper('getPayoutDesc', function(value) {
	var desc = "";
	if(!value){
		desc = "Report is under process";
	}else if(value < 0){
		desc = "Payment will be made on 20-27th of {month}";
	}else if(value >= 200){
		desc = "Payment has been made";
	}
	return desc;
});

Handlebars.registerHelper('getMonth', function(value) {
	var month = {
		"1": "January",
		"2": "February",
		"3": "March",
		"4": "April",
		"5": "May",
		"6": "June",
		"7": "July",
		"8": "August",
		"9": "September",
		"10": "October",
		"11": "November",
		"12": "December"
	};
	return i18n.t(month[String(value)], {defaultValue: month[String(value)]});
});


Handlebars.registerHelper('showDiffMoney', function(value1, value2, str) {
	var text = "";
	if(typeof str === "string"){
		text =  i18n.t("qg_partner.VS_period_30_days", {defaultValue: Handlebars.Utils.escapeExpression(str)});
	}
	var diff = value1 - value2;
	var result =  parseFloat(Math.abs(diff)).toFixed(2);
	var html = "";
	if(diff == 0){
		html = "";
	}else if(diff > 0){
		html ='<span class="b-down-desc_1 b-desc-margin b-arrow b-arrow_w b-arrow_up b-earning-span-count">&#36;'+result+" "+text+'</span>';
	}else if(diff < 0){
		html ='<span class="b-down-desc_1 b-desc-margin b-arrow b-arrow_w b-arrow_down b-earning-span-count">&#36;'+result+" "+text+'</span>';
	}
	return new Handlebars.SafeString(html);
});


Handlebars.registerHelper('getDiffViews', function(value1, value2, str) {
	var text = "";
	if(typeof str === "string"){
		text =  i18n.t("qg_partner.VS_period_30_days", {defaultValue: Handlebars.Utils.escapeExpression(str)});
	}
	var diff = value1 - value2;
	var result = Math.floor(Math.abs(diff));
	var html = "";
	var arrayPower = [1, 1000, 1000000, 1000000000];
	var moneyMap = {0: "", 1: "K", 2: "M", 3: "B"};
	var index = _.findIndex(arrayPower, function(item) {
		return item > result
	});
	if(index <= 0) {
		result = String(result);
	}else {
		result = parseFloat(String(result / arrayPower[index - 1])).toFixed(1) + " " + moneyMap[index - 1];
	}
	if(diff == 0){
		html = "";
	}else if(diff > 0){
		html ='<span class="b-down-desc_1 b-desc-margin b-arrow b-arrow_w b-arrow_up">'+result+" "+text+'</span>';
	}else if(diff < 0){
		html ='<span class="b-down-desc_1 b-desc-margin b-arrow b-arrow_w b-arrow_down">'+result+" "+text+'</span>';
	}
	return new Handlebars.SafeString(html);
});

Handlebars.registerHelper('getViews', function(value1) {
	var diff = value1;
	var text = "";
	var result = Math.floor(Math.abs(diff));
	var html = "";
	var arrayPower = [1, 1000, 1000000, 1000000000];
	var moneyMap = {0: "", 1: "K", 2: "M", 3: "B"};
	var index = _.findIndex(arrayPower, function(item) {
		return item > result
	});
	if(diff){
		if(index <= 0) {
			result = String(result);
		}else {
			result = parseFloat(String(result / arrayPower[index - 1])).toFixed(1) + " " + moneyMap[index - 1];
		}
	}else{
		result = "";
	}
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('sum', function() {
		var args = _.flatten(arguments);
		var sum = 0;
		var i = args.length - 1;
		while (i--) {
			sum +=  Number(args[i]) || 0;
		}
		return Number(sum);
});


Handlebars.registerHelper('tr', function(context, options) {
	var opts = i18n.functions.extend(options.hash, context);
	if (options.fn) opts.defaultValue = options.fn(context);
	var result = i18n.t(opts.key, opts);
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('translate', function(options) {
	return options.fn(this);
});

Handlebars.registerHelper('textCount', function(value, value2) {
	var result = '';
	var text = Handlebars.Utils.escapeExpression('show all >>');
	if(value){
		if(value.length > 80 || value2.length > 80){
			result = '<div class="b-page-link b-tags-show">'+text+'</div>';
		}
	}
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('tagsDots', function(value) {
	var result = '';
	var text = Handlebars.Utils.escapeExpression('b-add-dots');
	if(value.length > 80 ){
		result = text;
	}
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('shortNews', function(value) {
	var result = '';
	var text = Handlebars.Utils.escapeExpression('b-hide-item');
	if(value.length < 176 ){
		result = text;
	}else {
		
	}
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('compare', function(lvalue, rvalue, options) {
	var operators = {
		'==':       function(l,r) { return l == r; },
		'===':      function(l,r) { return l === r; },
		'!=':       function(l,r) { return l != r; },
		'<':        function(l,r) { return l < r; },
		'>':        function(l,r) { return l > r; },
		'<=':       function(l,r) { return l <= r; },
		'>=':       function(l,r) { return l >= r; },
		'typeof':   function(l,r) { return typeof l == r; }
	};
	var operator = options.hash.operator || "==";
	var result = operators[operator](lvalue,rvalue);


	if (operators[operator]){

		if( result ) {
			return options.fn(this);
		} else {
			return options.inverse(this);
		}
	}else{
		return options.fn(this);
	}
});

Handlebars.registerHelper('math', function(lvalue, operator, rvalue) {
	lvalue = parseFloat(lvalue);
	rvalue = parseFloat(rvalue);
	return {
		"+": lvalue + rvalue,
		"-": lvalue - rvalue,
		"*": lvalue * rvalue,
		"/": lvalue / rvalue,
		"%": lvalue % rvalue
	}[operator];
});


Handlebars.registerHelper('generatePagerRange', function(navbegin, nav_end, page_active) {
	var result = "";
	for(var i=navbegin, l=nav_end; i<=l; i++) {
		if(i==page_active){
			result = result + "<li class='b-pagination-item'><a class='b-change-page-link active js-pagination-page' data-page='1'>" + i + "</a></li>";
		}else{
			result = result + "<li class='b-pagination-item'><a class='b-change-page-link on js-pagination-page' data-page='" + i + "'>" + i + "</a></li>";
		}
	}
	return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('isExistInArray', function(array, id, options) {
	if(array){
		if(array.indexOf(id) > -1){
			return options.fn(this);
		}else{
			return options.inverse(this);
		}
	}
	return options.inverse(this);

});

Handlebars.registerHelper('getCheckReport', function (value) {
	var result = "";
	if(value === -2){
		result = "New report will be available after the 15th of "
	}else if(value == 1){
		result = "New report is available";
	}
	return result;
});