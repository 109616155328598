var MarkupTemplate = require("./templates/MarkupShow.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		changeSearchBtn: ".b-change-search",
		cancelSearchBtn: ".cearch-cancel",
		tableCpm: ".b-table",
		tabsItem: ".b-tabs-item"
	},
	events: {
		"click @ui.changeSearchBtn": "openChangeSearch",
		"click @ui.cancelSearchBtn": "closeChangeSearch",
		"click @ui.tabsItem": "changeContent"
	},
	className: "wrapper_n",
	template: MarkupTemplate,
	onShow: function(e){
		this.ui.tableCpm.dataTable();
	},
	openChangeSearch: function(){
		this.$el.find('.b-settings-close').slideUp(300);
		this.$el.find('.b-settings-open').slideDown(300);
	},
	closeChangeSearch: function(){
		this.$el.find('.b-settings-open').slideUp(300);
		this.$el.find('.b-settings-close').slideDown(300);
	},
	changeContent: function(e){
		var id = $(e.currentTarget).attr('data-tab');
		var $el = this.$el;
		$el.find('.b-box').removeClass('visible');
		$el.find('.b-box-'+id+'').addClass('visible');
		$el.find('.b-tabs-item').removeClass('b-tabs-active');
		$el.find('.b-'+id+'-tab').addClass('b-tabs-active');
	}
});