var JoinModal = require("./template/JoinModal.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		
	},
	events: {
		
	},
	className: "modal-wrap modal-bank-wrap",
	template: JoinModal,
	onShow: function(){
		// this.ui.modalScroll.niceScroll();
	}
});