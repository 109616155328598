var DashboardSetPassLayout = require("./templates/DashboardSetPassLayout.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		sendBtn: ".js-set-pass-submit",
		notify: "password-notify-error"
	},
	events: {
		"click @ui.sendBtn": "setPass",
		"submit .js-set-pass-submit": "setPass"
	},
	className: "wrapper_n",
	template: DashboardSetPassLayout,
	setPass: function(e){
		e.preventDefault();
		var password = $(".js-new-pass").val();
		var repassword = $(".js-confirm-new-pass").val();
		this.trigger("set:pass", password, repassword);
	},
	onShow: function(){
		
	},
	showPasswordError: function(){
		this.ui.notify.show();
	},
	passError: function(input){

		var $el = this.$el.find('.js-dashboard-password-login-code');
		$el.addClass('login-error');
		$el.find('.b-page-button').removeClass('b-button_yes').addClass('b-button_no');
		$el.find('.b-login-error-block').slideDown(300);
		$el.find('.b-login-error-block').text(text);
	}
});