/**
 * Created by sergey on 19.11.2015.
 */
var newsModel = require("../../models/Dashboard/News");
module.exports = Backbone.Collection.extend({
	model: newsModel,
	initialize: function(options){
		this.options = options;
	},
	url: function(){
		var url = GlobalConfig.urls.api_inbox_news_list;
		if(this.options.filter){
			var result = "";
			_.forIn(this.options.filter, function(value, key) {
				result+= key+"="+value+"&";
			});
			url = url+"?"+result;
		}
		return url;
	},
	parse: function(resp){
		var list = resp.body.answer;
		this.countNews = resp.body.count;
		return list;
	}
});