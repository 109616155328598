/**
 * Created by artem.eroshin on 17.03.2016.
 */
var HomeTemplate = require("./templates/HomeShow.hbs");
module.exports = Marionette.ItemView.extend(
{
        ui:
        {
                joinQBtn: ".b-join-button"
        },
        events:
        {
                //"click @ui.joinQBtn" : "onClickLogin"
        },
        className: "wrapper_n no_p",
        template: HomeTemplate,
        onClickLogin: function()
        {
                Radio.channel('login').trigger('click:login');
        }
});

