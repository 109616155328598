// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.channel_list : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.channel_id || (depth0 != null ? depth0.channel_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.year_range : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                     <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.month_range : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <option value=\""
    + alias4(((helper = (helper = helpers.month_value || (depth0 != null ? depth0.month_value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month_value","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.month_name || (depth0 != null ? depth0.month_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month_name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-settings-open\">\n    <div class=\"b-cpm-select b-ib\">\n        <label class=\"b-page-label\">Channel name</label>\n        <br>\n        <select class=\"b-page-input b-page-select b-channel-select js-cpm-search-channel\">\n"
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,(depth0 != null ? depth0.channel_id : depth0),{"name":"select","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"b-cpm-select b-ib\">\n        <label class=\"b-page-label\">Year</label>\n        <br>\n        <select class=\"b-page-input b-page-select b-page-input_xs js-cpm-search-year\">\n"
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,(depth0 != null ? depth0.year : depth0),{"name":"select","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"b-cpm-select b-ib\">\n        <label class=\"b-page-label\">Month</label>\n        <br>\n            <select class=\"b-page-input b-page-select b-page-input_xs js-cpm-search-month\">\n"
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,(depth0 != null ? depth0.month : depth0),{"name":"select","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n\n    </div>\n    <button type=\"button\" class=\"b-page-button b-button_yes b-ib js-cpm-search-btn\">Search</button>\n    <button type=\"button\" class=\"b-change-search cearch-cancel\">Cancel</button>\n</div>\n<div class=\"b-settings-close\">\n    <button type=\"button\" class=\"b-download-link cpm-link js-cpm-download-btn\" data-desc=\"Download report\">\n        <span class=\"i-edit cpm-download\"></span>\n    </button>\n    <h2 class=\"b-page-title select-title js-cpm-toolbar-short-info\"><span class=\"advanced-channel\">"
    + alias4(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "</span>&nbsp;/&nbsp;<span class=\"advanced-date\">"
    + alias4(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + " "
    + alias4((helpers.getMonth || (depth0 && depth0.getMonth) || alias2).call(alias1,(depth0 != null ? depth0.month : depth0),{"name":"getMonth","hash":{},"data":data}))
    + "</span></h2>\n</div>";
},"useData":true});
