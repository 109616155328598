/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var InboxTemplate = require("./templates/InboxBlock.hbs");
var Inbox768Template = require("./templates/InboxBlock768.hbs");


Views.InboxBlockView = Marionette.ItemView.extend({
	tagName: "a",
	getTemplate: function(){
		if (this.options["screen"]=== 768){
			return Inbox768Template;
		} else {
			return InboxTemplate;
		}
	},
	templateHelpers: function(){
		var me = this;
		return {
			count: me.options["count"]
		}
	},
	attributes:{
		href: "#?page=inbox"
	}
});
module.exports = Marionette.Object.extend({
	createView: function(region, region768, resp){
		this.inboxBlockView = new Views.InboxBlockView({count: resp.body.result_count});
		this.inboxBlock768View = new Views.InboxBlockView({count: resp.body.result_count, screen: 768});
		region.show(this.inboxBlockView);
		region768.show(this.inboxBlock768View);
	},
	show: function(region, region768){
		var me = this;
		$.get("/api/inbox/notification_list?isread=false&only_count=true").done(me.createView.bind(me, region, region768))
	}
});