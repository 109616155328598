var ToolbarTemplate = require("./templates/ReferralsToolbar.hbs");
var AutoCompleteWidget = require("widgets/AutoComplete");
module.exports = Marionette.ItemView.extend({
	events: {
		"click li[data-list]" : "onSetFilter",
		"click li[data-action]" : "onSelectAction",
		"submit form": "onSearchChange"
	},
	initialize: function(){
		this.autoComplete = new AutoCompleteWidget();
	},
	template: ToolbarTemplate,
	onShow: function(){
		var me = this;
		var searchList = this.model.getSearchList();
		this.autoComplete.show(this.$el.find(".js-input-search"),
			{	source: searchList,
				minLength: 3,
				select: me.onSearchChange.bind(me)
			});
		var dict = localStorage.getItem('dict');
		this.$el.find('.b-referral-link').text('join.quizgroup.com?ref='+dict+'').attr('href','https://join.quizgroup.com?ref='+dict+'');
	},
	onSearchChange: function(event, ui){
		event.preventDefault();
		var searchQuery = this.$el.find(".js-input-search").val();
		this.trigger("search", searchQuery);
		if(!searchQuery){
			$('.b-sum-block_channels').fadeIn(300);	
		}else{
			$('.b-sum-block_channels').fadeOut(300);
		}
	},
	onBeforeDestroy: function () {
		this.autoComplete.destroy();
	}
});