var Helpers = require("helpers/Helpers");
module.exports = Marionette.Object.extend({
	state: undefined,
	params: undefined,
	prevState: undefined,
	states: {
		init: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {

			},
			init: function () {
				this.target.notifyState("init");
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},

			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			joinstep1: function () {
				this.target.changeState(this.target.states.joinstep1);
			},
			joinstep2: function () {
				this.target.changeState(this.target.states.joinstep2);
			},
			unitedashboard: function () {
				this.target.changeState(this.target.states.unitedashboard);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}

		},
		login: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("login", this.target.params);
			},
			enter: function () {

			},
			login: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			joinstep1: function () {
				this.target.changeState(this.target.states.joinstep1);
			},
			joinstep2: function () {
				this.target.changeState(this.target.states.joinstep2);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		home: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("home");
			},
			enter: function () {
			},
			home: function () {

			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			joinstep1: function () {
				this.target.changeState(this.target.states.joinstep1);
			},
			joinstep2: function () {
				this.target.changeState(this.target.states.joinstep2);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}

		},
		dashboard: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("dashboard");
			},
			enter: function () {

			},
			dashboard: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		dashboardlogin: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("dashboardlogin");
			},
			enter: function () {

			},
			dashboardlogin: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		dashboardloginwithcode: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("dashboard:login:with:code");
			},
			enter: function () {

			},
			dashboardloginwithcode: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		dashboardloginwithpassword: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("dashboard:login:with:password");
			},
			enter: function () {

			},
			dashboardloginwithpassword: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		dashboardpasswordrecovery: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("dashboard:password:recovery");
			},
			enter: function () {

			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {

			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		dashboardsetpassword: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("dashboard:set:password");
			},
			enter: function () {

			},
			dashboardloginwithcode: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			dashboardsetpassword: function () {

			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		logout: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("logout", this.target.params);
			},
			enter: function () {

			},
			logout: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			joinstep1: function () {
				this.target.changeState(this.target.states.joinstep1);
			},
			joinstep2: function () {
				this.target.changeState(this.target.states.joinstep2);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			}
		},
		profile: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("profile");
			},
			enter: function () {

			},
			profile: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		markup: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("markup");
			},
			enter: function () {
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {

			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		payout: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("payout", this.target.params);
			},
			enter: function () {
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {

			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			}
		},
		channels: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("channels", this.target.params);
			},
			enter: function () {
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {

			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		music: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("music");
			},
			enter: function () {
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {

			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		inbox: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("inbox", this.target.params);
			},
			enter: function () {
			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {

			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		support: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("support");
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {

			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		cpm: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("cpm", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {

			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		advancedreport: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("advancedreport", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			advancedreport: function () {

			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		unitedashboard: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("unitedashboard", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			unitedashboard: function () {

			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		joinsignin: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("joinsignin", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			joinsignin: function () {

			},
			unitedashboard: function () {
				this.target.changeState(this.target.states.unitedashboard);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		joinstep1: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("joinstep1", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			joinstep1: function () {

			},
			joinstep2: function () {
				this.target.changeState(this.target.states.joinstep2);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			unitedashboard: function () {
				this.target.changeState(this.target.states.unitedashboard);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		joinstep2: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("joinstep2", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			autherrorhandler: function () {
				this.target.changeState(this.target.states.autherrorhandler);
			},
			joinstep2: function () {

			},
			joinstep1: function () {
				this.target.changeState(this.target.states.joinstep1);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			unitedashboard: function () {
				this.target.changeState(this.target.states.unitedashboard);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		},
		autherrorhandler: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {
				this.target.notifyState("autherrorhandler", this.target.params);
			},
			enter: function () {

			},
			home: function () {
				this.target.changeState(this.target.states.home);
			},
			dashboard: function () {
				this.target.changeState(this.target.states.dashboard);
			},
			login: function () {
				this.target.changeState(this.target.states.login);
			},
			profile: function () {
				this.target.changeState(this.target.states.profile);
			},
			dashboardlogin: function () {
				this.target.changeState(this.target.states.dashboardlogin);
			},
			markup: function () {
				this.target.changeState(this.target.states.markup);
			},
			dashboardloginwithcode: function () {
				this.target.changeState(this.target.states.dashboardloginwithcode);
			},
			payout: function () {
				this.target.changeState(this.target.states.payout);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			inbox: function () {
				this.target.changeState(this.target.states.inbox);
			},
			support: function () {
				this.target.changeState(this.target.states.support);
			},
			cpm: function () {
				this.target.changeState(this.target.states.cpm);
			},
			autherrorhandler: function () {

			},
			unitedashboard: function () {
				this.target.changeState(this.target.states.unitedashboard);
			},
			advancedreport: function () {
				this.target.changeState(this.target.states.advancedreport);
			},
			dashboardloginwithpassword: function () {
				this.target.changeState(this.target.states.dashboardloginwithpassword);
			},
			dashboardsetpassword: function () {
				this.target.changeState(this.target.states.dashboardsetpassword);
			},
			dashboardpasswordrecovery: function () {
				this.target.changeState(this.target.states.dashboardpasswordrecovery);
			},
			joinsignin: function () {
				this.target.changeState(this.target.states.joinsignin);
			},
			joinstep1: function () {
				this.target.changeState(this.target.states.joinstep1);
			},
			joinstep2: function () {
				this.target.changeState(this.target.states.joinstep2);
			},
			logout: function () {
				this.target.changeState(this.target.states.logout);
			}
		}
	},
	initialize: function () {
		this.states.login.initialize(this);
		this.states.dashboard.initialize(this);
		this.states.home.initialize(this);
		this.states.init.initialize(this);
		this.states.markup.initialize(this);
		this.states.dashboardlogin.initialize(this);
		this.states.dashboardloginwithcode.initialize(this);
		this.states.profile.initialize(this);
		this.states.logout.initialize(this);
		this.states.payout.initialize(this);
		this.states.channels.initialize(this);
		this.states.music.initialize(this);
		this.states.inbox.initialize(this);
		this.states.support.initialize(this);
		this.states.cpm.initialize(this);
		this.states.advancedreport.initialize(this);
		this.states.dashboardloginwithpassword.initialize(this);
		this.states.dashboardpasswordrecovery.initialize(this);
		this.states.dashboardsetpassword.initialize(this);
		this.states.unitedashboard.initialize(this);
		this.states.autherrorhandler.initialize(this);
		this.states.joinsignin.initialize(this);
		this.states.joinstep1.initialize(this);
		this.states.joinstep2.initialize(this);
		this.state = this.states.init;
		this.init();
		this.setHandlers();
	},
	init: function () {
		this.state.init();
	},
	dashboard: function () {
		this.state.dashboard();
	},
	login: function () {
		this.state.login();
	},
	home: function () {
		this.state.home();
	},
	markup: function () {
		this.state.markup();
	},
	dashboardlogin: function () {
		this.state.dashboardlogin();
	},
	dashboardloginwithcode: function () {
		this.state.dashboardloginwithcode();
	},
	profile: function () {
		this.state.profile();
	},
	logout: function () {
		this.state.logout();
	},
	payout: function () {
		this.state.payout();
	},
	channels: function () {
		this.state.channels();
	},
	music: function () {
		this.state.music();
	},
	inbox: function () {
		this.state.inbox();
	},
	support: function () {
		this.state.support();
	},
	cpm: function () {
		this.state.cpm();
	},
	advancedreport: function () {
		this.state.advancedreport();
	},
	dashboardloginwithpassword: function () {
		this.state.dashboardloginwithpassword();
	},
	dashboardpasswordrecovery: function () {
		this.state.dashboardpasswordrecovery();
	},
	dashboardsetpassword: function () {
		this.state.dashboardsetpassword();
	},
	unitedashboard: function () {
		this.state.unitedashboard();
	},
	joinsignin: function () {
		this.state.joinsignin();
	},
	joinstep1: function () {
		this.state.joinstep1();
	},
	joinstep2: function () {
		this.state.joinstep2();
	},
	autherrorhandler: function () {
		this.state.autherrorhandler();
	},
	changeState: function (state) {
		if (this.state !== state) {
			this.state.exit();
			this.prevState = this.state;
			this.state = state;
			this.state.enter(this.params);
			this.state.execute();
		}
	},
	notifyState: function (nameState, params) {
		Radio.channel('application').trigger('application:state:' + nameState, params);
	},
	setState: function (params) {
		this.params = params;
		var result = Radio.channel("login").request("user:is:auth");
		var checkType = Radio.channel("login").request("user:check:quest");
		if (params.page == "autherrorhandler") {
			this.autherrorhandler();
			return;
		}
		if (!result || result === "unauthorized") {
			if (params.page == "dashboardloginpassword") {
				this.dashboardloginwithpassword();
			} else if (params.page == "dashboardpasswordrecovery") {
				this.dashboardpasswordrecovery();
			} else if (params.page == "dashboardlogin") {
				this.dashboardlogin();
			} else if (params.page == "unitedashboard") {
				this.unitedashboard();
			} else if (params.page == "joinsignin") {
				this.joinsignin();
			} else if (params.page == "joinstep1") {
				this.joinstep1();
			} else if (params.page == "joinstep2") {
				this.joinstep2();
			} else if (params.page == "login"){
				this.login();
			} else {
				this.home();
				Backbone.history.navigate("#");
			}
			return;
		} else if (result === "need:code") {
			this.dashboardloginwithcode();
			return;
		} else if (result === "need:password" && checkType == true) {
			this.dashboardsetpassword();
			return;
		} else if (result === "need:password") {
			if (params.page == "dashboardpasswordrecovery") {
				this.dashboardpasswordrecovery();
				return;
			} else if (params.page == "logout") {
				this.logout();
				return;
			} else {
				this.dashboardlogin();
				return;
			}
		} else {
			if (result == "authorized" && (params.page == "home" || !params.page)) {
				this.dashboard();
				return;
			}
			switch (params.page) {
				case "dashboard":
					this.dashboard();
					break;
				case "dashboardlogin":
					this.dashboardlogin();
					break;
				case "login":
					this.login();
					break;
				case "logout":
					this.logout();
					break;
				case "markup":
					this.markup();
					break;
				case "profile":
					this.profile();
					break;
				case "payout":
					this.payout();
					break;
				case "channels":
					this.channels();
					break;
				case "music":
					this.music();
					break;
				case "inbox":
					this.inbox();
					break;
				case "support":
					this.support();
					break;
				case "cpm":
					this.cpm();
					break;
				case "advancedreport":
					this.advancedreport();
					break;
				case "dashboardloginpassword":
					this.dashboardloginwithpassword();
					break;
				case "dashboardpasswordrecovery":
					this.dashboardpasswordrecovery();
					break;
				case "dashboardsetpassword":
					this.dashboardsetpassword();
					break;
				default:
					if (result == "authorized") {
						this.home();
					} else {
						this.dashboard();
					}
			}
		}
	},
	setHandlers: function () {
		var me = this;
		Radio.channel('login').on('click:login', function () {
			me.login();
		});
		Radio.channel('login').on('click:logout', function () {
			me.logout();
		});
		Radio.channel('login').on('loaded:session:data', function () {
			me.dashboard();
		});
		Radio.channel('login').on('user:answer:secret:question:success', function () {
			me.dashboardlogin();
		});
		Radio.channel('login').on('user:logout:done', function () {
			me.home();
		});
		Radio.channel('login').on('user:need:guath:pswd', function () {
			me.dashboardlogin();
		});
		Radio.channel('login').on('user:close:google:window', function () {
			me.changeState(me.prevState);
		});
		Radio.channel('auth').on('auth:error:handler', function () {
			me.autherrorhandler();
		});

	}
});
