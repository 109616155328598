var AdvancedReportTemplate = require("./templates/AdvancedReportLayout.hbs");
var HeaderChannelTemplateChannels = require("./templates/AdvanceReportHeaderChannelInfo.hbs");
var HeaderPaymentTemplateChannels = require("./templates/AdvanceReportHeaderPaymentInfo.hbs");
var CountTemplate = require("./templates/CountPage.hbs");
module.exports = Marionette.LayoutView.extend({
	ui: {
		tabsItem: ".b-tabs-item",
		changeSearch: ".b-reports-change",
		beginYear: ".begin-year",
		beginMonth: ".begin-month",
		endYear: ".end-year",
		endMonth: ".end-month",
		channelInfo: ".js-advance-report-channel-info",
		paymentInfo: ".js-advance-report-payment-info",
		moreBtn: ".js-advance-report-more-btn",
		countPage: ".js-advance-report-table-page",
		upBtn: ".b-up-button"
	},
	events: {
		"click @ui.tabsItem": "changeContent",
		"click @ui.changeSearch": "showConditions",
		"click @ui.moreBtn": "onMoreBtnClick"
	},
	regions: {
		"toolbarRegion": ".js-advance-report-toolbar",
		"tableTabRegion": ".js-advance-report-table",
		"diagramTabRegion": ".js-advance-report-diagram"
	},
	className: "wrapper_n",
	template: AdvancedReportTemplate,
	onShow: function(){
	},
	setHeaderInfo: function(headerData){
		this.ui.channelInfo.html(HeaderChannelTemplateChannels(headerData));
		this.ui.paymentInfo.html(HeaderPaymentTemplateChannels(headerData));
	},
	onMoreBtnClick: function () {
		this.trigger("more:btn:click");
	},
	showMoreBtn: function(){
		this.ui.moreBtn.removeClass("b-hide-item");
	},
	hideMoreBtn: function(){
		this.ui.moreBtn.addClass("b-hide-item");
	},
	showCountPages: function(){
		this.ui.countPage.removeClass("b-hide-item");
	},
	hideCountPages: function(){
		this.ui.countPage.addClass("b-hide-item");
	},
	setCountPage: function(data){
		if(data.total_count < 20){
			this.ui.countPage.addClass("b-hide-item");
		}else{
			this.ui.countPage.html(CountTemplate(data));
			this.ui.countPage.removeClass("b-hide-item");
		}
	},
	changeContent: function(e){
		var id = $(e.currentTarget).attr('data-tab');
		var $el = this.$el;
		$el.find('.b-box').removeClass('visible');
		$el.find('.b-box-'+id+'').addClass('visible');
		$el.find('.b-tabs-item').removeClass('b-tabs-active');
		$el.find('.b-'+id+'-tab').addClass('b-tabs-active');
		this.trigger("user:click:tab", id);
	},
	showTabContent: function(id){
		var $el = this.$el;
		$el.find('.b-box').removeClass('visible');
		$el.find('.b-box-'+id+'').addClass('visible');
		$el.find('.b-tabs-item').removeClass('b-tabs-active');
		$el.find('.b-'+id+'-tab').addClass('b-tabs-active');
	},
	showConditions: function(){
		this.ui.changeSearch.fadeOut(200);
		this.$el.find('.b-settings-open').addClass('open');
	},
	hideConditions: function(){
		this.ui.changeSearch.fadeIn(200);
		this.$el.find('.b-settings-open').removeClass('open');
	}
});