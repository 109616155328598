var MusicLayout = require("./views/MusicLayout");
var MusicToolbar = require("./views/MusicToolbar");
var MusicList = require("./views/MusicList");
var MusicHeader = require("./views/MusicHeader");
var MusicModal = require("./views/MusicModal");
var MusicTerms = require("./views/MusicTerms");
var MusicEntities = require("entities/Music");
var MusicCategoriesList =  require("./views/block/categoryList/CategoriesList");
var MusicPaginator =  require("./views/MusicPaginatorTable");
var MusicBreadCrumbs =  require("./views/MusicBreadCrumbs");
module.exports = Marionette.Object.extend({
	state: undefined,
	states: {
		init: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {

			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			sounds: function () {
				this.target.changeState(this.target.states.sounds);
			},
			terms: function () {
				this.target.changeState(this.target.states.terms);
			},
			selected: function () {
				this.target.changeState(this.target.states.selected);
			}
		},
		music: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showMusic();
			},
			music: function () {

			},
			sounds: function () {
				this.target.changeState(this.target.states.sounds);
			},
			terms: function () {
				this.target.changeState(this.target.states.terms);
			},
			selected: function () {
				this.target.changeState(this.target.states.selected);
			}
		},
		sounds: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showSound();
			},
			sounds: function () {

			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			terms: function () {
				this.target.changeState(this.target.states.terms);
			},
			selected: function () {
				this.target.changeState(this.target.states.selected);
			}
		},
		terms: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showTerms();
			},
			terms: function () {

			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			sounds: function () {
				this.target.changeState(this.target.states.sounds);
			},
			selected: function () {
				this.target.changeState(this.target.states.selected);
			}
		},
		selected: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showSelected();
			},
			selected: function () {

			},
			music: function () {
				this.target.changeState(this.target.states.music);
			},
			sounds: function () {
				this.target.changeState(this.target.states.sounds);
			},
			terms: function () {
				this.target.changeState(this.target.states.terms);
			}
		}
	},
	initialize: function(){
		MusicEntities.initialize();
		this.states.init.initialize(this);
		this.states.music.initialize(this);
		this.states.sounds.initialize(this);
		this.states.terms.initialize(this);
		this.states.selected.initialize(this);
		this.state = this.states.init;
		this.setHandlers();
	},
	changeState: function(state) {
		if (this.state !== state) {
			this.state.exit();
			this.state = state;
			this.state.enter();
			this.state.execute();
		}
	},
	init: function(){
		this.state.init();
	},
	music: function(){
		this.state.music();
	},
	sounds: function(){
		this.state.sounds();
	},
	terms: function(){
		this.state.terms();
	},
	selected: function(){
		this.state.selected();
	},
	showMusic: function () {
		var me = this;
		var musicModel = Radio.channel("global").request("music:new:entity");
		var musicCategoriesList = new MusicCategoriesList({model: musicModel});
		var musicList = new MusicList({collection: musicModel.get("tracks"), selectedIds: musicModel.getSelectedId()});
		var musicHeader = new MusicHeader();
		var toolbarMusicView = new MusicToolbar({model: musicModel});
		var paginator = new MusicPaginator({model: musicModel});
		var breadcrumbs = new MusicBreadCrumbs();
		var arr = [];
		me.listenTo(me.musicLayout,"additional:show", me.notify.bind(me,musicModel));
		this.listenTo(musicCategoriesList, "category:changed", function(catId,text){
			musicModel.getTraksList(catId);
			musicHeader.setName(text);
			musicHeader.showHeader();
			me.musicLayout.hideListBlock();
		});

		this.listenTo(musicCategoriesList, "add:crumb", function(data){
			arr.push(data);
			me.musicLayout.addCrumbs(arr);
		});

		this.listenTo(paginator, "user:click:page", function(data){
			var options = {
				page: data,
				type: musicModel.get("type"),
				search_str: musicModel.get("search_str"),
				cat_id: musicModel.get("cat_id")
			};
			musicModel.getResultList(options);
			me.musicLayout.hideListBlock();
		});

		this.listenTo(musicModel.get("tracks"),"reset",function(){
			if(musicModel.get("total_size")){
				paginator.setTotalSize(musicModel.get("total_size"));
				paginator.render();
				paginator.show();
			}else{
				paginator.hide();
			}
			me.musicLayout.showListBlock();
		});

		this.listenTo(musicModel, "change:total_size", function(model,val){
			musicHeader.setItemFound(val);
		});

		this.listenTo(toolbarMusicView, "get:list", function(data){
			musicModel.getResultList(data);
			musicHeader.showHeader();
			me.musicLayout.hideListBlock();
		});

		this.listenTo(musicList, "user:click:track", function(data){
			musicModel.toggleStar(data);
		});

		this.listenTo(me.musicLayout, "update:crumb", function(catId, newBredcrams){
			arr =  newBredcrams;
			musicModel.getTraksList(catId);
			me.musicLayout.hideListBlock();
		});

		this.listenTo(me.musicLayout, "change:select", function(catId, data, resp){
			musicCategoriesList.categoriesListView.changeTemplate(catId, data);
			musicCategoriesList.categoriesListView.disableSelect(resp);
		});

		this.listenTo(toolbarMusicView, "show", function(data){
			musicCategoriesList.show(toolbarMusicView.getRegion("dropDownListRegion"), "music");
		});

		this.listenTo(musicHeader, "show", function(data){
			me.musicLayout.musicToolbarRegion.show(toolbarMusicView);
		});

		me.musicLayout.showCrumbs();
		me.musicLayout.activateMusicTab();
		me.musicLayout.musicTermsRegion.empty();
		me.musicLayout.musicListRegion.show(musicList);
		me.musicLayout.musicHeaderRegion.show(musicHeader);
		me.musicLayout.musicPagionatorRegion.show(paginator);
		Backbone.history.navigate("?page=music&subpage=music");
	},
	showSound: function () {
		var me = this;
		var musicModel = Radio.channel("global").request("music:new:entity");
		var musicCategoriesList = new MusicCategoriesList();
		var musicList = new MusicList({collection: musicModel.get("tracks"), selectedIds: musicModel.getSelectedId()});
		var musicHeader = new MusicHeader();
		var toolbarMusicView = new MusicToolbar({model: musicModel});
		var paginator = new MusicPaginator({model: musicModel});
		var arr = [];
		me.listenTo(me.musicLayout,"additional:show", me.notify.bind(me,musicModel));
		this.listenTo(musicCategoriesList, "category:changed", function(catId,text){
			musicModel.getTraksList(catId);
			musicHeader.setName(text);
			musicHeader.showHeader();
			me.musicLayout.hideListBlock();
		});

		this.listenTo(paginator, "user:click:page", function(data){
			var options = {
				page: data,
				type: musicModel.get("type"),
				search_str: musicModel.get("search_str"),
				cat_id: musicModel.get("cat_id")
			};
			musicModel.getResultList(options);
			me.musicLayout.hideListBlock();
		});

		this.listenTo(musicModel.get("tracks"),"reset",function(){
			if(musicModel.get("total_size")){
				paginator.setTotalSize(musicModel.get("total_size"));
				paginator.render();
				paginator.show();
			}else{
				paginator.hide();
			}
			me.musicLayout.showListBlock();
		});

		this.listenTo(musicCategoriesList, "add:crumb", function(data){
			arr.push(data);
			me.musicLayout.addCrumbs(arr);
		});

		this.listenTo(musicModel, "change:total_size", function(model,val){
			musicHeader.setItemFound(val);
		});

		this.listenTo(musicModel.get("tracks"),"reset",function(){
			me.musicLayout.showListBlock();
		});

		this.listenTo(toolbarMusicView, "get:list", function(data){
			musicModel.getResultList(data);
			musicHeader.showHeader();
			me.musicLayout.hideListBlock();
		});

		this.listenTo(musicList, "user:click:track", function(data){
			musicModel.toggleStar(data);
		});

		this.listenTo(me.musicLayout, "update:crumb", function(catId, newBredcrams){
			arr =  newBredcrams;
			musicModel.getTraksList(catId);
			me.musicLayout.hideListBlock();
		});

		this.listenTo(me.musicLayout, "change:select", function(catId, data, resp){
			musicCategoriesList.categoriesListView.changeTemplate(catId, data);
			musicCategoriesList.categoriesListView.disableSelect(resp);
		});

		me.listenTo(toolbarMusicView, "show", function(data){
			musicCategoriesList.show(toolbarMusicView.getRegion("dropDownListRegion"), "sound");
		});

		this.listenTo(musicHeader, "show", function(data){
			me.musicLayout.musicToolbarRegion.show(toolbarMusicView);
		});

		me.musicLayout.showCrumbs();
		me.musicLayout.activateSoundTab();
		me.musicLayout.musicTermsRegion.empty();
		me.musicLayout.musicPagionatorRegion.empty();
		me.musicLayout.musicListRegion.show(musicList);
		me.musicLayout.musicHeaderRegion.show(musicHeader);
		me.musicLayout.musicPagionatorRegion.show(paginator);
		Backbone.history.navigate("?page=music&subpage=sounds");
	},
	showTerms: function () {
		var me = this;
		var musicTerms = new MusicTerms();
		me.musicLayout.activateTermsTab();
		me.musicLayout.musicToolbarRegion.empty();
		me.musicLayout.musicListRegion.empty();
		me.musicLayout.musicHeaderRegion.empty();
		me.musicLayout.musicTermsRegion.show(musicTerms);
		Backbone.history.navigate("?page=music&subpage=terms");
	},
	showSelected: function(){
		var me = this;
		var musicModel = Radio.channel("global").request("music:new:entity");
		var musicList = new MusicList({collection: new Backbone.Collection(musicModel.getStaredItems()), selectedIds: musicModel.getSelectedId()});
		var musicHeader = new MusicHeader();
		var paginator = new MusicPaginator({model: musicModel});
		me.listenTo(me.musicLayout,"additional:show", me.notify.bind(me,musicModel));
		this.listenTo(paginator, "user:click:page", function(data){
			var options = {
				page: data,
				type: musicModel.get("type"),
				search_str: musicModel.get("search_str"),
				cat_id: musicModel.get("cat_id")
			};
			musicModel.getResultList(options);
			me.musicLayout.hideListBlock();
		});

		this.listenTo(musicModel.get("tracks"),"reset",function(){
			if(musicModel.get("total_size")){
				paginator.setTotalSize(musicModel.get("total_size"));
				paginator.render();
				paginator.show();
			}else{
				paginator.hide();
			}
			me.musicLayout.showListBlock();
		});

		this.listenTo(musicModel, "change:total_size", function(model,val){
			musicHeader.setItemFound(val);
		});


		this.listenTo(musicList, "user:click:track", function(data){
			musicModel.toggleStar(data);
		});

		me.musicLayout.hideCrumbs();
		me.musicLayout.activateSelectedTab();
		me.musicLayout.musicPagionatorRegion.empty();
		me.musicLayout.musicTermsRegion.empty();
		me.musicLayout.musicToolbarRegion.empty();
		me.musicLayout.musicListRegion.show(musicList);
		me.musicLayout.musicHeaderRegion.empty();
		me.musicLayout.musicPagionatorRegion.show(paginator);
		Backbone.history.navigate("?page=music&subpage=selected");
	},
	show: function(params){
		var me = this;
		me.musicLayout = new MusicLayout();
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		me.listenTo(me.musicLayout, "show", function(data){
			if(params ? params.subpage ? 1: 0 : 0){
				if(params.subpage === "music"){
					this.music();
				}else if(params.subpage === "sounds"){
					this.sounds();
				}else if(params.subpage === "terms"){
					this.terms();
				}else if(params.subpage === "selected"){
					this.selected();
				}else{
					this.music();
				}
			}else{
				this.music();
			}
		});

		me.listenTo(me.musicLayout, "user:click:tab", function(data){
			if(data=== "music"){
				this.music();
			}else if(data === "sound"){
				this.sounds();
			}else if(data === "terms"){
				this.terms();
			}else if(data === "selected") {
				this.selected();
			}
		});
		mainRegion.show(me.musicLayout);
	},
	notify: function (musicModel,id) {
		var notify = new MusicModal({fileId: id});
		var modalRegion =  Radio.channel("layout").request("get:modal:region");
		this.listenTo(notify, "get:file", function(file_id){
			musicModel.getDownload(file_id);
		});
		modalRegion.show(notify);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:music", function() {
			me.state = me.states.init;
			me.show();
		});
	}

});