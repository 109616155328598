// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-item-nums b-ib\">1-"
    + alias4(((helper = (helper = helpers.offset || (depth0 != null ? depth0.offset : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offset","hash":{},"data":data}) : helper)))
    + "&nbsp;"
    + alias4((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"of","key":"qg_partner.count_table_of"},"data":data}))
    + "</div>\r\n<div class=\"b-item-count b-ib\">"
    + alias4(((helper = (helper = helpers.total_count || (depth0 != null ? depth0.total_count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total_count","hash":{},"data":data}) : helper)))
    + "</div>";
},"useData":true});
