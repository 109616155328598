// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "&#36;"
    + container.escapeExpression(((helper = (helper = helpers.earning || (depth0 != null ? depth0.earning : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"earning","hash":{},"data":data}) : helper)))
    + " <br>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"b-content-stats__item b-content-stats-item_left\">\n    <span class=\"b-content-stats__title\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Referrals","key":"qg_partner.dashboard.profile_block_name"},"data":data}))
    + "</span>\n    <br>\n    <span class=\"i-edit i-edit_sp-ico i-edit_ref-ico\"></span>\n    <span class=\"i-edit i-edit_sp-ico i-edit_ref-ico i-edit-sp-ico_invis\"></span>\n</div>\n<div class=\"b-content-stats__item b-content-stats-item_center\">\n    <div class=\"b-content-stats__container\">\n        <div class=\"b-content-stats__container-inner\">\n            <span class=\"b-content-stats__number\">"
    + alias3(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"count","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"b-content-stats__text\">\n                <span>\n                    "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Active","key":"qg_partner.dashboard.referrals_block_active"},"data":data}))
    + "\n                </span>\n                <span>\n                    "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"referrals","key":"qg_partner.dashboard.referrals_block_referrals"},"data":data}))
    + "\n                </span>\n            </span>\n            <div class=\"b-content-stats__down-desc b-content-stats-desc_invis\">\n                "
    + alias3((helpers.showDiffMoney || (depth0 && depth0.showDiffMoney) || alias2).call(alias1,(depth0 != null ? depth0.sumCurrentMonth : depth0),(depth0 != null ? depth0.sumPrevMonth : depth0),{"name":"showDiffMoney","hash":{},"data":data}))
    + "  <br>vs prior 30 days\n\n               <span class=\"b-down-desc_2\"> "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"vs period 30 days","key":"qg_partner.vs_period_30_days"},"data":data}))
    + "</span>\n            </div>\n        </div>\n    </div>\n</div>\n<div class=\"b-content-stats__item b-content-stats-item_right\">\n    <div class=\"b-content-stats__container\">\n        <div class=\"b-content-stats__container-inner\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.earning : depth0),0,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + alias3((helpers.showDiffMoney || (depth0 && depth0.showDiffMoney) || alias2).call(alias1,(depth0 != null ? depth0.sumCurrentMonth : depth0),(depth0 != null ? depth0.sumPrevMonth : depth0),"vs prior period",{"name":"showDiffMoney","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n</div>";
},"useData":true});
