/**
 * Created by sergey on 13.01.2016.
 */
MusicPaginatorTemplate = require("./templates/MusicPaginator.hbs");
module.exports = Marionette.ItemView.extend({
	ui : {
		changePageBtn: '.js-pagination-page',
		nextPageBtn: '.js-paginator-next',
		prevPageBtn: '.js-paginator-prev'
	},
	events : {
		"click @ui.changePageBtn" : "changePage",
		"click @ui.nextPageBtn" : "nextPage",
		"click @ui.prevPageBtn" : "prevPage"
	},
	template: MusicPaginatorTemplate,
	page_count: null,
	page_active: null,
	page_show: 3,
	className: "b-hide-item",
	initialize: function() {
		this.page_count = Math.floor(this.options.page_count/6);
		if (this.page_count <= this.page_show) {
			this.page_show = this.page_count;
		}
		this.page_active = this.options.page_active;
	},
	templateHelpers: function(){
		var me = this;
		var range = Math.floor(this.page_show / 2);
		var nav_begin = this.page_active - range;
		var nav_end = this.page_active + range;
		var left_dots = true;
		var right_dots = true;
		if (this.page_show % 2 == 0) {
			nav_begin++;
		}

		if (nav_begin <= 2) {
			nav_end = this.page_show;
			if (nav_begin == 2) {
				nav_end++;
			}
			nav_begin = 1;
			left_dots = false;
		}

		if (nav_end >= this.page_count - 1 ) {
			nav_begin = this.page_count - this.page_show + 1;
			if (nav_end == this.page_count - 1) {
				nav_begin--;
			}
			nav_end = this.page_count;
			right_dots = false;
		}
		return {
			nav_begin: nav_begin,
			nav_end: nav_end,
			left_dots: left_dots,
			right_dots: right_dots,
			page_active: me.page_active,
			page_count: me.page_count
		}

	},
	changePage: function(e){
		this.page_active = Number($(e.currentTarget).attr('data-page'));
		this.render();
		this.trigger("user:click:page", this.page_active);
	},
	nextPage: function(e){
		e.preventDefault();
		if(this.page_active < this.page_count){
			this.page_active = this.page_active + 1;
			this.render();
			this.trigger("user:click:page", this.page_active);
		}
	},
	prevPage: function(e){
		e.preventDefault();
		if(this.page_active > 1){
			this.page_active = this.page_active - 1;
			this.render();
			this.trigger("user:click:page", this.page_active);
		}
	},
	show: function(){
		this.$el.removeClass("b-hide-item");
	},
	hide: function(){
		this.$el.addClass("b-hide-item");
	},
	setTotalSize: function(totalSize){
		this.page_count = Math.floor(totalSize/6);
		if (this.page_count <= this.page_show) {
			this.page_show = this.page_count;
		}
		this.page_active = this.model.get("page");
	}
});