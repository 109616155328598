/**
 * Created by sergey on 14.12.2015.
 */
module.exports = Backbone.Model.extend({
	defaults: function(){
		return {
			offset: 0,
			limit: 20
		}
	},
	url: function(){
		var lang = Radio.channel("login").request("user:lang");
		return "/api/inbox/news_list?offset=" + this.get("offset") +"&limit="+ this.get("limit") +"&lang="+lang;
	},
	getSearchList: function(){
		var result = _.map(this.get("news"), function(r){return r.title}).filter(function(x) {
			return typeof x !== 'undefined';
		});
		return result;
	},
	clearFilter: function(){
		this.set({rows: this.defaultRows}, {silent: true});
		this.trigger("refresh:filter:rows");
	},
	unSetFilterValue: function(){
		this.unset("filter", {silent: true});
	},
	rowsLength: function(){
		return this.get("news").length;
	},
	setStarred: function(data){
		var me = this,
			options = {
				success: function(){
					me.trigger("refresh:filter:rows");
				}
			};
		$.post("/api/inbox/change_news_status", data).done(function(){
			me.fetch(options);
		});
	},
	getNews: function(cb, id){
		var me = this,
			item,
			news = _.findWhere(me.get("news"), {id: id});
		if(news ? news.body ? 1: 0 : 0){
			cb(news.description);
		}else{
			$.get("/api/inbox/news_message?id="+id).then(function(resp){
				var result = [];
				result.push(id);
				cb(resp.body.description);
				item = _.findWhere(me.get("news"), {id: resp.body.id});
				item.description = resp.body.description;
				if(!resp.body.read){
					me._readeUnReadMessages({news_ids: result, read: true})
				}
			});
		}
	},
	getMoreNews: function () {
		var me = this;
		this.set({"offset": me.get("offset")+20});
		var lang = Radio.channel("login").request("user:lang");
		$.ajax({
			method: "GET",
			url: "/api/inbox/news_list?offset=" + me.get("offset") +"&limit="+ me.get("limit") +"&lang="+lang
		}).then(function (resp) {
			var newArr = resp.body.answer;
			var oldArr = me.get("news");
			me.set({"news": oldArr.concat(newArr)});
			me.trigger("refresh:filter:rows", newArr);
		})
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var newsListLength = this.get("news").length;
		if(newsListLength !== totalCount){
			result = true;
		}
		return result;
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		} else{
			return  this.get("offset")+this.get("limit");
		}
	},
	filterAction:function(ids){
		var objectMap={};
		_.reduce(ids, function(result, n, key){
			result[n] = true;
			return result;
		},objectMap);
		var me = this;
		if(this.get("action") === "markasread"){
			this._readeUnReadMessages({news_ids: ids, read: true}, function(respIds){
				me.trigger("read:news",respIds, objectMap);
			}, objectMap);
		}else if(this.get("action") === "markasunread"){
			this._readeUnReadMessages({news_ids: ids, read: false}, function(respIds){
				me.trigger("unread:news", respIds, objectMap);
			}, objectMap);
		}else if(this.get("action") === "addstar"){
			this._sendStarsArray({news_ids: ids, starred: true}, function(respIds){
				me.trigger("addstars:news", respIds, objectMap);
			});
		}else if(this.get("action") === "removestar") {
			this._sendStarsArray({news_ids: ids, starred: false}, function (respIds) {
				me.trigger("removestars:news", respIds, objectMap);
			});
		}
	},
	_sendStarsArray: function(data, cb){
		var me = this;
		$.post("/api/inbox/change_news_status", data).done(function(){
			_.forEach(data.news_ids, function(id){
				var news = _.findWhere(me.get("news"), {id: id});
				news.starred = data.starred;
			});
			if(typeof cb === "function"){
				cb(data.id);
			}

		});
	},
	_readeUnReadMessages:function(data, cb){
		var me = this;
		$.post("/api/inbox/change_news_status", data).then(function () {
			//Radio.channel("inbox").trigger("user:read:news");
			_.forEach(data.news_ids, function(id){
				var news = _.findWhere(me.get("news"), {id: id});
				news.read = data.read;
			});
			if(typeof cb === "function"){
				cb(data.news_ids);
			}
		});

	},
	parse: function(resp){
		var result = {};
		result.news = resp.body.answer;
		result.total_count = resp.body.result_count;
		return result;
	}
});