/**
 * Created by gila on 10.12.2015.
 */
var ToolbarTemplate = require("./templates/MusicToolbar.hbs");
module.exports = Marionette.LayoutView.extend({
	ui: {
		searchBtn: ".b-search-btn",
		chosenSel: ".chosen-select"
	},
	events: {
		"sumbit form": "getMusicList",
		"click @ui.searchBtn": "getMusicList"
	},
	template: ToolbarTemplate,
	regions: {
		dropDownListRegion: ".js-freemusic-music-categories-list"
	},
	onShow: function(){
		this.ui.chosenSel.chosen();
	},
	getMusicList: function (e) {
		e.preventDefault();
		var cat_id = this.model.get("cat_id"),
			page = this.model.get("page"),
			type = this.model.get("type"),
			search_str = this.$el.find('.b-search-input').val(),
			data = {
				page: page,
				type: type,
				search_str: search_str,
				cat_id: cat_id
			};
		this.trigger("get:list",data);
	}
});