/**
 * Created by sergey on 23.11.2015.
 */
var ProfileContactForm = require("./form/Form.js");
var ProfileAboutTemplate = require("./templates/ProfileAboutForm.hbs");
var DatePickerWidget = require("widgets/DateTimePicker");
var BornDateTemplate = require("./templates/BornDateTemplate.hbs");
module.exports = ProfileContactForm.extend({
	template: ProfileAboutTemplate,
	events:{
		"change .b-input_first-name": "onChangeFirstName",
		"change .b-input_last-name": "onChangeLastName",
		"click @ui.editLinkWrap": "editProfileData"
	},
	triggers: {
		"click .b-page_save-link ": "click:save:btn"
	},
	initialize: function(){
		var me = this;
		this.listenTo(this.model,  "change:date_of_born", this.onChangeDateBorn.bind(this));
		this.listenTo(this.model,  "change:given_name", this.changeFirstName.bind(this));
		this.listenTo(this.model,  "change:family_name", this.changeLastName.bind(this));
		this.listenTo(this.model,  "about:data:success", this.hideControls.bind(this));

		this.dateTimePicker = new DatePickerWidget();
		this.listenTo(this.dateTimePicker, "change", function(data){
			me.trigger("date:birthday:change", data);
		});
	},
	showValidationError: function(view, attr){
		var $el = view.$(".b-input_"+attr);
		$el.addClass('b-input-error');
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$(".b-input_"+attr);
		$el.removeClass('b-input-error');
	},
	onChangeDateBorn: function(){
		var me = this;
		this.$el.find(".b-edit_birth").html(BornDateTemplate({date_of_born: me.model.get("date_of_born")}));
	},
	onChangeFirstName: function(){
		var val = this.$el.find(".b-input_first-name").val();
		this.trigger("first:name:change", val);
	},
	onChangeLastName: function(){
		var val = this.$el.find(".b-input_last-name").val();
		this.trigger("last:name:change", val);
	},
	changeFirstName: function(){
		this.$el.find(".b-edit_first-name").text(this.model.get("given_name"))
	},
	changeLastName: function(){
		this.$el.find(".b-edit_last-name").text(this.model.get("family_name"))
	},
	onShow: function(){
		this.dateTimePicker.show(this.$el.find(".js-profile-date-born"), {value: this.model.get("date_of_born")});
	}
});