/**
 * Created by sergey on 02.12.2015.
 */

var PayoutHeaderTemplate = require("./templates/HeaderPayout.hbs");
var PayoutHeaderSumBlockTemplate = require("./templates/HeaderSumblock.hbs");
var PayoutHeaderTabsTemplate = require("./templates/HeaderTabs.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		payoutMonthBlock: ".js-payout-month",
		sumBlock: ".b-sum-block",
		tabsBlock: ".b-payout-tabs-wrap",
		channelTabs: ".js-tab-channel-payout",
		refsTabs: ".js-tab-referrals-payout"
	},
	initialize: function(){
		this.listenTo(this.model, "change:month", this.onChangeMonth);
		this.listenTo(this.model, "data:reload", this.onChange);
	},
	events:{
		"click @ui.channelTabs": "onClickChannelTab",
		"click @ui.refsTabs": "onClickRefsTab"
	},
	className: "clearfix",
	template: PayoutHeaderTemplate,
	onShow: function(){
		$('.b-sum-block__item').each(function(index, item){
		    var block = $(item);
		    var value = block.find('.b-sum-count_left');
		    var text = value.text();
		    if(text.length >= 8){
		    	if (text.indexOf("."))
		    	{  
		    	    arr = text.split('.');
		    	    sum = arr[0];
		    	    
		    	    value.html(sum);
		    	}
		    	$('.b-sum-count').css('font-size',43+'px');

		    }else if(text.length >= 7){
		    	$('.b-sum-count').css('font-size',40+'px');
		    }else if(text.length >= 6){
		    	$('.b-sum-count').css('font-size',46+'px');
		    }
		});
	},
	onChangeMonth: function(model, value){
		this.ui.payoutMonthBlock.text(value+" payout");
	},
	onChange: function(){
		var data = this.model.toJSON();
		var total = data.sum_earnings + data.referral_earnings;
		data.sum_earnings = total;
		this.ui.sumBlock.html(PayoutHeaderSumBlockTemplate(data));
		this.ui.tabsBlock.find(".js-payout-channel-earnings").text(this.model.get("sum_earnings"));
		this.ui.tabsBlock.find(".js-payout-referral_earnings").text(this.model.get("referral_earnings"));
		$('.b-sum-block__item').each(function(index, item){
		    var block = $(item);
		    var value = block.find('.b-sum-count_left');
		    var text = value.text();
		    if(text.length >= 8){
		    	if (text.indexOf("."))
		    	{  
		    	    arr = text.split('.');
		    	    sum = arr[0];
		    	    
		    	    value.html(sum);
		    	}
		    	$('.b-sum-count').css('font-size',43+'px');

		    }else if(text.length >= 7){
		    	$('.b-sum-count').css('font-size',40+'px');
		    }else if(text.length >= 6){
		    	$('.b-sum-count').css('font-size',46+'px');
		    }
		});
	},
	onClickChannelTab: function(e){
		var $curTarget = $(e.currentTarget);
		$curTarget.addClass("b-tabs-active");
		this.ui.refsTabs.removeClass("b-tabs-active");
		this.trigger("user:click:channel:tab");
	},
	onClickRefsTab: function(e){
		var $curTarget = $(e.currentTarget);
		$curTarget.addClass("b-tabs-active");
		this.ui.channelTabs.removeClass("b-tabs-active");
		this.trigger("user:click:refs:tab");
	}

});