/**
 * Created by sergey on 20.11.2015.
 */
module.exports = Marionette.Region.extend({
	onShow: function(view){
		this.listenTo(view, "dialog:close", this.closeDialog);

		this.$el.modal({
			backdrop: true,
			keyboard: true,
			show: true
		});
	},

	closeDialog: function(){
		this.stopListening();
		this.empty();
		this.$el.modal('hide');
	}
});