var SupportTemplate = require("./template/SupportLayout.hbs");
var SearchList = require("./template/SearchList.hbs");
var EmptySearchList = require("./template/EmptySearchList.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		supportItem: ".b-support-item",
		supportTitle: ".b-support-item-header",
		answerText: ".b-support-item-text",
		answerPdf: ".b-support-item-pdf",
		searchBlock: ".js-support-search-block"
	},
	events: {
		"click div[data-type]" : "showBox",
		"click @ui.supportTitle" : "showText",
		"submit form": "onSearchChange"
	},
	className: "wrapper_n",
	template: SupportTemplate,
	initialize: function(){
		this.listenTo(this.model, "search:done", this.onSearchDone.bind(this));
	},
	onSearchDone: function(){
		if(this.model.get("search_list").length >0){
			this.ui.searchBlock.html(SearchList({faq_list: this.model.get("search_list")}));
		}else{
			this.showNoDataDisplay();
		}
	},
	showNoDataDisplay: function(){
		this.ui.searchBlock.html(EmptySearchList());
	},
	onShow: function(){
	},
	showBox: function(e){
		var id = $(e.currentTarget).attr('data-type'),
			$el = this.$el;
		this.tabName = id;
		if($(e.currentTarget).hasClass('_open')){

		}else{
			$el.find('.b-support-item').addClass('_close').removeClass('_open');
			$el.find('.b-support-item.'+id).addClass('_open').removeClass('_close');
			$el.find('.b-box').removeClass('visible');
			$el.find('.b-box.'+id).addClass('visible');
		}
	},
	showText: function(e){
		var me = this;
		var itemTitle = $(e.currentTarget),
			id,
			pdfEl,
			textEl;
		itemTitle.toggleClass('_open');
		if(itemTitle.hasClass('_open')){
			
			itemTitle.closest('.b-support-list-item').find('.b-support-item-body').addClass('_show');
			if(me.tabName === "sup-3"){
				pdfEl = itemTitle.closest(".b-support-list-item").find(".b-support-item-pdf");
				textEl = itemTitle.closest(".b-support-list-item").find(".b-support-item-text");
				if(pdfEl.length > 0){
					id = pdfEl.attr("id").split("_")[2];
				}
				if(textEl.length > 0){
					id = textEl.attr("id").split("_")[2];
				}
			}else{
				id = itemTitle.attr("id");
			}
			var data = {
				id: id
			};
			this.trigger("get:answer", data, this.showContent.bind(me));
		}else{
			itemTitle.closest('.b-support-list-item').find('.b-support-item-body').removeClass('_show');
			itemTitle.closest('.b-support-list-item').find('.b-support-item-pdf').html('');
		}
	},
	showContent:  function(data) {
		var me = this;
		if (!data.pdf_src) {
			if(me.tabName === "sup-3"){
				me.$el.find('#search_text_' + data.id).html(data.answer);
			}else{
				me.$el.find('#text_' + data.id).html(data.answer);
			}

		} else {
			me.showPDFContent(data);
		}
	},
	showPDFContent: function(data){
		var me = this;
		var fullUrl = data.pdf_src;
		var str = fullUrl.split("/st/portal/pdf/");
		var url = "/st/portal/pdf/"+encodeURIComponent(str[1]),
			$el =[];
		var id = data.id,
			canvasItem = "the-canvas_"+id+"",
			prevPage = "prev_"+id+"",
			nextPage = "next_"+id+"";
		if(me.tabName === "sup-3"){
			$el = me.$el.find('#search_pdf_'+id)
		}else{
			$el = me.$el.find('#pdf_'+id)
		}
			$el.append('<canvas class="the-canvas" id="'+canvasItem+'"></canvas>\
													<div class="pager-wrap page-wrap-'+id+'" data-page="'+id+'">\
														<div class="pdf-pager prev" id="'+prevPage+'"></div>\
														<div class="pdf-pager next" id="'+nextPage+'"></div>\
													</div>\
													<div class="dots-wrap" id="dots-wrap-'+id+'"></div>\
												 ');
		PDFJS.workerSrc = './build/pdf.worker.js';
		var pdfDoc = null,
			pageNum = 1,
			pageRendering = false,
			pageNumPending = null,
			scale = 1.1,
			canvas = document.getElementById(canvasItem),
			ctx = canvas.getContext('2d');

		function renderPage(num) {
			pageRendering = true;
			pdfDoc.getPage(num).then(function (page) {
				var viewport = page.getViewport(scale);
				canvas.height = viewport.height;
				canvas.width = viewport.width;

				var renderContext = {
					canvasContext: ctx,
					viewport: viewport
				};
				var renderTask = page.render(renderContext);

				renderTask.promise.then(function () {
					pageRendering = false;
					if (pageNumPending !== null) {
						renderPage(pageNumPending);
						pageNumPending = null;
					}
				});
			});
		}


		function queueRenderPage(num,pageId) {
			if (pageRendering) {
				pageNumPending = num;
			} else {
				me.$el.find('#dots-wrap-'+pageId+' .dots').removeClass('active');
				me.$el.find('#dots-wrap-'+pageId+' #dot-'+num+'').addClass('active');
				renderPage(num);
			}
		}

		function onPrevPage(e) {
			var pageId = $(e.currentTarget).parent().data("page");
			if(pageNum <= 1){
				return;
			}
			pageNum--;
			queueRenderPage(pageNum,pageId);
		}
		document.getElementById(prevPage).addEventListener('click', onPrevPage);

		function onNextPage(e) {
			var pageId = $(e.currentTarget).parent().data("page");
			if (pageNum >= pdfDoc.numPages) {
				return;
			}
			pageNum++;
			queueRenderPage(pageNum,pageId);
		}
		document.getElementById(nextPage).addEventListener('click', onNextPage);

		function renderDots(id){
			for (var i = 1; i <= pdfDoc.numPages; i++) {
				if(pageNum == i){
					me.$el.find('#dots-wrap-'+id).append('<span class="dots active" id="dot-'+i+'" data-page="'+i+'"></span>');
				}else {
					me.$el.find('#dots-wrap-'+id).append('<span class="dots" id="dot-'+i+'" data-page="'+i+'"></span>');
				}
			}
			me.$el.find('#dots-wrap-'+id).on('click','.dots', showPage);
		}

		function showPage(e) {
			var dotId = $(e.currentTarget).data('page');
			me.$el.find('#dots-wrap-'+id+' .dots').removeClass('active');
			$(e.currentTarget).addClass('active');
			pageNum = dotId;
			renderPage(dotId);
		}

		PDFJS.getDocument(url).then(function (pdfDoc_) {
			pdfDoc = pdfDoc_;
			renderPage(pageNum);
			renderDots(id);
		});
	},
	onSearchChange: function(e){
		e.preventDefault();
		var query = this.$el.find(".b-search-input").val();
		if(query){
			this.trigger("search:support", query);
		}else{
			this.showNoDataDisplay();
		}

	}
});