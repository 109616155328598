/**
 * Created by sergey on 24.12.2015.
 */
var tableMixin = require("mixins/Table");
var TemplateTableCPM = require("./templates/CPMTable.hbs");
var Helpers = require("helpers/Helpers");
module.exports =  Marionette.ItemView.extend(_.extend(tableMixin, {
	className: "b-table f_12 general-table",
	tagName: "table",
	template: TemplateTableCPM,
	events:{
		"click .b-favorite": "onStarredClick"
	},
	detailRows: [],
	initialize: function(){
		this.listenTo(this.model, "change:filter", this.onFilterChange.bind(this));
		this.listenTo(this.model, "change:query", this.onSearchChange.bind(this));
	},
	refreshData: function(){
		this.drawTable();
	},
	getData: function(){
		return this.model.get("ads");
	},
	onShow: function(){
		var me = this;
		this.table = this.$el.DataTable(me.getConfig());
		this.addBodyClass();
		this.addSelected();
		this.detailRowsFn();
	},
	getConfig: function(){
		var me = this;
		return {
			"paging": false,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns(),
			"order": [[ 1, "desc" ]],
			"language": {
				"emptyTable": "N/A"
			}
		}
	},
	getColumns: function () {
		return [
			{
				className: "country_name", data: "country_name", render: function (data) {
				if (data) {
					return "<span class='b-tooltip b-tooltip_f-12 b-tooltip-nowrap' data-desc='"+data+"'><span>"+data+"</span></span>";
				}
				return "";
			}
			},
			{
				data: "country_total_views", render: function (data) {
				if (data) {
					return data;
				}
				return "";
			}
			},
			{
				data: "estimated_monetized_playbacks", render: function (data) {
				if (data) {
					return data;
				}
				return "";
			}
			},
			{
				data: "impressions",
				render: function (data) {
					if (data) {
						return data;
					}
					return "";
				}
			},
			{
				data: "sell_out", render: function (data) {
				if(data){
					return data;
				}
				return "";
			}
			},
			{
				className: "playback_based_cpm", data: "playback_based_cpm",
				render: function (data) {
					if (data) {
						return data.toFixed(2);
					}
					return "";
				}
			}
		];
	},
	checkSelectItems: function(value){
		var ids = this.getSelection();
		if(ids.length === 0){
			this.trigger("item:unselected");
		}else{
			this.trigger("item:selected");
		}
	},
	getSelection: function(){
		return $.map(this.table.rows('.selected').data(), function (item) {
			return item.id;
		});
	},
	//onStarredClick: function(e){
	//	var data = {};
	//	data.channel_id = $(e.currentTarget).data("event_id");
	//	data.switch = $(e.target).hasClass("favorite-active") ? "OFF" : "ON";
	//	$(e.target).toggleClass("favorite-active");
	//	this.trigger("user:click:starred", data);
	//},
	addSelected: function(){
		var me = this;
		this.$el.find('tbody').on( 'click', '.b-input-check', function () {

			if($(this).closest('tr').hasClass('selected')){
				$(this).closest('tr').removeClass('selected');
			}else {
				$(this).closest('tr').addClass('selected');
			}
			me.checkSelectItems();
			me.model.unSetFilterValue();
		});

	},
	detailRowsFn: function(){
		var me = this;
		this.$el.find('tbody').on( 'click', 'tr td.b-inbox-title', function () {
			var tr = $(this).closest('tr');
			var arrow = $(this).closest('tr').find('.i-edit-arrow');
			var row = me.table.row( tr );
			var idx = $.inArray( row.data().id, me.detailRows );

			if ( row.child.isShown() ) {
				tr.removeClass( 'details' );
				arrow.removeClass( 'details' );
				row.child.hide();

				// Remove from the 'open' array
				me.detailRows.splice( idx, 1 );
			}
			else {
				tr.addClass( 'details' );
				arrow.addClass( 'details' );
				row.child(row.data().description).show();
				row.child().addClass('_show');
				if(tr.hasClass('odd')){
					row.child().addClass('odd');
				}else{
					row.child().addClass('even');
				}
				// Add to the 'open' array
				if ( idx === -1 ) {
					me.detailRows.push( row.data().id);
				}
			}
		});

	},
	drawTable: function(data){
		this.table.clear();
		this.table.rows.add(this.getData()); // Add new data
		this.table.columns.adjust().draw(); // Redraw the DataTable
	}
}));