/**
 * Created by sergey on 25.11.2015.
 */
var PaymentTemplate = require("./templates/ProfilePaymentLayout.hbs");
module.exports = Marionette.LayoutView.extend({
	state: undefined,
	states: {
			init: {
				initialize: function (target) {
					this.target = target;
				},
				exit: function () {

				},
				execute: function () {

				},
				enter: function () {

				},
				webmoney: function () {
					this.target.changeState(this.target.states.webmoney);
				},
				bank: function () {
					this.target.changeState(this.target.states.bank);
				},
				payoneer: function () {
					this.target.changeState(this.target.states.payoneer);
				}
			},
			webmoney: {
				initialize: function (target) {
					this.target = target;
				},
				exit: function () {

				},
				execute: function () {

				},
				enter: function () {
					this.target.trigger("change:state", "webmoney");
					this.target.hideError();
					this.target.ui.webmoneyRadio.prop("checked", true);
				},
				webmoney: function () {

				},
				bank: function () {
					this.target.changeState(this.target.states.bank);
				},
				payoneer: function () {
					this.target.changeState(this.target.states.payoneer);
				}
			},
			bank: {
				initialize: function (target) {
					this.target = target;
				},
				exit: function () {

				},
				execute: function () {

				},
				enter: function () {
					this.target.trigger("change:state", "bank");
					this.target.ui.bankRadio.prop("checked", true);
					this.target.hideError();
				},
				webmoney: function () {
					this.target.changeState(this.target.states.webmoney);
				},
				bank: function () {

				},
				payoneer: function () {
					this.target.changeState(this.target.states.payoneer);
				}
			},
			payoneer: {
				initialize: function (target) {
					this.target = target;
				},
				exit: function () {

				},
				execute: function () {

				},
				enter: function () {
					this.target.trigger("change:state", "payoneer");
					this.target.ui.payoneerRadio.prop("checked", true);
					this.target.hideError();
				},
				payoneer: function () {

				},
				webmoney: function () {
					this.target.changeState(this.target.states.webmoney);
				},
				bank: function () {
					this.target.changeState(this.target.states.bank);
				}
			}
	},
	bank: function(){
		this.state.bank();
	},
	payoneer: function(){
		this.state.payoneer();
	},
	webmoney: function(){
		this.state.webmoney();
	},
	ui:{
		payoneerInfoBtn: ".show-info",
		payoneerStatus: ".js-payoneer-status",
		webmoneyRadio: ".js-webmoney-profile-radio",
		bankRadio: ".js-bank-profile-radio",
		payoneerRadio: ".js-payoneer-profile-radio",
		errorBox: ".b-error-text",
		paymentTitle: ".b-payment-title",
		paymentAccount: ".b-payment-account",
		changeLinkWrap: ".b-change-link-wrap"
	},
	events: {
		"click input[name='payment-type']": "onTypeChange",
		"click @ui.payoneerInfoBtn" : "showInfo",
		"click @ui.changeLinkWrap" : "showPaymentBlock"
	},
	initialize: function(){
		this.states.init.initialize(this);
		this.states.bank.initialize(this);
		this.states.payoneer.initialize(this);
		this.states.webmoney.initialize(this);
		this.state = this.states.init;
		this.validationState = false;
		this.listenTo(this.model, "change:payment_type", this.setState.bind(this));
		this.listenTo(this.model, "invlaid:user:save", this.showError.bind(this));
		this.listenTo(this.model, "validated:invalid", this.showError.bind(this));
		this.listenTo(this.model, "webmoney:save:success", this.changePaymentMethod.bind(this));
		this.listenTo(this.model, "payoneer:iframe:insert", this.changePaymentMethod.bind(this));
		this.listenTo(this.model, "payoneer:status:set", this.changePaymentMethod.bind(this));
		this.listenTo(this.model, "bank:save:success", this.changePaymentMethod.bind(this));
	},
	changeState: function(state) {
		if (this.state !== state) {
			this.state.exit();
			this.state = state;
			this.state.enter();
			this.state.execute();
		}
	},
	regions: {
		paymentsFormRegion: ".js-profile-payment-block"
	},
	template: PaymentTemplate,
	templateHelpers: function(){
		var me = this;
		return {
			payment_method: me.model.codeMap[me.model.get("payment_type")]
		}
	},
	setState: function(paramType, modelValue){
		var me = this,
			type;
		if(_.isNumber(paramType)){
			type = paramType;
		}else {
			type = modelValue;
		}
		typeof this[me.model.codeMap[String(type)]] === "function" ? this[me.model.codeMap[String(type)]](): this.webmoney();
	},
	onShow: function(){
		this.setState(this.model.get("payment_type"));
	},
	onTypeChange: function(e){
		var type = $(e.target).val();
		this.trigger("set:payment:type", Number(type));
	},
	showError: function(text, errors){
		if(_.isArray(errors)){
			this.ui.errorBox.find("span").text('You have entered incorrect bank details. Please read our notice below.')
		}else if(_.isObject(errors)){
			var p_type = text.attributes.payment_type;
			if(p_type == 7){
				this.ui.errorBox.find("span").text("You have entered incorrect WMZ purse format. WMZ purse consists of the Z letter and 12 digits.");
			}else if(p_type == 1){
				this.ui.errorBox.find("span").text("You have entered incorrect bank details. Please read our notice below.");
			}
		}else{
			this.ui.errorBox.find("span").text(text);
		}

		if(!this.validationState){
			this.ui.errorBox.slideDown(300);
		}
		this.validationState = true;
	},
	hideError: function(text){
		if(this.validationState){
			this.ui.errorBox.slideUp(300);
		}
		this.ui.errorBox.find("span").text("");
		this.validationState = false;
	},

	changePaymentMethod: function(){
		if(this.model.codeMap[this.model.get("payment_type")] == "payoneer"){
			this.ui.payoneerStatus.removeClass("b-hide-item");
			this.ui.paymentTitle.text(this.model.codeMap[this.model.get("payment_type")]);
			this.ui.paymentAccount.text("");
			if(this.model.get("payoneer_login")){
				this.ui.payoneerStatus.text(this.model.get("payoneer_login"));
			}else{
				if(this.model.get("payoneer_status") == 1){
					this.ui.payoneerStatus.text("Pending");
					this.ui.payoneerStatus.addClass("b-Pending");
					this.ui.payoneerStatus.removeClass("b-Approved");
					this.ui.payoneerStatus.removeClass("b-Denied");
				}else if(this.model.get("payoneer_status") == 10) {
					this.ui.payoneerStatus.text("Approved");
					this.ui.payoneerStatus.addClass("b-Approved");
					this.ui.payoneerStatus.removeClass("b-Pending");
					this.ui.payoneerStatus.removeClass("b-Denied");
				}else if(this.model.get("payoneer_status") >=2 && this.model.get("payoneer_status") <=9){
					this.ui.payoneerStatus.text("Pending");
					this.ui.payoneerStatus.addClass("b-Pending");
					this.ui.payoneerStatus.removeClass("b-Approved");
					this.ui.payoneerStatus.removeClass("b-Denied");
				}else if(this.model.get("payoneer_status") < 0){
					this.ui.payoneerStatus.text("Denied");
					this.ui.payoneerStatus.addClass("b-Denied");
					this.ui.payoneerStatus.removeClass("b-Approved");
					this.ui.payoneerStatus.removeClass("b-Pending");
				}
			}
		}else{
			this.ui.payoneerStatus.addClass("b-hide-item");
			this.ui.paymentTitle.text(this.model.codeMap[this.model.get("payment_type")]);
			this.ui.paymentAccount.text(this.model.get("payment_account"));
			this.showPaymentBlock();
			$('.b-change-link').css('opacity',0);
			$('.js-pay-success-link').fadeIn(300);
			setTimeout(function(){
				$('.b-change-link').animate({'opacity':1},400);
				$('.js-pay-success-link').fadeOut(300);
			},3000);
		}
		this.hideError();
	},
	showInfo: function(e){
		var id = $(e.currentTarget).attr('data-type');
		$('.b-content_'+id).slideToggle(300);
		$(e.currentTarget).toggleClass('_show');
		if($(e.currentTarget).hasClass('_show')){
			$(e.currentTarget).html('hide')
		}else{
			$(e.currentTarget).html('more')
		}
	},
	showPaymentBlock: function(){
		var link = this.ui.changeLinkWrap;
		link.toggleClass('_show');
		this.$el.find('.b-payment-type-block').toggleClass('open');
		this.$el.find('.b-change-link').addClass('b-link_invis');
		this.$el.find('.b-payment-type-form').addClass('_show');
		if(link.hasClass('_show')){
			link.find('.b-change-link').addClass('b-link_invis');
		}else{
			link.find('.b-change-link').removeClass('b-link_invis');
		}
	},
	timeOut: function(){
		var _this = this;
		setTimeout(function() {
		    _this.fadeInBlock();
		}, 300);
	},
	fadeInBlock: function(){
		this.$el.find('.b-payment-type-form').addClass('_show')
	}
});