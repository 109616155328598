/**
 * Created by sergey on 02.12.2015.
 */
var YearListTemplate = require("./templates/ToolbarYear.hbs");
var Carousel = require("widgets/Carousel");
module.exports = Marionette.ItemView.extend({
	className: "b-slick-center b-slick-year clearfix js-payout-carousel-year",
	template: YearListTemplate,
	events: {
		"click .slick-slide" : "onClickSlide"
	},
	initialize: function(){
		this.carousel = new Carousel();
		this.yearMap = {
			2015: 0,
			2016: 1,
			2017: 2,
			2018: 3,
			2019: 4,
			2020: 5,
			2021: 6,
                        2022: 7,
                        2023: 8,
                        2024: 9
		};
		this.yearMapRevers = {
			0: 2015,
			1: 2016,
			2: 2017,
			3: 2018,
			4: 2019,
			5: 2020,
			6: 2021,
                        7: 2022,
                        8: 2023,
                        9: 2024
		};
	},
	onShow: function(e){
		var me = this;
		this.carousel.show(this.$el, {
			centerMode: false,
			//centerPadding: '185px',
			centerPadding: '45px',
			slidesToShow: 6,
			focusOnSelect: true,
			initialSlide: me.yearMap[me.options["year"]],
			view: me
		});
	},
	onBeforeDestroy: function(){
		this.carousel.destroy();
	},
	onClickSlide: function (event) {
		this.trigger("user:change:payout:year", this.yearMapRevers[$(event.target).data("slick-index")]);
	}
});
