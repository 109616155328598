/**
 * Created by sergey on 14.12.2015.
 */
var tableMixin = require("mixins/Table");
var TemplateTableNews = require("./templates/NewsTable.hbs");
var Helpers = require("helpers/Helpers");
module.exports =  Marionette.ItemView.extend(_.extend(tableMixin, {
	className: "b-table f_12 b-inbox-table",
	tagName: "table",
	template: TemplateTableNews,
	events:{
		"click .b-favorite": "onStarredClick"
	},
	detailRows: [],
	initialize: function(){
		this.listenTo(this.model, "change:filter", this.onFilterChange.bind(this));
		this.listenTo(this.model, "change:query", this.onSearchChange.bind(this));
		this.listenTo(this.model, "read:news", this.readNotifies.bind(this));
		this.listenTo(this.model, "unread:news", this.unReadNotifies.bind(this));
		this.listenTo(this.model, "addstars:news", this.addStars.bind(this));
		this.listenTo(this.model, "removestars:news", this.removeStars.bind(this));
	},
	refreshData: function(data){
		this.drawTable(data);
	},
	getData: function(){
		return this.model.get("news");
	},
	onShow: function(){
		var me = this;
		this.table = this.$el.DataTable(me.getConfig());
		this.addBodyClass();
		this.addSelected();
		this.detailRowsFn();
	},
	getConfig: function(){
		var me = this;
		return {
			"paging": false,
			"createdRow": me.createRow,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns(),
			"language": {
				"emptyTable": "N/A"
			},
			"drawCallback": function( settings ) {
				$(this).find("thead").hide();
			}

		}
	},
	getColumns: function() {
		return [
			{
				className: "b-inbox-check b-not-open", render: function (data, type, full, meta) {
					return '<input type="checkbox" id="' + full.id + '" class="b-input-check"><label for="' + full.id + '"><span></span></label>';
				}
			},
			{
				className: "b-channel-favorite b-not-open",
				render: function (data, type, full, meta) {
					if(!full.starred){
						return '<button type="button" class="b-favorite" data-id="'+full.id+'"><span class="i-edit i-edit-favorite"></span></button>';
					}else {
						return '<button type="button" class="b-favorite" data-id="'+full.id+'""><span class="i-edit i-edit-favorite favorite-active"></span></button>';
					}

				}
			},
			{
				className: "b-inbox-title", data: "title"
			},
			{
				className: "b-inbox-date", data: "create_time"
			},
			{
				className: "details-control",
				orderable:      false,
				data:           null,
				defaultContent: "",
				render: function () {
					return '<span class="i-edit i-edit-arrow"></span>';
				}
			}
		];
	},
	checkSelectItems: function(value){
		var ids = this.getSelection();
		if(ids.length === 0){
			this.trigger("item:unselected");
		}else{
			this.trigger("item:selected");
		}
	},
	getSelection: function(){
		return $.map(this.table.rows('.selected').data(), function (item) {
			return item.id;
		});
	},
	onStarredClick: function(e){
		var data = {};
		data.news_ids = $(e.currentTarget).data("id");
		data.starred = $(e.target).hasClass("favorite-active") ? false : true;
		$(e.target).toggleClass("favorite-active");
		this.trigger("user:click:starred", data);
	},
	addSelected: function(){
		var me = this;
		this.$el.find('tbody').on( 'click', '.b-input-check', function () {

			if($(this).closest('tr').hasClass('selected')){
				$(this).closest('tr').removeClass('selected');
			}else {
				$(this).closest('tr').addClass('selected');
			}
			me.checkSelectItems();
			me.model.unSetFilterValue();
		});

	},
	detailRowsFn: function(){
		var me = this;
		this.$el.find('tbody').on( 'click', 'tr td:not(".b-not-open")', function () {
			var tr = $(this).closest('tr');
			var arrow = $(this).closest('tr').find('.i-edit-arrow');
			var row = me.table.row( tr );
			var idx = $.inArray( row.data().id, me.detailRows );

			if ( row.child.isShown() ) {
				tr.removeClass( 'details' );
				arrow.removeClass( 'details' );
				row.child.hide();

				// Remove from the 'open' array
				me.detailRows.splice( idx, 1 );
			}
			else {
				tr.addClass( 'details' );
				arrow.addClass( 'details' );
				me.trigger("user:click:row", function(data){
					row.child(data).show();
					$(row.node()).removeClass("b-unread-row");
					row.child().addClass('_show');
					if(tr.hasClass('odd')){
						row.child().addClass('odd');
					}else{
						row.child().addClass('even');
					}
				}, row.data().id);
				row.child(row.data().description).show();
				row.child().addClass('_show');
				if(tr.hasClass('odd')){
					row.child().addClass('odd');
				}else{
					row.child().addClass('even');
				}
				// Add to the 'open' array
				if ( idx === -1 ) {
					me.detailRows.push( row.data().id);
				}
			}
		});

		this.table.on( 'draw', function () {
			var objectMap={};
			_.reduce(me.detailRows, function(result, n, key){
				result[n] = true;
				return result;
			},objectMap);

			me.table.rows().data().each(function(el,index){
				if(objectMap[el.id]){
					var $node = $(me.table.row(index).node());
					$node.trigger('click');
				}
			});
		} );
		if(me.options["news_id"]){
			this.table.rows().data().each(function(el,index){
				if(me.options["news_id"] == el.id){
					var $node = $(me.table.row(index).node());
					$node.find(".b-inbox-title").trigger('click');
				}
			});
		}


	},
	drawTable: function(data){
		var me = this;
		this.table.clear().draw();
		this.table.rows.add(me.getData()); // Add new data
		this.table.columns.adjust().draw(); // Redraw the DataTable
	},
	onFilterChange: function(model, value){
		var me = this;
		if(value === "all"){
			this.selectAll();
		}else if(value === "none"){
			this.deSelectAll();
		}else if(value === "starred"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(me.table.row(index).data().starred){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "unstarred"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(!me.table.row(index).data().starred){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "read"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(me.table.row(index).data().read){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "inread"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(!me.table.row(index).data().read){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}
		this.checkSelectItems(value);
	},
	onSearchChange: function(model, value){
		this.table.search(value).draw();
	},
	createRow: function( row, data, dataIndex ) {
		if(!data.read){
			$(row).addClass("b-unread-row");
		}
	},
	readNotifies: function(ids, objectMap){
		var me = this;
		this.table.rows().data().each(function(el,index){
			if(objectMap[el.id]){
				var $node = $(me.table.row(index).node());
				$node.removeClass("b-unread-row");
			}
		});
	},
	unReadNotifies: function(ids, objectMap){
		var me = this;
		this.table.rows().data().each(function(el,index){
			if(objectMap[el.id]){
				var $node = $(me.table.row(index).node());
				$node.addClass("b-unread-row");
			}
		});
	},
	addStars: function(ids, objectMap){
		var me = this;
		this.table.rows().data().each(function(el,index){
			if(objectMap[el.id]){
				var $node = $(me.table.row(index).node());
				$node.find(".i-edit-favorite").addClass("favorite-active");
			}
		});
	},
	removeStars: function(ids, objectMap){
		var me = this;
		this.table.rows().data().each(function(el,index){
			if(objectMap[el.id]){
				var $node = $(me.table.row(index).node());
				$node.find(".i-edit-favorite").removeClass("favorite-active");
			}
		});
	}
}));