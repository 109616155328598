module.exports = Backbone.Model.extend({
	url: "/api/faq/faq_list",
	getAnswer: function(data, fn){
		var me = this;
		$.ajax({
			type: "POST",
			url: "/api/faq/faq_message",
			data: {id: data.id},
			dataType: "json"
		}).then(function(resp){
			me.set({rows: resp.body.answer});
			fn(resp.body.answer);
		});
	},
	searchSupport: function(data){
		var me =  this;
		this.set({search: data});
		$.ajax({
			type: "GET",
			url: "/api/faq/faq_list",
			data: {search: me.get("search")},
			dataType: "json"
		}).then(function(resp){
			me.set("search_list", resp.body.answer);
			me.trigger("search:done")
		});
	},
	parse: function(resp){
		var result = {};
		result.faq_list = resp.body.answer;
		return result;
	}
});