/**
 * Created by sergey on 23.11.2015.
 */
var ProfileContactForm = require("./form/Form.js");
var ProfileContactTemplate = require("./templates/ProfileContactForm.hbs");
var ProfileContactCountryTemplate = require("./templates/CountryTemplate.hbs");
module.exports = ProfileContactForm.extend({
	template: ProfileContactTemplate,
	events:{
		"change .b-input_country": "onChangeCountry",
		"change .b-input_address": "onChangeAddress",
		"change .b-input_profile_town": "onChangeTown",
		"change .b-input_email_for": "onChangeEmailFor",
		"change .b-input_email": "onChangeEmail",
		"click @ui.editLinkWrap": "editProfileData"
	},
	triggers: {
		"click .b-page_save-link ": "click:save:btn"
	},
	initialize: function(){
		this.listenTo(this.model,  "change:country", this.changeCountry.bind(this));
		this.listenTo(this.model,  "change:address", this.changeAddress.bind(this));
		this.listenTo(this.model,  "change:email_for", this.changeEmailFor.bind(this));
		this.listenTo(this.model,  "change:town", this.changeTown.bind(this));
		this.listenTo(this.model,  "change:email", this.changeEmail.bind(this));
		this.listenTo(this.model,  "contacts:data:success", this.hideControls.bind(this));
	},
	showValidationError: function(view, attr){
		var $el = view.$(".b-input_"+attr);
		$el.addClass('b-input-error');
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$(".b-input_"+attr);
		$el.removeClass('b-input-error');
	},
	onChangeCountry: function(){
		var val = this.$el.find(".b-input_country").val();
		this.trigger("user:change:country", val);
	},
	onChangeAddress: function(){
		var val = this.$el.find(".b-input_address").val();
		this.trigger("user:change:address", val);
	},
	onChangeTown: function(){
		var val = this.$el.find(".b-input_profile_town").val();
		this.trigger("user:change:town", val);
	},
	onChangeEmailFor: function(){
		var val = this.$el.find(".b-input_email_for").val();
		this.$el.find(".b-input_email_for").removeClass("b-input-error");
		this.trigger("user:change:email:for", val);
	},
	onChangeEmail: function(){
		var val = this.$el.find(".b-input_email").val();
		this.$el.find(".b-input_email").removeClass("b-input-error");
		this.trigger("user:change:email", val);
	},
	changeCountry: function(){
		var me = this;
		this.$el.find(".js-edit-profile-country-block").html(ProfileContactCountryTemplate({countries: me.options["countries"],
			country: me.model.get("country")}));
	},
	changeAddress: function(){
		var me = this;
		this.$el.find(".b-edit_address").text(me.model.get("address"));
	},
	changeTown: function(){
		var me = this;
		this.$el.find(".b-edit_town").text(me.model.get("town"));
	},
	changeEmail: function(){
		var me = this;
		this.$el.find(".b-edit_email").text(me.model.get("email"));
	},
	changeEmailFor: function(){
		var me = this;
		this.$el.find(".b-edit_email_for").text(me.model.get("email_for"));
	},
	templateHelpers: function(){
		var me = this;
		return{
			countries: me.options["countries"]
		}
	}
});