/**
 * Created by sergey on 26.11.2015.
 */
var Views = {};

Views.ProfilePaymentLayout = require("./views/ProfilePaymentLayout");

Views.ProfileWebMoneyForm = require("./views/ProfileWebMoneyForm");
Views.ProfileBankForm = require("./views/ProfileBankForm");
Views.ProfilePayoneerForm = require("./views/ProfilePayoneerForm");

module.exports = Marionette.Object.extend({
	payoneerAjax: {},
	createView: function(region, payment_account){
		var me = this;
		var view;
		this.profilePaymentLayout = new Views.ProfilePaymentLayout({model: payment_account});
		me.listenTo(this.profilePaymentLayout, "set:payment:type", function(data){
			payment_account.set("payment_type", data);
		});

		me.listenTo(this.profilePaymentLayout, "change:state", function(data){
			if(data === "webmoney"){
				if(typeof me.payoneerAjax.abort == "function"){
					me.payoneerAjax.abort();
				}
				payment_account.validation = {};
				payment_account.validation.payment_account = function(value){
					var expr = /^(Z|z)\d{12}$/;
					if(!expr.test(value)){
						return "You have entered incorrect WMZ purse format. WMZ purse consists of the Z letter and 12 digits.";
					}
				};
				view = new Views.ProfileWebMoneyForm({model: payment_account});
			} else if(data === "bank"){
				if(typeof me.payoneerAjax.abort == "function"){
					me.payoneerAjax.abort();
				}

				payment_account.validation = {};
				payment_account.validation = {
					bank_f_beneficiary: {
						required: true
					},
					bank_f_country: {
						required: true
					},
					bank_f_town: {
						required: true
					},
					bank_f_streat: {
						required: true
					},
					bank_f_postal_code: {
						required: true
					},
					bank_beneficiary: {
						required: true
					},
					bank_country: {
						required: true
					},
					bank_town: {
						required: true
					},
					bank_streat: {
						required: true
					},
					bank_postal_code: {
						required: true
					},
					bank_routing_code: "validateRouting",
					bank_iban: "validateAccounts",
					bank_swift: "validateNumbers"
				};
				view = new Views.ProfileBankForm({model: payment_account});
			} else if(data === "payoneer"){
				me.payoneerAjax = payment_account.isPayoneerExist();

				$.when(me.payoneerAjax).done(function(){
					view = new Views.ProfilePayoneerForm({model: payment_account});
					me.listenTo(view, "approved:exist:account", function(){
						payment_account.setPayoneer();
					});
					me.listenTo(view, "user:click:cancel", function(data){
						me.profilePaymentLayout.showPaymentBlock();
					});
					me.profilePaymentLayout.paymentsFormRegion.show(view);
				});
			}
			me.listenTo(view, "webmoney:save:data", function(data){
				payment_account.saveWebMoney(data);
			});
			me.listenTo(view, "bank:save:data", function(data){
				payment_account.saveBank(data);
			});
			me.listenTo(view, "user:click:cancel", function(data){
				me.profilePaymentLayout.showPaymentBlock();
			});
			if(view){
				me.profilePaymentLayout.paymentsFormRegion.show(view);
				me.profilePaymentLayout.timeOut();
			}

		});

		region.show(this.profilePaymentLayout);
	},
	show: function(region){
		var me = this;
		var profileParams = Radio.channel("global").request("profile:payment:account:entity");
		$.when(profileParams).done(me.createView.bind(me, region))
	}
});