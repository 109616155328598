/**
 * Created by sergey on 09.12.2015.
 */
var LoginModel =  require("../models/Login/Login");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getNewLoginEntity: function(options){
		return new LoginModel(options);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("new:login:entity", function(){
			return me.getNewLoginEntity();
		});
	}
};