// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"modal-dialog\">\n    <div class=\"modal-content "
    + alias4(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"size","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"modal-header\">\n           <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\"></span></button>\n           <h4 class=\"modal-title\">Confirmation</h4>\n        </div>\n        <div class=\"modal-body text-align-center modal-unite\">\n        	<div class=\"b-unite-desc-1\">\n	            <p class=\"f_16\">Would you like to connect this dashboard to your main dashboard?<br>\n	            Please note after uniting your payment method <span class=\"b-my-pay-method\">"
    + alias4(((helper = (helper = helpers.cabId || (depth0 != null ? depth0.cabId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cabId","hash":{},"data":data}) : helper)))
    + "</span> will be changed to.</p>\n	            <button type=\"submit\" class=\"b-page-button b-page-button_s b-button_yes js-next-desc-btn no-bottom-margin\">Yes</button>\n	            <button type=\"button\" class=\"b-page-button b-page-button_s b-button_no js-decline-btn no-bottom-margin\">No</button>\n        	</div>\n			<div class=\"b-unite-desc-2\" style=\"display: none;\">\n            	<p class=\"f_16\">To confirm the uniting of dashboards please check the mailboxes, which you have entered during each channel registration. We has sent you emails at the email addresses, which you have entered during the registration of each channel from this dashboard. Please read emails and click special security links. If you do not see emails please check the SPAM folder. If you do not find emails in the SPAM folder, please contact us at <a class=\"b-link f_16\" href=\"mailto:support@quizgroup.com\">support@quizgroup.com</a>. Special links are valid within 8 hours.</p>\n				<button type=\"submit\" class=\"b-page-button b-page-button_s b-button_yes js-unite-agree-btn no-bottom-margin\">Ok</button>\n			</div>\n        </div>\n    </div>\n    \n</div>";
},"useData":true});
