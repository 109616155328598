// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-sum-block__item\">\r\n    <span class=\"b-sum-count b-sum-count_left\">$"
    + alias4(((helper = (helper = helpers.sum_earnings || (depth0 != null ? depth0.sum_earnings : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sum_earnings","hash":{},"data":data}) : helper)))
    + "</span>\r\n    <span class=\"b-sum-title b-title_left\">Sum <br> earnings</span>\r\n</div>\r\n<div class=\"b-sum-block__item\">\r\n    <span class=\"b-sum-count b-sum-count_left b-sum-earnings\" data-desc=\"\">$"
    + alias4(((helper = (helper = helpers.sum_payments || (depth0 != null ? depth0.sum_payments : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sum_payments","hash":{},"data":data}) : helper)))
    + "</span>\r\n    <span class=\"b-sum-title b-title_left\">Sum <br> payment</span>\r\n</div>";
},"useData":true});
