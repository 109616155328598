/**
 * Created by gila on 10.12.2015.
 */

var TracksCollection = require("../../collections/Music/Tracks");
module.exports = Backbone.Model.extend({
	url:  "/api/music/category_list?cat_id=2",
	defaults: function(){
		return {
			page: 1,
			type: 2,
			total_size: 0,
			tracks: new TracksCollection()
		}
	},
	getTraksList: function(data){
		var me = this;
		this.set({cat_id: data}, {silent: true});
		var sendData = {
			type: me.get("type"),
			cat_id: me.get("cat_id"),
			page: me.get("page"),
			search_str: me.get("search_str")
		};
		$.ajax({
			type: "POST",
			url: "/api/music/search",
			data: sendData
		}).then(function(resp) {
			me.set({total_size: resp.body.numFound});
			me.get("tracks").reset(resp.body.tracks);
		});
	},
	getResultList: function(data){
		var me =  this;
		this.set({cat_id: data.cat_id, page: data.page, type: data.type, search_str: data.search_str});
		$.ajax({
			type: "POST",
			url: "/api/music/search",
			data: {cat_id: me.get("cat_id"), page: me.get("page"), type: me.get("type"), search_str: me.get("search_str")},
			dataType: "json"
		}).then(function(resp){
			me.set({total_size: resp.body.numFound});
			me.get("tracks").reset(resp.body.tracks);
		});
	},
	getDownload: function(data){
		var me =  this;
		$.ajax({
			type: "POST",
			url: "/api/music/get_full",
			data: {file_id: data},
			dataType: "json"
		}).then(function(resp){
			$(location).attr('href',resp.body.originalUrl);
		});
	},
	getSelectedId: function(){
		if(typeof(Storage) !== "undefined") {
			if(localStorage.getItem("musicSelectedId")){
				return JSON.parse(localStorage.getItem("musicSelectedId")).map(function(item){
					return item.fileID;
				});
			}else{
				return [];
			}
		}
		return [];
	},
	getStaredItems: function(){
		if(typeof(Storage) !== "undefined") {
			if(localStorage.getItem("musicSelectedId")){
				return JSON.parse(localStorage.getItem("musicSelectedId"));
			}else{
				return [];
			}
		}
	},
	toggleStar: function(data){
		var me =  this,
			selectedId = [],
			index, item;
		if(typeof(Storage) !== "undefined") {
			if(localStorage.getItem("musicSelectedId")){
				selectedId = JSON.parse(localStorage.getItem("musicSelectedId"));
			}
			item = _.findWhere(selectedId, {fileID: data.fileID});
			if(!item && data.selected){
				selectedId.push(data);
			}else{
				if(data.selected){
					item.selected = data.selected;
				}else{
					index = _.findIndex(selectedId, {fileID: item.fileID});
					selectedId.splice(index, 1);
				}
			}
			localStorage.setItem("musicSelectedId", JSON.stringify(selectedId));
		}
	}
});