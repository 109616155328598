// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.getMonth || (depth0 && depth0.getMonth) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.payMonth : depth0),{"name":"getMonth","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"b-content-stats__item b-content-stats-item_left\">\n    <span class=\"b-content-stats__title\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Earnings","key":"qg_partner.earning_plural"},"data":data}))
    + "</span>\n    <br>\n    <span class=\"i-edit i-edit_sp-ico i-edit_earning-ico\"></span>\n    <span class=\"i-edit i-edit_sp-ico i-edit_earning-ico i-edit-sp-ico_invis\"></span>\n</div>\n<div class=\"b-content-stats__item b-content-stats-item_center\">\n    <div class=\"b-content-stats__container\">\n        <div class=\"b-content-stats__container-inner\">\n            <span class=\"b-content-stats__number\">"
    + alias3(((helper = (helper = helpers.sumCurrentMonth || (depth0 != null ? depth0.sumCurrentMonth : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"sumCurrentMonth","hash":{},"data":data}) : helper)))
    + "</span>\n                                <span class=\"b-content-stats__text\">\n                                    <span>"
    + alias3((helpers.getMonth || (depth0 && depth0.getMonth) || alias2).call(alias1,(depth0 != null ? depth0.month : depth0),{"name":"getMonth","hash":{},"data":data}))
    + "</span> <span>"
    + alias3(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + "</span>\n                                </span>\n        </div>\n    <!--- ear: "
    + alias3(((helper = (helper = helpers.earning || (depth0 != null ? depth0.earning : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"earning","hash":{},"data":data}) : helper)))
    + " scm: "
    + alias3(((helper = (helper = helpers.sumCurrentMonth || (depth0 != null ? depth0.sumCurrentMonth : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"sumCurrentMonth","hash":{},"data":data}) : helper)))
    + " --->\n    </div>\n    <div class=\"b-content-stats__down-desc b-content-stats-desc_invis\">\n        <span class=\"b-content-stats__desc\">"
    + alias3((helpers.showDiffMoney || (depth0 && depth0.showDiffMoney) || alias2).call(alias1,(depth0 != null ? depth0.sumCurrentMonth : depth0),(depth0 != null ? depth0.sumPrevMonth : depth0),"vs prior period",{"name":"showDiffMoney","hash":{},"data":data}))
    + alias3((helpers.getCheckReport || (depth0 && depth0.getCheckReport) || alias2).call(alias1,(depth0 != null ? depth0.checkReport : depth0),{"name":"getCheckReport","hash":{},"data":data}))
    + "</span>\n    </div>\n</div>\n<div class=\"b-content-stats__item b-content-stats-item_right\">\n    <div class=\"b-content-stats__container\">\n        <div class=\"b-content-stats__container-inner\">\n            <span class=\"b-content-stats__desc\">"
    + alias3((helpers.showDiffMoney || (depth0 && depth0.showDiffMoney) || alias2).call(alias1,(depth0 != null ? depth0.sumCurrentMonth : depth0),(depth0 != null ? depth0.sumPrevMonth : depth0),"vs prior period",{"name":"showDiffMoney","hash":{},"data":data}))
    + "<span class=\"b-earning-span-count b-report\" style=\"font-size: 12px; display: inline-block;\">"
    + alias3((helpers.getCheckReport || (depth0 && depth0.getCheckReport) || alias2).call(alias1,(depth0 != null ? depth0.checkReport : depth0),{"name":"getCheckReport","hash":{},"data":data}))
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.checkReport : depth0),-2,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span></span>\n        </div>\n    </div>\n</div>";
},"useData":true});
