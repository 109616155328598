// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "b-hide-item";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-page-content\">\n	<div class=\"b-page-header\">\n		<div class=\"b-page-header-wrap\">\n			<h1 class=\"b-page-title b-title_header b-title_inbox\">Inbox</h1>\n			<span class=\"b-inbox-count js-inbox-total-text"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.totalCount : depth0),0,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = helpers.totalCount || (depth0 != null ? depth0.totalCount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalCount","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n	</div>			\n	<div class=\"b-page-block b-inbox-page-block\">\n		<div class=\"b-inbox-header\">\n			<div class=\"b-page-tabs\">\n				<div class=\"b-tabs-wrap\">\n					<ul class=\"b-tabs-list\">\n						<li class=\"b-tabs-item b-notif-tab f_12\" data-type=\"notifications\">\n							<button class=\"b-tabs-title\">Notifications</button>\n							<span class=\"b-inbox-count js-inbox-notification-text "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.notificationSum : depth0),0,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = helpers.notificationSum || (depth0 != null ? depth0.notificationSum : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notificationSum","hash":{},"data":data}) : helper)))
    + "</span>\n						</li>\n						<!--li class=\"b-tabs-item b-tickets-tab f_12\" data-type=\"tickets\">\n							<button class=\"b-tabs-title\">Tickets</button>\n							<span class=\"b-inbox-count js-inbox-tickets-text "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.ticketsSum : depth0),0,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = helpers.ticketsSum || (depth0 != null ? depth0.ticketsSum : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ticketsSum","hash":{},"data":data}) : helper)))
    + "</span>\n						</li-->\n						<li class=\"b-tabs-item b-tabs-active b-what-tab f_12\" data-type=\"news\">\n							<button class=\"b-tabs-title\">What’s new?</button>\n						</li>\n					</ul>\n				</div>\n			</div>\n			\n			<div class=\"js-inbox-toolbar\">\n			</div>\n		</div>\n\n		<div class=\"b-item-count-wrap js-inbox-table-page b-hide-item\">\n			<div class=\"b-item-nums b-ib\">1-20&nbsp;из</div>\n			<div class=\"b-item-count b-ib\">340</div>\n		</div>\n        <div class=\"b-box visible\">\n        	<div class=\"js-inbox-main-content b-inbox-body\">\n        		\n        	</div>\n        </div>\n        <div class=\"b-item-count-wrap js-inbox-table-page b-hide-item\">\n            <div class=\"b-item-nums b-ib\">1-20&nbsp;из</div>\n            <div class=\"b-item-count b-ib\">340</div>\n        </div>\n        <div class=\"b-page-form-buttons\">\n        	<button type=\"button\" class=\"b-page-button b-button_yes b-see-more js-inbox-more-btn b-hide-item\">See more +20</button>\n        </div>\n        <div class=\"b-up-button\">back</div>\n	</div>	\n</div>	\n\n"
    + ((stack1 = container.invokePartial(partials.limiter,depth0,{"name":"limiter","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
