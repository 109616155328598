/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var ProfileTemplate = require("./templates/ProfileBlock.hbs");


Views.ProfileBlockView = Marionette.ItemView.extend({
	template: ProfileTemplate,
	tagName: "a",
	attributes:{
		href: "#?page=profile"
	},
	onShow: function(){
		localStorage.setItem('dict',this.model.get("dict_id"));
	}
});
module.exports = Marionette.Object.extend({
	createView: function(region, profile_params){
		this.profile = new Views.ProfileBlockView({model: profile_params});
		region.show(this.profile);
	},
	show: function(region){
		var profileParams = Radio.channel("global").request("dashboard:profile:entity");
		var me = this;
		$.when(profileParams).done(me.createView.bind(me, region))
	}
});