/**
 * Created by sergey on 24.12.2015.
 */
module.exports = Backbone.Model.extend({
	url: "api/analytics/cpm_channels",
	isChannelExistInCpm: function(id){
		var index =_.indexOf(this.get("channels"), id);
		if(index == -1){
			return false;
		}
		return true;
	},

	parse: function(resp){
		return resp.body;
	}
});