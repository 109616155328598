/**
 * Created by sergey on 02.12.2015.
 */
var tableMixin = require("mixins/Table");
var TemplateTablePayout = require("./templates/ChannelsTable.hbs");
var Helpers = require("helpers/Helpers");
module.exports =  Marionette.ItemView.extend(_.extend(tableMixin, {
	className: "b-table f_12 channels-table",
	tagName: "table",
	template: TemplateTablePayout,
	events:{
		"click .b-autoclaim": "onAutoClaimClick",
		"click .b-favorite": "onStarredClick"
	},
	initialize: function(){
		this.contractMap = {
			1: "SignedPDF",
			2: "SignedRS",
			3: "Proсessing",
			4: "Not Signed",
			5: "Not Sent",
			6: "Sent",
			7: "Signed"
		};
		this.listenTo(this.model, "change:filter", this.onFilterChange.bind(this));
		this.listenTo(this.model, "change:query", this.onSearchChange.bind(this));

	},
	refreshData: function(data){
		this.drawTable(data);
	},
	getConfig: function(){
		var me = this;
		return {
			"paging": false,
			"createdRow": me.createRow,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns(),
			"order": [[ 3, "asc" ]],
			"language": {
				"emptyTable": "N/A"
			}
		}
	},
	getData: function(){
		return this.model.get("rows");
	},
	getColumns: function() {
		var me = this;
		return [
			{
				className: "b-channel-check", render: function (data, type, full, meta) {
				return '<input type="checkbox" id="' + full.dict_id + '" class="b-input-check"><label for="'+full.dict_id+'"><span></span></label>';
				}
			},
			{
				className: "b-channel-autoclaim",
				render: function (data, type, full, meta) {
					if(full.hasOwnProperty("autoclaim") && full.autoclaim ===  1){
						return '<button type="button" data-channel_id="'+full.channel_id+'" class="b-autoclaim">'+
						"<span class='i-edit i-edit-autoclaim autoclaim-active b-tooltip' data-desc='We have enabled automatic bulk update of monetization settings for your channel. We have enabled display ads, overlay ads, skippable and non-skippable video ads, long non-skippable video ads and ad breaks before, during and after the video. Natural breaks occur in your videos every 10-15 minutes. To exclude your channel from automatic bulk update of monetization settings, please click the \"Claim\" icon next to your channel. To learn how to enable monetization settings manually, please follow our guideline \"How to enable ads on your videos\".'></span></button>";
					}else{
						return '<button type="button" data-channel_id="'+full.channel_id+'" class="b-autoclaim">'+
						"<span class='i-edit i-edit-autoclaim b-tooltip' data-desc='We have enabled automatic bulk update of monetization settings for your channel. We have enabled display ads, overlay ads, skippable and non-skippable video ads, long non-skippable video ads and ad breaks before, during and after the video. Natural breaks occur in your videos every 10-15 minutes. To exclude your channel from automatic bulk update of monetization settings, please click the \"Claim\" icon next to your channel. To learn how to enable monetization settings manually, please follow our guideline \"How to enable ads on your videos\".'></span></button>";
					}
				}
			},
			{
				className: "b-channel-favorite",
				render: function (data, type, full, meta) {
					if(!full.hasOwnProperty("starred") || full.starred === 0){
						return '<button type="button" class="b-favorite" data-channel_id="'+full.channel_id+'"><span class="i-edit i-edit-favorite"></span></button>';
					}else {
						return '<button type="button" class="b-favorite" data-channel_id="'+full.channel_id+'""><span class="i-edit i-edit-favorite favorite-active"></span></button>';
					}

				}
			},
			{
				className: "b-channel-name",
				render: function (data, type, full, meta){
					return '<a class="b-tooltip b-tooltip_f-12 b-tooltip-nowrap" data-desc="'+full.channel_name+'" href="http://www.youtube.com/channel/'+full.channel_id+'" target="_blank"><span>'+full.channel_name+'</span></a>'
				}
			},
			{data: "channel_short_name", render: function (data, type, full, meta){
					if(data){
						return data;
					} else {
						return 	"&mdash;";
					}
				}
			},
			{
				render: function(data, type, full, meta){
					return '<span class=""><span class="b-text-overflow">'+full.dict_id+'</span></span>';
				}
			},
			{data: "date", render: function (data, type, full, meta) {
				return Helpers.revertDate(data);
			}},
			{data: "revenue", render: function (data, type, full, meta) {
				return data*100;
			}},
			{data: "status", render: function (data, type, full, meta) {
				if(data > 0){
					return "<span class='b-status b-status_good-standing b-download-link right' data-desc='Active'></span>"
				}else{
					return "<span class='b-status b-status_bad-standing b-download-link right' data-desc='Terminated'></span>"
				}
			}},
			{data: "contract_status", render: function (data, type, full, meta) {
				var contractStatus = me.contractMap[data];
				//return '<a href="/api/requests/create_contract?channel_id='+full.channel_id + '">'+ contractStatus +'</a>';
				return '<span>'+ contractStatus  +'</span>';
				
				//var signDescript,
				//	url;
				//if(full.sign_status === 1 && full.registration_id === "QG"){
				//	signDescript = "Signed";
				//	url = "st/content/ytlife/user/qg/"+full.req_id+"/doc"+ full.req_id +".pdf";
				//	return 	'<a class="b-download-link b-download-link_pdf" href="'+url+'" data-desc="'+signDescript+'"><span class="i-edit">' +
				//		'</span><span class="i-edit i-edit_invis"></span></a>';
				//}else{
				//	return 	'<span class="b-not-signed b-download-link" data-desc="Not signed"></span>';
				//}
			}}
		];
	},
	checkSelectItems: function(value){
		var ids = this.getSelection();
		if(ids.length === 0 && this.model.get("action") !== "hide"){
			this.trigger("item:unselected");
		}else{
			this.trigger("item:selected");
		}
	},
	getSelection: function(){
		return $.map(this.table.rows('.selected').data(), function (item) {
			return item.channel_id;
		});
	},
	onAutoClaimClick: function(e){
		var data = {};
		data.channel_id = $(e.currentTarget).data("channel_id");
		data.switch = $(e.target).hasClass("autoclaim-active") ? "OFF" : "ON";
		$(e.target).toggleClass("autoclaim-active");
		this.trigger("user:click:autoclaim", data);
	},
	onStarredClick: function(e){
		var data = {};
		data.channel_id = $(e.currentTarget).data("channel_id");
		data.switch = $(e.target).hasClass("favorite-active") ? "OFF" : "ON";
		$(e.target).toggleClass("favorite-active");
		this.trigger("user:click:starred", data);
	},
	addSelected: function(){
		var me = this;
		this.$el.find('tbody').on( 'click', '.b-input-check', function () {
			if($(this).closest('tr').hasClass('selected')){
				$(this).closest('tr').removeClass('selected');
			}else {
				$(this).closest('tr').addClass('selected');
			}
			me.checkSelectItems();
			me.model.unSetFilterValue();
		});

	},
	drawTable: function(data) {
		var me = this;
		if (data) {
			this.table.rows.add(data); // Add new data
			this.table.columns.adjust().draw(false); // Redraw the DataTable
		} else {
			this.table.clear().draw();
			this.table.rows.add(me.getData()); // Add new data
			this.table.columns.adjust().draw(); // Redraw the DataTable
		}
	},
	onFilterChange: function(model, value){
		var me = this;
		if(value === "all"){
			this.selectAll();
		}else if(value === "none"){
			this.deSelectAll();
		}else if(value === "terminated"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(typeof me.table.row(index).data().status === "undefined" || me.table.row(index).data().status < 0){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "autoclaimed"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(me.table.row(index).data().autoclaim  === 1){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "starred"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(typeof me.table.row(index).data().starred === "string" || Number(me.table.row(index).data().starred) === 1){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "unstarred"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index){
				if(typeof me.table.row(index).data().starred === "undefined"  || !me.table.row(index).data().starred){
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}

			});
		}else if(value === "noautoclaim") {
			this.deSelectAll();
			this.table.rows().data().each(function(el,index) {
				if (typeof me.table.row(index).data().autoclaim === "undefined"  || !me.table.row(index).data().autoclaim) {
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}
			});
		}else if(value === "signedcontract"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index) {
				if(me.table.row(index).data().contract_status === 1 || me.table.row(index).data().contract_status === 2) {
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}
			});
		}else if(value === "nocontract"){
			this.deSelectAll();
			this.table.rows().data().each(function(el,index) {
				if(me.table.row(index).data().contract_status != 1 && me.table.row(index).data().contract_status != 2) {
					var $node = $(me.table.row(index).node());
					$node.addClass("selected");
					$node.find("input[type='checkbox']").prop("checked", true);
				}
			});
		}
		this.checkSelectItems(value);
	},
	onSearchChange: function(model, value){
		this.table.search(value).draw();
	},
	createRow: function( row, data, dataIndex ) {
		if(typeof data.status === "undefined" || data.status < 0){
			$(row).addClass("b-rejected-row");
		}
	}
}));