var DashboardLoginView = require("./view/DashboardLogin");
var DashboardPasswordRecoveryModel = require("models/DashboardPasswordRecovery/DashboardPasswordRecovery");
var AccountEntities = require("entities/Account");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
		this.redirectToDashboard = function(){
			Radio.channel("login").trigger("user:success:login");
		};
		AccountEntities.initialize();
		this.codeMap ={
			"-10": "showGAthError",
			"-1": "showUserIsNotExistError",
			"-2": "showUserBlockError",
			"-3": "showPasswordError",
			"-7": "incorrectQuestOrPass",
			"-6": "limitQuestOrPass",
			"-100": "showUnknownError"
		};
		this.codePassMap ={
			"-1": "passDontMatch",
			"-2": "invalidPass"
		};
	},
	show: function(){
		var fetchQuestion = Radio.channel("global").request("account:secret:questions:entities");
		var me = this;
		$.when(fetchQuestion).done(function(questions){
			var recoveryModel = new DashboardPasswordRecoveryModel();
			var	mainRegion =  Radio.channel("layout").request("get:main:region");
				
			me.dashboardLogin = new DashboardLoginView({questions: questions.toJSON(), model: recoveryModel});
			me.listenTo(me.dashboardLogin, "send:passw", function(pswd){
				$.ajax({
					type: "POST",
					url: GlobalConfig.urls.api_account_pswd_gauth_login,
					data: {password: pswd},
					dataType: "json"
				}).then(me.onSuccessHandler.bind(me), me.onFailHandler.bind(me))
			});
			me.listenTo(me.dashboardLogin, "check:quest", function(data){
				recoveryModel.checkQuestion(data, {success: me.onSuccessRecoveryHandler.bind(me), fail: me.onFailRecoveryHandler.bind(me)});
			});
			me.listenTo(me.dashboardLogin, "set:pass", function(data){
				recoveryModel.setPassword(data, {success: me.onSuccessSetHandler.bind(me), fail: me.onFailSetHandler.bind(me)});
			});
			me.listenTo(me.dashboardLogin, "show", function(){
				Radio.channel("layout").trigger("show:wrapper_n");
			});
			mainRegion.show(me.dashboardLogin);
		});
		Backbone.history.navigate("?page=dashboardlogin");
	},
	onSuccessHandler: function(resp, stringResult, xhr){
		this.redirectToDashboard();
		this.dashboardLogin.loginErrorHide();
	},
	onFailHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardLogin;
		if(!_.isEmpty(xhr.responseJSON.body)){
			code = xhr.responseJSON.body.code;
			typeof  view[me.codeMap[String(code)]] === "function" ? view[me.codeMap[String(code)]](): view.showUnknownError();
		}
		view.loginError();
	},
	onSuccessRecoveryHandler: function(resp, stringResult, xhr){
		if(resp.body.status === 1){
			this.dashboardLogin.recoveryErrorHide();
		}
	},
	onFailRecoveryHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardLogin;
		if(!_.isEmpty(xhr.responseJSON.body)){
			code = xhr.responseJSON.body.code;
			typeof  view[me.codeMap[String(code)]] === "function" ? view[me.codeMap[String(code)]](): view.showUnknownError();
		}
		view.recoveryError();
	},
	onSuccessSetHandler: function(resp, stringResult, xhr){
		var code,
			me = this;
			view = this.dashboardLogin;
		view.passErrorHide();
	},
	onFailSetHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardLogin;
		if(!_.isEmpty(xhr.responseJSON.body)){
			code = xhr.responseJSON.body.code;
			typeof  view[me.codePassMap[String(code)]] === "function" ? view[me.codePassMap[String(code)]](): view.showUnknownError();
		}
		view.passError();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:dashboardlogin", function(){
			me.show();
		});
	}
});
