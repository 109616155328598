var SigninTemplate = require("./template/Signin.hbs");

module.exports = Marionette.LayoutView.extend({
	ui: {
		yesBtn: ".js-join-yes-btn"
	},
	events: {
		"click @ui.yesBtn": "onClickYesBtn"
	},
	className: "wrapper_n",
	template: SigninTemplate,
	onClickYesBtn: function(){
		this.trigger("user:click:yes:btn");
	}
});