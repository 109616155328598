var HeaderTemplate = require("./templates/HeaderShow.hbs");
var HeaderDashboardTemplate = require("./templates/HeaderDashboard.hbs");
var HeaderDashboardLoginTemplate = require("./templates/HeaderDashboardLogin.hbs");
var HeaderHomeTemplate = require("./templates/HeaderDashboardHome.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		loginLink: ".js-login",
		loginOut: ".js-logout",
		rightNavbar: ".js-header-navbar",
		menuLink: ".navbar-menu-ico",
		plusBtn: ".i-edit-open",
		loginBtn: ".navbar-login-button",
		passwordAccessLink: ".js-password-access",
		productChngBtn: ".b-header-switch-pr",
		inboxBtn: ".b-pr-inbox-item",
		labQBtn: ".b-product-labq",
		joinQBtn: ".b-join-button"
	},
	events: {
		"click @ui.loginLink" : "onClickLogin",
		"click @ui.loginOut" : "onClickLogout",
		"click @ui.menuLink" : "onClickMenu",
		"click @ui.plusBtn" : "dropDesc",
		"click @ui.loginBtn" : "showDropMenu",
		"click @ui.passwordAccessLink" : "hideDropMenu",
		"click @ui.productChngBtn" : "showProducts",
		"click @ui.labQBtn" : "goHome",
		"click @ui.inboxBtn" : "goInbox",
		"click @ui.joinQBtn" : "onClickLogin"
	},
	className: "container-fluid",
	template: HeaderTemplate,
	initialize: function(){
		this.listenTo(this.model, "change:auth:state", this.changeRightBar.bind(this))
	},
	onClickLogin: function(e){
		e.preventDefault();
		this.trigger("user:login");
		this.hideDropMenu();
	},
	onClickLogout: function(e){
		this.$el.find('.js-header-navbar').removeClass('b-hide-item');
		this.$el.find('.navbar-info').addClass('b-hide-item');
		e.preventDefault();
		this.trigger("user:logout");
	},
	onClickMenu: function (){
		this.$el.find('.navbar-menu-ico').toggleClass('show');
		if(this.$el.find('.navbar-menu-ico').hasClass('show')){
			$('.b-blackout').fadeIn(300);
		}else{
			$('.b-blackout').fadeOut(300);
		}
	},
	onShow: function(e){
		this.changeRightBar(this.model, this.model.get("isAuth"));
		$(window).scroll(function(){
		    if ($(this).scrollTop() > 30) {
		    	$('.b-header').addClass('_scroll');
		    } else {
		    	$('.b-header').removeClass('_scroll');
		    }
		});
		$('body').on('click', function (e) {
			if(!$('.navbar-login-select').is(e.target) && $('.navbar-login-select').has(e.target).length === 0
					&& $('._show').has(e.target).length === 0) {
						$('.navbar-login-select').removeClass('_show');
				$('.navbar-login-button').removeClass('_active');
			}
			$('.b-product-list').removeClass('_show');
		});
	},
	changeRightBar: function(){
		if(this.model.get("isAuth") === "need:password" || this.model.get("isAuth") === "need:code"){
			this.ui.rightNavbar.html(HeaderDashboardLoginTemplate(this.model.toJSON()));
		}else if(this.model.get("isAuth") === "authorized"){
			this.$el.find('.js-header-navbar').removeClass('b-hide-item');
			this.$el.find('.navbar-info').remove();
			this.ui.rightNavbar.html(HeaderDashboardTemplate(this.model.toJSON()));
		}else if(this.model.get("isAuth") ===  "unauthorized"){
			this.ui.rightNavbar.html(HeaderHomeTemplate());
		}
	},
	showGoogleInfo: function () {
		this.ui.rightNavbar.html(HeaderDashboardLoginTemplate(this.model.toJSON()));
	},
	showDropMenu: function(e){
		$(e.currentTarget).toggleClass('_active');	
		this.$el.find('.navbar-login-select').toggleClass('_show');
		return false;
	},
	hideDropMenu: function(e){
		$('.navbar-login-button').removeClass('_active');
		this.$el.find('.navbar-login-select').removeClass('_show')
	},
	dropDesc: function(e){
		$(e.currentTarget).toggleClass('_open');
		$(e.currentTarget).closest('.navbar-login-item').find('.navbar-pa-desc').slideToggle(300);
	},
	showProducts: function(){
		this.$el.find('.b-product-list').toggleClass('_show');
		return false;
	},
	goHome: function(){
		$(location).attr('href',"#")
	},
	goInbox: function(){
		$(location).attr('href',"#?page=inbox")
	}
});
