/**
 * Created by sergey on 19.11.2015.
 */
var NewCollection = require("../collections/Dashboard/News");
var DashboardProfileModel = require("../models/Dashboard/DashboardProfile");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getNewList: function(options){
		var options = options || {};
		var news = new NewCollection(options);
		var defer = $.Deferred();
		news.fetch({
			success: function(){
				defer.resolve(news);
			}
		});
		return defer.promise();
	},
	getProfile: function(options){
		var options = options || {};
		var profile = new DashboardProfileModel(options);
		var defer = $.Deferred();
		profile.fetch({
			success: function(){
				defer.resolve(profile);
			}
		});
		return defer.promise();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("dashboard:news:entities", function(options){
			return me.getNewList(options);
		});
		Radio.channel("global").reply("dashboard:profile:entity", function(options){
			return me.getProfile(options);
		});
	}
};