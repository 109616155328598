/**
 * Created by sergey on 20.11.2015.
 */
var NotifyMessage = require("./views/NotifyMessage");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	notify: function () {
		var notify = new NotifyMessage();
		var modalRegion =  Radio.channel("layout").request("get:modal:region");
		modalRegion.show(notify);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("error").on("502:notify", function(){
			me.notify();
		});
	}
});