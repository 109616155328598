/**
 * Created by sergey on 07.12.2015.
 */

var Widget = require("./Widget");


module.exports = Widget.extend({
	i: 0,
	show: function(container, options){
		this.container = container;
		container.autocomplete(options);
	},
	setOption: function(option, value){
		this.container.autocomplete("option", option, value);

	},
	destroy: function(){
		this.container.autocomplete("destroy");
	}

});