/**
 * Created by sergey on 04.12.2015.
 */
/**
 * Created by sergey on 19.11.2015.
 */
var ChannelsModel = require("../models/Channel/Channel");
var ReferralsModel = require("../models/Channel/Referrals");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getChannelsEntity: function(options){
		var options = options || {};
		var channels = new ChannelsModel(options);
		var defer = $.Deferred();
		channels.fetch({
			success: function(){
				defer.resolve(channels);
			}
		});
		return defer.promise();
	},
	getReferralsEntity: function(options){
		var options = options || {};
		var referrals = new ReferralsModel(options);
		var defer = $.Deferred();
		referrals.fetch({
			success: function(){
				defer.resolve(referrals);
			}
		});
		return defer.promise();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("channels:channels:entity", function(options){
			return me.getChannelsEntity(options);
		});
		Radio.channel("global").reply("channels:referrals:entity", function(options){
			return me.getReferralsEntity(options);
		});
	}
};