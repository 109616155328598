/**
 * Created by sergey on 24.12.2015.
 */
var ToolbarTemplate = require("./templates/Toolbar.hbs");
var ToolbarShortInfoTemplate = require("./templates/ToolbarShortInfo.hbs");
module.exports = Marionette.ItemView.extend({
	template: ToolbarTemplate,
	ui: {
		channelName: ".js-cpm-search-channel",
		monthValue: ".js-cpm-search-month",
		yearValue: ".js-cpm-search-year",
		shortInfoBlock: ".js-cpm-toolbar-short-info",
		downloadBtn: ".js-cpm-download-btn"
	},
	events:{
		"click .js-cpm-search-btn" : "onClickSearch",
		"click @ui.downloadBtn" : "onClickDownloadBtn"
	},
	initialize: function(){
		this.listenTo(this.model, "search:done", this.onChangeSearchParams)
	},
	templateHelpers: function () {
		var me = this;
		return {
			year_range: me.model.getYears(),
			channel_list: me.model.getChannelList(),
			channel_name: me.model.getChannelName(),
			month_range: me.model.getMonths()
		}
	},
	onChangeSearchParams: function(){
		var me = this;
		this.ui.shortInfoBlock.html(ToolbarShortInfoTemplate({
			channel_name: this.model.getChannelName(),
			month: this.model.get("month"),
			year: this.model.get("year")
		}));
	},
	disableDownloadBtn: function () {
		this.ui.downloadBtn.prop("disabled", true);
	},
	enableDownloadBtn: function () {
		this.ui.downloadBtn.prop("disabled", false);
	},
	onClickDownloadBtn: function (){
		this.disableDownloadBtn();
		this.trigger("download:file");
	},
	onClickSearch: function(){
		var data = {};
		data.channel_id = this.ui.channelName.val();
		data.month = this.ui.monthValue.val();
		data.year = this.ui.yearValue.val();
		this.trigger("user:change:params", data);
		$('.b-change-search').fadeIn(200);
		$('.b-settings-open').slideUp(300);
	}
});