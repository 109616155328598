// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "b-hide-item";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "	<div class=\"b-content-stats__item b-content-stats-item_left\">\n		<span class=\"b-content-stats__title\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Inbox","key":"qg_partner.dashboard.inbox_block_name"},"data":data}))
    + "</span>\n		<br>\n		<span class=\"i-edit i-edit_sp-ico i-edit_inbox-ico\">\n			<span class=\"b-inbox-count "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.count : depth0),0,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"count","hash":{},"data":data}) : helper)))
    + "</span>\n		</span>\n		<span class=\"i-edit i-edit_sp-ico i-edit_inbox-ico i-edit-sp-ico_invis\" ></span>\n	</div>\n	<div class=\"b-content-stats__item b-content-stats-item-tight_right\">\n		<div class=\"b-content-stats__container\">\n			<div class=\"b-content-stats__container-inner\">\n				<span class=\"b-content-stats__text\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"New","key":"qg_partner.dashboard.inbox.new_messages_new"},"data":data}))
    + " <br>\n                    "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"count":(depth0 != null ? depth0.count : depth0),"defaultValue":"messages","key":"qg_partner.message_plural"},"data":data}))
    + "</span>\n			</div>\n		</div>\n	</div>\n\n";
},"useData":true});
