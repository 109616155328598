/**
 * Created by sergey on 01.12.2015.
 */
module.exports = Backbone.Model.extend({
	url: "/api/account/check_secret_question",
	validation: {
		"question": {
			required: true
		},
		"answer": {
			required: true
		}
	},
	checkQuestion: function(data, options){
		var me = this;
		this.validation = {};
		this.validation = {
			"question": {
				required: true
			},
			"answer": {
				required: true
			}
		};
		this.set({question: data.question, answer: data.answer});
		if(this.isValid(true)){
			$.ajax({
				type: "POST",
				url: "/api/account/check_secret_question",
				data: {question: me.get("question"), answer: me.get("answer")},
				dataType: "json"
			}).then(options.success, options.fail);
		}
	},
	setPassword: function(data, options){
		var me =  this;
		this.validation = {};
		this.validation = {
			"password": {
				required: true
			},
			"repassword": {
				required: true
			}
		};
		this.set({password: data.newPass, repassword: data.confirmNewPass});
		if(this.isValid(true)){
			$.ajax({
				type: "POST",
				url: "/api/account/set_password",
				data: {password: me.get("password"), repassword: me.get("repassword")},
				dataType: "json"
			}).then(options.success, options.fail);
		}
	}
});