var CPMLayout = require("./views/CpmLayout");
var CPMEntities = require("entities/CPM");
var ToolbarView = require("./views/Toolbar");
var CpmTable = require("./views/CPMTable");
var CpmSkipTable = require("./views/SkipTable");
var CpmDisplayTable = require("./views/DisplayTable");
module.exports = Marionette.Object.extend({
	state: undefined,
	selectedIds: [],
	states: {
		init: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {

			},
			general: function () {
				this.target.changeState(this.target.states.general);
			},
			skippableVideos: function () {
				this.target.changeState(this.target.states.skippableVideos);
			},
			displayADS: function () {
				this.target.changeState(this.target.states.displayADS);
			}
		},
		general: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showGeneral(this.target.params);
			},
			general: function () {

			},
			skippableVideos: function () {
				this.target.changeState(this.target.states.skippableVideos);
			},
			displayADS: function () {
				this.target.changeState(this.target.states.displayADS);
			}
		},
		skippableVideos: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showSkippableVideos(this.target.params);
			},
			skippableVideos: function () {

			},
			general: function () {
				this.target.changeState(this.target.states.general);
			},
			displayADS: function () {
				this.target.changeState(this.target.states.displayADS);
			}

		},
		displayADS: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showDisplayADS(this.target.params);
			},
			displayADS: function () {

			},
			skippableVideos: function () {
				this.target.changeState(this.target.states.skippableVideos);
			},
			general: function () {
				this.target.changeState(this.target.states.general);
			}

		}
	},
	initialize: function(){
		this.setHandlers();
		CPMEntities.initialize(this);
		this.states.init.initialize(this);
		this.states.general.initialize(this);
		this.states.skippableVideos.initialize(this);
		this.states.displayADS.initialize(this);
		this.state = this.states.init;
	},
	changeState: function(state) {
		if (this.state !== state) {
			this.state.exit();
			this.state = state;
			this.state.enter();
			this.state.execute();
		}
	},
	init: function(){
		this.state.init();
	},
	general: function(){
		this.state.general();
	},
	skippableVideos: function(){
		this.state.skippableVideos();
	},
	displayADS: function(){
		this.state.displayADS();
	},

	showGeneral: function (params) {
		var options = {filter:{}};
		var channel_id = "";
		var month = Radio.channel("login").request("user:month:of:payment");
		var year =  Radio.channel("login").request("user:year:of:payment");
		if(params ? params.channel_id? 1: 0 :0){
			options.filter.channel_id = params.channel_id;
			channel_id = params.channel_id;
		}
		if(params ? params.month? 1: 0 :0){
			month  = params.month;
		}
		if(params ? params.year? 1: 0 :0){
			year  = params.year;
		}
		options.filter.from_date = year +"-"+ month;
		var fetchGeneralAds = Radio.channel("global").request("cpm:general_ads:entity", options);
		var me = this;
		$.when(fetchGeneralAds).done(function(generalAds){
			generalAds.setChannelsList(me.channelList);
			generalAds.setChannelsCpmList(me.cpmChannelList);
			generalAds.set({month: month});
			generalAds.set({year: year});
			generalAds.set({channel_id: channel_id});
			var toolBar = new ToolbarView({model: generalAds});
			me.listenTo(toolBar, "user:change:params", function(data){
				generalAds.setSearchParams(data);
				params.month= data.month;
				params.year= data.year;
			});
			me.listenTo(generalAds, "search:done", function(searchParams){
				cpmTable.refreshData();
				Backbone.history.navigate("#?page=cpm&subpage=general&channel_id="+searchParams.channel_id+
				"&month="+searchParams.month+"&year="+searchParams.year);
			});
			me.listenTo(toolBar, "download:file", function(searchParams){
				$.when(generalAds.getReport(searchParams)).done(function(resp){
					toolBar.enableDownloadBtn();
					$(location).attr('href',resp.body.report_link);
				});
			});
			var cpmTable = new CpmTable({model: generalAds});
			me.cpmLayout.toolbarRegion.show(toolBar);
			me.cpmLayout.generalTabRegion.show(cpmTable);
			Backbone.history.navigate(Backbone.history.getFragment());
		});

	},
	showSkippableVideos: function (params) {
		var options = {filter:{}};
		var channel_id = "";
		var month = Radio.channel("login").request("user:month:of:payment");
		var year = Radio.channel("login").request("user:year:of:payment");
		if(params ? params.channel_id? 1: 0 :0){
			options.filter.channel_id = params.channel_id;
			channel_id = params.channel_id;
		}
		if(params ? params.month? 1: 0 :0){
			month  = params.month;
		}
		if(params ? params.year? 1: 0 :0){
			year  = params.year;
		}
		options.filter.from_date = year +"-"+ month;
		options.filter.ad_type = 1;
		var fetchGeneralAds = Radio.channel("global").request("cpm:ad:type:entity", options);
		var me = this;
		$.when(fetchGeneralAds).done(function(AdsType){
			AdsType.setChannelsList(me.channelList);
			AdsType.setChannelsCpmList(me.cpmChannelList);
			AdsType.set({month: month});
			AdsType.set({year: year});
			AdsType.set({channel_id: channel_id});
			var toolBar = new ToolbarView({model: AdsType});
			var cpmSkipTable = new CpmSkipTable({model: AdsType});
			me.listenTo(toolBar, "user:change:params", function(data){
				data.ad_type = 2;
				AdsType.setSearchParams(data);
				params.month= data.month;
				params.year= data.year;
			});
			me.listenTo(AdsType, "search:done", function(searchParams){
				cpmSkipTable.refreshData();
				Backbone.history.navigate("#?page=cpm&subpage=skipads&channel_id="+searchParams.channel_id+
					"&month="+searchParams.month+"&year="+searchParams.year);
			});
			me.listenTo(toolBar, "download:file", function(searchParams){
				$.when(AdsType.getReport(searchParams)).done(function(resp){
					toolBar.enableDownloadBtn();
					$(location).attr('href',resp.body.link);
				});
			});
			me.cpmLayout.toolbarRegion.show(toolBar);
			me.cpmLayout.adsSkipTabRegion.show(cpmSkipTable);
			Backbone.history.navigate(Backbone.history.getFragment());
		});

	},
	showDisplayADS: function(params){
		var options = {filter:{}};
		var channel_id = "";
		var month = Radio.channel("login").request("user:month:of:payment");
		var year = Radio.channel("login").request("user:year:of:payment"),
			promises = [];
		if(params ? params.channel_id? 1: 0 :0){
			options.filter.channel_id = params.channel_id;
			channel_id = params.channel_id;
		}
		if(params ? params.month? 1: 0 :0){
			month  = params.month;
		}
		if(params ? params.year? 1: 0 :0){
			year  = params.year;
		}
		options.filter.from_date = year +"-"+ month;
		options.filter.ad_type = 2;
		var fetchGeneralAds = Radio.channel("global").request("cpm:ad:type:entity", options);
		var me = this;
		$.when(fetchGeneralAds).done(function(AdsType){
			AdsType.setChannelsList(me.channelList);
			AdsType.setChannelsCpmList(me.cpmChannelList);
			AdsType.set({month: month});
			AdsType.set({year: year});
			AdsType.set({channel_id: channel_id});
			var toolBar = new ToolbarView({model: AdsType});
			var displayTable = new CpmDisplayTable({model: AdsType});
			me.listenTo(toolBar, "user:change:params", function(data){
				data.ad_type = 2;
				AdsType.setSearchParams(data);
				params.month= data.month;
				params.year= data.year;
			});
			me.listenTo(AdsType, "search:done", function(searchParams){
				displayTable.refreshData();
				Backbone.history.navigate("#?page=cpm&subpage=ads&channel_id="+searchParams.channel_id+
					"&month="+searchParams.month+"&year="+searchParams.year);
			});
			me.listenTo(toolBar, "download:file", function(searchParams){
				$.when(AdsType.getReport(searchParams)).done(function(resp){
					toolBar.enableDownloadBtn();
					$(location).attr('href', resp.body.link);
				});
			});
			me.cpmLayout.toolbarRegion.show(toolBar);
			me.cpmLayout.adsTabRegion.show(displayTable);
			Backbone.history.navigate(Backbone.history.getFragment());
		});

	},
	show: function(params){
		var me = this,
			promises = [];
		this.params = params;
			me.cpmLayout = new CPMLayout();
		promises.push(Radio.channel("global").request("channels:channels:entity"));
		promises.push(Radio.channel("global").request("cpm:cpm_channels:entity"));
		$.when.apply($, promises).done(function(channels, cpm_channels) {
			var mainRegion = Radio.channel("layout").request("get:main:region");
			me.channelList = channels;
			me.cpmChannelList = cpm_channels;
			me.listenTo(me.cpmLayout, "show", function (data) {
				if (params ? params.subpage ? 1 : 0 : 0) {
					if (params.subpage === "general") {
						this.general();
						me.cpmLayout.showTabContent("general");
					} else if (params.subpage === "skipads") {
						this.skippableVideos();
						me.cpmLayout.showTabContent("skip")
					} else if (params.subpage === "ads") {
						this.displayADS();
						me.cpmLayout.showTabContent("display");
					} else {
						this.general();
					}
				} else {
					this.general();
				}
			});

			me.listenTo(me.cpmLayout, "user:click:tab", function (data) {
				if (data === "general") {
					this.general();
				} else if (data === "skip") {
					this.skippableVideos();
				} else if (data === "display") {
					this.displayADS();
				}
			});
			mainRegion.show(me.cpmLayout);
		});

	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:cpm", function(params) {
			me.state = me.states.init;
			me.show(params);
		});
	}

});
