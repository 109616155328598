var AdvanceReportLayout = require("./views/AdvancedReportLayout");
var AdvanceReportEntities = require("entities/AdvanceReport");
var ToolbarView = require("./views/AdvanceReportToolbar");
var AdvanceReportTable = require("./views/AdvanceReportTable");
var AdvanceReportDiagram = require("./views/AdvanceReportDiagram");
module.exports = Marionette.Object.extend({
	state: undefined,
	states: {
		init: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {

			},
			table: function () {
				this.target.changeState(this.target.states.table);
			},
			diagram: function () {
				this.target.changeState(this.target.states.diagram);
			}
		},
		table: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showTable(this.target.params);
			},
			table: function () {

			},
			diagram: function () {
				this.target.changeState(this.target.states.diagram);
			}
		},
		diagram: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showDiagram(this.target.params);
			},
			diagram: function () {

			},
			table: function () {
				this.target.changeState(this.target.states.table);
			}
		}
	},
	initialize: function(){
		this.setHandlers();
		AdvanceReportEntities.initialize(this);
		this.states.init.initialize(this);
		this.states.table.initialize(this);
		this.states.diagram.initialize(this);
		this.state = this.states.init;
	},
	changeState: function(state) {
		if (this.state !== state) {
			this.state.exit();
			this.state = state;
			this.state.enter();
			this.state.execute();
		}
	},
	init: function(){
		this.state.init();
	},
	table: function(){
		this.state.table();
	},
	diagram: function(){
		this.state.diagram();
	},
	showTable: function (params) {
		var params = params || {};
		var options = {filter:{}};
		var channel_id = "",
			channel = {};
		var month = Radio.channel("login").request("user:month:of:payment");
		var lastmonth = Radio.channel("login").request("user:month:of:payment");
		var year = Radio.channel("login").request("user:year:of:payment");
		var lastyear = Radio.channel("login").request("user:year:of:payment");
		if(params ? params.channel_id? 1: 0 :0){
			options.filter.channel_id = params.channel_id;
			channel_id = params.channel_id;
		}
		if(params ? params.month? 1: 0 :0){
			month  = params.month;
		}
		if(params ? params.year? 1: 0 :0){
			year  = params.year;
		}
		if(params ? params.last_month? 1: 0 :0){
			lastmonth  = params.last_month;
		}
		if(params ? params.last_year? 1: 0 :0){
			lastyear  = params.last_year;
		}
		options.filter.year = year;
		options.filter.month = month;
		options.filter.last_year = lastyear;
		options.filter.last_month = lastmonth;

		var fetchAdvanceReport = Radio.channel("global").request("advancereport:advancereport:entity", options);
		var me = this;
		$.when(fetchAdvanceReport).done(function(advanceReport){
			if(advanceReport.needShowMoreBtn()){
				me.advancedreportLayout.showMoreBtn();
			}else{
				me.advancedreportLayout.hideMoreBtn();
			}
			me.advancedreportLayout.setCountPage({offset: advanceReport.getPageCount(), total_count: advanceReport.get("total_count")});

			me.listenTo(me.advancedreportLayout, "more:btn:click", function(){
				advanceReport.getMorePayments();
			});
			advanceReport.set({month: month});
			advanceReport.set({year: year});
			advanceReport.set({last_year: lastyear});
			advanceReport.set({last_month: lastmonth});
			advanceReport.setChannelsList(me.channelList);
			if(!channel_id){
				channel = advanceReport.getChannel();
			}else{
				channel = advanceReport.getChannel(channel_id);
			}
			advanceReport.set({channel: channel});
			var toolBar = new ToolbarView({model: advanceReport});
			var reportTable = new AdvanceReportTable({model: advanceReport});

			me.setHeaderInfo(advanceReport);

			me.listenTo(toolBar, "user:click:cancel:btn", function(data){
				params.month=  Radio.channel("login").request("user:month:of:payment");
				params.year= Radio.channel("login").request("user:year:of:payment");
				params.last_year = Radio.channel("login").request("user:year:of:payment");
				params.last_month =  Radio.channel("login").request("user:month:of:payment");
				me.advancedreportLayout.hideConditions();
				advanceReport.setFilterOptions(params);
				$.when(advanceReport.getData()).then(function(){
					advanceReport.setDefaultParams();
					channel = advanceReport.getChannel();
					advanceReport.set({channel: channel});
					me.setHeaderInfo(advanceReport);
					reportTable.refreshData();
					Backbone.history.navigate("#?page=advancedreport&subpage=diagram");
				});
			});

			me.listenTo(toolBar, "user:change:params", function(data){
				advanceReport.setSearchParams(data);
				channel.channel = data.channel_name;
				params.month = data.month;
				params.year = data.year;
				params.last_year = lastyear;
				params.last_month = lastmonth;
			});

			me.listenTo(advanceReport, "search:done", function(searchParams, data){
				if(advanceReport.needShowMoreBtn()){
					me.advancedreportLayout.showMoreBtn();
				}else{
					me.advancedreportLayout.hideMoreBtn();
				}
				me.advancedreportLayout.setCountPage({offset: advanceReport.getPageCount(), total_count: advanceReport.get("total_count")});
				reportTable.refreshData(data);
				Backbone.history.navigate("#?page=advancedreport&subpage=table&channel_id="+searchParams.channel_id+
					"&month="+searchParams.month+"&year="+searchParams.year+"&last_month="+searchParams.last_month+
					"&last_year="+searchParams.last_year);
				channel = advanceReport.getChannel(searchParams.channel_id);
				advanceReport.set({channel: channel});
				me.setHeaderInfo(advanceReport);
			});

			me.advancedreportLayout.toolbarRegion.show(toolBar);
			me.advancedreportLayout.tableTabRegion.show(reportTable);
			Backbone.history.navigate(Backbone.history.getFragment());
		});

	},
	setHeaderInfo: function(model){
		this.advancedreportLayout.setHeaderInfo({
			month: model.get("month"),
			year: model.get("year"),
			last_year: model.get("last_year"),
			last_month: model.get("last_month"),
			channel_name: model.get("channel") ? model.get("channel").channel_name : "",
			sum_earnings: model.get("sum_earnings"),
			sum_payments: model.get("sum_payments")
		});
	},
	showDiagram: function (params) {
		var params = params || {};
		var options = {filter:{}};
		var channel_id = "",
			channel = {};
		var month = Radio.channel("login").request("user:month:of:payment");
		var lastmonth = Radio.channel("login").request("user:month:of:payment");
		var year = Radio.channel("login").request("user:year:of:payment");
		var lastyear = Radio.channel("login").request("user:year:of:payment");
		if(params ? params.channel_id? 1: 0 :0){
			options.filter.channel_id = params.channel_id;
			channel_id = params.channel_id;
		}
		if(params ? params.month? 1: 0 :0){
			month  = params.month;
		}
		if(params ? params.year? 1: 0 :0){
			year  = params.year;
		}
		if(params ? params.last_month? 1: 0 :0){
			lastmonth  = params.last_month;
		}
		if(params ? params.last_year? 1: 0 :0){
			lastyear  = params.last_year;
		}
		options.filter.year = year;
		options.filter.month = month;
		options.filter.last_year = lastyear;
		options.filter.last_month = lastmonth;
		var fetchAdvanceReport = Radio.channel("global").request("advancereport:advancereport:entity", options);
		var me = this;
		$.when(fetchAdvanceReport).done(function(advanceReport){
			advanceReport.set({month: month});
			advanceReport.set({year: year});
			advanceReport.set({last_year: lastyear});
			advanceReport.set({last_month: lastmonth});
			advanceReport.setChannelsList(me.channelList);
			if(!channel_id){
				channel = advanceReport.getChannel();
			}else {
				channel = advanceReport.getChannel(channel_id);
			}
			advanceReport.set({channel: channel});
			var toolBar = new ToolbarView({model: advanceReport});
			var reportDiagram = new AdvanceReportDiagram({model: advanceReport});
			me.setHeaderInfo(advanceReport);
			me.listenTo(toolBar, "user:change:params", function(data){
				advanceReport.setSearchParams(data);
				params.month= data.month;
				params.year= data.year;
				params.last_year = lastyear;
				params.last_month = lastmonth;
			});
			me.listenTo(toolBar, "user:click:cancel:btn", function(data){
				params.month=  Radio.channel("login").request("user:month:of:payment");
				params.year= Radio.channel("login").request("user:year:of:payment");
				params.last_year = Radio.channel("login").request("user:year:of:payment");
				params.last_month =  Radio.channel("login").request("user:month:of:payment");
				me.advancedreportLayout.hideConditions();
				advanceReport.setFilterOptions(params);
				$.when(advanceReport.getData()).then(function(){
					advanceReport.setDefaultParams();
					channel = advanceReport.getChannel();
					advanceReport.set({channel: channel});
					me.setHeaderInfo(advanceReport);
					reportDiagram.refreshData();
					Backbone.history.navigate("#?page=advancedreport&subpage=diagram");
				});
			});
			me.listenTo(advanceReport, "search:done", function(searchParams){
				reportDiagram.refreshData();
				Backbone.history.navigate("#?page=advancedreport&subpage=diagram&channel_id="+searchParams.channel_id+
					"&month="+searchParams.month+"&year="+searchParams.year+"&last_month="+searchParams.last_month+
					"&last_year="+searchParams.last_year);
				advanceReport.set({channel: channel});
				me.setHeaderInfo(advanceReport);
			});
			me.advancedreportLayout.toolbarRegion.show(toolBar);
			me.advancedreportLayout.diagramTabRegion.show(reportDiagram);
			Backbone.history.navigate(Backbone.history.getFragment());
		});

	},
	show: function(params){
		var me = this,
			promises = [];
		this.params = params;
		me.advancedreportLayout = new AdvanceReportLayout();
		var fetchChannel = Radio.channel("global").request("channels:channels:entity");
		$.when(fetchChannel).done(function(channels) {
			var mainRegion = Radio.channel("layout").request("get:main:region");
			me.channelList = channels;
			me.listenTo(me.advancedreportLayout, "show", function (data) {
				if (params ? params.subpage ? 1 : 0 : 0) {
					if (params.subpage === "table") {
						this.table();
						me.advancedreportLayout.showTabContent("table");
					} else if (params.subpage === "diagram") {
						this.diagram();
						me.advancedreportLayout.showTabContent("diagram");
					} else {
						this.table();
					}
				} else {
					this.table();
				}
			});
			me.listenTo(me.advancedreportLayout, "user:click:tab", function (data) {
				if (data === "table") {
					this.table();
				} else if (data === "diagram") {
					this.diagram();
				}
			});
			mainRegion.show(me.advancedreportLayout);
		});

	},

	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:advancedreport", function(params) {
			me.state = me.states.init;
			me.show(params);
		});
	}

});
