/**
 * Created by sergey on 25.11.2015.
 */
var ProfileTemplatePaynoneerForm = require("./templates/ProfilePayoneerForm.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		approvedBtn: ".js-profile-approved-exist-paynoneer",
		cancelBtn: ".js-profile-payoneer-cancel",
		statusBlock: ".js-payoneer-status-info",
		payoneerWrap: ".b-payoneer-wrap"
	},
	events:{
		"click @ui.saveBtn": "sendData",
		"click @ui.cancelBtn": "resetForm",
		"submit form": "sendData"
	},
	triggers:{
		"click @ui.approvedBtn": "approved:exist:account",
		"click @ui.cancelBtn": "user:click:cancel"
	},
	className: "b-payment-type-form b-payment-payoneer-form",
	template: ProfileTemplatePaynoneerForm,
	initialize: function(){
		this.listenTo(this.model, "payoneer:iframe:insert", this.insertIframe);
		this.listenTo(this.model, "payoneer:status:set", this.setStatus);
	},
	templateHelpers: function(){
		var me = this;
		return{
			isPayoneerExist: me.model.payoneerState > 0 ? 1 : 0
		}
	},
	insertIframe: function(url){
		//var iframe = $("<iframe frameborder='0' width='100%' height='600' />").attr("src", url);
                var iframe = $("<a class='b-page-button' target='_blank'>Continue at Payoneer</a>").attr('href', url);
		this.$el.find(".js-payoneer-iframe").append(iframe);
		this.$el.find(".js-payoneer-iframe").removeClass("b-hide-item");
		this.$el.find(".b-payoneer-accept").addClass("b-hide-item");
		this.$el.find(".b-payoneer-agree").addClass("b-hide-item");
	},
	setStatus: function(status){
		var url = 'https://payoneer.custhelp.com/app/Contact_us';
		if(this.model.get("payoneer_status") == 1){
			this.ui.statusBlock.text("At the moment your application is being reviewed by Payoneer. As soon as review is completed the status will be changed to \"Approved\". Please note that approvement process may take up to several business days for new Payoneer accounts.");
		}else if(this.model.get("payoneer_status") == 10) {
			this.ui.statusBlock.text("Your Payoneer account has been successfully approved.");
		}else if(this.model.get("payoneer_status") >=2 && this.model.get("payoneer_status") <=9){
			this.ui.statusBlock.text("At the moment your application is being reviewed by Payoneer. As soon as review is completed the status will be changed to \"Approved\". Please note that approvement process may take up to several business days for new Payoneer accounts.");
		}else if(this.model.get("payoneer_status") < 0){
			this.ui.statusBlock.html('Your account has been rejected by Payoneer. Please contact Payoneer at <a class="b-page-link" target="_blank" href="'+url+'">'+url+'</a>');
		}
		this.ui.statusBlock.removeClass("b-hide-item");
		this.ui.payoneerWrap.removeClass("b-hide-item");
		this.$el.find(".js-payoneer-iframe").addClass("b-hide-item");
		this.$el.find(".b-payoneer-accept").addClass("b-hide-item");
		this.$el.find(".b-payoneer-agree").addClass("b-hide-item");
	}

});
