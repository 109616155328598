/**
 * Created by sergey on 02.12.2015.
 */
var Widget = require("./Widget");


module.exports = Widget.extend({
	show: function(container, options){
		this.container = container;
		if(typeof options.beforeChange === "function"){
			container.on('beforeChange', function(){
				options.beforeChange.apply(options.view, Array.prototype.slice.call(arguments));
			});

		}
		container.slick(options);

	},
	getValue: function(){
		return this.container.slick("slickCurrentSlide");
	},
	destroy: function(){
		this.container.slick("unslick");
	}

});