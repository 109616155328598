var ChannelsLayout = require("./views/ChannelsLayout");
var ChannelsToolbar = require("./views/ChannelsToolbar");
var ReferralsToolbar = require("./views/ReferralsToolbar");
var ChannelsHeader = require("./views/ChannelsHeader");
var ReferralsHeader = require("./views/ReferralsHeader");
var ChannelsTable = require("./views/ChannelsTable");
var ReferralsTable = require("./views/ReferralsTable");
var PayoutChannelBlock = require("./views/sumaccount/Controller");
var ViewsAccountBlock = require("./views/sumviews/Controller");
var ChannelsEntities = require("entities/Channel");
var AddChannelView = require("./views/AddChannel");
var LoadingView = require("view/Loading");
module.exports = Marionette.Object.extend({
	state: undefined,
	selectedIds: [],
	prevState: undefined,
	states: {
		init: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {

			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			referrals: function () {
				this.target.changeState(this.target.states.referrals);
			},
			addchannel: function () {
				this.target.changeState(this.target.states.addchannel);
			}

		},
		channels: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showChannels(this.target.params);
			},
			channels: function () {

			},
			referrals: function () {
				this.target.changeState(this.target.states.referrals);
			},
			addchannel: function () {
				this.target.changeState(this.target.states.addchannel);
			}
		},
		referrals: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.showReferrals();
			},
			referrals: function () {

			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			addchannel: function () {
				this.target.changeState(this.target.states.addchannel);
			}
		},
		addchannel: {
			initialize: function (target) {
				this.target = target;
			},
			exit: function () {

			},
			execute: function () {

			},
			enter: function () {
				this.target.addChannel();
			},
			referrals: function () {
				this.target.changeState(this.target.states.referrals);
			},
			channels: function () {
				this.target.changeState(this.target.states.channels);
			},
			addchannel: function () {
				this.target.changeState(this.target.states.addchannel);
			}
		}
	},
	initialize: function(){
		this.setHandlers();
		ChannelsEntities.initialize(this);
		this.states.init.initialize(this);
		this.states.channels.initialize(this);
		this.states.referrals.initialize(this);
		this.states.addchannel.initialize(this);
		this.state = this.states.init;
	},
	changeState: function(state) {
		if (this.state !== state) {
			this.state.exit();
			this.prevState = this.state;
			this.state = state;
			this.state.enter();
			this.state.execute();
		}
	},
	init: function(){
		this.state.init();
	},
	channels: function(){
		this.state.channels();
	},
	referrals: function(){
		this.state.referrals();
	},
	addchannel: function(){
		this.state.addchannel();
	},
	showChannels: function (params) {
		var fetchChannel = Radio.channel("global").request("channels:channels:entity");
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		var me = this;
		var payoutChannelView = new PayoutChannelBlock();
		var viewsChannelView = new ViewsAccountBlock();
		$.when(fetchChannel).done(function(channel){
			var channelsLayout = new ChannelsLayout();
			var headerChannelsView = new ChannelsHeader({model: channel});
			var tableChannelsView = new ChannelsTable({model: channel});
			var toolbarChannelsView = new ChannelsToolbar({model: channel});
			me.listenTo(channelsLayout, "user:click:tab", function(data){
				if(data === "referrals"){
					me.referrals();
					channelsLayout.spinOn();
				}else{
					me.channels();
				}
			});

			me.listenTo(channelsLayout, "more:btn:click", function(){
				channel.getMoreChannels();
			});

			me.listenTo(toolbarChannelsView, "selected:filter", function(data){
				var selectedIds = tableChannelsView.getSelection();
				if(data === "createTicket"){
					Backbone.history.navigate("?page=inbox&subpage=tickets&action=create&channel_id="+selectedIds[0], {trigger: true});
				}
				channel.set("filter", data);
			});
			me.listenTo(tableChannelsView, "user:click:starred", function(data){
				me.selectedIds = tableChannelsView.getSelection();
				channel.setStarred(data);
			});
			me.listenTo(tableChannelsView, "user:click:autoclaim", function(data){
				me.selectedIds = tableChannelsView.getSelection();
				channel.setAutoClaim(data);
			});
			me.listenTo(toolbarChannelsView, "selected:action", function(data){
				channel.set("action", data);
				var ids = tableChannelsView.getSelection();
				var list = "";
				if(data === "hide"){
					if(ids.length > 0){
						toolbarChannelsView.showShowAll();
					}
					channel.filterAction(ids);
					channel.unSetFilterValue();
				}else if(data === "showall"){
					toolbarChannelsView.hideShowAll();
					toolbarChannelsView.deactivedActionMenu();
					channel.clearFilter();
					channel.unSetFilterValue();
				}else if(data === "payout"){
					list = ids.join(",");
					Backbone.history.navigate("?page=payout&channel_list="+list, {trigger: true});
				}else if(data === "cpm"){
					Backbone.history.navigate("?page=cpm&channel_id="+ids[0], {trigger: true});
				}
			});
			me.listenTo(channel, "refresh:filter:rows", function(data){
				if(channel.needShowMoreBtn()){
					channelsLayout.showMoreBtn();
					channelsLayout.setCountPage({offset: channel.getPageCount(), total_count: channel.get("total_count")});
				}else{
					channelsLayout.hideMoreBtn();
					channelsLayout.hideCountPages();
				}
				tableChannelsView.refreshData(data);
				toolbarChannelsView.setSearchList();
				if(me.selectedIds && me.selectedIds.length > 0){
					tableChannelsView.selectRows(me.selectedIds);
					me.selectedIds = [];
				}else{
					me.selectedIds = [];
				}
				if(channel.rowsLength() === 0 ){
					toolbarChannelsView.uncheckAllInput();
				}
			});
			me.listenTo(toolbarChannelsView, "search", function(data){
				channel.set("query", data);
			});
			me.listenTo(tableChannelsView, "item:selected", function(data){
				toolbarChannelsView.checkAllInput();
				var ids = tableChannelsView.getSelection();
				if(ids.length != 1){
					toolbarChannelsView.activedActionMenu();
				}else{
					toolbarChannelsView.showPreload();
					$.when(channel.isChannelCPM(ids[0])).done(function(isExist){
						if(isExist){
							toolbarChannelsView.showCPM();
						}else{
							toolbarChannelsView.hideCPM();
						}
						toolbarChannelsView.hidePreload();
						toolbarChannelsView.activedActionMenu();
					});
				}
				if(ids.length != channel.get("rows").length){
					toolbarChannelsView.checkDash();
				}else if(ids.length == channel.get("rows").length){
					toolbarChannelsView.removeCheckDash();
				}
			});

			me.listenTo(tableChannelsView, "item:unselected", function(data){
				toolbarChannelsView.uncheckAllInput();
				toolbarChannelsView.deactivedActionMenu();
				var ids = tableChannelsView.getSelection();
				if(ids.length != channel.get("rows").length){
					toolbarChannelsView.removeCheckDash();
				}else if(ids.length == channel.get("rows").length){
					toolbarChannelsView.checkDash();
				}else if(ids.length == 0){
					toolbarChannelsView.removeCheckDash();
				}
			});

			me.listenTo(channelsLayout, "show", function(){
				if(params ? params.subpage == "addchannel": 0){
					me.addchannel();
				}
				if(channel.needShowMoreBtn()){
					channelsLayout.showMoreBtn();
				}else{
					channelsLayout.hideMoreBtn();
				}
				channelsLayout.setCountPage({offset: channel.getPageCount(), total_count: channel.get("total_count")});
				channelsLayout.activateChannelsTab();
				channelsLayout.channelsHeaderRegion.show(headerChannelsView);
				channelsLayout.channelsTableRegion.show(tableChannelsView);
				channelsLayout.channelsToolbarRegion.show(toolbarChannelsView);
				channelsLayout.fadeInTable();
			});

			me.listenTo(headerChannelsView, "show", function(){
				payoutChannelView.show(headerChannelsView.getRegion("payoutChannelsRegion"));
				viewsChannelView.show(headerChannelsView.getRegion("viewsChannelsRegion"));
			});

			mainRegion.show(channelsLayout);
			if(params ? params.subpage !== "addchannel" : 0) {
				Backbone.history.navigate("?page=channels");
			}
		});

	},
	showReferrals: function () {
		var promises = [];
		promises.push(Radio.channel("global").request("channels:referrals:entity"));
		promises.push($.ajax({
			method: "GET",
			url: "api/analytics/get_referral_stat"
		}));
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		var me = this;
		$.when.apply($, promises).done(function(referrals, analytic){
			referrals.set(analytic[0].body);
			var channelsLayout = new ChannelsLayout();
			me.listenTo(channelsLayout, "user:click:tab", function(data){
				if(data === "referrals"){
					me.referrals();
				}else{
					channelsLayout.spinOn();
					me.channels();
				}
			});

			var headerReferralsView = new ReferralsHeader({model: referrals});
			var tableChannelsView = new ReferralsTable({model: referrals});
			var toolbarReferralsView = new ReferralsToolbar({model: referrals});

			me.listenTo(referrals, "refresh:filter:rows", function(data){
				if(referrals.needShowMoreBtn()){
					channelsLayout.showMoreBtn();
				}else{
					channelsLayout.hideMoreBtn();
				}
				channelsLayout.setCountPage({offset: referrals.getPageCount(), total_count: referrals.get("total_count")});
				tableChannelsView.refreshData(data);
			});
			
			me.listenTo(tableChannelsView, "item:unselected", function(data){
				toolbarChannelsView.uncheckAllInput();
				toolbarChannelsView.deactivedActionMenu();
			});

			me.listenTo(toolbarReferralsView, "search", function(data){
				referrals.set("query", data);
			});

			me.listenTo(channelsLayout, "more:btn:click", function(){
				referrals.getMoreChannels();
			});

			me.listenTo(channelsLayout, "show", function(){
				if(referrals.needShowMoreBtn()){
					channelsLayout.showMoreBtn();
				}else{
					channelsLayout.hideMoreBtn();
				}
				channelsLayout.setCountPage({offset: referrals.getPageCount(), total_count: referrals.get("total_count")});
				channelsLayout.activateReferralsTab();
				channelsLayout.channelsHeaderRegion.show(headerReferralsView);
				channelsLayout.channelsTableRegion.show(tableChannelsView);
				channelsLayout.channelsToolbarRegion.show(toolbarReferralsView);
				channelsLayout.fadeInTable();
			});
			mainRegion.show(channelsLayout);
			Backbone.history.navigate("?page=channels&subpage=referrals");
		});

	},
	addChannel: function () {
		var me = this;
		window.onLoadRecaptchaCallback = function(){
			var addChannelView = new AddChannelView();
			var modalRegion = Radio.channel("layout").request("get:modal:region");
			me.listenTo(addChannelView, "show", function(){
				grecaptcha.render(addChannelView.getRecaptchaElement(), {
					'sitekey' : '6LdHuRgTAAAAAII49zhmjIVAhlt_6mClIIYilZsF',
					'callback': function(responce){
						$.post("/api/join/add_new_channel", {"g-recaptcha-response": responce}).then(function(resp){

						});
					},
					'theme' : 'light'
				});
			});
			modalRegion.show(addChannelView);
			modalRegion.$el.on('hidden.bs.modal', function (e) {
				delete me.params.subpage;
				me.channels();
			});
			Backbone.history.navigate("?page=channels&subpage=addchannel");
		};
		var s = document.createElement("script");
		s.src = "https://www.google.com/recaptcha/api.js?onload=onLoadRecaptchaCallback&render=explicit";
		$("body").append(s);
	},
	show: function(params){
		var me = this;
		this.params = params;
		if(params ? params.subpage==="referrals"? 1: 0 : 0){
			this.referrals();
		}else{
			this.channels();
		}
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:channels", function(params) {
			me.state = me.states.init;
			me.show(params);
		});
	}
});