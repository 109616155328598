var Step1View = require("./views/Step1");
var JoinModal = require("./views/JoinModal");

module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	show: function(){
		var me = this;
		var step1View = new Step1View();
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		me.listenTo(step1View,"bank:show", me.notify);
		mainRegion.show(step1View);
		Backbone.history.navigate("#?page=joinstep1");
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:joinstep1", function() {
			me.show();
		});
	},
	notify: function () {
		var notify = new JoinModal();
		var modalRegion =  Radio.channel("layout").request("get:modal:region");
		modalRegion.show(notify);
	}
});
