var HeaderTemplate = require("./templates/DashboardPassRecovery.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		questBlock: ".js-question-block",
		sendBtn: ".js-dashboard-recovery-submit",
		errorBlock: ".b-login-error-block",
		passBlock: ".js-password-block",
		setPassBtn: ".js-set-pass-submit"
	},
	events: {
		"click @ui.sendBtn": "sendData",
		"submit .js-dashboard-recovery-submit": "sendData",
		"click @ui.setPassBtn": "setRecoveryPass",
		"submit .js-set-pass-submit": "setRecoveryPass"
	},
	className: "wrapper_n",
	template: HeaderTemplate,
	onRender: function(){
		var me = this;
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				me.hideValidationError(view, attr);
			},
			invalid: function(view, attr, error) {
				me.showValidationError(view, attr);
			}
		});
	},
	showValidationError: function(view, attr){
		var $el = view.$(".recovery-"+attr);
		$el.addClass('b-input-error');
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$(".recovery-"+attr);
		$el.removeClass('b-input-error');
	},
	templateHelpers: function(){
		var me = this;
		return {
			questions: me.options["questions"]
		}
	},
	sendData: function (e) {
		e.preventDefault();
		var question = $(".recovery-question").val();
		var answer = $(".recovery-answer").val();
		var data = {
				question: question,
				answer: answer
			};
		this.trigger("check:quest", data);
	},
	setRecoveryPass: function (e) {
		e.preventDefault();
		var newPass = $(".js-new-pass").val();
		var confirmNewPass = $(".js-confirm-new-pass").val();
		var data = {
				newPass: newPass,
				confirmNewPass: confirmNewPass
			};
		this.trigger("set:pass", data);
	},
	onShow: function () {
	},
	loginError: function(){
		this.$el.find('.js-question-block').addClass('login-error');
		this.$el.find('.b-page-button').removeClass('b-button_yes').addClass('b-button_no');
		var login = $('.b-input-login');
		var loginInput = login.val();
		this.$el.find('.b-login-error-block').fadeIn(300);
	},
	loginErrorHide: function(){
		this.$el.find('.b-login-block').removeClass('login-error');
		this.$el.find('.b-page-button').removeClass('b-button_no').addClass('b-button_yes');
		this.$el.find('.b-login-error-block').fadeOut(300);
	},
	passError: function(text){
		this.$el.find('.js-password-block').addClass('login-error');
		$el.find('.b-login-error-block').text(text);
	},
	passErrorHide: function(){
		this.$el.find('.js-password-block').removeClass('login-error');
	},
	showPassForm: function(){
		this.ui.passBlock.removeClass("b-hide-item");
		this.ui.questBlock.find(".b-page-button").prop('disabled',true);
		$('html, body').animate({scrollTop:150}, 500);
	},
	showGAthError: function(){

	}
});