/**
 * Created by sergey on 17.12.2015.
 */
var TrackModel = require("../../models/Music/Track");

module.exports = Backbone.PageableCollection.extend({
	model: TrackModel,
	state: {
		firstPage: 0,
		pageSize: 20
	},
	mode: "client"
});