/**
 * Created by sergey on 24.12.2015.
 */
var Helpers = require("helpers/Helpers");
module.exports = Backbone.Model.extend({
	initialize: function (options) {
		this.options = options;
		this.month = {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		};
	},
	setChannelsList: function (list) {
		this.channelsList = list;
	},
	setChannelsCpmList: function (cpm_list) {
		this.channelsCpmList = cpm_list;
	},
	getChannelName: function () {
		var item = _.findWhere(this.getChannelList(), {channel_id: this.get("channel_id")});
		if (item) {
			return item.channel_name;
		} else {
			return "";
		}
	},
	getChannelList: function () {
		return this.channelsList.get("rows");
	},
	getCpmPeriods: function () {
		return this.channelsCpmList.get("periods");
	},
	getYears: function () {
		return _.uniq(_.map(this.getCpmPeriods(), function (item) {
			return item.split("-")[0]
		}));
	},
	getMonths: function () {
		var me = this;
		return _.map(_.uniq(this.getCpmPeriods()), function (item) {
			return {
				month_value: Helpers.getMonthNumber(item),
				month_name: me.month[Helpers.getMonthNumber(item)]
			}
		});
	},
	setSearchParams: function(data){
		var newFilterObject = {},
			promises;
		newFilterObject.channel_id = data.channel_id;
		newFilterObject.from_date = data.year+"-"+data.month;
		this.set(data);
		var me = this;
		if(!_.isEqual(this.options.filter, newFilterObject)){
			this.options.filter = newFilterObject;
			$.when(this.getData()).done(function() {
				me.trigger("search:done", data);
			});
		}

	},
	getReport: function(data){
		var sendData = {},
			month = "";
		sendData.channel_id = this.get("channel_id");
		if(Number(this.get("month")) <10){
			month = "0" + this.get("month");
		}else{
			month = this.get("month");
		}
		sendData.from_date = this.get("year")+"-"+month;
		return $.ajax({
			type: "POST",
			url: "/api/analytics/cpm_report_link",
			data: sendData,
			dataType: "json"
		});
	},
	getData: function () {
		var defer = $.Deferred();
		var me = this;
		var params = this.options.filter.from_date.split("-");
		if(Number(params[1])< 10){
			var temp = params[1];
			params[1] = "0"+temp;
			this.options.filter.from_date = params.join("-");
		}
		$.post("/api/analytics/cpm_general_stat", this.options.filter).done(function (resp) {
			me.set({ads: resp.body.answer}, {silent: true});
			defer.resolve(me);
		});
		return defer.promise();
	}
});
