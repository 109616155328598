/**
 * Created by sergey on 22.12.2015.
 */
var CPMChannelsModel = require("../models/CPM/CPMChannels");
var CPMGeneralAdsModel = require("../models/CPM/CPMGeneralAds");
var CPMTypeAdsModel = require("../models/CPM/CPMTypeAds");
var CPMReportModel = require("../models/CPM/CPMReports");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getChannelsForCPM: function(){
		var cpm_channel = new CPMChannelsModel();
		var defer = $.Deferred();
		cpm_channel.fetch({
			success: function(){
				defer.resolve(cpm_channel);
			}
		});
		return defer.promise();
	},
	getGeneralAds: function(options){
		var options = options || {};
		var generalAds = new CPMGeneralAdsModel(options);
		return generalAds.getData();
	},
	getTypeAds: function(options){
		var options = options || {};
		var generalAds = new CPMTypeAdsModel(options);
		return generalAds.getData();
	},
	getReports: function(options){
		var options = options || {};
		var generalAds = new CPMReportModel(options);
		return generalAds.getData();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("cpm:cpm_channels:entity", function(options){
			return me.getChannelsForCPM(options);
		});
		Radio.channel("global").reply("cpm:general_ads:entity", function(options){
			return me.getGeneralAds(options);
		});
		Radio.channel("global").reply("cpm:ad:type:entity", function(options){
			return me.getTypeAds(options);
		});
		Radio.channel("global").reply("cpm:report:entity", function(options){
			return me.getReports(options);
		});
	}
};