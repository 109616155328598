/**
 * Created by sergey on 20.11.2015.
 */
var NotifyMessage = require("./templates/NotifyMessage.hbs");
module.exports = Marionette.ItemView.extend({
	template: NotifyMessage,
	templateHelpers: function(){
		return {
			size: "modal-sm"
		}
	}

});