var RootLayout = require("view/layout/Root");
var ModalRegion = require("config/Marionette/regions/ModalRegion");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.RootView = new RootLayout();
		this.RootView.addRegions({header: ".b-header"});
		this.RootView.addRegions({main: ".b-main-wrapper"});
		this.RootView.addRegions({modal: {el: ".js-modal-region", regionClass: ModalRegion}});
		this.setHandlers();
	},
	showMainLayoutBg: function(){
		this.RootView.getRegion("main").$el.addClass("login-layout");
		$('body').css({'overflow-y': 'hidden'});
	},
	hideMainLayoutBg: function(){
		this.RootView.getRegion("main").$el.removeClass("login-layout");
		$('body').css({'overflow-y': 'scroll'});
	},
	loginFormShow: function(){
		$('body').addClass('_login');
		$('.b-footer').addClass('_login');
		$('.b-header').addClass('hide-login');
	},
	gPlusShow: function(){
		$('.b-header').removeClass('hide-login');
	},
	loginFormHide: function(){
		$('body').removeClass('_login');
		$('.b-footer').removeClass('_login');
		$('.b-header').removeClass('hide-login');
	},
	wrapperShow: function(){
		$('.wrapper_n').css('opacity',1);
	},
	footerRemoveLoginClass: function(){
		$('.b-footer').removeClass('_login');
	},
	showLeftMenu: function(){
		$('.b-left-menu').addClass('_show');
	},
	hideLeftMenu: function(){
		$('.b-left-menu').removeClass('_show');
	},
	joinBgEdit: function(){
		$('body').addClass('join');
	},
	rmJoinBg: function(){
		$('body').removeClass('join');
	},
	setHandlers: function(){
		var me = this;
		Radio.channel('layout').reply("get:header:region", function(){
			return me.RootView.getRegion("header");
		});
		Radio.channel('layout').reply("get:main:region", function(){
			return me.RootView.getRegion("main");
		});
		Radio.channel('layout').reply("get:modal:region", function(){
			return me.RootView.getRegion("modal");
		});
		Radio.channel('layout').on("show:wrapper_n", function(){
			me.wrapperShow();
		});
		Radio.channel("application").on("application:state:login", function(){
			me.showMainLayoutBg();
			me.loginFormShow();	
			me.hideLeftMenu();	
		});
		Radio.channel("application").on("application:state:joinsignin", function(){
			me.loginFormHide();
			me.wrapperShow();
			me.joinBgEdit();
			$('.b-main-wrapper').removeClass('login-layout');
		});
		Radio.channel("application").on("application:state:home", function(){
			me.hideMainLayoutBg();
			me.loginFormHide();
			me.hideLeftMenu();
		});
		Radio.channel("application").on("application:state:dashboard", function(){
			me.hideMainLayoutBg();
			me.loginFormHide();
			me.hideLeftMenu();
			me.rmJoinBg();
		});
		Radio.channel("application").on("application:state:dashboardlogin", function(){
			me.hideMainLayoutBg();
			me.loginFormShow();
			me.gPlusShow();
			me.wrapperShow();
			me.footerRemoveLoginClass();
			me.hideLeftMenu();
		});
		Radio.channel("application").on("application:state:unitedashboard", function(){
			me.hideMainLayoutBg();
			me.loginFormShow();
			me.wrapperShow();
			me.hideLeftMenu();
		});
		Radio.channel("application").on("application:state:dashboard:login:with:code", function(){
			me.hideMainLayoutBg();
			me.loginFormShow();
			me.wrapperShow();
			me.footerRemoveLoginClass();
		});
		Radio.channel("application").on("application:state:dashboard:login:with:password", function(){
			me.hideMainLayoutBg();
			me.loginFormShow();
			me.wrapperShow();
			me.footerRemoveLoginClass();
		});
		Radio.channel("application").on("application:state:dashboard:password:recovery", function(){
			me.hideMainLayoutBg();
			me.loginFormShow();
			me.wrapperShow();
			me.footerRemoveLoginClass();
		});
		Radio.channel("application").on("application:state:dashboard:set:password", function(){
			me.hideMainLayoutBg();
			me.loginFormShow();
			me.wrapperShow();
			me.footerRemoveLoginClass();
		});
		Radio.channel("application").on("application:state:profile", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:payout", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:channels", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:music", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:inbox", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:support", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:cpm", function(){
			me.showLeftMenu();
		});
		Radio.channel("application").on("application:state:advancedreport", function(){
			me.showLeftMenu();
		});
	}
});
