/**
 * Created by sergey on 01.12.2015.
 */
var secretModel = require("../../models/Account/SecretQuestion");
module.exports = Backbone.Collection.extend({
	model: secretModel,
	url: "/api/account/get_secret_questions",
	initialize: function(options){
		this.options = options;
	},
	parse: function(resp){
		return resp.body;
	}
});