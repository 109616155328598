/**
 * Created by sergey on 19.01.2016.
 */
var CreateFormTemplate = require("./templates/TicketsCreateFormTemplate.hbs");
var FileTemplate = require("./templates/FileTemplate.hbs");
var Helpers = require("helpers/Helpers");
module.exports = Marionette.ItemView.extend({
	ui: {
		cancelBtn: ".js-inbox-create-form-ticket",
		selectQustion: ".js-input-question",
		selectChannels: ".js-select-channels"
	},
	events: {
		"submit": "onSubmitForm",
		"change @ui.selectQustion" : "changeSuccessText",
		"click .b-file-remove": "onCancelClick"
	},
	triggers: {
		"click @ui.cancelBtn" : "user:cancel:ticket"
	},
	template: CreateFormTemplate,
	tagName: "form",
	viewsList: {},
	initialize: function(){
		var me = this,
			validatorView = {};
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				validatorView = view;
				me.hideValidationError(validatorView, attr);
			},
			invalid: function(view, attr, error) {
				validatorView = view;
				me.showValidationError(validatorView, attr);
			}
		});
		// this.listenTo(this.model, "validated:invalid", this.showServerError);
	},
	templateHelpers: function () {
		var me = this;
		return{
			channels: me.options["channels"]
		}
	},
	showValidationError: function(view, attr){

		var $el = [];
		if(this.model.getType() == "ticket") {
			$el = view.$(".b-input_"+attr);
			$el.addClass('b-input-error');
		}else{
			$el = view.$("[name='"+attr+"']");
			$el.addClass('b-input-error');
		}
	},
	hideValidationError: function(view, attr, error){
		var $el = [];
		if(this.model.getType() == "ticket") {
			$el = view.$(".b-input_"+attr);
			$el.removeClass('b-input-error');
		}else{
			$el = view.$("[name='"+attr+"']");
			$el.removeClass('b-input-error');
		}
	},
	onShow: function () {
		var me = this,
			countFiles = 0;
			var rows = "";
			me.filesArray = [];

		this.$el.find('.fileupload').fileupload({
			url: '/api/upload/ticket_attach',
			limitMultiFileUploads: 3,
			add: function(e, data){
				var id;
				if(me.filesArray.length > 2){
					me.trigger("user:add:more:files");
					return;
				}else if(me.filesArray.length == 2){
					$('.b-upload-btn').addClass('max');
					$('.fileupload').prop('disabled',true);
				}
				id = Helpers.getHashFromName(data.files[0].name);
				var item = _.findWhere(me.filesArray, {id: id});
				if(data.files[0].size > 5000000){
					$('.b-error-text').fadeIn(300);
					$('.b-page-tabs').addClass('_hide-border');
					$('.b-error-text').text('Please do not upload files larger than 5 MB.');
					me.trigger("user:add:big:files");
					return;
				}else if(data.files[0].size < 5000000){
					$('.b-error-text').fadeOut(300);
					$('.b-page-tabs').removeClass('_hide-border');
				}
				if(!item){
					var row = FileTemplate({name: data.files[0].name, size: Helpers.formatBytes(data.files[0].size), id: id});
					me.filesArray.push({id: id, data: data, jqXHR : data.submit()});
					me.$el.find(".b-upload-item-list").append(row);
				}
			},
			progress: function(e, data){
				var id = Helpers.getHashFromName(data.files[0].name);
				var progress = parseInt(data.loaded / data.total * 100, 10);
				me.$el.find(".b-upload-item-list").find("#"+id).find(".b-progress-state").css("width", progress+"%");
			},
			done: function(e, data){
				var id = Helpers.getHashFromName(data.files[0].name);
				me.$el.find(".b-upload-item-list").find("#"+id).find(".b-file-progress").addClass("b-hide-item");
				var item = _.findWhere(me.filesArray, {id: id});
				item.result = data.result;
			}
		});
	},
	resetFilesFiled: function(){
		this.filesArray = [];
		this.$el.find(".b-upload-file-item-wrap").each(function(index, element){
			$(element).remove();
		});
	},
	resetForm: function(){
		this.$el[0].reset();
		this.resetFilesFiled();
	},
	onSubmitForm: function(event, ui){
		event.preventDefault();
		var data = Backbone.Syphon.serialize(this);
		delete data.files;
		data.hash = _.map(this.filesArray, function (sendItem) {
			if(sendItem.result){
				return sendItem.result.body.hash;
			}
		});
		var channels = this.$el.find('.js-select-channels').val(),
			questions = this.$el.find('.js-input-question').val(),
			message = this.$el.find('textarea.b-page-input').text();
		if(!channels || !questions || !message){
			$('.b-error-text').fadeIn(300);
			$('.b-error-text').text('Please fill in all the required fields.');
			$('.b-page-tabs').addClass('_hide-border');
		}
		this.trigger("user:create:ticket", data);
	},
	changeSuccessText: function(){
		$('.b-question-name').text(this.$el.find('.js-input-question option:selected').text());
	},
	setChannelId: function(id){
		this.ui.selectChannels.val(id);
	},
	onCancelClick: function(e){
		var id = $(e.currentTarget).closest(".b-upload-file-item-wrap").attr("id");
		var item = _.findWhere(this.filesArray,  {id: Number(id)});
		item.jqXHR.abort();
		_.remove(this.filesArray, function (file){
			if(file.id === Number(id)){
				return true;
			}
		});
		$(e.currentTarget).closest(".b-upload-file-item-wrap").remove();
		if($('.b-upload-file-item-wrap').length <= 2){
			$('.b-upload-btn').removeClass('max');
			$('.fileupload').prop('disabled',false);
		}
	}
});