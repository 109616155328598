/**
 * Created by sergey on 28.12.2015.
 */
var Helpers = require("helpers/Helpers");
module.exports = Backbone.Model.extend({
	defaults: {
		offset: 0,
		limit: 20
	},
	initialize: function (options) {
		this.options = options;
		this.month = {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		};
		this.ticketHash = {};
	},
	setChannelsList: function (list) {
		this.channelsList = list;
	},

	_getChannelOfId: function (channel_id){
		var item = _.findWhere(this.getChannelList(), {channel_id: channel_id});
		if (item) {
			return item
		} else {
			return {};
		}
	},
	getChannelList: function () {
		return this.channelsList.get("rows");
	},
	setSearchParams: function(data){
		var newFilterObject = {},
			promises;
		newFilterObject = data;
		this.set(data);
		var me = this;
		if(!_.isEqual(this.options.filter, newFilterObject)){
			this.options.filter = newFilterObject;
			$.when(this.getData()).done(function() {
				me.trigger("search:done", data);
			});
		}

	},
	getChannel: function(channel_id){
		var result = {};
		if(!channel_id){
			result = _.max(this.get("payments"), function(payment){
				return payment.earning_amount;
			});
			if(Number.NEGATIVE_INFINITY === result) {
				result = this.getChannelList()[0];
			}

		}else{
			result = this._getChannelOfId(channel_id);
		}
		return result;
	},
	getTickets: function () {
		return this.monthArray;
	},
	_ticketHash: function(){
		this.ticketHash = {};
		var monthArray = [];
		var fullMonthArray = [];
		var firstMonthArray = [];
		var lastMonthArray = [];
		var me = this;
		var i = 0;
		var k = 0,
			m = 1;
		var setToTicketHash = function(month, year){
			if(month<10){
				month = "0" + month;
			}
			me.ticketHash[year+"-"+month] = true;
		};
		if(this.get("year") == this.get("last_year")){
			i = Number(this.get("month"));
			while(i<=Number(this.get("last_month"))){
				monthArray.push(this.month[i]);
				setToTicketHash(i, this.get("year"));
				i++;
			}
		}else{
			for(var j = Number(this.get("year")); j <Number(this.get("last_year"))+1; j++){
				if(j == this.get("year")){
					k = this.get("month");
					while(k<13){
						firstMonthArray.push(this.month[k]+" "+j);
						setToTicketHash(k, j);
						k++;
					}
					Array.prototype.push.apply(monthArray, firstMonthArray);
				}else if(j == this.get("last_year")){
					k = this.get("last_month");
					while(k>0){
						lastMonthArray.push(this.month[k]+" "+j);
						setToTicketHash(k, j);
						k--;
					}
					lastMonthArray.reverse();
					Array.prototype.push.apply(monthArray, lastMonthArray);
				}else{
					while(m<13){
						fullMonthArray.push(this.month[m]+" "+j);
						setToTicketHash(m, j);
						m++;
					}
					m = 1;
					Array.prototype.push.apply(monthArray, fullMonthArray);
				}
			}

		}
		this.monthArray = monthArray;
	},
	getMorePayments: function () {
		var me = this;
		this.set({"offset": me.get("offset")+20});
		this.options.filter.offset = this.get("offset");
		$.post("/api/payouts/get_payments", this.options.filter).then(
			function (resp) {
				var newArr = resp.body.payments;
				var oldArr = me.get("payments");
				me.set({"payments": oldArr.concat(newArr)});
				me.trigger("search:done", me.options.filter, newArr);
			}
		);
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var paymentsListLength = this.get("payments").length;
		if(paymentsListLength !== totalCount){
			result = true;
		}
		return result;
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		} else{
			return  this.get("offset")+this.get("limit");
		}
	},
	getDiagramData: function(){
		var me = this;
		var sumArray = [];
		var sumPayment = [];
		var sumEarnigs = [],
			payouts;
		me._ticketHash();
		var paymentHash = _.groupBy(me.get("payments"), function(item){
			return item.period.substr(0, 7);
		});
		_.forOwn(me.ticketHash, function (item, key) {
			var payouts = paymentHash[key];
			if(payouts){
				sumPayment.push(Number(_.sum(payouts, function(item){
					if(item.payment_amount){
						return item.payment_amount;
					} else {
						return 0;
					}
				}).toFixed(2)));
				sumEarnigs.push(Number(_.sum(payouts, function(item){
					if(item.earning_amount){
						return item.earning_amount;
					} else {
						return 0;
					}
				}).toFixed(2)));
			}else{
				sumPayment.push(null);
				sumEarnigs.push(null);
			}
		});
		sumArray.push(sumPayment);
		sumArray.push(sumEarnigs);
		return sumArray;
	},
	setDefaultParams: function(){
		var month = Radio.channel("login").request("user:month:of:payment");
		var lastmonth = Radio.channel("login").request("user:month:of:payment");
		var year = Radio.channel("login").request("user:year:of:payment");
		var lastyear = Radio.channel("login").request("user:year:of:payment");
		this.set({month: month});
		this.set({year: year});
		this.set({last_year: lastyear});
		this.set({last_month: lastmonth});
	},
	setFilterOptions: function(value){
		this.options.filter = value;
	},
	getData: function () {
		var defer = $.Deferred();
		var me = this;
		this.options.filter.offset = 0;
		this.options.filter.limit = 20;
		$.post("/api/payouts/get_payments", this.options.filter).done(function (resp) {
			var total_count = resp.body.rows;
			delete resp.body.rows;
			resp.body.total_count = total_count;
			me.set(resp.body, {silent: true});
			defer.resolve(me);
		});
		return defer.promise();
	}
});
