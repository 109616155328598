// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.countries : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						 <option value=\""
    + alias4(((helper = (helper = helpers.code2 || (depth0 != null ? depth0.code2 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code2","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.country_name || (depth0 != null ? depth0.country_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country_name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<h2 class=\"b-page-title text-align-left\">Contacts</h2>\n<div class=\"b-edit__item b-edit-item_m b-item_no-margin\">\n	<label for=\"\" class=\"b-page-label\">Country</label>\n	<div class=\"b-edit-wrapper\">\n		<div class=\"b-edit-text-block js-edit-profile-country-block\">\n			<span class=\"b-page-text b-profile-text b-edit_country\">"
    + alias3((helpers.getCountryOnCode || (depth0 && depth0.getCountryOnCode) || alias2).call(alias1,(depth0 != null ? depth0.countries : depth0),(depth0 != null ? depth0.country : depth0),{"name":"getCountryOnCode","hash":{},"data":data}))
    + "</span>\n		</div>\n		<div class=\"b-edit-input-block\">\n			 <select class=\"b-page-input b-page-select b-input_country\">\n"
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,(depth0 != null ? depth0.country : depth0),{"name":"select","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			 </select>\n		</div>\n	</div>\n</div>\n<div class=\"b-edit__item b-edit-item_m\">\n	<label for=\"\" class=\"b-page-label\">Town/Location</label>\n	<div class=\"b-edit-wrapper\">\n		<div class=\"b-edit-text-block\">\n			<span class=\"b-page-text b-profile-text b-edit_town\">"
    + alias3(((helper = (helper = helpers.town || (depth0 != null ? depth0.town : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"town","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n		<div class=\"b-edit-input-block\">\n			<input type=\"text\" class=\"b-page-input b-page-input_text b-input_profile_town\" value="
    + alias3(((helper = (helper = helpers.town || (depth0 != null ? depth0.town : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"town","hash":{},"data":data}) : helper)))
    + ">\n		</div>\n	</div>\n</div>\n<div class=\"b-edit__item b-edit-item_l b-item_no-margin\">\n	<label for=\"\" class=\"b-page-label\">Street address & House number</label>\n	<div class=\"b-edit-wrapper\">\n		<div class=\"b-edit-text-block\">\n			<span class=\"b-page-text b-profile-text b-edit_address\">"
    + alias3(((helper = (helper = helpers.address || (depth0 != null ? depth0.address : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"address","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n		<div class=\"b-edit-input-block\">\n			<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_l b-input_address\" value=\""
    + alias3(((helper = (helper = helpers.address || (depth0 != null ? depth0.address : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"address","hash":{},"data":data}) : helper)))
    + "\">\n		</div>\n	</div>\n</div><div class=\"b-edit__item b-edit-item_m b-item_no-margin\">\n	<label for=\"\" class=\"b-page-label\">E-mail address for reports</label>\n	<div class=\"b-edit-wrapper\">\n		<div class=\"b-edit-text-block\">\n			<span class=\"b-page-text b-profile-text b-edit_email_for\">"
    + alias3(((helper = (helper = helpers.email_for || (depth0 != null ? depth0.email_for : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email_for","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n		<div class=\"b-edit-input-block\">\n			<input type=\"text\" class=\"b-page-input b-page-input_text b-input_email_for\" value=\""
    + alias3(((helper = (helper = helpers.email_for || (depth0 != null ? depth0.email_for : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email_for","hash":{},"data":data}) : helper)))
    + "\">\n		</div>\n	</div>\n</div><div class=\"b-edit__item b-edit-item_m\">\n	<label for=\"\" class=\"b-page-label\">E-mail address</label>\n	<div class=\"b-edit-wrapper\">\n		<div class=\"b-edit-text-block\">\n			<span class=\"b-page-text b-profile-text b-edit_email\">"
    + alias3(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n		<div class=\"b-edit-input-block\">\n			<input type=\"text\" class=\"b-page-input b-page-input_text b-input_email\" value=\""
    + alias3(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "\">\n		</div>\n	</div>\n</div>\n<div class=\"b-edit-link-wrap b-link-wrap b-profile-edit-link\">\n	<button class=\"b-page-link b-page_edit-link\">\n		<span class=\"i-edit b-edit-ico\"></span>\n		<span class=\"i-edit b-edit-ico i-edit-invis\"></span>\n		<span>Edit</span>\n	</button>\n	<button class=\"b-page-link b-page_save-link b-link_hide\">\n		<span class=\"i-edit b-edit-ico\"></span>\n		<span class=\"i-edit b-edit-ico i-edit-invis\"></span>\n		<span>Save changes</span>\n	</button>\n	<button class=\"b-page-link b-page_successd-link _show\">\n		<span class=\"i-edit b-edit-ico\"></span>\n		<span>Saved</span>\n	</button>\n</div>";
},"useData":true});
