/**
 * Created by sergey on 07.12.2015.
 */
var ToolbarTemplate = require("./templates/ChannelsToolbar.hbs");
var AutoCompleteWidget = require("widgets/AutoComplete");
module.exports = Marionette.ItemView.extend({
	ui:{
		checkboxAll: ".b-all-check",
		dropIco: ".b-menu-btn.active",
		cpmItem: "li[data-action='cpm']"
	},
	events: {
		"click @ui.dropIco" : "dropMenu",
		"click li[data-list]" : "onSetFilter",
		"click li[data-action]" : "onSelectAction",
		"submit form": "onSearchChange"
	},
	initialize: function(){
		this.autoComplete = new AutoCompleteWidget();
	},
	template: ToolbarTemplate,
	onShow: function(){
		var me = this;
		var searchList = this.model.getSearchList();
		this.autoComplete.show(this.$el.find(".js-input-search"),
			{	source: searchList,
				minLength: 3,
				select: me.onSearchChange.bind(me)
			});
	},
	setSearchList: function(){
		var searchList = this.model.getSearchList();
		this.autoComplete.setOption("source", searchList);
	},
	onSearchChange: function(event, ui){
		event.preventDefault();
		var searchQuery = this.$el.find(".js-input-search").val();
		this.trigger("search", searchQuery);
		if(!searchQuery){
			$('.b-sum-block_channels').fadeIn(300);	
		}else{
			$('.b-sum-block_channels').fadeOut(300);
		}
	},
	checkAllInput: function(){
		this.ui.checkboxAll.prop('checked',true);
	},
	uncheckAllInput: function(){
		this.ui.checkboxAll.prop('checked',false);
	},
	onSetFilter: function(e){
		var filter = $(e.target).data("list");
		this.trigger("selected:filter", filter);
	},
	onSelectAction: function(e){
		var action = $(e.target).data("action");
		if(action == "hide"){
			this.removeCheckDash();
		}
		this.trigger("selected:action", action);
	},
	showShowAll: function(){
		this.$el.find("[data-action='showall']").removeClass("b-hide-item");
		this.uncheckAllInput();
	},
	hideShowAll: function(){
		this.uncheckAllInput();
		this.$el.find("[data-action='showall']").addClass("b-hide-item");
	},
	onBeforeDestroy: function () {
		this.autoComplete.destroy();
	},
	showPreload: function(){
		this.$el.find(".js-drop-icon-action").addClass("preload");
	},
	hidePreload: function(){
		this.$el.find(".js-drop-icon-action").removeClass("preload");
	},
	showCPM: function(){
		this.ui.cpmItem.removeClass("b-hide-item");
	},
	hideCPM: function(){
		this.ui.cpmItem.addClass("b-hide-item");
	},
	activedActionMenu: function(){
		this.$el.find('.b-actions-btn').addClass('active');
		this.$el.find('.b-actions-btn').children('.dropdown-toggle').prop('disabled',false);
	},
	deactivedActionMenu: function(){
		this.$el.find('.b-actions-btn').removeClass('active');
		this.$el.find('.b-actions-btn').children('.dropdown-toggle').prop('disabled',true);
	},
	checkDash: function(){
		this.ui.checkboxAll.addClass('dash');	
	},
	removeCheckDash: function(){
		this.ui.checkboxAll.removeClass('dash');	
	}
});