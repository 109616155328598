var DashboardLoginShowPassword = require("./templates/DashboardLoginShowPassword.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		sendBtn: ".js-dashboard-login-password-submit",
		sendCodeBtn: ".js-dashboard-login-code-submit",
		errorBlock: ".b-login-error-block",
		codeBlock: ".js-dashboard-password-login-code",
		loginBlock: ".b-login",
	},
	events: {
		"click @ui.sendBtn": "sendData",
		"submit .js-dashboard-login-password": "sendData",
		"click @ui.sendCodeBtn": "sendCode",
		"submit .js-dashboard-login-code": "sendCode"
	},
	className: "wrapper_n",
	template: DashboardLoginShowPassword,
	sendData: function (e){
		e.preventDefault();
		var login = this.$el.find(".js-dashboard-login-password-login").val(),
			pswd = this.$el.find(".js-dashboard-login-password-password").val();
			var data = {
				login: login,
				pswd: pswd
			};
		this.trigger("send:login", data);
	},
	sendCode: function (e){
		e.preventDefault();
		var code = this.$el.find(".js-dashboard-login-password-code").val();
		this.trigger("send:code", code);
	},
	onShow: function () {
		var me = this;
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				me.hideValidationError(view, attr);
			},
			invalid: function(view, attr, error) {
				me.showValidationError(view, attr);
			}
		});
	},
	showLimitError: function(){
		this.$el.find('.b-password-error').html('The username or password that you entered is incorrect. <br> You cannot log into your dashboard. Please try again in 3 hours.');
	},
	showLoginError: function(){
		this.$el.find('.b-password-error').html('The username or password that you entered is incorrect. Try again.');
	},
	showCodeLimitError: function(){
		this.$el.find('.b-error-code').html('The code that you entered is incorrect. <br> You cannot log into your dashboard. Please contact partner care at <a class="b-error-link" href="mailto:support@quizgroup.com">support@quizgroup.com</a>.');
	},
	showCodeError: function(){
		this.$el.find('.b-error-code').html('The code that you entered is incorrect. Try again.');
	},
	showUnknownError: function(){
	},
	showValidationError: function(view, attr){
		var $el = view.$(".js-dashboard-login-password-"+attr);
		$el.addClass('b-input-error');
		if(attr == "login" || attr == "password"){
			this.$el.find('.js-dashboard-login-password-login').val('');
			this.$el.find('.js-dashboard-login-password-password').val('');
			this.$el.find('.b-login').addClass('login-error');
			this.$el.find('.b-password-error').fadeIn(300);
			this.$el.find('.b-password-error').html('The username or password that you entered is incorrect. Try again.');
		}else if(attr == "code"){
			this.$el.find('.js-dashboard-login-password-code').val('');
			this.$el.find('.b-code').addClass('login-error');
			this.$el.find('.b-error-code').fadeIn(300);
			this.$el.find('.b-error-code').html('The code that you entered is incorrect. Try again.');
		}
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$(".js-dashboard-login-password-"+attr);
		$el.removeClass('b-input-error');
	},
	showCodeForm: function(code){
		this.ui.codeBlock.removeClass("b-hide-item");
		this.ui.codeBlock.find(".b-unique-number").text(code);
		this.ui.loginBlock.find(".b-page-button").prop('disabled',true);
		$('html, body').animate({scrollTop:150}, 500);
	},
	loginError: function(text){
		var $el = this.$el.find('.b-login');
		$el.addClass('login-error');
		$el.find('.b-password-error').fadeIn(300);
		$el.find('.js-dashboard-login-password-login').val('');
		$el.find('.js-dashboard-login-password-password').val('');
	},
	loginErrorHide: function(){
		var $el = this.$el.find('.b-login');
		$el.removeClass('login-error');
		$el.find('.b-password-error').fadeOut(300);
	},
	codeError: function(text){
		var $el = this.$el.find('.js-dashboard-password-login-code');
		$el.addClass('login-error');
		$el.find('.b-error-code').fadeIn(300);
		$el.find('.b-error-code').html(text);
		$el.find('.js-dashboard-login-password-code').val('');
	},
	codeErrorHide: function(){
		var $el = this.$el.find('.js-dashboard-password-login-code');
		$el.removeClass('login-error');
		$el.find('.b-error-code').fadeOut(300);
	}
});