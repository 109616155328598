module.exports = Backbone.Model.extend({
	defaults: {
		offset: 0,
		limit: 20
	},
	initialize: function (options) {
		this.options = options;
		this.month = {
			"1": "January",
			"2": "February",
			"3": "March",
			"4": "April",
			"5": "May",
			"6": "June",
			"7": "July",
			"8": "August",
			"9": "September",
			"10": "October",
			"11": "November",
			"12": "December"
		};
		this.reverseMonth = {
			"January" : "1" ,
			"February": "2",
			"March": "3",
			"April":"4",
			"May": "5",
			"June": "6",
			"July": "7",
			"August": "8",
			"September": "9",
			"October": "10",
			"November": "11",
			"December": "12"
		};
		this.listenTo(this, "get:new:data", this.getNewPayout);
	},
	url: "/api/payouts/get_period",
	getData: function(options){
		var defer = $.Deferred();
		var me = this;
		this.options.filter.offset = this.get("offset");
		this.options.filter.limit = this.get("limit");
		$.post("/api/payouts/get_period", this.options.filter).done(function(resp){
			var total_count = resp.body.rows;
			delete resp.body.rows;
			resp.body.total_count = total_count;
			me.set(resp.body, {silent: true});
			defer.resolve(me);
		});
		return defer.promise();
	},
	getMorePayments: function () {
		var me = this;
		this.set({"offset": me.get("offset")+20});
		var lang = Radio.channel("login").request("user:lang");
		var url = "";
		if(me.get("ref_page")){
			url = "/api/payouts/get_period?offset=" + me.get("offset") +"&limit="+ me.get("limit") + "&month="+
			me.reverseMonth[me.get("month")]+"&year="+me.get("year")+"&ref_page="+me.get("ref_page")
		}else{
			url = "/api/payouts/get_period?offset=" + me.get("offset") +"&limit="+ me.get("limit") + "&month="+
				me.reverseMonth[me.get("month")]+"&year="+me.get("year");
		}
		$.ajax({
			method: "GET",
			url: url
		}).then(function (resp) {
			var newArr = resp.body.payments;
			var oldArr = me.get("payments");
			me.set({"payments": oldArr.concat(newArr)});
			me.trigger("data:reload", newArr);
		})
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var paymentsListLength = this.get("payments").length;
		if(paymentsListLength !== totalCount){
			result = true;
		}
		return result;
	},
	setMonth: function(data, options){
		var options = options || {};
		this.options.filter.month = data;
		this.set({"offset": 0}, options);
		this.set({month: this.month[String(data)]}, options);
		if(!options.silent){
			this.trigger("get:new:data");
		}

	},
	setYear: function(data, options){
		var options = options || {};
		this.options.filter.year = data;
		this.set({"offset": 0}, options);
		this.set({year: data}, options);
		if(!options.silent){
			this.trigger("get:new:data");
		}
	},
	resetChannelFilter: function(){
		delete this.options.filter.channel_ids;
		delete this.channels;
		this.trigger("get:new:data");
	},
	setTab: function(data, options){
		if(this.options.filter.channel_ids){
			this.channels = this.options.filter.channel_ids;
		}
		delete this.options.filter.channel_ids;
		this.set({"offset": 0}, options);
		this.options.filter.ref_page = true;
		this.set({"ref_page": true}, options);
		this.trigger("get:new:data");
	},
	unsetTab: function(data, options){
		if(this.channels){
			this.options.filter.channel_ids = this.channels;
		}
		this.set({"offset": 0}, options);
		this.unset("ref_page", options);
		delete this.options.filter.ref_page;
		this.trigger("get:new:data");
	},
	getNewPayout: function(){
		var me = this;
		$.when(this.getData()).done(function(){
			me.trigger("data:reload");
		});
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		} else{
			return  this.get("offset")+this.get("limit");
		}
	},
	needShowResetFilter: function (){
		return this.options.filter.channel_ids ? true : false;

	},
	parse: function(resp){
		var result = resp.body;
		var total_count = resp.body.rows;
		delete resp.body.rows;
		result.total_count = total_count;
		return result;
	}
});