/**
 * Created by sergey on 15.12.2015.
 */
module.exports = Backbone.Model.extend({
	readNotificationMessage: function(ids, status){
		var me = this;
		var arrayPromise = [$.get("/api/inbox/notification_list?isread=false&only_count=true"),
							$.get("/api/inbox/ticket_list?isread=false&only_count=true")];
		$.when.apply($,arrayPromise).then(function(notificationCount, ticketCount){
			me.set("notificationSum", notificationCount[0].body.result_count);
			me.set("ticketsSum", ticketCount[0].body.unread);
		});
	},
	setActiveTab: function(name){
		this.activeTab = name;
	},
	getActiveTab: function(name){
		return this.activeTab;
	}
});