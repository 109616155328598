/**
 * Created by sergey on 14.12.2015.
 */
module.exports = Backbone.Model.extend({
	defaults: function(){
		return {
			offset: 0,
			limit: 20
		}
	},
	url: function(){
		var lang = Radio.channel("login").request("user:lang");
		return "api/inbox/notification_list?offset=" + this.get("offset") +"&limit="+ this.get("limit");
	},
	getSearchList: function(){
		var result = _.map(this.get("notifications"), function(r){return r.subject}).filter(function(x) {
				return typeof x !== 'undefined';
			});
		return result;
	},
	getNotification: function(cb, id){
		var me = this,
			item,
			notification;
		notification = _.findWhere(me.get("notifications"), {event_id: id});
		if(notification ? notification.body ? 1: 0: 0){
			cb(notification.body);
		}else{
			$.get("/api/inbox/notification?event_id="+id).then(function(resp){
				var result = [];
				result.push(id);
				cb(resp.body.body);
				item = _.findWhere(me.get("notifications"), {event_id: resp.body.event_id});
				item.body = resp.body.body;
				if(resp.body.status == 1){
					me._readeUnReadMessages({event_id: result, switch: "ON"})
				}
			});
		}
	},
	filterAction:function(ids){
		var objectMap={};
		_.reduce(ids, function(result, n, key){
			result[n] = true;
			return result;
		},objectMap);
		var me = this;
		if(this.get("action") === "markasread"){
			this._readeUnReadMessages({event_id: ids, switch: "ON"}, function(respIds){
				me.trigger("read:notifies",respIds, objectMap);
			}, objectMap);
		}else if(this.get("action") === "markasunread"){
			this._readeUnReadMessages({event_id: ids, switch: "OFF"}, function(respIds){
				me.trigger("unread:notifies", respIds, objectMap);
			}, objectMap);
		}else if(this.get("action") === "addstar"){
			this._sendStarsArray({event_id: ids, switch: "ON"}, function(respIds){
				me.trigger("addstars:notifies", respIds, objectMap);
			});
		}else if(this.get("action") === "removestar"){
			this._sendStarsArray({event_id: ids, switch: "OFF"}, function(respIds){
				me.trigger("removestars:notifies", respIds, objectMap);
			});
		}else if(this.get("action") === "delete"){
			this._deleteMessages({event_id: ids}, function(respIds){
				me.trigger("delete:notifies", respIds, objectMap);
				Radio.channel("inbox").trigger("user:read:notification");
			}, objectMap);
		}
	},
	_sendStarsArray: function(data, cb){
		var me = this;
		$.post("/api/inbox/star_notification", data).done(function(){
			_.forEach(data.event_id, function(id){
				var notification = _.findWhere(me.get("notifications"), {event_id: id});
				if(data.switch === "ON"){
					notification.starred = 1;
				}else{
					notification.starred = 0;
				}
			});
			if(typeof cb === "function"){
				cb(data.event_id);
			}

		});
	},
	_readeUnReadMessages:function(data, cb){
		var me = this;
		$.post("/api/inbox/read_notification", data).then(function () {
			Radio.channel("inbox").trigger("user:read:notification");
			_.forEach(data.event_id, function(id){
				var notification = _.findWhere(me.get("notifications"), {event_id: id});
				if(data.switch === "ON"){
					notification.status = -2;
				}else{
					notification.status = 1;
				}
			});
			if(typeof cb === "function"){
				cb(data.event_id);
			}
		});

	},
	_deleteMessages:function(data, cb, objectMap){
		var me = this;
		$.post("/api/inbox/delete_notification", data).then(function () {
			var newArray = _.clone(me.get("notifications"));
			_.forEach(me.get("notifications"), function(notify, index){
				if(objectMap[notify.event_id]){
					_.remove(newArray, {event_id: notify.event_id});
				}
			});
			me.set({notifications: newArray});
			cb(data.event_id);
		});
	},
	clearFilter: function(){
		this.set({rows: this.defaultRows}, {silent: true});
		this.trigger("refresh:filter:rows");
	},
	unSetFilterValue: function(){
		this.unset("filter", {silent: true});
	},
	rowsLength: function(){
		return this.get("notifications").length;
	},
	setStarred: function(data){
		var result  = [];
		result.push(data.event_id);
		var me = this,
			options = {
				success: function(){
					me.trigger("refresh:filter:rows");
				}
			};
		this._sendStarsArray({event_id: result, switch: data.switch});

	},
	getMoreNotifications: function (offsetParam, limitParam) {
		var me = this;
		var offset = 0,
			limit = 0;
		if(offsetParam){
			offset = offsetParam;
		}else if(offsetParam === 0){
			if(this.get("offset") !== 0){
				this.set({"offset": this.get("offset")-20});
			}
			offset = me.get("offset");
		}else{
			this.set({"offset": this.get("offset")+20});
			offset = me.get("offset");
		}
		if(limitParam){
			limit = limitParam;
		}else{
			limit = me.get("limit");
		}
		$.ajax({
			method: "GET",
			url: "/api/inbox/notification_list?offset="+offset+"&limit="+limit
		}).then(function (resp) {
				var newArr = resp.body.answer;
				var oldArr = me.get("notifications").concat(newArr);
				me.set({"notifications": oldArr});
				me.trigger("refresh:filter:rows", newArr );
		});
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var notificationListLength = this.get("notifications").length;
		if(notificationListLength != totalCount && totalCount > 20){
			result = true;
		}
		return result;
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		} else{
			return  this.get("offset")+this.get("limit");
		}
	},
	setTotalCount: function(objectMap){
		if(objectMap){
			this.set({total_count: this.get("total_count")- _.keys(objectMap).length});
		}
	},
	parse: function(resp){
		var result = {};
		result.notifications = resp.body.answer;
		result.total_count = resp.body.result_count;
		return result;
	}
});