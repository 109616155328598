/**
 * Created by sergey on 14.12.2015.
 */
var NewsModel = require("../models/Inbox/News");
var NotificationModel = require("../models/Inbox/Notification");
var TicketModel = require("../models/Inbox/Ticket");
var InboxModel = require("../models/Inbox/Inbox");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getNotificationEntity: function(options){
		var options = options || {};
		var notifications = new NotificationModel(options);
		var defer = $.Deferred();
		notifications.fetch({
			success: function(){
				defer.resolve(notifications);
			}
		});
		return defer.promise();
	},
	getTicketsEntity: function(options){
		var options = options || {};
		var tickets = new TicketModel(options);
		var defer = $.Deferred();
		tickets.fetch({
			success: function(){
				defer.resolve(tickets);
			}
		});
		return defer.promise();
	},
	getNewsEntity: function(options){
		var options = options || {};
		var news = new NewsModel(options);
		var defer = $.Deferred();
		news.fetch({
			success: function(){
				defer.resolve(news);
			}
		});
		return defer.promise();
	},
	getNewInboxEntity: function (options) {
		var options = options || {};
		return new InboxModel(options);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("inbox:notification:entity", function(options){
			return me.getNotificationEntity(options);
		});
		Radio.channel("global").reply("inbox:tickets:entity", function(options){
			return me.getTicketsEntity(options);
		});
		Radio.channel("global").reply("inbox:news:entity", function(options){
			return me.getNewsEntity(options);
		});
		Radio.channel("global").reply("inbox:new:inbox:entity", function(options){
			return me.getNewInboxEntity(options);
		});
	}
};