/**
 * Created by sergey on 12.01.2016.
 */
var TemplateLayout= require("./templates/ProfileAdditionalSettingsLayout.hbs");
module.exports = Marionette.LayoutView.extend({
	ui:{
		modalCheck: ".b-settings-row__item.ok",
		agreeLink: ".b-button_yes",
		cancelLink: ".b-button_no"
	},
	events: {
		"click @ui.modalCheck" : "showAgreeForm",
		"click @ui.agreeLink" : "showEditForm",
		"click @ui.cancelLink" : "hideForm"
	},
	regions: {
		profileSecurityRegion: ".js-profile-additional-settings-profile-security",
		uniteDashboardRegion: ".js-profile-additional-settings-unite-dashboard",
		passwordAccessRegion: ".js-profile-additional-settings-password-access"
	},
	className: "modal-wrap",
	template: TemplateLayout,
	templateHelpers: function(){
		return {
			size: "modal-sm"
		}
	},
	showAgreeForm: function(e){
		var curTarget = $(e.currentTarget),
			settingsRow = curTarget.closest('.b-settings-row'),
			agreeForm = settingsRow.find('.b-agree-form');
		curTarget.toggleClass('_show');
		if(curTarget.hasClass('_show')){
			agreeForm.slideDown(300);
			agreeForm.slideDown(300);
		}else{
			agreeForm.slideUp(300);
			settingsRow.find('.b-edit-form').slideUp(300);
			settingsRow.find('.b-error-text').fadeOut(300);
		}
	},
	showEditForm: function(e){
		var curTarget = $(e.currentTarget);
		curTarget.closest('.b-agree-form').slideUp(300);
		curTarget.closest('.b-settings-row').find('.b-edit-form').slideDown(300);
	},
	hideForm: function(e){
		var curTarget = $(e.currentTarget),
			settingsRow = curTarget.closest('.b-settings-row');
		settingsRow.find('.b-page-form').slideUp(300);
		settingsRow.find('.b-settings-row__item').removeClass('_show');
		settingsRow.find('.b-error-text').fadeOut(300);
	}
});