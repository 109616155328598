/**
 * Created by sergey on 14.12.2015.
 */
var ToolbarTemplate = require("./templates/Toolbar.hbs");
var AutoCompleteWidget = require("widgets/AutoComplete");
module.exports = Marionette.LayoutView.extend({
	ui:{
		checkboxAll: ".b-all-check",
		dropIco: ".b-menu-btn.active",
		createTicketBtn: ".b-create-ticket-btn"
	},
	events: {
		"click @ui.dropIco" : "dropMenu",
		"click li[data-list]" : "onSetFilter",
		"click li[data-action]" : "onSelectAction",
		"click @ui.createTicketBtn" : "createTicket",
		"submit form": "onSearchChange"
	},
	initialize: function(){
		this.autoComplete = new AutoCompleteWidget();
		this.listenTo(this.model, "success:ticket:create", this.createTicket);
		this.listenTo(this.model, "success:text:show", this.showSuccess);
	},
	regions:{
		ticketsFormRegion: ".js-inbox-tickets-create-form"
	},
	template: ToolbarTemplate,
	templateHelpers: function () {
		var me = this;
		return{
			is_news: me.options["type"] === "news"? true: false,
			is_ticket: me.options["type"] === "tickets"? true: false,
			channels: me.options["channels"]
		}
	},
	onShow: function(){
		var me = this;
		var searchList = this.model.getSearchList();
		this.autoComplete.show(this.$el.find(".js-input-search"),
				{
					source: searchList,
					minLength: 3,
					select: me.onSearchChange.bind(me)
				});
	},
	setSearchList: function(){
		var searchList = this.model.getSearchList();
		this.autoComplete.setOption("source", searchList);
	},
	onSearchChange: function(event, ui){
		event.preventDefault();
		var searchQuery = this.$el.find(".js-input-search").val();
		this.trigger("search", searchQuery);
	},
	checkAllInput: function(){
		this.ui.checkboxAll.prop('checked',true);
	},
	uncheckAllInput: function(){
		this.ui.checkboxAll.prop('checked',false);
	},
	onSetFilter: function(e){
		var filter = $(e.target).data("list");
		this.trigger("selected:filter", filter);
	},
	onSelectAction: function(e){
		var action = $(e.target).data("action");
		this.trigger("selected:action", action);
	},
	showShowAll: function(){
		this.$el.find("[data-action='showall']").removeClass("b-hide-item");
		this.uncheckAllInput();
	},
	hideShowAll: function(){
		this.uncheckAllInput();
		this.$el.find("[data-action='showall']").addClass("b-hide-item");
	},
	onBeforeDestroy: function () {
		this.autoComplete.destroy();
	},
	activedActionMenu: function(){
		this.$el.find('.b-actions-btn').addClass('active');
		this.$el.find('.b-actions-btn').children('.dropdown-toggle').prop('disabled',false);
	},
	deactivedActionMenu: function(){
		this.$el.find('.b-actions-btn').removeClass('active');
		this.$el.find('.b-actions-btn').children('.dropdown-toggle').prop('disabled',true);
	},
	showCreateBlock: function(){
		this.$el.find('.b-create-ticket-block').addClass('_show');
	},
	createTicket: function(e){
		this.ui.createTicketBtn.toggleClass('_open');
		this.ui.createTicketBtn.closest('.b-ticket-form').find('.b-upload-block').toggleClass('_show');
		this.ticketsFormRegion.currentView.resetForm();
	},
	showSuccess: function(){
		$('.b-success-ticket').fadeIn(300);
		setTimeout(function() {
		    $('.b-success-ticket').fadeOut(300);
		}, 3000);
		$('.b-error-text').fadeOut(300);
		$('.b-page-tabs').removeClass('_hide-border');
	}
});