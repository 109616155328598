/**
 * Created by sergey on 16.12.2015.
 */
var categoriesModel = require("../../models/Music/Categories");
module.exports = Backbone.Collection.extend({
	model: categoriesModel,
	initialize: function(options){
		this.options = options;
	},
	url: function(){
		var url = "/api/music/category_list";
		if(this.options.filter){
			var result = "";
			_.forIn(this.options.filter, function(value, key) {
				result+= key+"="+value+"&";
			});
			url = url+"?"+result;
		}
		return url;
	},
	parse: function(resp){
		var list = resp.body.category;
		return list;
	}
});