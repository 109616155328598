var MusicModal = require("./templates/MusicModal.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		modalScroll: ".b-music-modal-wrap",
		musicAgree: ".music-agree",
		noticeBtn: ".modal-download.active",
		downloadBtn: ".b-button_yes",
	},
	events: {
		"click @ui.musicAgree": "activeDownload",
		"click @ui.noticeBtn": "noticeShow",
		"click @ui.downloadBtn": "downloadFile"
	},
	className: "modal-wrap",
	template: MusicModal,
	onShow: function(){
		// this.ui.modalScroll.niceScroll();
	},
	activeDownload: function(){
		this.$el.find('.modal-download').addClass('active');
	},
	downloadFile: function(){
		var file_id = this.$el.find('#file_id').val();
		this.trigger("get:file",file_id);
		this.trigger("dialog:close");
	},
	noticeShow: function () {
		this.$el.find('.b-music-terms-modal').addClass('_hide');
		this.$el.find('.b-music-notice-modal').addClass('_show');
		this.$el.find('.modal-title').text('NOTICE!');
	}
});