// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                                        "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"Add a new","key":"qg_partner.dashboard.add_a_new_channel"},"data":data}))
    + "<br>\n                                        "
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"channel","key":"qg_partner.channel"},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=helpers.blockHelperMissing, buffer = 
  "<div class=\"b-content-wrapper\">\n    <!-- INFO -->\n    <div class=\"b-content-info\">\n        <div class=\"b-info-g-ico\">\n            <img class=\"b-info-g-ico__img\" src=\""
    + alias4(((helper = (helper = helpers.picture || (depth0 != null ? depth0.picture : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"picture","hash":{},"data":data}) : helper)))
    + "\" alt=\"\">\n        </div>\n        <div class=\"b-info-g\">\n            <span class=\"b-info-name info-name\">"
    + alias4(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + "&nbsp;"
    + alias4(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + "</span>\n            <br>\n            <span class=\"b-info-email info-email\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n    </div>\n    <!-- INFO END -->\n    <!-- CONTENT NEWS -->\n    <div class=\"b-content-news\">\n\n    </div>\n    <!-- CONTENT NEWS END -->\n    <!-- CONTENT STATS -->\n    <div class=\"b-content-stats\">\n        <!-- CONTENT LEFT STATS -->\n        <div class=\"b-content-stats__left b-content-inline-block\">\n            <div class=\"b-content-stats__item b-content-stats-item_wide b-content-stats-item_green js-dashboard-payouts-panel\">\n            </div>\n            <div class=\"b-content-stats__item js-dashboard-channels-panel b-content-stats-item_wide b-content-stats-item_orange\">\n            </div>\n            <div class=\"b-content-stats__item b-content-stats-item_wide b-content-stats-item_white js-dashboard-referral-panel\">\n            </div>\n        </div>\n        <!-- CONTENT LEFT STATS END -->\n        <!-- CONTENT RIGHT STATS -->\n        <div class=\"b-content-stats__right b-content-inline-block\">\n            <div class=\"b-content-stats__item js-dashboard-profile b-content-stats-item_tight b-content-stats-item_white\">\n\n            </div>\n            <div class=\"b-content-stats__item b-content-stats-item_tight b-content-stats-item_white\">\n                <!-- a href=\"#?page=channels&subpage=addchannel\" -->\n		<a href=\"#\" onclick=\"quizcb_LoadForm(this);\" data-formfile=\"addnewch\">\n                    <div class=\"b-content-stats__item b-content-stats-item_left\">\n                        <span class=\"b-content-stats__title\">"
    + alias4((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"New Channel","key":"qg_partner.dashboard.new_channel"},"data":data}))
    + "</span>\n                        <br>\n                        <span class=\"i-edit i-edit_sp-ico i-edit_new-ico\"></span>\n                        <span class=\"i-edit i-edit_sp-ico i-edit_new-ico i-edit-sp-ico_invis\"></span>\n                    </div>\n                    <div class=\"b-content-stats__item b-content-stats-item-tight_right\">\n                        <div class=\"b-content-stats__container\">\n                            <div class=\"b-content-stats__container-inner\">\n                                <span class=\"b-content-stats__text\">\n";
  stack1 = ((helper = (helper = helpers.translate || (depth0 != null ? depth0.translate : depth0)) != null ? helper : alias2),(options={"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.translate) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </a>\n            </div>\n            <div class=\"b-content-stats__item b-content-stats-item_tight b-content-stats-item_white js-dashboard-inbox-panel\">\n\n            </div>\n        </div>\n        <!-- CONTENT RIGHT STATS END -->\n        <!-- CONTENT RIGHT STATS 768PX -->\n        <div class=\"b-content-stats__right b-content-inline-block b-content-stats-right_invis\">\n            <div class=\"b-content-stats__item b-content-stats-item_tight b-content-stats-item_white\">\n                <a href=\"#?page=profile\">\n                    <div class=\"b-content-stats-item_up\">\n                        <span class=\"b-content-stats__title\">Profile</span>\n                        <br>\n                        <span class=\"i-edit i-edit_sp-ico i-edit_profile-ico\"></span>\n                        <span class=\"i-edit i-edit_sp-ico i-edit_profile-ico i-edit-sp-ico_invis\"></span>\n                    </div>\n                    <div class=\"b-content-stats-item_down\">\n                        <div class=\"b-content-stats__container\">\n                            <div class=\"b-content-stats__container-inner\">\n                                <span class=\"b-content-stats__text\">View<br />profile</span>\n                            </div>\n                        </div>\n                    </div>\n                </a>\n            </div>\n            <div class=\"b-content-stats__item b-content-stats-item_tight b-content-stats-item_white js-dashboard-inbox-panel-768\">\n\n            </div>\n            <div class=\"b-content-stats__item b-content-stats-item_tight b-content-stats-item_white\">\n                <a href=\"#\">\n                    <div class=\"b-content-stats-item_up\">\n                        <span class=\"b-content-stats__title\">"
    + alias4((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"New Channel","key":"qg_partner.dashboard.new_channel"},"data":data}))
    + "</span>\n                        <br>\n                        <span class=\"i-edit i-edit_sp-ico i-edit_new-ico\"></span>\n                        <span class=\"i-edit i-edit_sp-ico i-edit_new-ico i-edit-sp-ico_invis\"></span>\n                    </div>\n                    <div class=\"b-content-stats-item_down\">\n                        <div class=\"b-content-stats__container\">\n                            <div class=\"b-content-stats__container-inner\">\n                                <span class=\"b-content-stats__text\">\n";
  stack1 = ((helper = (helper = helpers.translate || (depth0 != null ? depth0.translate : depth0)) != null ? helper : alias2),(options={"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.translate) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </a>\n            </div>\n        </div>\n        <!-- CONTENT RIGHT STATS 768PX END -->\n    </div>\n    <!-- CONTENT STATS END -->\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials.limiter,depth0,{"name":"limiter","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
