// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.payment_account || (depth0 != null ? depth0.payment_account : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"payment_account","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "value=\"\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<form>\n	<div class=\"b-payment-row b-center-row\">\n	    <div class=\"b-payment-row__item\">\n	        <label class=\"b-page-label b-label_nm\">Your WMZ purse</label><br>\n	        <input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" placeholder=\"\" name=\"payment_account\"\n                   "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.defaultPaymentType : depth0),7,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n	    </div>\n	</div>\n	<div class=\"b-payment-form-buttons\">\n	    <button type=\"submit\" class=\"b-page-button b-button_yes js-profile-webmoney-save\">Submit</button>\n	    <button type=\"button\" class=\"b-page-button b-button_no js-profile-webmoney-cancel\">Cancel</button>\n	</div>\n</form>";
},"useData":true});
