// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "-"
    + container.escapeExpression(((helper = (helper = helpers.last_year || (depth0 != null ? depth0.last_year : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"last_year","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "&nbsp;-&nbsp;"
    + container.escapeExpression((helpers.getMonth || (depth0 && depth0.getMonth) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.last_month : depth0),{"name":"getMonth","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<h2 class=\"b-page-title select-title\">\r\n    <span class=\"advanced-channel\">"
    + alias4(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "</span>\r\n    &nbsp;/&nbsp;<span class=\"advanced-date\">"
    + alias4(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + ((stack1 = (helpers.unlessCond || (depth0 && depth0.unlessCond) || alias2).call(alias1,(depth0 != null ? depth0.year : depth0),(depth0 != null ? depth0.last_year : depth0),{"name":"unlessCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&nbsp;"
    + alias4((helpers.getMonth || (depth0 && depth0.getMonth) || alias2).call(alias1,(depth0 != null ? depth0.month : depth0),{"name":"getMonth","hash":{},"data":data}))
    + ((stack1 = (helpers.unlessCond || (depth0 && depth0.unlessCond) || alias2).call(alias1,(depth0 != null ? depth0.month : depth0),(depth0 != null ? depth0.last_month : depth0),{"name":"unlessCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\r\n</h2>";
},"useData":true});
