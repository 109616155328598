var DashboardLoginView = require("./view/DashboardLoginWihCode");
var DashboardLoginModel = require("models/DashboardPasswordLogin/DasboardLoginPassword");

module.exports = Marionette.Object.extend({
	validation: {},
	initialize: function(){
		this.setHandlers();
		this.redirectToDashboard = function(){
			Radio.channel("login").trigger("user:success:login");
		};
		this.codeMap ={
			"-10": "showGAthError",
			"-1": "showUserIsNotExistError",
			"-2": "showUserBlockError",
			"-3": "showPasswordError",
			"-100": "showUnknownError"
		};
	},
	show: function(){
		var codeNum = Radio.channel("login").request("user:auth:code:num");
		var dashboardModel = new DashboardLoginModel();
		this.dashboardLogin = new DashboardLoginView({model: dashboardModel});
		var	mainRegion =  Radio.channel("layout").request("get:main:region"),
			me = this;

		this.listenTo(this.dashboardLogin, "send:code", function(data){
			dashboardModel.sendCode(data, {success: me.onSuccessCodeHandler.bind(me), fail: me.onFailCodeHandler.bind(me)});
		});

		mainRegion.show(this.dashboardLogin);
		Backbone.history.navigate("?page=dashboardloginwihcode");
	},
	onSuccessCodeHandler: function(){
		this.redirectToDashboard();
	},
	onFailCodeHandler: function(xhr, stringResult, result){
		var code,
			me = this;
			view = this.dashboardLogin;
		if(!_.isEmpty(xhr.responseJSON.body)){
			view.codeError("Wrong code");
			code = xhr.responseJSON.body.code;
			typeof  view[me.codeMap[String(code)]] === "function" ? view[me.codeMap[String(code)]](): view.showUnknownError();
		}
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:dashboard:login:with:code", function(){
			me.show();
		});
	}
});
