/**
 * Created by sergey on 09.12.2015.
 */
module.exports = Backbone.Model.extend({
	default:{
		isAuth: "unauthorized"
	},
	getUserInfo: function(){
		var promiseProfile;
		var deffer = $.Deferred();
		var me = this;
		if(this.get("authType") === "gauth"){
			$.when(Radio.channel("global").request("header:get:google:info:entity")).then(function(ginfo){
				me.set(ginfo.attributes);
				deffer.resolve(me);
			}, function(){
				deffer.resolve(me);
			});
		}else if(this.get("authType") === "loginauth" || this.get("authType") === "parking"){
			promiseProfile = Radio.channel("global").request("profile:entity");
			$.when(promiseProfile).then(function(profile){
				me.set(profile.attributes);
				deffer.resolve(me);
			}, function(){
				deffer.resolve(me);
			});
		}else{
			me.clear();
			me.set({isAuth: "unauthorized"});
			deffer.resolve(me);
		}
		return deffer.promise();
	}
});