/**
 * Created by sergey on 04.12.2015.
 */
module.exports = Backbone.Model.extend({
	defaults: {
		offset: 0,
		limit: 20
	},
	initialize: function(options){
		this.options = options;
	},
	url: function(){
		this.hideItems = 0;
		var lang = Radio.channel("login").request("user:lang");
		return  "/api/dashboard/get_channel_list?offset=" + this.get("offset") +"&limit="+ this.get("limit");
	},
	filterAction:function(ids){
		this.hideItems += ids.length;
		var objectMap={};
		_.reduce(ids, function(result, n, key){
			result[n] = true;
			return result;
		},objectMap);
		var filterData = _.filter(this.get("rows"), function(item){
				return !objectMap[item.channel_id];
		});
		this.set({rows: filterData}, {silent: true});
		this.trigger("refresh:filter:rows");
	},
	clearFilter: function(){
		this.hideItems = 0;
		this.set({rows: this.defaultRows}, {silent: true});
		this.trigger("refresh:filter:rows");
	},
	unSetFilterValue: function(){
		this.unset("filter", {silent: true});
	},
	rowsLength: function(){
		return this.get("rows").length;
	},
	getSearchList: function(){
		var result = _.map(this.get("rows"), function(r){return r.channel_name})
			.concat(_.map(this.get("rows"), function(r){if(r.channel_short_name !=r.channel_name) return r.channel_short_name})).
			concat(_.map(this.get("rows"), function(r){return r.channel_id})).filter(function(x) {
				return typeof x !== 'undefined';
			});
		return result;
	},
	setAutoClaim: function(data){
		var me = this,
			options = {
				success: function(){
					me.trigger("refresh:filter:rows");
				}
			};
		$.post("/api/channel/autoclaim", data).done(function(){
			me.fetch(options);
		})
	},
	setStarred: function(data){
		var me = this,
			options = {
				success: function(){
					me.trigger("refresh:filter:rows");
				}
			};
		$.post("/api/channel/starred", data).done(function(){
			me.fetch(options);
		});

	},
	getMoreChannels: function () {
		var me = this;
		this.set({"offset": me.get("offset")+20});
		$.ajax({
			method: "GET",
			url: "/api/dashboard/get_channel_list?offset=" + me.get("offset") +"&limit="+ me.get("limit")
		}).then(function (resp) {
			var newArr = resp.body.rows;
			var oldArr = me.get("rows");
			var defaultsOld = me.defaultRows;
			me.set({"rows": oldArr.concat(newArr)});
			me.defaultRows = defaultsOld.concat(newArr);
			me.trigger("refresh:filter:rows", newArr);
		})
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var rowsListLength = this.get("rows").length;
		if(this.hideItems + rowsListLength !== totalCount){
			result = true;
		}
		return result;
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		}else{
			return  this.get("offset")+this.get("limit");
		}
	},
	isChannelCPM: function(id){
		var me = this;
		var fetchCPMChannel = Radio.channel("global").request("cpm:cpm_channels:entity");
		var deffer = $.Deferred();
		if(!this.get("cpm_channels")){
			$.when(fetchCPMChannel).done(function(cpm_channels){
				me.set({cpm_channels: cpm_channels});
				deffer.resolve(me.get("cpm_channels").isChannelExistInCpm(id));
			});
		}else{
			deffer.resolve(me.get("cpm_channels").isChannelExistInCpm(id));
		}
		return deffer.promise();
	},
	parse: function(resp){
		var result ={};
		result.blocked_channels = resp.body.sum.blocked_channels;
		result.active_channels = resp.body.sum.active_channels;
		this.defaultRows = resp.body.rows;
		result.rows = resp.body.rows;
		result.total_count = resp.body.sum_rows;
		return result;
	}
});