// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.channel_id || (depth0 != null ? depth0.channel_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"b-page-form-row\">\n    <div class=\"b-page-form-row__item\">\n        <label class=\"b-page-label\">Channel</label><br>\n        <select class=\"b-page-input b-input_channel_id b-page-select b-input_question js-select-channels\" name=\"channel_id\">\n            <option value=\"\">Name of channel</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.channels : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"b-page-form-row__item\">\n        <label class=\"b-page-label\">Select question</label><br>\n        <select class=\"b-page-input b-input_subject b-page-select b-input_question js-input-question\" name=\"subject\">\n            <option value=\"\">Select question</option>\n            <option value=\"payout\">Payout</option>\n            <option value=\"channel_building\">Channel building </option>\n            <option value=\"channel_termination\">Channel Termination</option>\n        </select>\n    </div>\n</div>\n<div class=\"b-page-form-row\">\n    <label class=\"b-page-label\">Message</label><br>\n    <textarea class=\"b-page-input b-input_body\" placeholder=\"Message\" name=\"body\"></textarea>\n</div>\n<div class=\"b-upload-list\">\n    <div class=\"b-upload-file-wrap\">\n        <span class=\"b-upload-btn f_light\">Upload file (max. 3):</span>\n        <input class=\"fileupload\" type=\"file\" name=\"files[]\" accept=\"image/jpeg,image/png,image/bmp\" multiple>\n    </div>\n    <div class=\"b-upload-item-list\">\n\n    </div>\n</div>\n<div class=\"b-page-form-buttons\">\n    <button type=\"submit\" class=\"b-page-button b-button_yes\">Submit</button>\n    <button type=\"button\" class=\"b-page-button b-button_no js-inbox-create-form-ticket\">Cancel</button>\n</div>";
},"useData":true});
