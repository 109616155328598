/**
 * Created by sergey on 04.12.2015.
 */

var ChannelsHeaderTemplate =  require("./templates/ChannelsHeader.hbs");
module.exports = Marionette.LayoutView.extend({
	className: "b-sum-wrap",
	template: ChannelsHeaderTemplate,
	regions: {
		payoutChannelsRegion: ".js-channels-header-payout",
		viewsChannelsRegion: ".js-channels-header-views"
	},
	onShow: function(e){
	}
});