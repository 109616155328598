var SupportView = require("./view/SupportLayout");
var SupportEntities = require("entities/Support");
var SupportModel = require("models/Support/Support");

module.exports = Marionette.Object.extend({
	initialize: function(){
		SupportEntities.initialize();
		this.setHandlers();
	},
	show: function(){
		var fetchSupport = Radio.channel("global").request("support:entity"),
			me = this;
		$.when(fetchSupport).done(function(support){
			var supportView = new SupportView({model: support});
			me.listenTo(supportView, "search:support", function(data){
						support.searchSupport(data);
			});
			var mainRegion =  Radio.channel("layout").request("get:main:region");
			me.listenTo(supportView, "get:answer", function(data, fn){
				support.getAnswer(data, fn);
			});
			mainRegion.show(supportView);
		});
		Backbone.history.navigate("#?page=support");
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:support", function() {
			me.show();
		});
	}

});
