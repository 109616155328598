/**
 * Created by sergey on 01.12.2015.
 */
SecretQuestionsCollection =  require("../collections/Accounts/SecretQuestions");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getSecretQuestions: function(options){
		var options = options || {};
		var secretQuestions = new SecretQuestionsCollection(options);
		var defer = $.Deferred();
		secretQuestions.fetch({
			success: function(){
				defer.resolve(secretQuestions);
			}
		});
		return defer.promise();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("account:secret:questions:entities", function(options){
			return me.getSecretQuestions(options);
		});
	}
};