// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <a class=\"b-download-link\" data-desc=\"Download report\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n        <span class=\"i-edit\"></span>\n        <span class=\"i-edit i-edit_invis\"></span>\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"b-download-link b-download-link_disabled\" data-desc=\"No report available\" href=\"javascript:void(0);\">\n        <span class=\"i-edit\"></span>\n        <span class=\"i-edit i-edit_invis\"></span>\n    </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"b-table-earning__item\">"
    + container.escapeExpression(((helper = (helper = helpers.earning_amount || (depth0 != null ? depth0.earning_amount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"earning_amount","hash":{},"data":data}) : helper)))
    + "</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
