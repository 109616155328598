// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"b-content-wrapper\">\n    <br>\n    <br>\n    <div class=\"b-page-block b-login-block b-code js-dashboard-code-login b-margin\">\n        <div class=\"b-error-border\"></div>\n        <form class=\"js-dashboard-login-code\">\n            <p class=\"b-login-text f_light\">Please enter your unique code number №<span class=\"b-unique-number\">"
    + container.escapeExpression(((helper = (helper = helpers.codeNum || (depth0 != null ? depth0.codeNum : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"codeNum","hash":{},"data":data}) : helper)))
    + "</span></p>\n            <label class=\"b-page-label\">Code</label>\n            <input type=\"text\" class=\"b-input-login js-dashboard-login-password-code\" placeholder=\"Enter your code\">\n            <div class=\"b-page-form-buttons\">\n                <button  type=\"submit\" class=\"b-page-button b-button_yes js-dashboard-login-code-submit b-submit-button\">OK</button>\n            </div>\n            <div class=\"b-login-error-block \"></div>\n        </form>\n    </div>\n</div>\n"
    + ((stack1 = container.invokePartial(partials.limiter,depth0,{"name":"limiter","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
