var PayoutLayout = require("./views/PayoutLayout");
var ToolbarMonth = require("./views/ToolbarMonth");
var ToolbarYear = require("./views/ToolbarYear");
var PayoutHeader = require("./views/PayoutHeader");
var PayoutTable = require("./views/PayoutTable");
var AdvanceReportToolbar = require("./views/PayoutToolbar");

module.exports = Marionette.Object.extend({
	initialize: function () {
		this.setHandlers();

	},
	show: function (params) {
		var payoutLayout = new PayoutLayout(),
			mainRegion = Radio.channel("layout").request("get:main:region"),
			month = Radio.channel("login").request("user:month:of:payment"),
			year = Radio.channel("login").request("user:year:of:payment");
		options = {
			filter: {
				year: year
			}
		};
		options.filter.month = month;
		if (params ? params.channel_list && params.channel_list.length != 0 ? 1 : 0 : 0) {
			options.filter.channel_ids = params.channel_list.split(",");
		}
		var fetchPayout = Radio.channel("global").request("payout:entity", options);
		var fetchChanels = $.ajax({
			type: "GET",
			url: "api/dashboard/get_channel_list?offset=0&limit=20"
		});
		var fetchArray = [fetchPayout, fetchChanels];

		var me = this;
		$.when.apply($, fetchArray).done(function (payout, channels) {
			var toolbarModel = new Backbone.Model({
				month: month,
				year: year,
				last_month: month,
				last_year: year
			});
			payout.setMonth(month, {silent: true});
			payout.setYear(year, {silent: true});
			var toolbarMonth = new ToolbarMonth({month: month - 1});
			var toolbarYear = new ToolbarYear({year: year});
			var header = new PayoutHeader({model: payout});
			var payoutTable = new PayoutTable({model: payout});
			var toolBar = new AdvanceReportToolbar({model: toolbarModel, channels: channels[0].body.rows});


			me.listenTo(payoutLayout, "more:btn:click", function () {
				payout.getMorePayments();
			});

			me.listenTo(payoutLayout, "user:click:reset:btn", function () {
				payout.resetChannelFilter();
				Backbone.history.navigate("?page=payout");
			});

			me.listenTo(toolbarMonth, "user:change:payout:month", function (data) {
				payout.setMonth(data + 1);
			});

			me.listenTo(toolbarYear, "user:change:payout:year", function (data) {
				payout.setYear(data);
			});

			me.listenTo(header, "user:click:channel:tab", function (data) {
				payout.unsetTab();
			});

			me.listenTo(header, "user:click:refs:tab", function (data) {
				payoutLayout.hideResetBtn();
				payout.setTab();
			});

			me.listenTo(payout, "data:reload", function (data) {
				if (payout.needShowMoreBtn()) {
					payoutLayout.showMoreBtn();
				} else {
					payoutLayout.hideMoreBtn();
				}
				if (payout.needShowResetFilter()) {
					payoutLayout.showResetBtn();
				} else {
					payoutLayout.hideResetBtn();
				}
				payoutLayout.setCountPage({offset: payout.getPageCount(), total_count: payout.get("total_count")});
				payoutTable.refreshData(data);
			});

			me.listenTo(payoutLayout, "show", function () {
				if (payout.needShowMoreBtn()) {
					payoutLayout.showMoreBtn();
				} else {
					payoutLayout.hideMoreBtn();
				}
				if (payout.needShowResetFilter()) {
					payoutLayout.showResetBtn();
				} else {
					payoutLayout.hideResetBtn();
				}
				payoutLayout.setCountPage({offset: payout.getPageCount(), total_count: payout.get("total_count")});
				payoutLayout.toolbarMonthRegion.show(toolbarMonth);
				payoutLayout.toolbarYearRegion.show(toolbarYear);
				payoutLayout.payoutHeaderRegion.show(header);
				payoutLayout.payoutTableRegion.show(payoutTable);
			});

			me.listenTo(toolBar, "user:click:cancel:btn", function (data) {
				toolbarModel.clear();
				payoutLayout.hideConditions();
			});

			me.listenTo(toolBar, "user:change:advance:report:params", function (searchParams) {
				Backbone.history.navigate("#?page=advancedreport&subpage=table&channel_id="+searchParams.channel_id+
					"&month="+searchParams.month+"&year="+searchParams.year+"&last_month="+searchParams.last_month+
					"&last_year="+searchParams.last_year, {trigger: true});
			});

			mainRegion.show(payoutLayout);
			payoutLayout.toolbarRegion.show(toolBar);
			Backbone.history.navigate(Backbone.history.getFragment());
		});
	},
	setHandlers: function () {
		var me = this;
		Radio.channel("application").on("application:state:payout", function (params) {
			me.show(params);
		});
	}
});