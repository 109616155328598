var Step2View = require("./views/Step2");

module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	show: function(){
		var me = this;
		var step2View = new Step2View();
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		mainRegion.show(step2View);
		Backbone.history.navigate("#?page=joinstep2");
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:joinstep2", function() {
			me.show();
		});
	}

});
