// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <img class=\"navbar-g-ico-img\" src=\""
    + container.escapeExpression(((helper = (helper = helpers.picture || (depth0 != null ? depth0.picture : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"picture","hash":{},"data":data}) : helper)))
    + "\" alt=\"\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <img class=\"navbar-g-ico-img\" alt=\"\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<span class=\"b-inbox-count js-header-inbox-msg\">"
    + container.escapeExpression(((helper = (helper = helpers.messageCount || (depth0 != null ? depth0.messageCount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"messageCount","hash":{},"data":data}) : helper)))
    + "</span>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"navbar-slide-ico\">\n	<div class=\"navbar-menu-ico\">\n		<span class=\"navbar-menu-row\"></span>\n	</div>\n	<div class=\"navbar-slide-menu\">\n		<div class=\"navbar-menu-close\">\n			<div class=\"navbar-menu-ico\">\n				<span class=\"navbar-menu-row\"></span>\n			</div>\n		</div>\n		<ul class=\"navbar-slide-list\">\n			<li class=\"navbar-slide-item\"><a href=\"#?page=profile\">My profile</a></li>\n			<li class=\"navbar-slide-item\"><a class=\"active js-logout\" href=\"#\">Log out</a></li>\n		</ul>\n	</div>\n	<div class=\"b-blackout\"></div>\n</div>\n<div class=\"navbar-info\">\n	<div class=\"navbar-g-ico\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.authType : depth0),"gauth",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "		<div class=\"navbar-menu\">\n			<ul class=\"navbar-menu-list\">\n				<li class=\"navbar-menu-item\"><a href=\"#?page=profile\">My profile</a></li>\n				<li class=\"navbar-menu-item\"><a class=\"active js-logout\" href=\"#\">Log out</a></li>\n			</ul>\n		</div>\n	</div>\n	<div class=\"b-header-switch-pr\">\n		<span class=\"i-edit\"></span>\n		<span class=\"i-edit i-edit-invis\"></span>\n		<div class=\"b-product-list\">\n			<div class=\"b-product-item b-product-labq\">\n				<span class=\"i-edit\"></span>\n				<span class=\"i-edit i-edit-invis\"></span>\n				<div>Lab Q</div>\n			</div>\n		</div>\n	</div>\n	<div class=\"b-pr-inbox-list\">\n		<div class=\"b-pr-inbox-item\">\n			<span class=\"i-edit\"></span>\n			<span class=\"i-edit i-edit-invis\"></span>\n			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.messageCount : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		</div>\n	</div>\n	<div class=\"navbar-g-info\">\n		<span class=\"navbar-info-name info-name\">"
    + alias4(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + "&nbsp;"
    + alias4(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + "</span>\n		<br>\n		<span class=\"navbar-info-email info-email\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</span>\n	</div>\n</div>";
},"useData":true});
