var StateManager = require("StateManager");
var LayoutManager = require("helpers/LayoutManager");
var Helpers = require("helpers/Helpers");
var ValidationHelper = require("helpers/BackboneValidation");

module.exports = Marionette.Controller.extend({
	initialize: function () {
		App.core.vent.trigger('app:log', 'Controller: Initializing');
		this.layoutManager = new LayoutManager();
		this.stateManager = new StateManager();
		ValidationHelper();
	},

	changeRoute: function (stringParams) {
		var params = Helpers.parseUrl(stringParams);
		this.stateManager.setState(params);
	}


});
