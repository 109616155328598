// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "b-hide-item";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "	<div class=\"navbar-info\">\n		<div class=\"navbar-g-ico\">\n	            <img class=\"navbar-g-ico-img\" src=\""
    + alias4(((helper = (helper = helpers.picture || (depth0 != null ? depth0.picture : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"picture","hash":{},"data":data}) : helper)))
    + "\" alt=\"\">\n			<div class=\"navbar-menu\">\n				<ul class=\"navbar-menu-list\">\n					<li class=\"navbar-menu-item\"><a class=\"active js-logout\" href=\"#\">Log out</a></li>\n				</ul>\n			</div>\n		</div>\n		<div class=\"navbar-g-info\">\n			<span class=\"navbar-info-name info-name\">"
    + alias4(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + "&nbsp;"
    + alias4(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + "</span>\n			<br>\n			<span class=\"navbar-info-email info-email\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n	</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"navbar-header clearfix\">\n	<div class=\"navbar-left\">\n		<a href=\"#\" class=\"navbar-logo\">\n			<span class=\"i-edit i-edit_logo i-edit-logo_visible\"></span>\n			<span class=\"i-edit i-edit_logo i-edit-logo_hover\"></span>\n		</a>\n	</div>\n	<div class=\"navbar-right js-header-navbar "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.picture : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"navbar-login\">\n			<a class=\"active navbar-login-button\" href=\"javascript:void(0);\">Log In</a>\n			<span class=\"navbar-spinner\"></span>\n		</div>\n		<div class=\"navbar-login-select\">\n			<ul class=\"navbar-login-list\">\n				<li class=\"navbar-login-item\" style=\"padding: 1px 0px 1px 0px !important;\"><a href=\"#\" class=\"js-login\"><img src=\"/sign-in-with-google.png\"></a></li>\n				<li class=\"navbar-login-item\">\n					<span class=\"i-edit i-edit-open\"></span>\n					<a class=\"js-password-access\" href=\"#?page=dashboardloginpassword\">Password access</a>\n					<div class=\"navbar-pa-desc\">\n						<span>\n							You may login to your personal dashboard via username and password. To use the new option, please go to the \"Profile\" section of your personal dashboard and enable the \"Password access\" option. Otherwise you will continue to use the sign-in option.\n						</span>\n					</div>\n				</li>\n			</ul>\n		</div>\n	</div>\n</div>\n\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.picture : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
