var MusicTemplate = require("./templates/MusicLayout.hbs");
module.exports = Marionette.LayoutView.extend({
	ui: {
		downloadBtn: ".b-music-download",
		chosenScroll: ".chosen-results"
	},
	regions: {
		musicToolbarRegion: ".js-music-toolbar",
		musicListRegion: ".js-music-list",
		musicHeaderRegion: ".js-music-header",
		musicTermsRegion: ".js-music-terms",
		musicPagionatorRegion: ".js-music-paginator-region"
	},
	className: "wrapper_n",
	template: MusicTemplate,
	events: {
		"click @ui.downloadBtn" : "notifyShow",
		"click li[data-tab]": "onTabClick"
	},
	onShow: function(){
		var me = this;
		this.$el.on('click', ".b-music-cat.active-crumb",function(e){
			me.breadcrums;
			var newBreadCrums = [];
			var index = -1;
			var catId = $(e.currentTarget).data("id"),
				catName = $(e.currentTarget).text().replace('>',' ');
				crumbIndex = $(e.currentTarget).index(),
				disIndex = $('.disable-crumb').index();
			_.every(me.breadcrums, function(item, itemIndex){
				newBreadCrums.push(item);
				if(item.catId == catId){
					return false;
				}
				return true;
			});

			var div = $("<div/>");
			$.each(newBreadCrums,function(i,item){
				div.append('<span class="b-music-cat active-crumb" data-id="'+item.catId+'">'+item.text+'<span class="b-next-cat"> > </span></span>');
			});
			me.breadcrums = newBreadCrums;
			setTimeout(function(){
				me.editCrumbs();
			},300);
			$('.js-music-breadcrumbs').html(div);
			$.ajax({
				type: "GET",
				url: "/api/music/category_list?cat_id="+catId+""
			}).then(function(resp){
				var data = {
					catId: catId,
					text: catName
				};
				me.trigger("change:select", resp.body.category, data, resp);
			});
			me.trigger("update:crumb", catId, newBreadCrums);
			return false;
		})
	},	
	initialze: function(){

	},
	onTabClick: function(e){
		var target = $(e.currentTarget);
		var data = $(e.currentTarget).data("tab");
		this.trigger("user:click:tab", data)
	},
	activateMusicTab: function(){
		this.$el.find("li[data-tab='sound']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='music']").addClass("b-tabs-active");
		this.$el.find("li[data-tab='terms']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='selected']").removeClass("b-tabs-active");
	},
	activateSoundTab: function(){
		this.$el.find("li[data-tab='sound']").addClass("b-tabs-active");
		this.$el.find("li[data-tab='music']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='terms']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='selected']").removeClass("b-tabs-active");
	},
	activateTermsTab: function(){
		this.$el.find("li[data-tab='sound']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='music']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='selected']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='terms']").addClass("b-tabs-active");

	},
	activateSelectedTab: function(){
		this.$el.find("li[data-tab='sound']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='music']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='terms']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='selected']").addClass("b-tabs-active");
	},
	notifyShow: function(e){
		var id = $(e.currentTarget).data("id");
		this.trigger("additional:show", id);
		$('#file_id').val(id);
	},
	hideListBlock: function(){
		this.$el.find('.b-music-list').fadeOut(300);
		this.$el.find('.navbar-spinner').addClass('_show');
		this.$el.find('.b-breadcrumbs-bottom').hide();
	},
	showListBlock: function(){
		this.$el.find('.b-music-list').fadeIn(300);
		this.$el.find('.navbar-spinner').removeClass('_show');
		this.$el.find('.b-breadcrumbs-bottom').show();
	},
	showCrumbs: function(){
		$('.js-music-breadcrumbs').show();
	},
	hideCrumbs: function(){
		$('.js-music-breadcrumbs').hide();
	},
	editCrumbs: function(){
		$('.js-music-breadcrumbs .b-music-cat:last-child .b-next-cat').remove();
		$('.js-music-breadcrumbs .b-music-cat:last-child').removeClass('active-crumb').addClass('disable-crumb');
	},
	addCrumbs: function(data){
		var me = this;
		this.breadcrums = data;
		$('.js-music-breadcrumbs').html('');
		$.each(data,function(i,item){
			$('.js-music-breadcrumbs').append('<span class="b-music-cat active-crumb" data-id="'+item.catId+'">'+item.text+'<span class="b-next-cat"> > </span></span>');
		});
		me.editCrumbs();
		
	}
});