var ProfileLayoutTemplate = require("./templates/ProfileLayout.hbs");
module.exports = Marionette.LayoutView.extend({
	ui:{
		editLinkWrap: ".b-edit-link-wrap",

		checkPayMethod: ".b-input-check",
		settingsBtn: ".b-profile-settings"
	},
	events: {
		"click @ui.editLinkWrap" : "editProfileData"
	},
	regions: {
		aboutRegion: ".js-profile-about-form",
		contactRegion: ".js-profile-contact-form",
		paymentRegion: ".js-payment-region"
	},
	triggers: {
		"click @ui.settingsBtn" : "additional:show"
	},
	className: "wrapper_n",
	template: ProfileLayoutTemplate

	
});