/**
 * Created by sergey on 02.12.2015.
 */
var tableMixin = require("mixins/Table");
var TemplateTablePayout = require("./templates/ReferralsTable.hbs");
var Helpers = require("helpers/Helpers");
module.exports =  Marionette.ItemView.extend(_.extend(tableMixin, {
	className: "b-table f_12 refferal-table",
	tagName: "table",
	template: TemplateTablePayout,
	initialize: function(){
		this.listenTo(this.model, "change:query", this.onSearchChange.bind(this));

	},
	refreshData: function(data){
		this.drawTable(data);
	},
	getData: function(){
		return this.model.get("referal_channels");
	},
	getConfig: function(){
		var me = this;
		return {
			"paging": false,
			"createdRow": me.createRow,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns(),
			"order": [[ 0, "asc" ]],
			"language": {
				"emptyTable": "N/A"
			}
		}
	},
	getColumns: function() {
		return [
			{data: "channel_name", className: "b-channel-name b-referrals-row", render: function (data, type, full, meta){
				return '<a class="b-tooltip b-tooltip_f-12 b-tooltip-nowrap" data-desc="'+full.channel_name+'" href="http://www.youtube.com/channel/'+full.channel_id+'" target="_blank"><span>'+full.channel_name+'</span></a>'
			}},
			{data: "channel_id", render: function (data, type, full, meta){
				return full.dict_id;
			}},
			{data: "date", render: function (data, type, full, meta) {
				return Helpers.revertDate(data);
			}},
			{data: "revenue", render: function (data, type, full, meta) {
				return data*100;
			}},
			{data: "status", render: function (data, type, full, meta) {
				if(data > 0){
					return "<span class='b-status b-status_good-standing'>Active</span>"
				}else{
					return "<span class='b-status b-status_bad-standing'>Blocked</span>"
				}
			}}
		];
	},
	onSearchChange: function(model, value){
		this.table.search(value).draw();
	},
	createRow: function( row, data, dataIndex ) {
		if ( data.status < 0 ) {
			$(row).addClass("b-rejected-row");
		}
	},
	drawTable: function(data) {
		var me = this;
		if (data) {
			this.table.rows.add(data); // Add new data
			this.table.columns.adjust().draw(false); // Redraw the DataTable
		} else {
			this.table.clear().draw();
			this.table.rows.add(me.getData()); // Add new data
			this.table.columns.adjust().draw(); // Redraw the DataTable
		}
	}
}));