/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var ItemTemplate = require("./templates/ItemNews.hbs");
Views.ItemNews = Marionette.ItemView.extend({
	template: ItemTemplate,
	className: "b-content-news-list",
	onShow: function(){
		$('.b-content-news-list').each(function(index, item){
		    var block = $(item),
		    	text = block.find('.b-short-body'),
		    	text2 = block.find('.b-hidden-text p'),
		    	fullText = text2.text(),
		    	shortText = text.text().slice(0,172);
		    text.text(shortText);
		});
	}
});

Views.ListNews = Marionette.CollectionView.extend({
	childView: Views.ItemNews
});
module.exports = Marionette.Object.extend({
	createView: function(region, newsList){
		this.newsListView = new Views.ListNews({collection: newsList});
		region.show(this.newsListView);
	},
	show: function(region){

		var options = {
				filter: {
					limit: 2
				}
		};
		options.filter.lang = Radio.channel("login").request("user:lang");
		var fetchNews = Radio.channel("global").request("dashboard:news:entities", options);
		var me = this;
		$.when(fetchNews).done(me.createView.bind(me, region))
	}
});