/**
 * Created by sergey on 28.12.2015.
 */
var PayuotToolbar = require("./templates/PayoutToolbar.hbs");
var PeriodTemplate = require("./templates/PayoutPeriodTemplate.hbs");
var Carousel = require("widgets/Carousel");
module.exports = Marionette.ItemView.extend({
	ui: {
		changeSearch: ".b-reports-change",
		cancelBtn: ".js-advance-report-cancel",
		beginYear: ".begin-year",
		beginMonth: ".begin-month",
		endYear: ".end-year",
		endMonth: ".end-month",
		sendBtn: ".js-advance-report-set-filter",
		channelSelect: ".js-advance-report-channel-list",
		beginPeriodBtn: ".js-payout-begin-period-advance-report",
		endPeriodBtn: ".js-payout-last-period-advance-report"
	},
	events: {
		"click @ui.sendBtn": "onSendBtnClick"
	},
	triggers:{
		"click @ui.cancelBtn": "user:click:cancel:btn"
	},
	className: "b-settings-wrap",
	template: PayuotToolbar,
	templateHelpers: function (data) {
		var me = this;
		return {
			channel_list: me.options["channels"]
		}
	},
	initialize: function () {
		this.yearMap = {
			2015: 0,
			2016: 1,
			2017: 2,
			2018: 3,
			2019: 4,
			2020: 5,
			2021: 6,
                        2022: 7,
                        2023: 8,
                        2024: 9
		};
		this.yearMapRevers = {
			0: 2015,
			1: 2016,
			2: 2017,
			3: 2018,
			4: 2019,
			5: 2020,
			6: 2021,
                        7: 2022,
                        8: 2023,
                        9: 2024
		};
		this.beginMonthCarousel = new Carousel();
		this.beginYearCarousel = new Carousel();
		this.lastMonthCarousel = new Carousel();
		this.lastYearCarousel = new Carousel();
		this.listenTo(this.model, "change:month", this.onBeginPeriodChange);
		this.listenTo(this.model, "change:year", this.onBeginPeriodChange);
		this.listenTo(this.model, "change:last_month", this.onEndPeriodChange);
		this.listenTo(this.model, "change:last_year", this.onEndPeriodChange);
	},
	onShow: function(){
		var me = this;
		$('.dropdown-btn').on('click', function (event) {
			$(this).parent().toggleClass("open");
		});

		$('body').on('click', function (e) {
			if (!$('div.dropdown').is(e.target) && $('div.dropdown').has(e.target).length === 0) {
						$('div.dropdown').removeClass('open');
			}
		});
		var month = Radio.channel("login").request("user:month:of:payment");
		var lastmonth = Radio.channel("login").request("user:month:of:payment");
		var year = Radio.channel("login").request("user:year:of:payment");
		var lastyear = Radio.channel("login").request("user:year:of:payment");

		this.beginMonthCarousel.show(this.ui.beginMonth, {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			beforeChange: me.onBeginMonthChange.bind(me),
			initialSlide: month-1,
			view: me
		});
		this.beginYearCarousel.show(this.ui.beginYear, {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			beforeChange: me.onBeginYearChange.bind(me),
			initialSlide: me.yearMap[year],
			view: me
		});
		this.lastMonthCarousel.show(this.ui.endMonth, {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			beforeChange: me.onLastMonthChange.bind(me),
			initialSlide: lastmonth-1,
			view: me
		});
		this.lastYearCarousel.show(this.ui.endYear, {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			beforeChange: me.onLastYearChange.bind(me),
			initialSlide: me.yearMap[lastyear],
			view: me
		});
	},
	onSendBtnClick: function(){
		var data = {
			month: this.beginMonthCarousel.getValue()+1,
			year: this.yearMapRevers[this.beginYearCarousel.getValue()],
			last_month:  this.lastMonthCarousel.getValue()+1,
			last_year: this.yearMapRevers[this.lastYearCarousel.getValue()],
			channel_id: this.ui.channelSelect.val(),
			channel_name: this.ui.channelSelect.find("option:selected").text()
		};
		this.trigger("user:change:advance:report:params", data);
		$('.b-reports-change').fadeIn(200);
		$('.b-settings-open').removeClass('open');
	},
	onBeginPeriodChange: function(){
		var content = "";
		if(!this.model.get("month") && !this.model.get("year")){
			content = "Begin period"
		}else{
			content = PeriodTemplate({
				month: this.model.get("month"),
				year: this.model.get("year")
			})
		}
		this.ui.beginPeriodBtn.html(content);
	},
	onEndPeriodChange: function(){
		var content = "";
		if(!this.model.get("last_month") && !this.model.get("last_year")){
			content = "End period"
		}else{
			content = PeriodTemplate(
				{
					month: this.model.get("last_month"),
					year: this.model.get("last_year")
				});
		}
		this.ui.endPeriodBtn.html(content);
	},
	disableSearchBtn: function(){
		this.ui.sendBtn.prop('disabled', true);
	},
	enableSearchBtn: function(){
		this.ui.sendBtn.prop('disabled', false);
	},
	onBeginMonthChange: function(event, slick, currentSlide, nextSlide){
		this.model.set({month:  nextSlide+1});
		var yearEqual = this.yearMapRevers[this.beginYearCarousel.getValue()] == this.yearMapRevers[this.lastYearCarousel.getValue()],
			yearMore = this.yearMapRevers[this.beginYearCarousel.getValue()] > this.yearMapRevers[this.lastYearCarousel.getValue()];
		if((yearEqual && (nextSlide >= this.lastMonthCarousel.getValue()+1)) || yearMore){
			this.disableSearchBtn();
		}else{
			this.enableSearchBtn();
		}
	},
	onBeginYearChange: function(event, slick, currentSlide, nextSlide){
		this.model.set({year:  this.yearMapRevers[nextSlide]});
		if(this.yearMapRevers[nextSlide] > this.yearMapRevers[this.lastYearCarousel.getValue()]){
			this.disableSearchBtn();
		}else{
			this.enableSearchBtn();
		}
	},
	onLastMonthChange: function(event, slick, currentSlide, nextSlide){
		this.model.set({last_month:  nextSlide+1});
		var yearEqual = this.yearMapRevers[this.beginYearCarousel.getValue()] == this.yearMapRevers[this.lastYearCarousel.getValue()],
			yearMore = this.yearMapRevers[this.beginYearCarousel.getValue()] > this.yearMapRevers[this.lastYearCarousel.getValue()];
		if((yearEqual &&(this.beginMonthCarousel.getValue() >= nextSlide+1)) || yearMore) {
			this.disableSearchBtn();
		}else{

			this.enableSearchBtn();
		}
	},
	onLastYearChange: function(event, slick, currentSlide, nextSlide){
		this.model.set({last_year: this.yearMapRevers[nextSlide]});
		if(this.yearMapRevers[nextSlide] >=  this.yearMapRevers[this.beginYearCarousel.getValue()]){
			this.enableSearchBtn();
		}else{
			this.disableSearchBtn();
		}

	},
	onBeforeDestroy: function(){
		this.lastYearCarousel.destroy();
		this.lastMonthCarousel.destroy();
		this.beginYearCarousel.destroy();
		this.beginMonthCarousel.destroy();
		delete this.lastYearCarousel;
		delete this.lastMonthCarousel;
		delete this.beginYearCarousel;
		delete this.beginMonthCarousel;
	}
});
