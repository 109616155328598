var InboxTemplate = require("./templates/InboxLayout.hbs");
var CountTemplate = require("./templates/CountPage.hbs");
module.exports = Marionette.LayoutView.extend({
	ui:{
		notificationText: ".js-inbox-notification-text",
		ticketsText: ".js-inbox-tickets-text",
		totalText: ".js-inbox-total-text",
		moreBtn: ".js-inbox-more-btn",
		countPage: ".js-inbox-table-page",
		upBtn: ".b-up-button"
	},
	events: {
		"click li[data-type]": "onTabClick",
		"click @ui.moreBtn": "onMoreBtnClick",
		"click @ui.upBtn": "toTop"
	},
	className: "wrapper_n",
	template: InboxTemplate,
	regions: {
		"mainContentRegion": ".js-inbox-main-content",
		"toolBarRegion": ".js-inbox-toolbar"
	},
	templateHelpers: function(){
		var me = this;
		return {
			totalCount: me.model.get("notificationSum") //+ me.model.get("ticketsSum")
		}
	},
	initialize: function () {
		this.listenTo(this.model, "change:notificationSum", this.onNotificationSumChange);
		this.listenTo(this.model, "change:ticketsSum", this.onNotificationSumChange);
	},
	onShow: function(e){
		$(window).scroll(function(){
			if ($(window).scrollTop() > 170) {
				$('.b-up-button').fadeIn(300);
			} else {
				$('.b-up-button').fadeOut(300);
			}
		});
	},
	setActiveTab: function () {
		this.$el.find('[data-type]').each(function(index, el){
			$(el).removeClass("b-tabs-active");
			$(el).removeClass("b-tabs-active");
		});
		this.$el.find('[data-type="'+ this.model.getActiveTab() +'"]').addClass("b-tabs-active");
		if(this.model.getActiveTab() == "tickets"){
			$('.b-inbox-body').css('background','transparent');
			$('.js-inbox-main-content').css('padding-top','0');
		}else{
			$('.b-inbox-body').css('background','#fff');
			$('.js-inbox-main-content').css('padding-top','55'+'px');
		}
	},
	onTabClick: function(e){
		var data = $(e.currentTarget).data("type");
		this.trigger("user:click:tab", data);
		
	},
	onNotificationSumChange: function(){
		if( this.model.get("notificationSum") !== 0) {
			this.ui.notificationText.text(this.model.get("notificationSum"));
			this.ui.notificationText.removeClass("b-hide-item");
		}else{
			this.ui.notificationText.addClass("b-hide-item");
		}
		if( this.model.get("ticketsSum") !== 0) {
			this.ui.ticketsText.text(this.model.get("ticketsSum"));
			this.ui.ticketsText.removeClass("b-hide-item");
		}else{
			this.ui.ticketsText.addClass("b-hide-item");
		}
		if( this.model.get("notificationSum") + this.model.get("ticketsSum") !== 0){
			this.ui.totalText.text( this.model.get("notificationSum") + this.model.get("ticketsSum"));
			this.ui.totalText.removeClass("b-hide-item");
		}else{
			this.ui.totalText.addClass("b-hide-item");
		}

	},
	onMoreBtnClick: function () {
		this.trigger(this.model.getActiveTab()+":more:btn:click");
	},
	showMoreBtn: function(){
		this.ui.moreBtn.removeClass("b-hide-item");
	},
	hideMoreBtn: function(){
		this.ui.moreBtn.addClass("b-hide-item");
	},
	showCountPages: function(){
		this.ui.countPage.removeClass("b-hide-item");
	},
	hideCountPages: function(){
		this.ui.countPage.addClass("b-hide-item");
	},
	setCountPage: function(data){
		if(data.total_count < 20){
			this.ui.countPage.addClass("b-hide-item");
		}else{
			this.ui.countPage.html(CountTemplate(data));
			this.ui.countPage.removeClass("b-hide-item");
		}
	},
	toTop: function(e){
		e.preventDefault();
		$('html, body').animate({scrollTop:0}, 1100);
	}

});