/**
 * Created by gila on 10.12.2015.
 */
module.exports = Backbone.Model.extend({
	initialize: function(options){
		this.options = options;
	},
	url: "/api/music/category_list?cat_id=3",
	parse: function(resp){
		return resp.body;
	}
});