// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li class=\"b-pagination-item\"><a class=\"b-change-page-link on js-paginator-prev\" data-page=\""
    + container.escapeExpression((helpers.math || (depth0 && depth0.math) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.page_active : depth0),"-",1,{"name":"math","hash":{},"data":data}))
    + "\"><<</a></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <li class=\"b-pagination-item\"><a class=\"b-change-page-link on js-pagination-page\" data-page=\"1\">1</a></li>\n        <li class=\"b-pagination-item disabled\"><a class=\"b-change-page-link disabled\">...</a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <li class=\"b-pagination-item disabled\" ><a class=\"b-change-page-link disabled\">...</a></li>\n        <li class=\"b-pagination-item\"><a class=\"b-change-page-link on js-pagination-page\" data-page=\""
    + alias4(((helper = (helper = helpers.page_count || (depth0 != null ? depth0.page_count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page_count","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.page_count || (depth0 != null ? depth0.page_count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page_count","hash":{},"data":data}) : helper)))
    + "</a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <li class=\"b-pagination-item\"><a class=\"b-change-page-link on js-paginator-next\" data-page=\""
    + container.escapeExpression((helpers.math || (depth0 && depth0.math) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.page_active : depth0),"+",1,{"name":"math","hash":{},"data":data}))
    + "\">>></a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing;

  return "<ul class=\"b-pagination\">\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.page_active : depth0),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.left_dots : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + container.escapeExpression((helpers.generatePagerRange || (depth0 && depth0.generatePagerRange) || alias2).call(alias1,(depth0 != null ? depth0.nav_begin : depth0),(depth0 != null ? depth0.nav_end : depth0),(depth0 != null ? depth0.page_active : depth0),{"name":"generatePagerRange","hash":{},"data":data}))
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.right_dots : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias2).call(alias1,(depth0 != null ? depth0.page_active : depth0),(depth0 != null ? depth0.page_count : depth0),{"name":"compare","hash":{"operator":"<"},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>";
},"useData":true});
