/**
 * Created by sergey on 20.11.2015.
 */
var MessagePage = require("./templates/MessagePage.hbs");
module.exports = Marionette.ItemView.extend({
	template: MessagePage,
	className: "b-content-wrapper",
	triggers: {
		"click .js-autherror-handler-accept": "user:accept"
	}
});