/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var ListTemplate = require("./templates/CategoriesList.hbs");
Views.Categories = Marionette.ItemView.extend({
	template: false,
	tagName: "select",
	className: "b-music-select chosen-select",
	
	events: {
		"change": "onSelectChange"
	},
	onShow: function(){
		this.$el.chosen();
		if(this.options['tabName'] === "music"){
			$('.chosen-single span').text("Roalty Free Music");
			this.trigger("user:select:category", 2, "Roalty Free Music");
		}else{
			$('.chosen-single span').text("Free Sound Effects");
			this.trigger("user:select:category", 3, "Free Sound Effects");			
		}
	},
	onSelectChange: function(){
		var val = this.$el.val();
		var text = this.$el.find("option:selected").text();
		this.trigger("user:select:category", val, text);
	},
	changeTemplate: function(category, data){
		this.$el.html(ListTemplate({items: category}));
		this.$el.trigger("chosen:updated");
		$('.chosen-single span').text(data.text);
	},
	disableSelect: function(data){
		if(data.body.numFound == 0){
			$('.chosen-single b').hide();
			$('.chosen-drop').hide();
			$('.chosen-single').addClass('_hide');
		}else{
			$('.chosen-single b').show();
			$('.chosen-drop').show();
			$('.chosen-single').removeClass('_hide');
		}
	}
});

module.exports = Marionette.Object.extend({
	createView: function(region, tabName){
		var me = this;
		this.categoriesListView = new Views.Categories({tabName: tabName});
		this.listenTo(this.categoriesListView, "user:select:category", function(catId, text){
				me.trigger("category:changed", catId, text);
				$.ajax({
					type: "GET",
					url: "/api/music/category_list?cat_id="+catId+"",
				}).then(function(resp){
					var data = {
						catId: catId,
						text: text
					};
					me.categoriesListView.changeTemplate(resp.body.category, data);
					me.trigger("add:crumb", data);
					me.categoriesListView.disableSelect(resp);
				});
		});
		region.show(this.categoriesListView);
	},
	show: function(region, tabName){
		var me = this;
		me.createView(region, tabName);
	}
});