var DashboardSetPassView = require("./view/DashboardSetPassView");

module.exports = Marionette.Object.extend({
	validation: {},
	initialize: function(){
		this.setHandlers();
		this.redirectToDashboard = function(){
			Radio.channel("login").trigger("user:success:login");
		};
	},
	show: function(){
		this.dashboardSetPass = new DashboardSetPassView();
		var	mainRegion =  Radio.channel("layout").request("get:main:region"),
			me = this;
		this.listenTo(this.dashboardSetPass, "set:pass", function(password, repassword){
			var inputPass = $('.js-dashboard-pass-recovery .b-input-login'),
				inputPassVal = inputPass.val();
			if(!inputPassVal){
				this.dashboardSetPass.passError(inputPass);
				return false;
			}else{
				$.ajax({
					type: "POST",
					url: "/api/account/set_password",
					data: {password: password, repassword: repassword},
					dataType: "json"
				}).then(me.onSuccessHandler.bind(me), me.onFailHandler.bind(me))
			}
		});
		mainRegion.show(this.dashboardSetPass);
		Backbone.history.navigate("?page=dashboardsetpassword");
	},
	onSuccessHandler: function(){

	},
	onFailHandler: function(resp, stringResult, xhr){
		
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:dashboard:set:password", function(){
			me.show();
		});
	}
});
