var Profilelayout = require("./view/ProfileLayout");
var AboutProfileForm = require("./view/AboutForm");
var ContactProfileForm = require("./view/ContactForm");
var PaymentBlock  = require("./view/paymentAccountBlock/Controller");
var AdditionalSettingLayout = require("./view/ProfileAdditionalSettingsLayout");
var ProfileSecurityView = require("./view/ProfileSecurity");
var UniteDashboardView = require("./view/ProfileUniteDashboard");
var PasswordAccessView = require("./view/ProfilePasswordAccess");
var Validator = require("./view/Validator");
module.exports = Marionette.Object.extend({
	initialize: function(){

		this.setHandlers();
	},
	show: function(){
		var profile = new Profilelayout();
		var paymentAccountBlock = new PaymentBlock();
		var promiseArray = [];
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		promiseArray.push(Radio.channel("global").request("profile:entity"));
		var me = this;
		promiseArray.push($.ajax({
			url: "/api/list/countries",
			method: "GET"
		}));
		$.when.apply($, promiseArray).done(function(paramsModel, countries){
			var validator = new Validator({model: paramsModel});
			me.listenTo(profile,"additional:show", me.notify.bind(me, paramsModel, validator));
			var aboutForm = new AboutProfileForm({model: paramsModel});
			var contactProfile = new ContactProfileForm({model: paramsModel, countries: countries[0].body});
			validator.addViews({contact: contactProfile});
			validator.addViews({about: aboutForm});
			me.listenTo(aboutForm, "date:birthday:change", function(data){
				paramsModel.set("date_of_born", data);
			});
			me.listenTo(aboutForm, "first:name:change", function(data){
				paramsModel.set("given_name", data);
			});
			me.listenTo(aboutForm, "last:name:change", function(data){
				paramsModel.set("family_name", data);
			});
			me.listenTo(aboutForm, "click:save:btn", function(){
				paramsModel.saveAboutContact();
			});

			me.listenTo(contactProfile, "user:change:country", function(data){
				paramsModel.set("country", data);
			});
			me.listenTo(contactProfile, "user:change:address", function(data){
				paramsModel.set("address", data);
			});
			me.listenTo(contactProfile, "user:change:town", function(data){
				paramsModel.set("town", data);
			});
			me.listenTo(contactProfile, "user:change:email:for", function(data){
				paramsModel.set("email_for", data);
			});
			me.listenTo(contactProfile, "user:change:email", function(data){
				paramsModel.set("email", data);
			});

			me.listenTo(contactProfile, "click:save:btn", function(){
				paramsModel.saveContacts();
			});

			me.listenTo(paramsModel, "webmoney:save:succes", function(){
				profile.saveContacts();
			});
			me.listenTo(paramsModel, "bank:save:success", function(){
				profile.saveContacts();
			});

			me.listenTo(profile, "show", function(){
				profile.aboutRegion.show(aboutForm);
				profile.contactRegion.show(contactProfile);
				paymentAccountBlock.show(profile.paymentRegion);
			});
			mainRegion.show(profile);
			Backbone.history.navigate("#?page=profile");
		});
	},
	notify: function (paramsModel, validator) {
		var me = this;
		var modalRegion =  Radio.channel("layout").request("get:modal:region");
		var fetchQuestion = Radio.channel("global").request("account:secret:questions:entities");
		$.when(fetchQuestion).then(function(questions){
			var layout = new AdditionalSettingLayout({model: paramsModel});
			var profileSecurity = new ProfileSecurityView({model: paramsModel, questions: questions.toJSON()});
			var uniteDashboard = new UniteDashboardView({model: paramsModel});
			var passwordAccess = new PasswordAccessView({model: paramsModel, questions: questions.toJSON()});
			validator.addViews({security: profileSecurity});
			validator.addViews({uniteDashboard: uniteDashboard});
			validator.addViews({passwordaccess: passwordAccess});
			me.listenTo(paramsModel,"profile:password:set:success", function(){
				layout.trigger("dialog:close");
				Radio.channel("login").trigger("user:change:password");
				Backbone.history.navigate("#?page=logout", {trigger: true});
			});
			me.listenTo(profileSecurity, "send:password", function(data){
				paramsModel.savePassword(data);
			});

			me.listenTo(passwordAccess, "send:question", function(data){
				paramsModel.setPasswordAccess(data);
			});

			me.listenTo(passwordAccess, "disable:access", function(){
				paramsModel.passwordAccessOff();
			});

			me.listenTo(uniteDashboard, "unite:dashboard", function(){
				paramsModel.uniteDashboard();
			});

			me.listenTo(paramsModel, "unite:dashboard:success", function(){
				layout.trigger("dialog:close");
				Backbone.history.navigate("#?page=logout&redirecturi=#?", {trigger: true});
			});

			me.listenTo(paramsModel,"profile:password:access:set:success", function(data){
				passwordAccess.showUniqueForm(data);
			});

			me.listenTo(passwordAccess,"user:go:to:login:dashboard", function(data){
				layout.trigger("dialog:close");
				Backbone.history.navigate("#?page=logout&redirecturi=dashboardloginpassword", {trigger: true});
			});

			me.listenTo(layout, "show", function(){
				layout.profileSecurityRegion.show(profileSecurity);
				layout.uniteDashboardRegion.show(uniteDashboard);
				layout.passwordAccessRegion.show(passwordAccess);
			});
			modalRegion.show(layout);
		});

	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:profile", function() {
			me.show();
		});
	}

});
