var HeaderTemplate = require("./templates/DashboardLoginShow.hbs");
var GAuthRenderTemplate = require("./templates/DashboardLoginGAuhtError.hbs");
var UnknownErrorTemplate = require("./templates/DashboardLoginUnknownError.hbs");
var LoginUserBlockTemplate = require("./templates/DashboardLoginUserBlock.hbs");
var LoginUserIsNowExistTemplate = require("./templates/DashboardLoginUserIsNotExist.hbs");
var ErrorCodeTemplate = require("./templates/DashboardLoginErrorCode.hbs");
var ErrorData = require("./templates/DashboardLoginIncorrecData.hbs");
var LimitData = require("./templates/DashboardLoginLimitData.hbs");
var IncorrectPass = require("./templates/DashboardLoginIncorrectPass.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		sendBtn: ".js-dashboard-login-submit",
		errorBlock: ".js-login-error",
		recoveryErrorBlock: ".js-recovery-error",
		passErrorBlock: ".js-pass-error",
		recoveryBtn: ".b-login-link",
		sendBtn: ".js-dashboard-recovery-submit",
		setPassBtn: ".js-set-pass-submit",
		closeRecoveryBtn: ".b-close-btn"
	},
	events: {
		"click @ui.recoveryBtn": "showRecoveryForm",
		"click @ui.sendBtn": "sendData",
		"submit .js-login-block": "sendData",
		"click @ui.sendBtn": "sendQuest",
		"submit .js-dashboard-recovery-submit": "sendQuest",
		"click @ui.closeRecoveryBtn": "closeRecoveryForm",
		"click @ui.setPassBtn": "setRecoveryPass",
		"submit .js-set-pass-submit": "setRecoveryPass"
	},
	className: "wrapper_n",
	template: HeaderTemplate,
	sendData: function (e) {
		e.preventDefault();
		var pswd = $("input[name='dashboard-login-pass']").val();
		this.trigger("send:passw", pswd);
	},
	sendQuest: function (e) {
		e.preventDefault();
		var question = $(".recovery-question").val();
		var answer = $(".recovery-answer").val();
		var data = {
				question: question,
				answer: answer
			};
		this.trigger("check:quest", data);
	},
	setRecoveryPass: function (e) {
		e.preventDefault();
		var newPass = $(".js-new-pass").val();
		var confirmNewPass = $(".js-confirm-new-pass").val();
		var data = {
				newPass: newPass,
				confirmNewPass: confirmNewPass
			};
		this.trigger("set:pass", data);
	},
	onRender: function () {
		var me = this;
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				me.hideValidationError(view, attr);
			},
			invalid: function(view, attr, error) {
				me.showValidationError(view, attr);
			}
		});
	},
	showValidationError: function(view, attr){
		this.$el.find('.js-question-block').addClass('login-error');
		var $el = view.$(".recovery-"+attr);
		$el.addClass('b-input-error');
		if(attr == "password" || attr == "repassword"){
			this.ui.passErrorBlock.html(IncorrectPass());
			this.ui.passErrorBlock.fadeIn(300);
			this.$el.find('.js-password-block').addClass('login-error');
			this.$el.find('.recovery-password').val('');
			this.$el.find('.recovery-repassword').val('');
		}else{
			this.ui.recoveryErrorBlock.html(ErrorData());
			this.$el.find('.js-recovery-error').fadeIn(300);
			this.$el.find('.recovery-input').val('');
			this.$el.find('.recovery-question').addClass('b-input-error');
		}
	},
	hideValidationError: function(view, attr, error){
		this.$el.find('.js-question-block').removeClass('login-error');
		var $el = view.$(".recovery-"+attr);
		$el.removeClass('b-input-error');
		this.$el.find('.js-recovery-error').fadeOut(300);
	},
	templateHelpers: function(){
		var me = this;
		return {
			questions: me.options["questions"]
		}
	},
	showPasswordError: function () {
		this.ui.errorBlock.html(ErrorCodeTemplate())
	},
	showGAthError: function () {
		this.ui.errorBlock.html(GAuthRenderTemplate())
	},
	showUserBlockError: function () {
		this.ui.errorBlock.html(LoginUserBlockTemplate())
	},
	showUserIsNotExistError: function () {
		this.ui.errorBlock.html(LoginUserIsNowExistTemplate())
	},
	showUnknownError: function () {
		this.ui.errorBlock.html(UnknownErrorTemplate())
	},
	incorrectQuestOrPass: function(){
		this.ui.recoveryErrorBlock.html(ErrorData())
	},
	limitQuestOrPass: function(){
		this.ui.recoveryErrorBlock.html(LimitData())
	},
	passDontMatch: function(){
		this.ui.passErrorBlock.html("These passwords don't match. Try again")
	},
	invalidPass: function(){
		this.ui.passErrorBlock.html(IncorrectPass())
	},
	loginError: function(){
		this.$el.find('.js-login-block').addClass('login-error');
		var login = $('.js-input-login');
		var loginInput = login.val();
		if(!loginInput){
			login.addClass('error');
			this.$el.find('.js-login-error').slideDown(300);
			this.$el.find('.js-login-error').html('The password that you entered is not correct. Try again.');
		}else{
			this.$el.find('.js-login-error').slideDown(300);
			login.val('');
		}
	},
	loginErrorHide: function(){
		this.$el.find('.js-login-block').removeClass('login-error');
		this.$el.find('.js-login-error').slideUp(300);
	},
	showRecoveryForm: function(){
		var blockHeight = this.$el.find('.js-question-block').outerHeight();
		this.$el.find('.b-hide-block').animate({'height': blockHeight+115+'px'},300);
		this.$el.find('.js-question-block').css({'top': 0, 'bottom':'inherit'});
		this.$el.find('.js-dashboard-login-submit').attr('disabled',true);
		this.loginErrorHide();

		var passBlockHeight = this.$el.find('.js-password-block').outerHeight();
		this.$el.find('.js-password-block').css('top',-passBlockHeight);
	},
	recoveryError: function(){
		this.$el.find('.js-question-block').addClass('login-error');
		this.$el.find('.js-recovery-error').fadeIn(300);
		this.$el.find('.recovery-answer').val('');
	},
	recoveryErrorHide: function(){
		this.$el.find('.js-question-block').removeClass('login-error');
		this.$el.find('.js-recovery-error').fadeOut(300);

		var passBlockHeight = this.$el.find('.js-password-block').outerHeight();
		var blockHeight = this.$el.find('.js-question-block').outerHeight();
		this.$el.find('.b-hide-block').animate({'height': passBlockHeight+120+'px'},300);
		this.$el.find('.js-password-block').animate({'top': 0},300);
		this.$el.find('.js-question-block').animate({'top': -blockHeight},300);
	},
	passError: function(text){
		this.$el.find('.js-password-block').addClass('login-error');
		this.$el.find('.js-pass-error').fadeIn(300);
		this.$el.find('.recovery-password').val('');
		this.$el.find('.recovery-repassword').val('');
	},
	passErrorHide: function(){
		this.$el.find('.js-password-block').removeClass('login-error');
		this.$el.find('.js-pass-error').fadeOut(300);
		this.$el.find('.b-hide-block').animate({'height': 0},300);
		this.$el.find('.js-dashboard-login-submit').attr('disabled',false);
	},
	closeRecoveryForm:function(){
		var blockHeight = this.$el.find('.js-question-block').outerHeight();
		this.$el.find('.js-question-block').animate({'top': -blockHeight},300);
		this.$el.find('.js-password-block').animate({'top': 0},300);
		this.$el.find('.b-hide-block').animate({'height': 0},300);
		this.$el.find('.js-dashboard-login-submit').attr('disabled',false);
		this.$el.find('.js-question-block').removeClass('login-error');
		this.$el.find('.js-recovery-error').fadeOut(300);
		this.$el.find('.b-input-login').removeClass('b-input-error');
	}
});