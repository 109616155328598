// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function";

  return "						<span class=\"b-page-text b-edit-birth-year\">"
    + ((stack1 = ((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>.\n						<span class=\"b-page-text b-edit-birth-month\">"
    + ((stack1 = ((helper = (helper = helpers.month || (depth0 != null ? depth0.month : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>.\n						<span class=\"b-page-text b-edit-birth-day\">"
    + ((stack1 = ((helper = (helper = helpers.day || (depth0 != null ? depth0.day : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"day","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "	<h2 class=\"b-page-title text-align-left\">About</h2>\n	<div class=\"b-edit__item b-edit-item_s b-item_no-margin\">\n		<label for=\"\" class=\"b-page-label b-tooltip b-tooltip-ex\" data-desc=\"Please enter English characters only. Do not use non-letter symbols. \nNumbers, sign dot ., sign hyphen - and sign underscore _ are acceptable.\">First Name</label>\n		<div class=\"b-edit-wrapper\">\n			<div class=\"b-edit-text-block\">\n				<span class=\"b-page-text b-profile-text b-edit_first-name\">"
    + alias4(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n			<div class=\"b-edit-input-block\">\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_s b-input_first-name\" value="
    + alias4(((helper = (helper = helpers.given_name || (depth0 != null ? depth0.given_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"given_name","hash":{},"data":data}) : helper)))
    + ">\n			</div>\n		</div>\n	</div>\n	<div class=\"b-edit__item b-edit-item_s\">\n		<label for=\"\" class=\"b-page-label\">Last Name</label>\n		<div class=\"b-edit-wrapper\">\n			<div class=\"b-edit-text-block\">\n				<span class=\"b-page-text b-profile-text b-edit_last-name\">"
    + alias4(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n			<div class=\"b-edit-input-block\">\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_s b-input_last-name\" value="
    + alias4(((helper = (helper = helpers.family_name || (depth0 != null ? depth0.family_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"family_name","hash":{},"data":data}) : helper)))
    + ">\n			</div>\n		</div>\n	</div>\n	<div class=\"b-edit__item b-edit-item_m\">\n		<label for=\"\" class=\"b-page-label\">Date of Birth</label>\n		<div class=\"b-edit-wrapper\">\n			<div class=\"b-edit-text-block\">\n				<div class=\"b-page-text b-profile-text b-edit_birth\">\n"
    + ((stack1 = (helpers.dateOfBorn || (depth0 && depth0.dateOfBorn) || alias2).call(alias1,(depth0 != null ? depth0.date_of_born : depth0),{"name":"dateOfBorn","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n			<div class=\"b-edit-input-block js-profile-date-born\">\n\n			</div>\n		</div>\n	</div>\n	<div class=\"b-edit-link-wrap b-link-wrap b-profile-edit-link\">\n		<button class=\"b-page-link b-page_edit-link\">\n			<span class=\"i-edit b-edit-ico\"></span>\n			<span class=\"i-edit b-edit-ico i-edit-invis\"></span>\n			<span>Edit</span>\n		</button>\n		<button class=\"b-page-link b-page_save-link b-link_hide\">\n			<span class=\"i-edit b-edit-ico\"></span>\n			<span class=\"i-edit b-edit-ico i-edit-invis\"></span>\n			<span>Save changes</span>\n		</button>\n		<button class=\"b-page-link b-page_successd-link _show\">\n			<span class=\"i-edit b-edit-ico\"></span>\n			<span>Saved</span>\n		</button>\n	</div>";
},"useData":true});
