var HeaderView = require("./view/Header");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	createView: function(header,resp){
		this.header = header;
		var headerRegion =  Radio.channel("layout").request("get:header:region");
		if(resp){
			header.set({messageCount: resp[0].body.result_count});
		}
		var session = Radio.channel("login").request("user:session:model");
		header.set(session.toJSON());
		this.headerView = new HeaderView({model: header});
		this.listenTo(this.headerView , "user:login",function(){
			Radio.channel('login').trigger('click:login');
		});
		this.listenTo(this.headerView , "user:logout",function(){
			Radio.channel('login').trigger('click:logout');
		});

		headerRegion.show(this.headerView);
	},
	show: function(){
		var options = {};
		options.isAuth = Radio.channel("login").request("user:is:auth");
		options.authType = Radio.channel("login").request("user:auth:type");
		var fetchHeader = Radio.channel("global").request("header:new:entity", options);
		if(options.isAuth == "unauthorized"){
			$.when(fetchHeader).done(this.createView.bind(this));
		}else{
			var fetchInboxCount = $.get("/api/inbox/notification_list?isread=false&only_count=true");
			var fetchArr = [fetchHeader, fetchInboxCount];
			$.when.apply($, fetchArr).done(this.createView.bind(this));
		}
	},
	onChangeAuth: function(value){
		var me = this;
		var authType = Radio.channel("login").request("user:auth:type");
		var isAuth = Radio.channel("login").request("user:is:auth");
		this.header.set({authType: authType, isAuth: isAuth});
		if(isAuth == 'unauthorized'){
			$.when(this.header.getUserInfo()).done(function(){
				me.header.trigger("change:auth:state");
			});
		}else{
			var fetchInboxCount = $.get("/api/inbox/notification_list?isread=false&only_count=true");
			$.when.apply($, [this.header.getUserInfo(), fetchInboxCount]).done(function(header, resp){
				header.set({messageCount: resp[0].body.result_count});
				me.header.trigger("change:auth:state");
			});
		}
	},
	showGoogleInfo: function(){
		var me = this;
		$.when(Radio.channel("global").request("header:get:google:info:entity")).then(function(ginfo){
			me.header.set(ginfo.attributes);
			me.headerView.showGoogleInfo();
		});
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("login").on("user:change:auth:state", function(value){
			me.onChangeAuth();
		});
		Radio.channel("login").on("user:need:guath:pswd", function(value){
			me.showGoogleInfo();
		});
	}
});