// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.channel_list : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {};

  return "                <option value=\""
    + container.escapeExpression(((helper = (helper = helpers.channel_id || (depth0 != null ? depth0.channel_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"channel_id","hash":{},"data":data}) : helper)))
    + "\"> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.channel_name : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.channel_name || (depth0 != null ? depth0.channel_name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"channel_name","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.channel_id || (depth0 != null ? depth0.channel_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"channel_id","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"b-cpm-select b-ib channel-sel-wrap\">\n    <label class=\"b-page-label\">Channel id - Channel name</label>\n    <br>\n    <select class=\"b-page-input b-page-select b-channel-select js-advance-report-channel-list\">\n"
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.channel : depth0)) != null ? stack1.channel_id : stack1),{"name":"select","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n</div>\n<div class=\"b-cpm-select b-ib\">\n    <label class=\"b-page-label\">Begin period</label>\n    <br>\n    <div class=\"dropdown dropdown-begin-period active\">\n        <button class=\"dropdown-btn dropdown-toggle js-advance-report-begin-period-btn\" type=\"button\">\n           <span class=\"js-payout-begin-period-advance-report\">"
    + alias3((helpers.getMonth || (depth0 && depth0.getMonth) || alias2).call(alias1,(depth0 != null ? depth0.last_month : depth0),{"name":"getMonth","hash":{},"data":data}))
    + "/"
    + alias3(((helper = (helper = helpers.last_year || (depth0 != null ? depth0.last_year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"last_year","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"b-drop-ico\"></span>\n        </button>\n        <ul class=\"dropdown-list dropdown-menu\">\n            <div class=\"dropdown-period begin-year\">\n                <div class=\"dropdown-item\">2015</div>\n                <div class=\"dropdown-item\">2016</div>\n		<div class=\"dropdown-item\">2017</div>\n		<div class=\"dropdown-item\">2018</div>\n		<div class=\"dropdown-item\">2019</div>\n                <div class=\"dropdown-item\">2020</div>\n		<div class=\"dropdown-item\">2021</div>\n                <div class=\"dropdown-item\">2022</div>\n                <div class=\"dropdown-item\">2023</div>\n                <div class=\"dropdown-item\">2024</div>\n            </div>\n            <div class=\"dropdown-period begin-month\">\n                <div  class=\"dropdown-item\">January</div>\n                <div  class=\"dropdown-item\">February</div>\n                <div  class=\"dropdown-item\">March</div>\n                <div  class=\"dropdown-item\">April</div>\n                <div  class=\"dropdown-item\">May</div>\n                <div  class=\"dropdown-item\">June</div>\n                <div  class=\"dropdown-item\">July</div>\n                <div  class=\"dropdown-item\">August</div>\n                <div  class=\"dropdown-item\">September</div>\n                <div  class=\"dropdown-item\">October</div>\n                <div  class=\"dropdown-item\">November</div>\n                <div  class=\"dropdown-item\">December</div>\n            </div>\n        </ul>\n    </div>\n</div>\n<div class=\"b-cpm-select b-ib\">\n    <label class=\"b-page-label\">End period</label>\n    <br>\n    <div class=\"dropdown dropdown-begin-period active\">\n        <button class=\"dropdown-btn dropdown-toggle js-advance-report-end-period-btn\" type=\"button\">\n            <span class=\"js-payout-last-period-advance-report\">"
    + alias3((helpers.getMonth || (depth0 && depth0.getMonth) || alias2).call(alias1,(depth0 != null ? depth0.last_month : depth0),{"name":"getMonth","hash":{},"data":data}))
    + "/"
    + alias3(((helper = (helper = helpers.last_year || (depth0 != null ? depth0.last_year : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"last_year","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"b-drop-ico\"></span>\n        </button>\n        <ul class=\"dropdown-list dropdown-menu\">\n            <div class=\"dropdown-period end-year\">\n                <div class=\"dropdown-item\">2015</div>\n                <div class=\"dropdown-item\">2016</div>\n		<div class=\"dropdown-item\">2017</div>\n		<div class=\"dropdown-item\">2018</div>\n		<div class=\"dropdown-item\">2019</div>\n		<div class=\"dropdown-item\">2020</div>\n		<div class=\"dropdown-item\">2021</div>\n                <div class=\"dropdown-item\">2022</div>\n                <div class=\"dropdown-item\">2023</div>\n                <div class=\"dropdown-item\">2024</div>\n            </div>\n            <div class=\"dropdown-period end-month\">\n                <div  class=\"dropdown-item\">January</div>\n                <div  class=\"dropdown-item\">February</div>\n                <div  class=\"dropdown-item\">March</div>\n                <div  class=\"dropdown-item\">April</div>\n                <div  class=\"dropdown-item\">May</div>\n                <div  class=\"dropdown-item\">June</div>\n                <div  class=\"dropdown-item\">July</div>\n                <div  class=\"dropdown-item\">August</div>\n                <div  class=\"dropdown-item\">September</div>\n                <div  class=\"dropdown-item\">October</div>\n                <div  class=\"dropdown-item\">November</div>\n                <div  class=\"dropdown-item\">December</div>\n            </div>\n        </ul>\n    </div>\n</div>\n<button type=\"button\" class=\"b-page-button b-button_yes b-ib js-advance-report-set-filter\">Search</button>\n<button type=\"button\" class=\"b-change-search search-cancel js-advance-report-cancel\">Cancel</button>\n";
},"useData":true});
