// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "ok";
},"3":function(container,depth0,helpers,partials,data) {
    return "			<span class=\"b-tooltip-qu b-tooltip\" data-desc='You should set the password and secret question in Profile security.'></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    	<span class=\"i-edit additional-success\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    	<span class=\"i-edit additional-success b-hide-item\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "Disable";
},"11":function(container,depth0,helpers,partials,data) {
    return "Enable";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "b-hide-form-btn";
},"17":function(container,depth0,helpers,partials,data) {
    return "				You may login to your personal dashboard via usemame and password. After enabling the \"Password access\" option, you shouldn't sign in your Google-account to enter your dashboard. You will need just to enter your usemame and your password.\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "				Would you like to enable the \"Password access\" option?\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "				Would you like to disable the \"Password access\" option and login to your dashboard only via sign in your Google-account?\n";
},"23":function(container,depth0,helpers,partials,data) {
    return " b-button_yes ";
},"25":function(container,depth0,helpers,partials,data) {
    return " b-disable-access-option-btn b-button_or b-show-form-btn";
},"27":function(container,depth0,helpers,partials,data) {
    return "b-pass-access-hide-2";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"b-settings-row__item clearfix b-access-row "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_password : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n	<div class=\"b-settings-title\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.has_password : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        Password access\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "	<div class=\"b-change-link-wrap b-link-wrap b-modal-link-wrap b-settings-check \">\n		<button type=\"button\" class=\"b-page-link b-change-link\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "</button>\n	</div>\n</div>\n\n<div class=\"b-error-text js-b-error-profile-password-access-text\">\n	<span></span>\n</div>\n<div class=\"b-page-form b-edit-form b-pass-access-form\">\n	<p class=\"f_regular text_white text-align-left\">You have chosen to login to your dashboard via username and password. You wіll need to use your Cnannel ID as username and Profie password. To enable the option please enter your security question and answer.</p>\n	<br>\n	<form class=\"js-profile-access-password-form\">\n		<div class=\"b-page-form-row \">\n			<div class=\"b-page-form-row__item\">\n				<label class=\"b-page-label\">Secret Question</label><br>\n				<select class=\"b-page-input b-page-select b-input_question\" name=\"question\">\n					<option value=\"\">Select question</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.questions : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n			</div>\n			<div class=\"b-page-form-row__item\">\n				<label class=\"b-page-label\">Your Answer</label><br>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_answer\" name=\"answer\" placeholder=\"\" value=\"\">\n			</div>\n		</div>\n		<br>\n		<p class=\"f_regular text_white text-align-left\">То make your access secure we inform you 5 unique codes. After you enter your username and password, you will be asked then to enter one of these codes. You will see the message: Please enter code No{}.</p>\n		<p class=\"f_regular text_white text-align-left\">If you are ready to enable the ”Password access”, please cick the ”Submit” tab and prepare to save your unique codes.</p>\n		<div class=\"b-page-form-buttons\">\n			<button type=\"submit\" class=\"b-page-button b-button_yes js-profile-password-access-set "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">Submit</button>\n			<button type=\"button\" class=\"b-page-button b-button_no\">Cancel</button>\n		</div>\n	</form>\n</div>\n\n<div class=\"b-page-form b-agree-form b-center-row\">\n	<div class=\"b-pass-access-active\">\n		<p class=\"f_regular text-align-left b-pass-access-desc\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</p>\n		<span class=\"f_18\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "		</span>\n		<div class=\"b-page-form-buttons\">\n			<button type=\"button\" class=\"b-page-button  b-buttons_s "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "\">Yes</button>\n			<button type=\"button\" class=\"b-page-button b-button_no b-buttons_s\">No</button>\n		</div>\n	</div>\n	<div class=\"b-pass-access-dis\">\n		<span class=\"f_18\">\n			Would you like to disable the \"Password access\" option and login to your dashboard only via sign in your Google-account?\n		</span>\n		<div class=\"b-page-form-buttons\">\n			<button type=\"submit\" class=\"b-page-button b-button_or b-disable-access-option-btn b-buttons_s\">Yes</button>\n			<button type=\"button\" class=\"b-page-button b-button_no b-buttons_s\">No</button>\n		</div>\n	</div>\n	<div class=\"b-pass-access-act\">\n		<p class=\"f_regular text-align-left b-pass-access-desc\">\n			You may login to your personal dashboard via usemame and password. After enabling the \"Password access\" option, you shouldn't sign in your Google-account to enter your dashboard. You will need just to enter your usemame and your password.\n		</p>\n		<span class=\"f_18\">\n			Would you like to enable the \"Password access\" option?\n		</span>\n		<div class=\"b-page-form-buttons\">\n			<button type=\"button\" class=\"b-page-button  b-buttons_s b-button_yes\">Yes</button>\n			<button type=\"button\" class=\"b-page-button b-button_no b-buttons_s\">No</button>\n		</div>\n	</div>\n</div>\n<div class=\"b-page-form b-center-row b-unique-form\">\n	<span class=\"b-pass-access-info f_18\">Save your unique codes:</span>\n	<div class=\"b-unique-codes\">\n		<ol class=\"b-unique-list\">\n			\n		</ol>\n	</div>\n	<br>\n	<p class=\"valid-codes\">Codes are valid only during 6 months. After 6 months please do not forget to enable the Password Access Option again and receive new codes.</p>\n	<div class=\"b-page-form-buttons\">\n		<button type=\"button\" class=\"b-page-button b-buttons_s b-pass-access-ok\">Ok</button>\n		<button type=\"button\" class=\"b-page-button b-buttons_s b-pass-access-hide "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.activated_pass_access : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">Ok</button>\n	</div>\n</div>";
},"useData":true});
