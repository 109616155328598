var Helpers = require("helpers/Helpers");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
		this.session = Radio.channel("global").request("new:login:entity");
		this.listenTo(this.session, "change:isAuth", this.onChangeAuth);
	},
	codeMap: {
		"-1": "unauthorized",
		"1": "authorized",
		"-3": "need:password",
		"-2": "need:code"
	},
	authTypeMap: {
		"2": "loginauth",
		"3": "gauth",
		"4": "parking"
	},
	popupCenter: function r(url, title, w, h) {
			// Fixes dual-screen position                         Most browsers      Firefox
			var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
			var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

			width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
			height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

			var left = ((width / 2) - (w / 2)) + dualScreenLeft;
			var top = ((height / 2) - (h / 2)) + dualScreenTop;
			var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + 170 + ', left=' + left);

			// Puts focus on the newWindow
			if (window.focus) {
				newWindow.focus();
			}
		return newWindow;
	},
	login: function(params){
		var me =  this;
		// Create IE + others compatible event handler
		var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
		var eventer = window[eventMethod];
		var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
		var removeMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
		var remover = window[removeMethod];
		var messageHandler = function(e) {
			remover(messageEvent, messageHandler, false);
			me.handleAuthResult.call(me, e.data, params);
			clearInterval(i);
			clearInterval(postMessInterval);
			win.close();
		};
		// Listen to message from child window
		eventer(messageEvent, messageHandler,false);


		var win = this.popupCenter(Helpers.getAuthUrl(), "Google auth", 650, 600);


		var postMessInterval = setInterval(function() {
			win.postMessage("ping_message", GlobalConfig.domain);
		},1000);

		var i = setInterval(function() {
			if (win.closed) {
				clearInterval(i);
				clearInterval(postMessInterval);
				remover(messageEvent, messageHandler, false);
				Radio.channel("login").trigger("user:close:google:window");
			}
		}, 100);

	},
	accountPing: function(){
		return $.get(GlobalConfig.urls.api_account_ping)
	},
	isUserLoggedIn: function(){
		var me = this;
		var successCallback = function(resp, stringResult, xhr){
			if(!_.isEmpty(resp.body)){
				me.session.set({isAuth: me.codeMap[String(resp.body.auth)]}, {silent: true});
				me.session.set({auth_type: me.authTypeMap[String(resp.body.auth_type)]});
				if(me.codeMap[String(resp.body.auth)] === "authorized"){
					me.session.set({month_of_payment: resp.body.month_of_payment});
				}
				me.session.set({codeNum: resp.body.code_num});
				me.session.set({sqPass: resp.body.sq_pass});
				me.session.set({uniteCabId: resp.body.unite_cab_id});
				if(resp.body.lang){
					me.session.set({lang: resp.body.lang});
				}else{
					$.post("/api/account/set_lang", {lang: i18n.lng().split("-")[0]});
					me.session.set({lang:  i18n.lng().split("-")[0]});
				}
			}
			Radio.channel("login").trigger("user:ping:then");
		};
		var failCallback = function(xhr, stringstatus, description){
			Radio.channel("login").trigger("user:ping:then");
		};
		var ajaxOptions = {
			success: successCallback.bind(me),
			fail:  failCallback.bind(me)
		};
		$.when(this.accountPing()).then(ajaxOptions.success, ajaxOptions.fail);
	},
	handleAccessSuccess: function(params, resp, stringResult, xhr){
		var me = this;
		var body = undefined;
		if(!_.isEmpty(resp.body)){
			body = resp.body;
			if(body.code === 2){
				Radio.channel("login").trigger("user:need:guath:pswd");
				$.ajax({
					type: "GET",
					url: "/api/account/get_gplus_info"
				}).then(function(resp2){
					me.session.set(resp2.body);
				});
			}else{
				Radio.channel("login").trigger("user:success:login");
			}
		}
	},
	handleAccessFail: function(xhr, stringResult, description){
		Backbone.history.navigate("#?page=autherrorhandler&code="+xhr.responseJSON.body.code+"&category=login", {trigger: true});
	},
	handleAuthResult: function(authResult, params){
		var me = this;
		$.get(GlobalConfig.urls.api_account_google+authResult).then(this.handleAccessSuccess.bind(me, params), this.handleAccessFail);
	},
	onChangeAuth: function(model, value){
		Radio.channel("login").trigger("user:change:auth:state", value);
	},
	isUserAuth: function(){
		return this.session.get("isAuth");
	},
	setUserAuth: function(value){
		this.session.unset("isAuth", {silent: true});
		this.session.set({isAuth: value});
	},
	getCodeNum: function(){
		return this.session.get("codeNum");
	},
	getLang: function(){
		return this.session.get("lang");
	},
	getSqPass: function(){
		return this.session.get("sqPass");
	},
	getUniteCabId: function(){
		return this.session.get("uniteCabId");
	},
	getSessionModel: function(){
		return this.session;
	},
	getUserType: function(){
		return this.session.get("auth_type");
	},
	getPaymentMonth: function(){
		return this.session.getMonth();
	},
	getPaymentYear: function(){
		return this.session.getYear();
	},
	logout: function(params){
		var me = this;
		var options = {
			success: function() {
				me.session.clear({silent: true});
				me.setUserAuth("unauthorized");
				if(params ? params.redirecturi ? 1 : 0 : 0){
					Backbone.history.navigate("#?page="+params.redirecturi, {trigger: true});
				}else{
					Radio.channel("login").trigger("user:logout:done");
				}
			},
			error: function(){
				Radio.channel("login").trigger("user:logout:fail");
			}
		};
		$.post(GlobalConfig.urls.api_account_logout).then(options.success, options.error);
	},
	getBaseUserInfo: function(fn){
		var me = this;
		me.accountPing().then(function(resp, stringResult, xhr){
			me.session.set({auth_type: me.authTypeMap[String(resp.body.auth_type)]});
			me.session.set({month_of_payment: resp.body.month_of_payment});
			me.session.set({uniteCabId: resp.body.unite_cab_id});
			me.setUserAuth("authorized");
			if(typeof fn  === "function"){
				fn();
			}else{
				Radio.channel("login").trigger("loaded:session:data");
			}
		}, function(){
			Radio.channel("login").trigger("loaded:session:data");
		});
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:login", function(params){
			me.login(params);
		});
		Radio.channel("application").on("application:state:logout", function(params){
			me.logout(params);
		});
		Radio.channel("login").reply("user:is:auth", function(){
			return me.isUserAuth();
		});
		Radio.channel("login").reply("user:auth:type", function(){
			return me.isUserAuth();
		});
		Radio.channel("login").reply("user:check:quest", function(){
			return me.getSqPass();
		});
		Radio.channel("login").reply("user:auth:code:num", function(){
			return me.getCodeNum();
		});
		Radio.channel("login").reply("user:unite:cabid", function(){
			return me.getUniteCabId();
		});
		Radio.channel("login").reply("user:lang", function(){
			return me.getLang();
		});
		Radio.channel("login").reply("user:month:of:payment", function(){
			return me.getPaymentMonth();
		});
		Radio.channel("login").reply("user:year:of:payment", function(){
			return me.getPaymentYear();
		});
		Radio.channel("login").reply("user:auth:type", function(){
			return me.getUserType();
		});
		Radio.channel("login").reply("user:session:model", function(){
			return me.getSessionModel();
		});
		Radio.channel("login").on("user:success:login", function(){
			me.getBaseUserInfo();
		});
		Radio.channel("login").on("user:answer:secret:question:success", function(){
			me.setUserAuth("need:password");
		});
		Radio.channel("login").on("user:change:password", function(){
			me.setUserAuth("need:password");
		});
		Radio.channel("login").on("is:user:logged", function(){
			me.isUserLoggedIn();
		});
	}



});