/**
 * Created by sergey on 02.12.2015.
 */
var MonthListTemplate = require("./templates/ToolbarMonth.hbs");
var Carousel = require("widgets/Carousel");
module.exports = Marionette.ItemView.extend({
	className: "b-slick-center b-slick-month clearfix js-payout-carousel-month",
	template: MonthListTemplate,
	initialize: function(){
		this.carousel = new Carousel()
	},
	onShow: function(e){
		var me = this;
		this.carousel.show(this.$el, {
			centerMode: true,
			centerPadding: '45px',
			slidesToShow: 5,
			focusOnSelect: true,
			initialSlide: me.options["month"],
			beforeChange: me.beforeChange,
			view: me
		});
	},
	onBeforeDestroy: function(){
		this.carousel.destroy();
	},
	beforeChange: function (event, slick, currentSlide, nextSlide) {
		this.trigger("user:change:payout:month", nextSlide);
	}
});