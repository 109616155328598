/**
 * Created by sergey on 26.11.2015.
 */
module.exports = function () {
	_.extend(Backbone.Model.prototype, Backbone.Validation.mixin);
	_.extend(Backbone.Validation.patterns, {
		password: /(?=.*[\W\d]{1,})(?=.*[A-Za-z]{1,})/
	});

	_.extend(Backbone.Validation.messages, {
		password: 'Password in wrong format. Min length 8 characters'
	});
};

