/**
 * Created by sergey on 19.11.2015.
 */
var ProfileModels = require("../models/Profile/Profile");
var PaymentAccount = require("../models/Profile/PaymentAccount");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getProfile: function(options){
		var options = options || {};
		var profile = new ProfileModels(options);
		var defer = $.Deferred();
		profile.fetch({
			success: function(){
				defer.resolve(profile);
			}
		});
		return defer.promise();
	},
	getPaymentAccount: function(options){
		var options = options || {};
		var paymentAccoutn = new PaymentAccount(options);
		var defer = $.Deferred();
		paymentAccoutn.fetch({
			success: function(){
				defer.resolve(paymentAccoutn);
			}
		});
		return defer.promise();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("profile:entity", function(options){
			return me.getProfile(options);
		});
		Radio.channel("global").reply("profile:payment:account:entity", function(options){
			return me.getPaymentAccount(options);
		});
	}
};