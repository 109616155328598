/**
 * Created by sergey on 09.12.2015.
 */
var Helpers = require("helpers/Helpers");
module.exports = Backbone.Model.extend({
	getMonth: function () {
		var date = this.get("month_of_payment");
		var month = "";
		if(!date){
			month = new Date().getMonth()+1;
		}else{
			month = Helpers.getMonthNumber(date);
		}
		return Number(month);
	},
	getYear: function () {
		var date = this.get("month_of_payment");
		var year = date.split("-")[0];
		return Number(year);
	}
});