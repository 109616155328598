// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"i-edit additional-success\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "Change";
},"5":function(container,depth0,helpers,partials,data) {
    return "Setup";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <p class=\"f_light text_white text-align-left\">Make your password at 8 characters in length. Password MUST include at least 1 alphabetic character and 1 numeric character. Use a combination of English letters and numbers (0–9).</p>\n    <br>\n        <div class=\"b-page-form-row\">\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">New Password</label><br>\n                <input type=\"password\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"password\" placeholder=\"\">\n            </div>\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Confirm Password</label><br>\n                <input type=\"password\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"repassword\" placeholder=\"\">\n            </div>\n        </div>\n        <div class=\"b-page-form-row \">\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Sequrity Question</label><br>\n                <select class=\"b-page-input b-page-select b-input_question\" name=\"question\">\n                    <option value=\"\">Select question</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.questions : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\n            </div>\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Your Answer</label><br>\n                <input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_answer\" name=\"answer\" placeholder=\"\" value=\"\">\n            </div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <p class=\"f_light text_white text-align-left\">Make your password at 8 characters in length. Password MUST include at least 1 alphabetic character and 1 numeric character. Use a combination of English letters and numbers (0–9).</p>\n        <br>\n        <div class=\"b-page-form-row \">\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Password</label><br>\n                <input type=\"password\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"password\" placeholder=\"\">\n            </div>\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Confirm Password</label><br>\n                <input type=\"password\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"repassword\" placeholder=\"\">\n            </div>\n        </div>\n        <div class=\"b-page-form-row\">\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Sequrity Question</label><br>\n                <select class=\"b-page-input b-page-select b-input_question\" name=\"question\">\n                    <option value=\"\">Select question</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.questions : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\n            </div>\n            <div class=\"b-page-form-row__item\">\n                <label class=\"b-page-label\">Your Answer</label><br>\n                <input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_answer\" name=\"answer\" placeholder=\"\" value=\"\">\n            </div>\n        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "change";
},"14":function(container,depth0,helpers,partials,data) {
    return "set";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"b-settings-row__item clearfix ok\">\n    <div class=\"b-settings-title\">\n        Profile security\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_password : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"b-change-link-wrap b-link-wrap b-modal-link-wrap b-settings-check\">\n        <button type=\"button\" class=\"b-page-link b-change-link\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_password : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</button>\n    </div>\n    \n</div>\n<div class=\"b-error-text js-b-error-profile-security-text\">\n    <span></span>\n</div>\n<form class=\"js-profile-additional-settings-password-set\">\n    <div class=\"b-page-form b-edit-form b-ps-form\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_password : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"b-page-form-buttons\">\n        <button type=\"submit\" class=\"b-page-button b-button_yes js-set-profile-password\">Submit</button>\n        <button type=\"button\" class=\"b-page-button b-button_no\">Cancel</button>\n    </div>\n</div>\n</form>\n<div class=\"b-page-form b-agree-form b-center-row\">\n    <span class=\"f_18\">Are you sure you want to "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_password : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + " password?</span>\n    <div class=\"b-page-form-buttons\">\n        <button type=\"button\" class=\"b-page-button b-button_yes b-buttons_s\">Yes</button>\n        <button type=\"button\" class=\"b-page-button b-button_no b-buttons_s\">No</button>\n    </div>\n</div>";
},"useData":true});
