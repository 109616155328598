// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <p><span class=\"b-short-body\">"
    + alias3((helpers.getDashboardNews || (depth0 && depth0.getDashboardNews) || alias2).call(alias1,(depth0 != null ? depth0.short_body : depth0),{"name":"getDashboardNews","hash":{},"data":data}))
    + "</span> <span class=\"b-dots "
    + alias3((helpers.shortNews || (depth0 && depth0.shortNews) || alias2).call(alias1,(depth0 != null ? depth0.short_body : depth0),{"name":"shortNews","hash":{},"data":data}))
    + "\">...</span></p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression((helpers.getDashboardNews || (depth0 && depth0.getDashboardNews) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.description : depth0),{"name":"getDashboardNews","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "           <p>"
    + container.escapeExpression((helpers.getDashboardNews || (depth0 && depth0.getDashboardNews) || helpers.helperMissing).call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.short_body : depth0),{"name":"getDashboardNews","hash":{},"data":data}))
    + "</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"b-content-title-new\"><span>"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"New","key":"qg_partner.dashboard.news_list_new"},"data":data}))
    + "</span></div>\n<div class=\"b-content-news-text\">\n    <span class=\"b-content-news-title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n    <div class=\"b-visible-text\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.short_body : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        <a href=\"#?page=inbox&subpage=news&news_id="
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"b-content-news-learn-more\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"learn more","key":"qg_partner.dashboard.news_list_learn_more"},"data":data}))
    + "</a>\n    </div>\n    <div class=\"b-hidden-text\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.short_body : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "	    <a href=\"#?page=inbox&subpage=news&news_id="
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"b-content-news-learn-more\">"
    + alias3((helpers.tr || (depth0 && depth0.tr) || alias2).call(alias1,depth0,{"name":"tr","hash":{"defaultValue":"learn more","key":"qg_partner.dashboard.news_list_learn_more"},"data":data}))
    + "</a>\n    </div>\n</div>\n<div class=\"b-content-news-open b-ib "
    + alias3((helpers.shortNews || (depth0 && depth0.shortNews) || alias2).call(alias1,(depth0 != null ? depth0.short_body : depth0),{"name":"shortNews","hash":{},"data":data}))
    + "\">\n	<span class=\"i-edit\"></span>\n	<span class=\"i-edit i-edit-invis\"></span>\n</div>";
},"useData":true});
