/**
 * Created by gila on 25.11.2015.
 */

PayoutModel =  require("../models/Payout/Payout");
module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getPayout: function(options){
		var options = options || {};
		var payout = new PayoutModel(options);
		return payout.getData();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("payout:entity", function(options){
			return me.getPayout(options);
		});
	}
};