// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<h1 class=\"b-page-title b-pay-title b-title_white js-payout-month\">"
    + alias4(((helper = (helper = helpers.month || (depth0 != null ? depth0.month : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month","hash":{},"data":data}) : helper)))
    + " payout</h1>\n<div class=\"b-sum-block clearfix\">\n    <div class=\"b-sum-block__item\">\n        <span class=\"b-sum-count b-sum-count_left\">$"
    + alias4(((helper = (helper = helpers.init_balance || (depth0 != null ? depth0.init_balance : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"init_balance","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"b-sum-title b-title_left\">Initial <br> balance</span>\n    </div>\n    <div class=\"b-sum-block__item\">\n        <span class=\"b-sum-count b-sum-count_left b-sum-earnings\" data-desc=\""
    + alias4((helpers.sum || (depth0 && depth0.sum) || alias2).call(alias1,(depth0 != null ? depth0.sum_earnings : depth0),(depth0 != null ? depth0.referral_earnings : depth0),{"name":"sum","hash":{},"data":data}))
    + "\">$"
    + alias4((helpers.sum || (depth0 && depth0.sum) || alias2).call(alias1,(depth0 != null ? depth0.sum_earnings : depth0),(depth0 != null ? depth0.referral_earnings : depth0),{"name":"sum","hash":{},"data":data}))
    + "</span>\n        <span class=\"b-sum-title b-title_left\">Sum <br> earnings</span>\n    </div>\n    <div class=\"b-sum-block__item\">\n        <span class=\"b-sum-count b-sum-count_left b-sum-payment\">$"
    + alias4(((helper = (helper = helpers.sum_payments || (depth0 != null ? depth0.sum_payments : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sum_payments","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"b-sum-title b-title_left\">Sum <br> payment</span>\n    </div>\n    <div class=\"b-sum-block__item\">\n        <span class=\"b-sum-count b-sum-count_left\">$"
    + alias4(((helper = (helper = helpers.end_balance || (depth0 != null ? depth0.end_balance : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"end_balance","hash":{},"data":data}) : helper)))
    + "</span>\n        <span class=\"b-sum-title b-title_left\">End <br> balance</span>\n    </div>\n</div>\n<div class=\"b-payout-page-tabs\">\n    <div class=\"b-payout-tabs-wrap\">\n        <ul class=\"b-payout-tabs-list\">\n            <li class=\"b-payout-tabs-item b-tabs_hover f_12 js-tab-channel-payout  b-tabs-active\">\n                <span class=\"b-payout-tabs-title\">Channels</span>\n                <div class=\"b-tabs_hover__item\">\n                    <span class=\"b-sum-desc b-ib f_16 f_bold js-payout-channel-earnings\">$"
    + alias4(((helper = (helper = helpers.channel_earnings || (depth0 != null ? depth0.channel_earnings : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel_earnings","hash":{},"data":data}) : helper)))
    + "</span>\n                    <span class=\"b-sum-desc b-ib f_12 f_light\"> - Sum earnings</span>\n                </div>\n            </li>\n            <li class=\"b-payout-tabs-item b-tabs_hover f_12 js-tab-referrals-payout\">\n                <span class=\"b-payout-tabs-title\">Referrals</span>\n                <div class=\"b-tabs_hover__item\">\n                    <span class=\"b-sum-desc b-ib f_16 f_bold js-payout-referral_earnings\">$"
    + alias4(((helper = (helper = helpers.referral_earnings || (depth0 != null ? depth0.referral_earnings : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"referral_earnings","hash":{},"data":data}) : helper)))
    + "</span>\n                    <span class=\"b-sum-desc b-ib f_12 f_light\"> - Sum earnings</span>\n                </div>\n            </li>\n        </ul>\n    </div>\n</div>";
},"useData":true});
