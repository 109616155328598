// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.is_pdf : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"b-support-list-item\">\n                <div class=\"b-support-item-header  b-header-instruct\">\n                    <div class=\"b-arrow-wrap b-ib\">\n                        <span class=\"i-edit i-edit-arrow\"></span>\n                        <span class=\"i-edit i-edit-arrow i-edit-invis\"></span>\n                    </div>\n                    <span class=\"i-edit i-edit-support i-edit-instruct\"></span>\n                    <div class=\"b-support-title b-ib\">\n                        "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                    </div>\n                </div>\n                <div class=\"b-support-item-body\">\n                    <div class=\"b-support-pdf-wrap\">\n                        <div class=\"b-support-item-pdf\" id=\"search_pdf_"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n                        </div>\n                        <div class=\"b-support-info b-support-pdf\">\n                            <span class=\"b-suppore-more f_medium\">More information:</span><br>\n                            <a href=\"#?page=inbox&subpage=tickets\" class=\"b-page-link\">\n                                <span class=\"i-edit i-edit-support i-edit-tickets\"></span>\n                                <span class=\"i-edit i-edit-support i-edit-tickets i-edit-invis\"></span>\n                                <span>Tickets</span>\n                            </a>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"b-support-list-item\">\n                <div class=\"b-support-item-header  b-header-question\">\n                    <div class=\"b-arrow-wrap b-ib\">\n                        <span class=\"i-edit i-edit-arrow\"></span>\n                        <span class=\"i-edit i-edit-arrow i-edit-invis\"></span>\n                    </div>\n                   <span class=\"i-edit i-edit-support i-edit-question\"></span>\n                    <div class=\"b-support-title b-ib\">\n                        "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                    </div>\n                </div>\n                <div class=\"b-support-item-body\">\n                    <div class=\"b-support-item-wrap\">\n                        <div class=\"b-support-item-text\" id=\"search_text_"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n                        </div>\n                        <div class=\"b-support-info\">\n                            <span class=\"b-suppore-more f_medium\">More information:</span><br>\n                            <a href=\"#?page=inbox&subpage=tickets\" class=\"b-page-link\">\n                                <span class=\"i-edit i-edit-support i-edit-tickets\"></span>\n                                <span class=\"i-edit i-edit-support i-edit-tickets i-edit-invis\"></span>\n                                <span>Tickets</span>\n                            </a>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"b-support-list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.faq_list : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});
