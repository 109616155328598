/**
 * Created by sergey on 25.11.2015.
 */
var ProfileTemplateWebMoneyForm = require("./templates/ProfileWebMoneyForm.hbs");
module.exports = Marionette.ItemView.extend({
	initialize: function(){
		this.listenTo(this.model, "webmoney:save:fail", this.showValidationError.bind(this, this, "payment_account"));
		this.listenTo(this.model, "webmoney:save:success", this.hideValidationError.bind(this, this, "payment_account"));
	},
	ui:{
		saveBtn: ".js-profile-webmoney-save",
		cancelBtn: ".js-profile-webmoney-cancel",
		valInput: "input[name='payment_account']"
	},
	events:{
		"click @ui.saveBtn": "sendData",
		"click @ui.cancelBtn": "resetForm",
		"input input[name='payment_account']": "removeValidationClass",
		"submit form": "sendData"
	},
	templateHelpers: function() {
		var me = this;
		return{
			defaultPaymentType: me.model.defaultValue.payment_type
		}
	},
	className: "b-payment-type-form b-payment-wm-form",
	template: ProfileTemplateWebMoneyForm,
	onShow: function(){
		var me = this;
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				me.hideValidationError(view, attr);
			},
			invalid: function(view, attr, error) {
				me.showValidationError(view, attr);
			}
		});
	},
	showValidationError: function(view, attr){
		var $el = view.$('[name=' + attr + ']');
		$el.addClass('b-input-error');
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$('[name=' + attr + ']');
		$el.removeClass('b-input-error');
	},
	sendData: function(e){
		e.preventDefault();
		var data = Backbone.Syphon.serialize(this);
		this.trigger("webmoney:save:data", data);
	},
	removeValidationClass: function(e){
		if($(e.target).val() === this.model.get("payment_account")){
			this.ui.saveBtn.prop("disabled", true);
		}else{
			this.ui.saveBtn.prop("disabled", false);
		}
		$(e.target).removeClass('b-input-error');
	},
	resetForm: function(){
		this.trigger("user:click:cancel");
	}

});