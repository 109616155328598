var SigninView = require("./views/Signin");
var Helpers = require("helpers/Helpers");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	show: function(){
		var me = this;
		var signinView = new SigninView();
		this.listenTo(signinView, "user:click:yes:btn", function(){
			Backbone.history.navigate("#?page=login&redirect_uri=addchannel", {trigger: true});
		});
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		mainRegion.show(signinView);
		Backbone.history.navigate("#?page=joinsignin");
	},
	popupCenter: function r(url, title, w, h) {
		// Fixes dual-screen position                         Most browsers      Firefox
		var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
		var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

		width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		var left = ((width / 2) - (w / 2)) + dualScreenLeft;
		var top = ((height / 2) - (h / 2)) + dualScreenTop;
		var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + 170 + ', left=' + left);

		// Puts focus on the newWindow
		if (window.focus) {
			newWindow.focus();
		}
		return newWindow;
	},
	login: function(){
		var me =  this;
		// Create IE + others compatible event handler
		var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
		var eventer = window[eventMethod];
		var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
		var removeMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
		var remover = window[removeMethod];
		var messageHandler = function(e) {
			remover(messageEvent, messageHandler, false);
			me.handleAuthResult.call(me, e.data);
			clearInterval(i);
			clearInterval(postMessInterval);
			win.close();
		};
		// Listen to message from child window
		eventer(messageEvent, messageHandler,false);


		var win = this.popupCenter(Helpers.getJoinAuthUrl(), "Google auth", 650, 600);


		var postMessInterval = setInterval(function() {
			win.postMessage("ping_message", GlobalConfig.domain);
		},1000);

		var i = setInterval(function() {
			if (win.closed) {
				clearInterval(i);
				clearInterval(postMessInterval);
				remover(messageEvent, messageHandler, false);
			}
		}, 100);

	},
	handleAccessSuccess: function(resp, stringResult, xhr){
		var body = undefined;
		if(!_.isEmpty(resp.body)){
			body = resp.body;
		}
	},
	handleAccessFail: function(xhr, stringResult, description){

		//Backbone.history.navigate("#?page=autherrorhandler&code="+xhr.responseJSON.body.code+"&category=join", {trigger: true});
	},
	handleAuthResult: function(authResult){
		var me = this;
		$.get("/api/join/check_reg?token="+authResult).then(this.handleAccessSuccess.bind(me), this.handleAccessFail);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:joinsignin", function() {
			me.show();
		});
	}

});
