/**
 * Created by sergey on 16.12.2015.
 */
/**
 * Created by sergey on 14.12.2015.
 */
module.exports = Backbone.Model.extend({
	defaults: function(){
		return {
			offset: 0,
			limit: 20
		}
	},
	url: function(){
		var lang = Radio.channel("login").request("user:lang");
		return "/api/inbox/ticket_list?offset=" + this.get("offset") +"&limit="+ this.get("limit");
	},
	getSearchList: function(){
		var result = _.map(this.get("tickets"), function(r){return r.title}).filter(function(x) {
			return typeof x !== 'undefined';
		});
		return result;
	},
	
	getTicket: function(cb, id){
		var me = this,
			item,
			ticket = _.findWhere(me.get("tickets"), {ticket_id: id});
		if(ticket ? ticket.body ? 1: 0 : 0){
			cb(ticket.articles);
		}else{
			$.get("/api/inbox/ticket?ticket_id="+id).then(function(resp){
				var result = [];
				result.push(id);
				cb(resp.body.articles);
				item = _.findWhere(me.get("tickets"), {ticket_id: resp.body.ticket_id});
				item.articles = resp.body.articles;
				if(resp.body.read == 0){
					me._readeUnReadMessages({ticket_id: result, read: 1})
				}
			});
		}
	},
	clearFilter: function(){
		this.set({tickets: this.defaultRows}, {silent: true});
		this.trigger("refresh:filter:rows");
	},
	unSetFilterValue: function(){
		this.unset("filter", {silent: true});
	},
	rowsLength: function(){
		return this.get("tickets").length;
	},
	setStarred: function(data){
		var me = this,
			options = {
				success: function(){
					me.trigger("refresh:filter:rows");
				}
			};
		$.post("/api/inbox/change_ticket_status", data).done(function(){
			me.fetch(options);
		});

	},
	getRecentTickets: function(){
		if(this.get("tickets").length > 0){
			return this.get("tickets").slice(0, 5);
		}else{
			return [];
		}

	},
	getHistoryTickets: function(){
		if(this.get("tickets").length > 0){
			return this.get("tickets").slice(5, this.get("tickets").length);
		}else{
			return [];
		}

	},
	getMoreTickets: function () {
		var me = this;
		this.set({"offset": me.get("offset")+20});
		$.ajax({
			method: "GET",
			url: "/api/inbox/ticket_list?offset=" + me.get("offset") +"&limit="+ me.get("limit")
		}).then(function (resp) {
			var newArr = resp.body.answer;
			var oldArr = me.get("tickets");
			me.set({"tickets": oldArr.concat(newArr)});
			me.trigger("refresh:filter:rows", newArr);
		})
	},
	needShowMoreBtn: function(){
		var result = false;
		var totalCount = this.get("total_count");
		var ticketsListLength = this.get("tickets").length;
		if(ticketsListLength !== totalCount){
			result = true;
		}
		return result;
	},
	getPageCount: function () {
		if((this.get("offset")+this.get("limit")) > this.get("total_count")) {
			return this.get("total_count");
		} else{
			return  this.get("offset")+this.get("limit");
		}
	},
	filterAction:function(ids){
		var objectMap={};
		_.reduce(ids, function(result, n, key){
			result[n] = true;
			return result;
		},objectMap);
		var me = this;
		if(this.get("action") === "markasread"){
			this._readeUnReadMessages({ticket_id: ids, read: 1}, function(respIds){
				me.trigger("read:tickets",respIds, objectMap);
			}, objectMap);
		}else if(this.get("action") === "markasunread"){
			this._readeUnReadMessages({ticket_id: ids, read: 0}, function(respIds){
				me.trigger("unread:tickets", respIds, objectMap);
			}, objectMap);
		}else if(this.get("action") === "addstar"){
			this._sendStarsArray({ticket_id: ids, starred: 1}, function(respIds){
				me.trigger("addstars:tickets", respIds, objectMap);
			});
		}else if(this.get("action") === "removestar") {
			this._sendStarsArray({ticket_id: ids, starred: 0}, function (respIds) {
				me.trigger("removestars:tickets", respIds, objectMap);
			});
		}
	},
	_sendStarsArray: function(data, cb){
		var me = this;
		$.post("/api/inbox/change_ticket_status", data).done(function(){
			_.forEach(data.ticket_id, function(id){
				var ticket = _.findWhere(me.get("tickets"), {ticket_id: id});
					ticket.starred = data.starred;
			});
			if(typeof cb === "function"){
				cb(data.ticket_id);
			}

		});
	},
	_readeUnReadMessages:function(data, cb){
		var me = this;
		$.post("/api/inbox/change_ticket_status", data).then(function () {
			Radio.channel("inbox").trigger("user:read:ticket");
			_.forEach(data.ticket_id, function(id){
				var ticket = _.findWhere(me.get("tickets"), {ticket_id: id});
				ticket.read = data.read;
			});
			if(typeof cb === "function"){
				cb(data.ticket_id);
			}
		});

	},
	createTicket: function(data){
		var me = this;
		this.validation = {
			channel_id: {
				required: true
			},
			subject: {
				required: true
			},
			body: {
				required: true
			}
		};
		this.type = "ticket";
		this.set(data);
		if(this.isValid(true)){
			$.post("/api/inbox/create_ticket", data).done(function(){
				me.trigger("success:ticket:create");
				me.trigger("success:text:show");
			});
		}
	},
	getType: function () {
		return 	this.type;
	},
	parse: function(resp){
		var result = {};
		result.tickets = resp.body.answer;
		result.total_count = resp.body.result_count;
		return result;
	}
});