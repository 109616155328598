/**
 * Created by sergey on 25.11.2015.
 */
var ProfileTemplateBankForm = require("./templates/ProfileBankForm.hbs");
module.exports = Marionette.ItemView.extend({
	initialize: function(){
		this.listenTo(this.model, "bank:save:fail", this.showValidationError.bind(this, this));
		this.listenTo(this.model, "bank:save:success", this.hideValidationError.bind(this, this, "payment_account"));
	},
	ui:{
		saveBtn: ".js-profile-bank-save",
		cancelBtn: ".js-profile-bank-cancel",
		errorShowBtn: ".js-error-btn",
	},
	events:{
		"click @ui.saveBtn": "sendData",
		"click @ui.cancelBtn": "resetForm",
		"click @ui.errorShowBtn": "errorShow",
		"submit form": "sendData",
		"input input": "removeValidationClass"
	},
	className: "b-payment-type-form b-payment-bank-form",
	template: ProfileTemplateBankForm,
	sendData: function(e){
		e.preventDefault();
		var data = Backbone.Syphon.serialize(this);
		this.trigger("bank:save:data", data);
	},
	onShow: function(){
		var me = this;
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				me.hideValidationError(view, attr);
			},
			invalid: function(view, attr, error) {
				me.showValidationError(view, attr);
			}
		});
	},
	showValidationError: function(view, attr, error){
		if(_.isArray(attr)){
			_.each(attr, function(item){
				var $el = view.$('[name=' + _.keys(item)[0] + ']');
				$el.addClass('b-input-error');
				$el.parent('.b-payment-row__item').find('.b-bank-error-field').addClass('_show');
			});
		} else{
			var $el = view.$('[name=' + attr + ']');
			$el.addClass('b-input-error');
			$el.parent('.b-payment-row__item').find('.b-bank-error-field').addClass('_show');
		}

	},
	hideValidationError: function(view, attr, error){
		var $el = view.$('[name=' + attr + ']');
		$el.removeClass('b-input-error');
		$el.parent('.b-payment-row__item').find('.b-bank-error-field').removeClass('_show');
	},
	resetForm: function(){
		this.trigger("user:click:cancel");
	},
	removeValidationClass: function(e){
		this.$el.find('.js-error-btn').removeClass('_open');
		$(e.target).removeClass('b-input-error');
		$(e.target).parent('.b-payment-row__item').find('.b-bank-error-field').removeClass('_show');
		$(e.target).parent('.b-payment-row__item').find('.b-bank-error-box').find('.b-bank-error-desc').slideUp(300);
		$(e.target).parent('.b-payment-row__item').find('.b-bank-error-tooltip').fadeOut(300);
	},
	errorShow: function(e){
		// this.$el.find('.js-error-btn').removeClass('_open');
		this.$el.find('.b-bank-error-desc').slideUp(300);
		this.$el.find('.b-bank-error-tooltip').fadeOut(300);
		if($(e.currentTarget).hasClass('_open')){
			$(e.currentTarget).removeClass('_open');
			$(e.currentTarget).closest('.b-bank-error-box').find('.b-bank-error-desc').slideUp(300);
			$(e.currentTarget).closest('.b-bank-error-box').find('.b-bank-error-tooltip').fadeOut(300);
		}else{
			$(e.currentTarget).closest('.b-bank-error-box').find('.b-bank-error-desc').slideDown(300);
			$(e.currentTarget).closest('.b-bank-error-box').find('.b-bank-error-tooltip').fadeIn(300);
			this.$el.find('.js-error-btn').removeClass('_open');
			$(e.currentTarget).addClass('_open');
		}
	}

});