var ChannelsTemplate = require("./templates/ChannelsLayout.hbs");
var CountTemplate = require("./templates/CountPage.hbs");
module.exports = Marionette.LayoutView.extend({
	className: "wrapper_n",
	template: ChannelsTemplate,
	ui: {
		moreBtn: ".js-channels-more-btn",
		countPage: ".js-channels-table-page",
		upBtn: ".b-up-button"
	},
	events: {
		"click li[data-tab]": "onTabClick",
		"click @ui.moreBtn"	: "onMoreBtnClick"
	},
	regions: {
		toolbarMonthRegion: ".js-toolbar-month",
		channelsHeaderRegion: ".js-header-channels",
		channelsTableRegion: ".js-channels-list",
		channelsToolbarRegion: ".js-channel-toolbar"
	},
	onShow: function(e){
	},
	onTabClick: function(e){
		var target = $(e.currentTarget);
		var data = $(e.currentTarget).data("tab");
		this.trigger("user:click:tab", data)
	},
	activateChannelsTab: function(){
		this.$el.find("li[data-tab='referrals']").removeClass("b-tabs-active");
		this.$el.find("li[data-tab='channels']").addClass("b-tabs-active");
	},
	activateReferralsTab: function(){
		this.$el.find("li[data-tab='referrals']").addClass("b-tabs-active");
		this.$el.find("li[data-tab='channels']").removeClass("b-tabs-active");
	},
	spinOn: function(){
		this.$el.find('.navbar-spinner').addClass('_show');
		this.$el.find('.js-channels-list').addClass('_hide');
		this.$el.find('.b-channels-layer').addClass('_hide');
	},
	fadeInTable: function(){
		this.$el.find('.js-channels-list').removeClass('_hide');
		this.$el.find('.b-channels-layer').removeClass('_hide');
	},
	onMoreBtnClick: function () {
		this.trigger("more:btn:click");
	},
	showMoreBtn: function(){
		this.ui.moreBtn.removeClass("b-hide-item");
	},
	hideMoreBtn: function(){
		this.ui.moreBtn.addClass("b-hide-item");
	},
	showCountPages: function(){
		this.ui.countPage.removeClass("b-hide-item");
	},
	hideCountPages: function(){
		this.ui.countPage.addClass("b-hide-item");
	},
	setCountPage: function(data){
		if(data.total_count < 20){
			this.ui.countPage.addClass("b-hide-item");
		}else{
			this.ui.countPage.html(CountTemplate(data));
			this.ui.countPage.removeClass("b-hide-item");
		}
	}
});