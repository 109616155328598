var Controller = require("RouterController.js");
var Router = require("Router.js");
var Helpers = require("helpers/Helpers");
var Login = require("login/Controller");
var Home = require("home/Controller");
var Header = require("header/Controller");
var Markup = require("markup/Controller");
var Dashboard = require("dashboard/Controller");
var DashboardLogin = require("dashboardlogin/Controller");
var DashboardLoginWithCode = require("dashboardloginwithcode/Controller");
var DashboardPassRecovery = require("dashboardpasswordrecovery/Controller");
var DashboardSetPassword = require("dashboardsetpassword/Controller");
var Profile = require("profile/Controller");
var Notify = require("notify/Controller");
var Payout = require("payout/Controller");
var Channels = require("channels/Controller");
var Music = require("music/Controller");
var Inbox = require("inbox/Controller");
var Support = require("support/Controller");
var Cpm = require("cpm/Controller");
var AdvancedReport = require("advancedreport/Controller");
var LoginPassw = require("dashboardloginpassword/Controller");
var UniteDashboard = require("unitedashboard/Controller");
var ErrorAuthHandler = require("autherrorhandler/Controller");
var jqueryConfig = require("config/Jquery/Config");
var DashboardEntities = require("entities/Dashboard");
var HeaderEntities = require("entities/Header");
var PayoutEntities = require("entities/Payout");
var ProfileEntities = require("entities/Profile");
var AccountEntities = require("entities/Account");
var LoginEntities = require("entities/Login");

var JoinSignIn = require("join/signin/Controller");
var JoinStep1 = require("join/step1/Controller");
var JoinStep2 = require("join/step2/Controller");

require("helpers/HandleBarsHelpers");
require("helpers/HandleBarsPartials");

module.exports = App = function App() { };

App.prototype._loadModules = function(ctx){
	DashboardEntities.initialize();
	HeaderEntities.initialize();
	PayoutEntities.initialize();
	ProfileEntities.initialize();
	AccountEntities.initialize();
	LoginEntities.initialize();
	ctx.modules = {};
	ctx.modules.login = new Login();
	ctx.modules.home = new Home();
	ctx.modules.header = new Header();
	ctx.modules.Dashboard = new Dashboard();
	ctx.modules.DashboardLogin = new DashboardLogin();
	ctx.modules.DashboardLoginWithCode = new DashboardLoginWithCode();
	ctx.modules.DashboardPassRecovery = new DashboardPassRecovery();
	ctx.modules.DashboardSetPassword = new DashboardSetPassword();
	ctx.modules.Profile = new Profile();
	ctx.modules.Notify = new Notify();
	ctx.modules.markup = new Markup();
	ctx.modules.payout = new Payout();
	ctx.modules.channels = new Channels();
	ctx.modules.music = new Music();
	ctx.modules.inbox = new Inbox();
	ctx.modules.support = new Support();
	ctx.modules.cpm = new Cpm();
	ctx.modules.advancedreport = new AdvancedReport();
	ctx.modules.unitedashboard = new UniteDashboard();
	ctx.modules.LoginPassw = new LoginPassw();
	ctx.modules.errorAuthHandler = new ErrorAuthHandler();
	ctx.modules.JoinSignIn = new JoinSignIn();
	ctx.modules.JoinStep1 = new JoinStep1();
	ctx.modules.JoinStep2 = new JoinStep2();

};
App.prototype.start = function () {

    App.core = new Marionette.Application();
	this._loadModules(App);
    App.core.on("before:start", function () {
        App.core.vent.trigger('app:log', 'App: Initializing');
        App.views = {};
        App.data = {};
		jqueryConfig();
		Radio.channel("login").trigger("is:user:logged");
		Radio.channel("login").on("user:ping:then", function(){
			App.core.vent.trigger('app:start');
		});


    });
	App.core.vent.bind('app:start', function () {
        App.core.vent.trigger('app:log', 'App: Starting');
        if (Backbone.history) {
			App.controller = new Controller();
            App.router = new Router({ controller: App.controller });
            App.core.vent.trigger('app:log', 'App: Backbone.history starting');
            Backbone.history.start();
			App.modules.header.show();
        }

        //new up and views and render for base app here...
        App.core.vent.trigger('app:log', 'App: Done starting and running!');
    });

    App.core.vent.bind('app:log', function (msg) {

    });

    App.core.start();
};