SupportModel =  require("../models/Support/Support");

module.exports = {
	initialize: function(){
		this.setHandlers()
	},
	getSupport: function(options){
		var options = options || {};
		var support = new SupportModel(options);
		var defer = $.Deferred();
		support.fetch({
			success: function(){
				defer.resolve(support);
			}
		});
		return defer.promise();
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("global").reply("support:entity", function(options){
			return me.getSupport(options);
		});
	}
};