var Step2Template = require("./template/Step2.hbs");

module.exports = Marionette.LayoutView.extend({
	ui: {
	},
	events: {
	},
	className: "wrapper_n",
	template: Step2Template,
	onShow: function(){
		$('body').addClass('join');
	}
});