/**
 * Created by sergey on 15.01.2016.
 */
module.exports = Marionette.ItemView.extend({
	template: false,
	viewsList: {},
	fieldMap:{
		"given_name": "first-name",
		"family_name": "last-name",
		"address": "address",
		"town": "profile_town"
	},
	initialize: function(){
		var me = this,
			validatorView = {};
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				validatorView = me.viewsList[me.model.getType()];
				me.hideValidationError(validatorView, attr);
			},
			invalid: function(view, attr, error) {
				validatorView = me.viewsList[me.model.getType()];
				me.showValidationError(validatorView, attr);
			}
		});
		this.listenTo(this.model, "validated:invalid", this.showServerError);
		this.listenTo(this.model, "profile:password:set:fail", this.showServerError);
		this.listenTo(this.model, "profile:password:access:set:fail", this.showServerError);
		this.listenTo(this.model, "profile:password:access:set:fail", this.showServerError);
		this.listenTo(this.model, "about:data:fail", this.showServerError);
		this.listenTo(this.model, "contacts:data:fail", this.showServerError);
	},
	showValidationError: function(view, attr){
		var $el = [];
		if(this.model.getType() == "contact") {
			$el = view.$(".b-input_"+attr);
			$el.addClass('b-input-error');
		}else{
			$el = view.$("[name='"+attr+"']");
			$el.addClass('b-input-error');
		}
	},
	hideValidationError: function(view, attr, error){
		var $el = [];
		if(this.model.getType() == "contact") {
			$el = view.$(".b-input_"+attr);
			$el.removeClass('b-input-error');
		}else{
			$el = view.$("[name='"+attr+"']");
			$el.removeClass('b-input-error');
		}
	},
	showServerError: function(model, errors, code){
		var me = this;
		var validatorView = {};
		validatorView = this.viewsList[this.model.getType()];
		if(this.model.getType() !== "contact" && this.model.getType() !== "about"){
			validatorView.ui.errorBox.fadeIn(300);
			if(_.isObject(errors)){
				validatorView.ui.errorBox.text(_.values(errors).join(", "));
			}else{
				validatorView.ui.errorBox.html(errors);
			}
			if(code === -7){
				$('.b-input_email').removeClass('b-input-error');
				$('.b-input_question').addClass('b-input-error');
				$('.b-input_answer').addClass('b-input-error');
			}else if(code === -1){
				$('.b-input_question').removeClass('b-input-error');
				$('.b-input_answer').removeClass('b-input-error');
				$('.b-input_email').addClass('b-input-error');
			}else if(code === -2){
				$('.b-input_question').removeClass('b-input-error');
				$('.b-input_answer').removeClass('b-input-error');
				$('.b-input_email').addClass('b-input-error');
			}
		} else{
			_.forEach(errors, function (item) {
				validatorView.showValidationError(validatorView, me.fieldMap[item]);
			});
		}


	},
	addViews: function (obj) {
		var me = this;
		for(var k in obj){
			if(obj.hasOwnProperty(k)){
				me.viewsList[k] = obj[k];
			}
		}
	}
});