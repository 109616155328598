var HomeView = require("./views/HomeShow");
module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	show: function(){
		var home = new HomeView();
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		mainRegion.show(home);
		Backbone.history.navigate("#");
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:home", function() {
			me.show();
		});
	}
});