/**
 * Created by sergey on 28.12.2015.
 */
var tableMixin = require("mixins/Table");
var TemplateTableAdvanceReport = require("./templates/AdvanceReportTable.hbs");
var Helpers = require("helpers/Helpers");
var AdvanceReportEarningTemplate = require("./templates/AdvanceReportEarningTemplate.hbs");
var AdvanceReportPayoutTemplate = require("./templates/AdvanceReportPayoutStatusTemplate.hbs");
module.exports = Marionette.ItemView.extend(_.extend(tableMixin, {
	className: "b-table f_12 advanced-table",
	tagName: "table",
	template: TemplateTableAdvanceReport,
	refreshData: function (data) {
		this.drawTable(data);
	},
	getData: function () {
		return this.model.get("payments");
	},
	onShow: function () {
		var me = this;
		this.table = this.$el.DataTable(me.getConfig());
		this.addBodyClass();
		this.addSelected();
	},
	getConfig: function () {
		var me = this;
		return {
			"paging": false,
			"lengthChange": false,
			"caseInsensitive": false,
			"info": false,
			"data": me.getData(),
			"columns": me.getColumns(),
			"language": {
				"emptyTable": "N/A"
			}
		}
	},
	getColumns: function () {
		return [
			{
				type: 'de_date', data: "bill_date", render: function (data, type, full, meta) {
				return Helpers.revertDate(full.last_day_of_month);
			}
			},
			{
				render: function (data, type, full, meta) {
					if(data === 4){
						return "YouTube RED";
					}
					return "YouTube";
				}
			},
			{
				data: "share_rate", render: function (data, type, full, meta) {
					return "80"
			}
				},
			{
				data: "earning_amount", className: "b-table-earning", render: function (data, type, full, meta) {
				return AdvanceReportEarningTemplate({
					earning_amount: data,
					is_referral: full.is_referral,
					url: full.report_url
				});
			}
			},

			{
				data: "payment_date", render: function (data, type, full, meta) {
				return Helpers.revertDate(data);
			}
			},
			{
				data: "p_account", render: function (data, type, full, meta) {
				var result = "";
				if(data){
					if(full.payment_type == 7){
						return '<span class="b-download-link right" data-desc="'+data.account+'" style="margin-left: 25px;">WebMoney</span>';
					}else if(full.payment_type == 11){
						return '<span class="b-download-link right" data-desc="'+data.account+'" style="margin-left: 29px;">Payoneer</span>';
					}else if(full.payment_type == 1){
						return '<span class="b-download-link right" data-desc="'+data.account+'" style="margin-left: 42px;">Bank</span>';
					}else if(full.payment_type == 4){
						return '<span class="b-download-link right" data-desc="'+data.account+'" style="margin-left: 37px;">PayPal</span>';
					}
				}
				return result;
			}
			},
			{
				data: "payments_status", render: function (data, type, full, meta) {
				return AdvanceReportPayoutTemplate({status: data})
			}
			},
			{
				data: "pid", render: function (data, type, full, meta) {
				if(!data){
					return "";
				}
				return data

			}
			}
		];
	},
	drawTable: function (data) {
		var me = this;
		if (data) {
			this.table.rows.add(data); // Add new data
			this.table.columns.adjust().draw(false); // Redraw the DataTable
		} else {
			this.table.clear().draw();
			this.table.rows.add(me.getData()); // Add new data
			this.table.columns.adjust().draw(); // Redraw the DataTable
		}
	}
}));