// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.referal_clicks || (depth0 != null ? depth0.referal_clicks : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"referal_clicks","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "0";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"b-sum-block__item\">\n    <span class=\"b-sum-count b-sum-count_left\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.referal_clicks : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n    <span class=\"b-sum-title b-title_left\">Referrals <br> link clicks</span>\n</div>\n<div class=\"b-sum-block__item\">\n    <span class=\"b-sum-count b-sum-count_left\">"
    + alias4(((helper = (helper = helpers.app_recieved || (depth0 != null ? depth0.app_recieved : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_recieved","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"b-sum-title b-title_left\">Applications <br> received</span>\n</div>\n<div class=\"b-sum-block__item\">\n    <span class=\"b-sum-count b-sum-count_left\">"
    + alias4(((helper = (helper = helpers.app_accepted || (depth0 != null ? depth0.app_accepted : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"app_accepted","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"b-sum-title b-title_left\">Applications <br> accepted</span>\n</div>\n<div class=\"b-sum-block__item\">\n    <span class=\"b-sum-count b-sum-count_left\">"
    + alias4(((helper = (helper = helpers.sum || (depth0 != null ? depth0.sum : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sum","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"b-sum-title b-title_left\">Active <br> channels</span>\n</div>";
},"useData":true});
