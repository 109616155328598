/**
 * Created by sergey on 20.11.2015.
 */


module.exports = Marionette.Object.extend({
	initialize: function(){

	},
	show: function(container){

	}
});