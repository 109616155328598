// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<form>\n	<span class=\"f_12 f_light\">Please enter English characters only</span>\n	<div class=\"b-payment-form b-payment-form_top\">\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<label class=\"b-page-label\">Beneficiary*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" placeholder=\"\" name=\"bank_f_beneficiary\" value=\""
    + alias4(((helper = (helper = helpers.bank_f_beneficiary || (depth0 != null ? depth0.bank_f_beneficiary : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_f_beneficiary","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<label class=\"b-page-label\">Beneficiary address*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_f_country\" placeholder=\"Country name*\" value=\""
    + alias4(((helper = (helper = helpers.bank_f_country || (depth0 != null ? depth0.bank_f_country : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_f_country","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n			<div class=\"b-payment-row__item\">\n				<br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_f_town\" placeholder=\"Town/Locality*\" value=\""
    + alias4(((helper = (helper = helpers.bank_f_town || (depth0 != null ? depth0.bank_f_town : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_f_town","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_f_streat\" placeholder=\"Street & bld.*\" value=\""
    + alias4(((helper = (helper = helpers.bank_f_streat || (depth0 != null ? depth0.bank_f_streat : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_f_streat","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n			<div class=\"b-payment-row__item\">\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_f_postal_code\" placeholder=\"Postal code*\" value=\""
    + alias4(((helper = (helper = helpers.bank_f_postal_code || (depth0 != null ? depth0.bank_f_postal_code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_f_postal_code","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n	</div>\n	<div class=\"b-payment-form b-payment-form_mid\">\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<label class=\"b-page-label\">Beneficiary bank*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_beneficiary\" placeholder=\"\" value=\""
    + alias4(((helper = (helper = helpers.bank_beneficiary || (depth0 != null ? depth0.bank_beneficiary : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_beneficiary","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n		</div>\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<label class=\"b-page-label\">Beneficiary bank address*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_country\" placeholder=\"Country name*\" value=\""
    + alias4(((helper = (helper = helpers.bank_country || (depth0 != null ? depth0.bank_country : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_country","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n			<div class=\"b-payment-row__item\">\n				<br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_town\" placeholder=\"Town/Locality*\" value=\""
    + alias4(((helper = (helper = helpers.bank_town || (depth0 != null ? depth0.bank_town : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_town","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_streat\" placeholder=\"Street & bld.*\" value=\""
    + alias4(((helper = (helper = helpers.bank_streat || (depth0 != null ? depth0.bank_streat : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_streat","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n			<div class=\"b-payment-row__item\">\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter English characters only.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_postal_code\" placeholder=\"Postal code*\" value=\""
    + alias4(((helper = (helper = helpers.bank_postal_code || (depth0 != null ? depth0.bank_postal_code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_postal_code","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n	</div>\n	<div class=\"b-payment-form b-payment-form_bot\">\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<span class=\"b-tooltip-qu b-bank-tooltip\">\n					<span class=\"b-tooltip-desc\">\n						IBAN (International Bank Account Number) consists of up to 34 alphanumeric characters, as follows: \n						<br>\n						a) country code using ISO 3166-1 alpha-2 - two letters, <br>\n						b) check digits - two digits, and  <br>\n						c) Basic Bank Account Number (BBAN) - up to 30 alphanumeric characters that are country-specific.  <br>\n						<br>				\n						If your country has not implemented the IBAN, you may only fill out the Account field.  \n						After filling out IBAN you are not to fill out the Account field.\n					</span>\n				</span>\n				<label class=\"b-page-label b-bank-label\">IBAN*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						The IBAN consists of up to 34 alphanumeric characters, as follows: <br>\n						country code - two letters, <br>\n						check digits - two digits, and <br>\n						Basic Bank Account Number (BBAN) - up to 30 alphanumeric characters. <br>\n						Permitted IBAN characters are the digits 0 to 9 and the 26 upper-case Latin alphabetic characters A to Z. This applies even in countries (e.g., Thailand) where these characters are not used in the national language.\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_iban\" placeholder=\"\" value=\""
    + alias4(((helper = (helper = helpers.bank_iban || (depth0 != null ? depth0.bank_iban : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_iban","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n			<div class=\"b-payment-row__item\">\n				\n				<label class=\"b-page-label\">Account*</label><br>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_beneficiary_account\" placeholder=\"\" value=\""
    + alias4(((helper = (helper = helpers.bank_beneficiary_account || (depth0 != null ? depth0.bank_beneficiary_account : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_beneficiary_account","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n		\n		<div class=\"b-payment-row\">\n			<div class=\"b-payment-row__item\">\n				<span class=\"b-tooltip-qu b-bank-tooltip b-tooltip\" data-desc='After filling out SWIFT you are not to fill out the Routing number.'></span>\n				<label class=\"b-page-label b-bank-label\">Bank SWIFT*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						The SWIFT code is 8 or 11 characters, made up of: <br>\n						4 letters: bank code. <br>\n						2 letters: country code <br>\n						2 letters or digits: location code <br>\n						3 letters or digits: branch code, optional ('XXX' for primary office) \n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_swift\" placeholder=\"\" value=\""
    + alias4(((helper = (helper = helpers.bank_swift || (depth0 != null ? depth0.bank_swift : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_swift","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n			<div class=\"b-payment-row__item\">\n				<span class=\"b-tooltip-qu b-bank-tooltip b-tooltip\" data-desc='Only for U.S. banks. After filling out the Routing number you are not to fill out SWIFT.'></span>\n				<label class=\"b-page-label b-bank-label\">Routing code*</label><br>\n				<div class=\"b-bank-error-box\">\n					<div class=\"b-bank-error-desc\">\n						Enter alphanumeric characters only (the collection of Latin letters and Arabic digits, not more than 70 characters).\n						<div class=\"b-bank-error-tooltip\"></div>\n					</div>\n					<div class=\"b-bank-error-field clearfix\">\n						<div class=\"b-bank-error-btn js-error-btn\"></div>\n						<span>Error</span>\n					</div>\n				</div>\n				<input type=\"text\" class=\"b-page-input b-page-input_text b-page-input_m b-input_email\" name=\"bank_routing_code\" placeholder=\"\" value=\""
    + alias4(((helper = (helper = helpers.bank_routing_code || (depth0 != null ? depth0.bank_routing_code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank_routing_code","hash":{},"data":data}) : helper)))
    + "\">\n			</div>\n		</div>\n	</div>\n	<span class=\"f_12 f_light\">* Indicated required fields</span>\n	<div class=\"b-payment-form-buttons\">\n		<button type=\"submit\" class=\"b-page-button b-button_yes js-profile-bank-save\">Submit</button>\n		<button type=\"button\" class=\"b-page-button b-button_no js-profile-bank-cancel\">Cancel</button>\n	</div>\n</form>";
},"useData":true});
