/**
 * Created by sergey on 23.11.2015.
 */
module.exports = Marionette.ItemView.extend({
	template: false,
	ui:{
		editLinkWrap: ".b-edit-link-wrap"
	},
	events: {
		"click @ui.editLinkWrap": "editProfileData"
	},
	editProfileData: function(e){
		var curTarget = $(e.currentTarget);
		curTarget.toggleClass('save');
		this.$el.find('.b-page-link').hide();
		if(curTarget.hasClass('save')){
			this.controlAnimation(".b-page_save-link", 0, 50);
		}else{
			this.controlAnimation(".b-page_edit-link", -50, 0);
		}
	},
	hideControls: function(){
		this.ui.editLinkWrap.removeClass("save");
		this.$el.find('.b-page-link').hide();
		this.$el.find('.b-page_successd-link').fadeIn(300);
		this.controlAnimation(".b-page_edit-link", -50, 6, 'save');
		this.$el.find("input").removeClass("b-input-error");
	},
	showControls: function(){
		this.ui.editLinkWrap.addClass("save");
		this.$el.find('.b-page-link').hide();
		this.controlAnimation(".b-page_save-link", 0, 50);
	},
	controlAnimation: function(selector, topInput, topText, state){
		var me = this;
		if(state == 'save'){
			me.$el.find(selector).css('opacity',0).show();
			setTimeout(function(){
				me.$el.find(selector).animate({'opacity':1},400);
				me.$el.find('.b-page_successd-link').fadeOut(300);
			},3000);
		}else{
			this.$el.find(selector).show();
		}
		this.$el.find(".b-edit-input-block").animate({top: topInput+"px"},300);
		this.$el.find(".b-edit-text-block").animate({top: topText+"px"},300);
	}
});