var Views = {};

var PayoutsChannelsTemplate = require("./templates/ChannelsPayout.hbs");


Views.PayoutsChannelsView = Marionette.ItemView.extend({
	templateHelpers: function(){
		var me = this;
		return {
			sum_earnings: me.options["sum_earnings"]
		}
	},
	template: PayoutsChannelsTemplate
});
module.exports = Marionette.Object.extend({
	createView: function(region, resp){
		var total_sum = resp.body.sum_earnings + resp.body.referral_earnings;
		this.payoutsChannelsView = new Views.PayoutsChannelsView({sum_earnings: total_sum});
		region.show(this.payoutsChannelsView);
	},
	show: function(region){
		var me = this,
			month = Radio.channel("login").request("user:month:of:payment"),
			year = Radio.channel("login").request("user:year:of:payment");
		$.get("/api/payouts/summary?year="+year+"&month="+month).done(me.createView.bind(me, region))
	}
});