/**
 * Created by sergey on 08.02.2015.
 */
var MessagePage = require("./views/MessagePage");
module.exports = Marionette.Object.extend({
	initialize: function () {
		this.codeMap = {
			"-2":{
					login:{
						title: "Auth error",
						header: "Error",
						text: ""
					}
				}
		};
		this.setHandlers();
	},
	show: function (params) {
		if (params ? params.code : 0) {
			var model = new Backbone.Model(this.codeMap[params.code][params.category]);
			var notify = new MessagePage({model: model});
			this.listenTo(notify, "user:accept", function(){
				Backbone.history.navigate("#?", {trigger: true});
			});
			var modalRegion = Radio.channel("layout").request("get:main:region");
			modalRegion.show(notify);
		}

	},
	setHandlers: function () {
		var me = this;
		Radio.channel("application").on("application:state:autherrorhandler", function (params) {
			me.show(params);
		});
	}
});