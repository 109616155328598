var DashboardLoginShow = require("./templates/DashboardLoginWihCodeShow.hbs");
module.exports = Marionette.ItemView.extend({
	ui:{
		sendBtn: ".js-dashboard-login-submit",
		notify: "password-notify-error"
	},
	events: {
		"click @ui.sendBtn" : "sendData",
		"submit form": "sendData"
	},
	className: "wrapper_n",
	template: DashboardLoginShow,
	onRender: function(){
		var me = this;
		Backbone.Validation.bind(this, {
			valid: function(view, attr) {
				me.hideValidationError(view, attr);
			},
			invalid: function(view, attr, error) {
				me.showValidationError(view, attr);
			}
		});
	},
	showValidationError: function(view, attr){
		var $el = view.$(".js-dashboard-login-password-"+attr);
		$el.addClass('b-input-error');
	},
	hideValidationError: function(view, attr, error){
		var $el = view.$(".js-dashboard-login-password-"+attr);
		$el.removeClass('b-input-error');
	},
	templateHelpers: function(){
		var me = this;
		return {
			codeNum: me.options["codeNum"]
		}
	},
	sendData: function(e){
		e.preventDefault();
		var code = $(".js-dashboard-login-password-code").val();
		this.trigger("send:code", code);
	},
	onShow: function(){
		this.ui.notify.hide();
	},
	showPasswordError: function(){
		this.ui.notify.show();
	},
	showUserIsNotExistError: function(){
	},
	codeError: function(text){
		var $el = this.$el.find('.js-dashboard-code-login');
		$el.addClass('login-error');
		$el.find('.b-page-button').removeClass('b-button_yes').addClass('b-button_no');
		$el.find('.b-login-error-block').fadeIn(300);
		$el.find('.b-login-error-block').text(text);
	}
});