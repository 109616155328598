/**
 * Created by sergey on 03.11.2015.
 */
module.exports = {
	/**
	 *
	 * @param {String} paramString routeParams form route
	 * @returns {Object} Params route
	 */
	parseUrl: function (paramString) {
		var result = {};
		if (!paramString) {
			return result;
		}
		$.each(paramString.split('&'), function (index, value) {
			if (value) {
				var param = value.split('=');
				result[param[0]] = param[1];
			}
		});
		return result;
	},
	/**
	 *
	 * @param {String} str Url
	 * @returns {String} str EncodeUrl
	 */
	fixedEncodeURIComponent: function (str) {
		return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
			return '%' + c.charCodeAt(0).toString(16);
		});
	},
	/**
	 *	 * @param {Array} String list scope for url Auth
	 * @returns {String} scope concat string from array scope strings
	 */
	createAuthListScope: function (list) {
		var urlEncodeList = [];
		_.each(list, function (item) {
			urlEncodeList.push(encodeURIComponent(item));
		});
		return urlEncodeList.join(encodeURIComponent(" "));
	},
	/**
	 *
	 *  @returns {String} url for Auth Google authentication
	 */
	getAuthUrl: function () {
		var config = {};
		var baseUrl = "https://www.google.com/accounts/Logout?continue=";
		var appendUrl = "https://appengine.google.com/_ah/logout?continue=";
		config.OAUTHURL = GlobalConfig.oauthurl;
		config.SCOPE = this.createAuthListScope(GlobalConfig.scopes);
		config.CLIENTID = GlobalConfig.clientId;
		config.REDIRECT = GlobalConfig.redirectURL;
		config.TYPE = GlobalConfig.type;
		var _url = config["OAUTHURL"] + "?scope=" + config["SCOPE"] + "&redirect_uri=" + encodeURIComponent(config["REDIRECT"])
			+ "&response_type=" + config["TYPE"] + "&client_id=" + config["CLIENTID"];
		appendUrl += encodeURIComponent(_url);
		baseUrl += encodeURIComponent(appendUrl);
		return baseUrl
	},
	getJoinAuthUrl: function () {
		var config = {};
		var baseUrl = "https://www.google.com/accounts/Logout?continue=";
		var appendUrl = "https://appengine.google.com/_ah/logout?continue=";
		config.OAUTHURL = GlobalConfig.oauthurl;
		config.SCOPE = this.createAuthListScope(GlobalConfig.join_scopes);
		config.CLIENTID = GlobalConfig.clientId;
		config.REDIRECT = GlobalConfig.redirectURL;
		config.TYPE = GlobalConfig.type;
		var _url = config["OAUTHURL"] + "?scope=" + config["SCOPE"] + "&redirect_uri=" + encodeURIComponent(config["REDIRECT"])
			+ "&response_type=" + config["TYPE"] + "&client_id=" + config["CLIENTID"];
		appendUrl += encodeURIComponent(_url);
		baseUrl += encodeURIComponent(appendUrl);
		return baseUrl
	},
	isUserLoggedIn: function () {
		return $.get(GlobalConfig.urls.api_account_test)
	},
	/**
	 *
	 * @param {String} born date in string formate "01-01-1975"
	 * @returns {Object} return date in object
	 */
	 getBornDate: function(date) {
		var result = date.split("-");
		if(result[2].indexOf("0") === 0){
			result[2] = result[2][1];
		}
		if(result[1].indexOf("0") === 0){
			result[1] = result[1][1];
		}
		return {
			year: result[0],
			month: result[1],
			day: result[2]
		}
	},
	/**
	 *
	 * @param {String} url request from google
	 * @param {String} name params from url request
	 * @returns {String} results result params
	 */
	gup: function (url, name) {
		name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
		var regexS = "[\\#&]" + name + "=([^&#]*)";
		var regex = new RegExp(regexS);
		var results = regex.exec(url);
		if (results == null)
			return "";
		else
			return results[1];
	},
	uniteDashboardParse: function (url, name) {
		name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
		var regexS = "[\\#&?]" + name + "=([^&#]+)";
		var regex = new RegExp(regexS);
		var results = regex.exec(url);
		if (results == null)
			return "";
		else
			return results[1];
	},
	stringRevers: function(s){
			var o = '';
			for (var i = s.length - 1; i >= 0; i--)
				o += s[i];
			return o;
	},
	revertDate: function(date){
		var result = "";
		if(typeof date === "string")
		{
			result = date.split("-").reverse().join(".");
		}
		return result;
	},
	debounce: function (func, wait, immediate) {
		// 'private' variable for instance
		// The returned function will be able to reference this due to closure.
		// Each call to the returned function will share this common timer.
		var timeout;

		// Calling debounce returns a new anonymous function
		return function() {
			// reference the context and args for the setTimeout function
			var context = this,
				args = arguments;

			// Should the function be called now? If immediate is true
			//   and not already in a timeout then the answer is: Yes
			var callNow = immediate && !timeout;

			// This is the basic debounce behaviour where you can call this
			//   function several times, but it will only execute once
			//   [before or after imposing a delay].
			//   Each time the returned function is called, the timer starts over.
			clearTimeout(timeout);

			// Set the new timeout
			timeout = setTimeout(function() {

				// Inside the timeout function, clear the timeout variable
				// which will let the next execution run when in 'immediate' mode
				timeout = null;

				// Check if the function already ran with the immediate flag
				if (!immediate) {
					// Call the original function with apply
					// apply lets you define the 'this' object as well as the arguments
					//    (both captured before setTimeout)
					func.apply(context, args);
				}
			}, wait);

			// Immediate mode and no wait timer? Execute the function..
			if (callNow) func.apply(context, args);
		};
	},
	/**
	 *
	 * @param {String} value date in format yyyy-mm-dd
	 * @returns {String} number of month
	 */
	getMonthNumber: function (value) {
		var monthNumber = "";
		if(value.split("-")[1][0] === "0"){
			monthNumber = value.split("-")[1][1];
		}else{
			monthNumber = value.split("-")[1];
		}
		return monthNumber
	},
	/**
	 *
	 * @param {String} value string file bytes
	 * @returns {String} string in 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
	 */
	formatBytes: function (bytes,decimals) {
		if(bytes == 0) return '0 Byte';
		var k = 1000;
		var dm = decimals + 1 || 3;
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		var i = Math.floor(Math.log(bytes) / Math.log(k));
		return (bytes / Math.pow(k, i)).toPrecision(dm) + ' ' + sizes[i];
	},
	/**
	 *
	 * @param {String} name string
	 * @returns {Integer} hash
	 */
	getHashFromName: function(str){
		var hash = 0, i, chr, len;
		if (str.length === 0) return hash;
		for (i = 0, len = str.length; i < len; i++) {
			chr   = str.charCodeAt(i);
			hash  = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}
};
