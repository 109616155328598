/**
 * Created by gila on 14.12.2015.
 */
var MusicHeaderTemplate = require("./templates/MusicHeader.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		catName: ".b-genre-title",
		numFound: ".b-found-items-count",
		breadcrumbs: ".breadcrumbs"
	},
	template: MusicHeaderTemplate,
	onShow: function(){
		var me = this;
		this.$el.on('click', ".b-music-cat.active-crumb",function(e){
			me.breadcrums;
			var newBreadCrums = [];
			var index = -1;
			var catId = $(e.currentTarget).data("id"),
				catName = $(e.currentTarget).text().replace('>',' ');
				crumbIndex = $(e.currentTarget).index(),
				disIndex = $('.disable-crumb').index();
			_.every(me.breadcrums, function(item, itemIndex){
				newBreadCrums.push(item);
				if(item.catId == catId){
					return false;
				}
				return true;
			});

			var div = $("<div/>");
			$.each(newBreadCrums,function(i,item){
				div.append('<span class="b-music-cat active-crumb" data-id="'+item.catId+'">'+item.text+'<span class="b-next-cat"> > </span></span>');
			});
			me.breadcrums = newBreadCrums;
			setTimeout(function(){
				me.editCrumbs();
			},300);
			$('.js-music-breadcrumbs').html(div);
			$.ajax({
				type: "GET",
				url: "/api/music/category_list?cat_id="+catId+""
			}).then(function(resp){
				var data = {
					catId: catId,
					text: catName
				};
				me.trigger("change:select", resp.body.category, data, resp);
			});
			me.trigger("update:crumb", catId, newBreadCrums);
			return false;
		})
	},	
	showHeader: function(){
		this.$el.find('.b-music-header').removeClass('b-hide-item');
	},
	setName: function(text){
		this.ui.catName.text(text);
	},
	setItemFound: function(text){
		this.ui.numFound.text(text);
	},
	editCrumbs: function(){
		$('.js-music-breadcrumbs .b-music-cat:last-child .b-next-cat').remove();
		$('.js-music-breadcrumbs .b-music-cat:last-child').removeClass('active-crumb').addClass('disable-crumb');
	},
	addCrumbs: function(data){
		var me = this;
		this.breadcrums = data;
		$('.js-music-breadcrumbs').html('');
		$.each(data,function(i,item){
			$('.js-music-breadcrumbs').append('<span class="b-music-cat active-crumb" data-id="'+item.catId+'">'+item.text+'<span class="b-next-cat"> > </span></span>');
		});
		me.editCrumbs();
		
	}
});