/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var ChannelDashboardPanelTemplate = require("./templates/ReferralsBlock.hbs");


Views.ChannelDashboardBlockView = Marionette.ItemView.extend({
	templateHelpers: function(){
		var me = this;
		return {
			count: me.options["count"],
			sumCurrentMonth: me.options["sumCurrentMonth"],
			sumPrevMonth: me.options["sumPrevMonth"],
			earning: me.options["earning"]
		}
	},
	template: ChannelDashboardPanelTemplate,
	tagName: "a",
	attributes:{
		href: "#?page=channels&subpage=referrals"
	}
});
module.exports = Marionette.Object.extend({
	createView: function(region,  respCount, respCurrentMonth, respPrevMonth){
		this.channelDashboardBlock = new Views.ChannelDashboardBlockView({count: respCount[0].body.result_count,
			sumCurrentMonth: respCurrentMonth[0].body.referral_earnings,	sumPrevMonth: respPrevMonth[0].body.referral_earnings, earning: respCurrentMonth[0].body.referral_earnings});
		region.show(this.channelDashboardBlock);
	},
	show: function(region){
		var  prevMonth = 1,
			 prevYear = new Date().getFullYear();
		var me = this,
			month = Radio.channel("login").request("user:month:of:payment"),
			year = Radio.channel("login").request("user:year:of:payment");
			if(month !== 1){
				prevMonth = month - 1;
				prevYear = year;
			}else{
				prevMonth = 12;
				prevYear = year - 1;
			}
			var promises = [];
		promises.push($.get("/api/dashboard/get_referal_list?only_count=true"));
		promises.push($.get("/api/payouts/summary?year="+year+"&month="+month+""));
		promises.push($.get("/api/payouts/summary?year="+prevYear+"&month="+prevMonth));
		$.when.apply($, promises).done(me.createView.bind(me, region));
	}
});