/**
 * Created by sergey on 03.12.2015.
 */
/**
 * Created by sergey on 19.11.2015.
 */
var Views = {};

var PayoutsDashboardPanelTemplate = require("./templates/PayoutsBlock.hbs");


Views.PayoutsDashboardBlockView = Marionette.ItemView.extend({
	templateHelpers: function(){
		var me = this;
		return {
			sumCurrentMonth: me.options["sumCurrentMonth"],
			sumPrevMonth: me.options["sumPrevMonth"],
			checkReport: me.options["checkReport"],
			month: me.options["month"],
			year: me.options["year"],
			earning: me.options["earning"],
			payMonth: me.options["payMonth"]
		}
	},
	template: PayoutsDashboardPanelTemplate,
	tagName: "a",
	attributes:{
		href: "#?page=payout"
	}
});
module.exports = Marionette.Object.extend({
	createView: function(region, month, respCurrentMonth, respPrevMonth, respCheckReport){
		var date = new Date();
		var year = Radio.channel("login").request("user:year:of:payment");
		var totalCurrentMonth = respCurrentMonth[0].body.sum_earnings + respCurrentMonth[0].body.referral_earnings,
		 	totalPrevMonth = respPrevMonth[0].body.sum_earnings + respPrevMonth[0].body.referral_earnings,
		 	earningChannel = respCurrentMonth[0].body.channel_earnings,
		 	payMonth = date.getMonth() + 1;
		this.payoutsDashboardBlock = new Views.PayoutsDashboardBlockView({sumCurrentMonth: totalCurrentMonth,
			sumPrevMonth: totalPrevMonth, year: year, month: String(month), checkReport: respCheckReport[0].body.status, earning: earningChannel, payMonth: payMonth});
		region.show(this.payoutsDashboardBlock);

	},
	show: function(region){
		var  prevMonth = 12,
			prevYear = new Date().getFullYear();
		var me = this,
			month = Radio.channel("login").request("user:month:of:payment"),
			year = Radio.channel("login").request("user:year:of:payment");
		if(month !== 1){
			prevMonth = month - 1;
			prevYear = year;
		}else{
			prevMonth = 12;
			prevYear = year - 1;
		}
			var promises = [];
		promises.push($.get("/api/payouts/summary?year="+year+"&month="+month));
		promises.push($.get("/api/payouts/summary?year="+prevYear+"&month="+prevMonth));
		promises.push($.get("/api/payouts/check_report?year="+year+"&month="+month));
		$.when.apply($, promises).done(me.createView.bind(me, region, month))
	}
});