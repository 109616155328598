var DashboardModal = require("./templates/DashboardModal.hbs");
module.exports = Marionette.ItemView.extend({
	ui: {
		cancelBtn: ".b-button_no",
		agreeBtn: ".js-unite-agree-btn",
		declineBtn: ".js-decline-btn",
		nextDescBtn: ".js-next-desc-btn"
	},
	events: {
		"click @ui.cancelBtn": "hideNotify",
		"click @ui.nextDescBtn": "nextDesc",
		"click @ui.agreeBtn": "uniteDashboard",
		"click @ui.declineBtn": "declineUnite"
	},
	className: "modal-wrap",
	template: DashboardModal,
	templateHelpers: function(){
		var me = this;
		return {
			cabId: me.options.cabId
		};
	},
	onShow: function(){
		
	},
	hideNotify: function(){
		$('.modal-backdrop').fadeOut(300);
		$('.modal').fadeOut(300);
		$('body').removeClass('modal-open');
	},
	nextDesc: function(){
		this.$el.find('.b-unite-desc-1').hide(300);
		this.$el.find('.b-unite-desc-2').fadeIn(300);
	},
	uniteDashboard: function(){
		this.trigger("unite:dashboard:success");
		this.hideNotify();
	},
	declineUnite: function(){
		this.trigger("decline:unite");
		this.hideNotify();
	}
});