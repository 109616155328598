/**
 * Created by sergey on 23.11.2015.
 */
var Widget = require("./Widget");
var Helpers = require("../helpers/Helpers");
var DateTimePicker = Marionette.ItemView.extend({
	template: require("./templates/DateTimePicker.hbs"),
	events:{
		"change select": "onChange"
	},
	onRender: function(){
		var me = this,
			config = {
				monthElement: me.$el.find('.js-date-month'),
				dayElement: me.$el.find('.js-date-day'),
				yearElement: me.$el.find('.js-date-year'),
				generateOptions: true
			};
		if(!this.options["value"]){
			config.keepLabels = true
		}
		this.$el.dateSelectBoxes(config);
		if(this.options["value"]) {
			this.setValue();
		}
	},
	onChange: function(){
		var result = this.getValue();
		this.trigger("change", result);
	},
	getValue: function(){
		var result,
			day,
			month,
			year;
		result = this.$el.selectedValues();
		if(result[2] < 10){
			result[2] = "0"+result[2];
		}
		if(result[1] < 10){
			result[1] = "0"+result[1];
		}
		return result.join("-");
	},
	setValue: function() {
		var value = Helpers.getBornDate(this.options["value"]);
		this.$el.find('.js-date-day').selectOptions(value.day);
		this.$el.find('.js-date-month').selectOptions(value.month);
		this.$el.find('.js-date-year').selectOptions(value.year);
	}

});
module.exports = Widget.extend({
	show: function(container, options){
		var options = options || {};
		var dateTimePicker = new DateTimePicker(options);
		var me = this;
		this.listenTo(dateTimePicker, "change", function(data){
			me.trigger("change", data);
		});
		container.append(dateTimePicker.render().el);
	}
});
