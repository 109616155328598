var CpmTemplate = require("./templates/CpmLayout.hbs");


module.exports = Marionette.LayoutView.extend({
	ui:{
		changeSearchBtn: ".b-change-search",
		cancelSearchBtn: ".cearch-cancel",
		tableCpm: ".b-table",
		tabsItem: ".b-tabs-item"
	},
	events: {
		"click @ui.changeSearchBtn": "openChangeSearch",
		"click @ui.cancelSearchBtn": "closeChangeSearch",
		"click @ui.tabsItem": "changeContent"
	},
	className: "wrapper_n",
	template: CpmTemplate,
	regions: {
		toolbarRegion: ".js-cpm-toolbar",
		generalTabRegion: ".js-cpm-general-tab",
		adsSkipTabRegion: "	.js-cpm-adds-skip-tab",
		adsTabRegion: ".js-cpm-ads-tab"
	},
	onShow: function(e){
	},
	openChangeSearch: function(){
		this.ui.changeSearchBtn.fadeOut(200);
		this.$el.find('.b-settings-open').slideDown(300);
	},
	closeChangeSearch: function(){
		this.ui.changeSearchBtn.fadeIn(200);
		this.$el.find('.b-settings-open').slideUp(300);
	},
	showTabContent: function(id){
		var $el = this.$el;
		$el.find('.b-box').removeClass('visible');
		$el.find('.b-box-'+id+'').addClass('visible');
		$el.find('.b-tabs-item').removeClass('b-tabs-active');
		$el.find('.b-'+id+'-tab').addClass('b-tabs-active');
	},
	changeContent: function(e){
		var id = $(e.currentTarget).attr('data-tab');
		var $el = this.$el;
		$el.find('.b-box').removeClass('visible');
		$el.find('.b-box-'+id+'').addClass('visible');
		$el.find('.b-tabs-item').removeClass('b-tabs-active');
		$el.find('.b-'+id+'-tab').addClass('b-tabs-active');
		this.trigger("user:click:tab", id);
	}
});