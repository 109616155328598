var MarkupView = require("./view/Markup");

module.exports = Marionette.Object.extend({
	initialize: function(){
		this.setHandlers();
	},
	show: function(){
		var markup = new MarkupView();
		var mainRegion =  Radio.channel("layout").request("get:main:region");
		Backbone.history.navigate("#?page=markup");
		mainRegion.show(markup);
	},
	setHandlers: function(){
		var me = this;
		Radio.channel("application").on("application:state:markup", function() {
			me.show();
		});
	}

});
