// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"b-content-wrapper\">\n	<br>\n	<br>\n	<h1 class=\"b-page-title\">Profile login</h1>\n	<div class=\"b-page-block b-login-block js-login-block\">\n		<div class=\"b-error-border\"></div>\n		<form>\n			<p class=\"b-login-text f_light\">Log into dashboard with your Profile Password</p>\n			<label class=\"b-page-label\">Enter your password</label>\n			<input type=\"password\" class=\"b-input-login js-input-login\" name=\"dashboard-login-pass\" placeholder=\"\">\n			<div class=\"b-page-form-buttons\">\n				<button class=\"b-page-button b-button_yes js-dashboard-login-submit b-submit-button\">Login</button>\n			</div>\n			<a href=\"javascript:void(0);\" class=\"b-page-link b-login-link f_12\">Forgot password. Create new password immediately</a>\n			<div class=\"b-login-error-block js-login-error\"></div>\n		</form>\n	</div>\n	<div class=\"b-hide-block\">\n		<div class=\"b-page-block b-login-block js-question-block\">\n			<div class=\"b-close-btn\">\n				<span class=\"b-close-block\"></span>	\n			</div>\n			<div class=\"b-error-border\"></div>\n			<p class=\"b-login-text f_light\">Password recovery</p>\n			<form>\n				<br>\n				<label class=\"b-page-label\">Secret questtion</label>\n				<select class=\"b-page-input b-login-select recovery-input recovery-question\">\n					<option value=\"\">Select question</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.questions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n				<br>\n				<br>\n				<label class=\"b-page-label\">Enter your answer</label>\n				<input type=\"text\" class=\"b-input-login recovery-input recovery-answer\">\n				<div class=\"b-page-form-buttons\">\n					<button class=\"b-page-button b-button_yes js-dashboard-recovery-submit b-submit-button\">Submit</button>\n				</div>\n				<div class=\"b-login-error-block js-recovery-error\"></div>\n			</form>\n		</div>\n		<div class=\"b-page-block b-login-block js-password-block\">\n			<div class=\"b-close-btn\">\n				<span class=\"b-close-block dark\"></span>	\n			</div>\n			<div class=\"b-error-border\"></div>\n			<p class=\"b-login-text f_light\">Password recovery</p>\n			<form autocomplete=\"off\" class=\"js-dashboard-pass-recovery\">\n				<label class=\"b-page-label\">Enter your new password</label>\n				<input autocomplete=\"off\" type=\"password\" class=\"b-input-login js-new-pass recovery-password\" placeholder=\"\">\n				<br>	\n				<br>	\n				<label class=\"b-page-label\">Confirm your new password</label>\n				<input autocomplete=\"off\" type=\"password\" class=\"b-input-login js-confirm-new-pass recovery-repassword\" placeholder=\"\">\n				<div class=\"b-page-form-buttons\">\n					<button  type=\"submit\" class=\"b-page-button b-button_yes js-set-pass-submit b-submit-button\">OK</button>\n				</div>\n				<div class=\"b-login-error-block js-pass-error\"></div>\n			</form>\n		</div>\n	</div>\n</div>\n"
    + ((stack1 = container.invokePartial(partials.limiter,depth0,{"name":"limiter","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
